import { gql } from "@apollo/client";

export const GQL_PAGINATED_ORGANIZATIONS = gql`
   query paginatedOrganizations(
      $sorting: SortingInput
      $pagination: PaginationInput
      $filter: FilterOrganizationInput
   ) {
      paginatedOrganizations(
         sorting: $sorting
         pagination: $pagination
         filter: $filter
      ) {
         items {
            id
            name
            base_url
            created_at
         }
         pagination {
            page
            pages
            current_items
            total_items
            size
         }
      }
   }
`;

export const GQL_SINGLE_ORGANIZATION = gql`
   query organization($id: String!, $context: String!) {
      logos: assets(context: $context) {
         id
         file_name
         url
      }
      organization(id: $id) {
         id
         name
         address
         email
         pec
         phone_number
         base_url
         created_at
         provider {
            stripe {
               public_key
            }
            aws_sns {
               enabled
               sent
            }
         }
         logo {
            id
            url
         }
         settings {
            reservation {
               mode
               min_time
               min_admin_time
               max_time
               rounding
            }
         }
      }
   }
`;

export const GQL_CURRENT_ORGANIZATION = gql`
   query currentOrganization {
      currentOrganization {
         id
         name
      }
   }
`;
