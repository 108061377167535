import React from "react";
import { Button, message } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import IntlMessages from "../../../../util/IntlMessages";
import { useLazyQuery } from "@apollo/client";
import { GQL_EXPORT_PRODUCT_RESERVATIONS } from "../../../../apollo/query/reuse-board";
import { useIntl } from "react-intl";
import { has } from "lodash";
import { downloadBase64File } from "../../../../util/file";
import { formatDate } from "../../../../util/date";

function getFiltersDescription(filters) {
   let items = [];
   const { term, status, date_range, delivery_date } = filters;

   if (term) {
      items.push(term);
   }

   if (status) {
      items.push(status);
   }

   if (date_range) {
      const { min_date, max_date } = date_range;
      items.push(`${formatDate(min_date)}~${formatDate(max_date)}`);
   }

   if (delivery_date) {
      items.push(formatDate(delivery_date));
   }

   return items.length > 0 ? "(" + items.join("_") + ")" : "";
}

const ExportProductReservationsButton = ({ filters }) => {
   const intl = useIntl();
   const [exportReuseBoardProductReservations, { loading }] = useLazyQuery(
      GQL_EXPORT_PRODUCT_RESERVATIONS,
      {
         onCompleted: (data) => {
            if (data && has(data, "exportReuseBoardProductReservations.content")) {
               try {
                  downloadBase64File(
                     intl.formatMessage(
                        {
                           id: "reuse_board.reservations_export_filename",
                        },
                        {
                           filters: getFiltersDescription(filters),
                        }
                     ),
                     data.exportReuseBoardProductReservations.content
                  );
               } catch (e) {
                  message.error(
                     intl.formatMessage({ id: "common.cannot_download_file" })
                  );
               }
            }
         },
         onError: (error) => {
            message.error(error.message);
         },
      }
   );

   return (
      <Button
         type="default"
         loading={loading}
         onClick={() =>
            exportReuseBoardProductReservations({
               variables: {
                  filters,
               },
            })
         }
      >
         <FileExcelOutlined /> <IntlMessages id="common.export" />
      </Button>
   );
};

export default ExportProductReservationsButton;
