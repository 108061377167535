import React from "react";
import { useHistory } from "react-router";
import { Avatar, Popover, Divider, Skeleton } from "antd";
import IntlMessages from "../../util/IntlMessages";
import { Link } from "react-router-dom";
import { GQL_INIT_QUERY, GQL_QUERY_ME } from "../../apollo/query/user";
import { useMutation, useQuery } from "@apollo/client";
import { localStorageClass } from "../../apollo/local/storage";
import { getAdminPath, getFrontendPath } from "../../util/router";
import { getInitials } from "../../util/string";
import { hasAdminAccess } from "../../util/user";
import { LIGHT_BLUE, PRIMARY_COLOR } from "../../constants/ThemeSetting";
import { GQL_ADMIN_USER_LOGOUT } from "../../apollo/mutation/user";

const GetAdminOptions = ({ me, history }) => {
   const [logout, { loading: loadingMutation }] = useMutation(GQL_ADMIN_USER_LOGOUT);
   return (
      <ul className="gx-user-popover">
         <li>
            <Link className="gx-d-block" to={getAdminPath("settings")}>
               <IntlMessages id="sidebar.settings" />
            </Link>
         </li>
         <li>
            <span
               className="gx-d-block gx-text-danger"
               onClick={(e) => {
                  e.stopPropagation();

                  if (!loadingMutation) {
                     logout({
                        refetchQueries: [{ query: GQL_INIT_QUERY }],
                        awaitRefetchQueries: true,
                     })
                        .then((data) => {
                           localStorageClass.logout();
                           history.push(getAdminPath());
                        })
                        .catch((error) => console.log(error));
                  }
               }}
            >
               <IntlMessages id="sidebar.logout" />
            </span>
         </li>
      </ul>
   );
};

const getFrontendUserMenuOptions = (me, history) => (
   <ul className="gx-user-popover gx-frontend-user-popover">
      {hasAdminAccess(me) && [
         <li key="admin_1">
            <a className="gx-d-block" href={getAdminPath()}>
               <IntlMessages id="sidebar.admin" />
            </a>
         </li>,
         <li key="divider">
            <Divider />
         </li>,
      ]}
      <li>
         <Link className="gx-d-block" to={getFrontendPath("impostazioni")}>
            <IntlMessages id="sidebar.settings" />
         </Link>
      </li>
      <li>
         <Link className="gx-d-block" to={getFrontendPath("termini-condizioni-uso")}>
            <IntlMessages id="sidebar.privacy_policy" />
         </Link>
      </li>
      <li>
         <a
            href="/#"
            className="gx-d-block gx-text-danger"
            onClick={(e) => {
               e.stopPropagation();
               localStorageClass.logout();
               history.push(getFrontendPath());
            }}
         >
            <IntlMessages id="sidebar.logout" />
         </a>
      </li>
   </ul>
);

const UserProfile = ({ isAdminRoute }) => {
   const history = useHistory();
   //TODO riorganizzare query startup, no cache per evitare duplicazione chiamate
   const { data, loading, error } = useQuery(GQL_QUERY_ME, {
      fetchPolicy: "no-cache",
   });

   if (loading) return <Skeleton.Avatar />;

   if (error || !data) return <div></div>;

   const { me } = data;
   const initials = getInitials(`${me.first_name} ${me.last_name}`);

   return (
      <div>
         <Popover
            className="gx-d-block"
            placement="bottomRight"
            content={
               isAdminRoute ? (
                  <GetAdminOptions me={me} history={history} />
               ) : (
                  getFrontendUserMenuOptions(me, history)
               )
            }
            trigger="click"
         >
            <span className="gx-avatar-name gx-mr-1">
               <small className={isAdminRoute ? "" : "color-blue"}>
                  {me.full_name}
               </small>
            </span>

            <Avatar
               style={{
                  backgroundColor: isAdminRoute ? PRIMARY_COLOR : LIGHT_BLUE,
               }}
               className={`gx-size-40 gx-pointer gx-mr-1 ${
                  isAdminRoute ? "" : "frontend-label-avatar"
               }`}
               alt=""
            >
               {initials.toUpperCase()}
            </Avatar>

            <i className="icon icon-chevron-down gx-fs-xxs" />
         </Popover>
      </div>
   );
};

UserProfile.defaultProps = {
   isAdminRoute: true,
};

export default UserProfile;
