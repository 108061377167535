import "styles/styles-frontend.less";
import React, { memo, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import AdminApp from "./AdminApp";
import SignIn from "../SignIn";
import ForgetPassword from "../ForgetPassword";
import ResetPassword from "../ResetPassword";

import {
   LAYOUT_TYPE_BOXED,
   LAYOUT_TYPE_FRAMED,
   LAYOUT_TYPE_FULL,
   NAV_STYLE_ABOVE_HEADER,
   NAV_STYLE_BELOW_HEADER,
   NAV_STYLE_DARK_HORIZONTAL,
   NAV_STYLE_DEFAULT_HORIZONTAL,
   NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
   THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";

import { useReactiveVar } from "@apollo/client";
import { layoutSettingsVar } from "../../apollo/local/cache";
import { localStorageClass } from "../../apollo/local/storage";
import FrontendApp from "./FrontendApp";
import { getAdminPath, getFrontendPath } from "../../util/router";
import NotFound from "../NotFound";

import FrontendLogin from "../../routes/frontend/login";
import FrontendRegistration from "../../routes/frontend/registration";
import FrontendForgetPassword from "../../routes/frontend/forget-password";
import FrontendResetPassword from "../../routes/frontend/reset-password";
import VerifyUser from "../VerifyUser";
import ReservationDelete from "../ReservationDelete";
import ReservationFeedback from "../ReservationFeedback";
import CircularProgress from "../../components/CircularProgress";
import useCurrentOrganization from "../../hooks/useCurrentOrganization";
import { isDevelopment } from "../../util/config";
import { FRONTEND_ROUTE_PREFIX } from "../../constants/App";
import FrontendPrivacyPolicySettings from "../../routes/frontend/privacy-policy";

// const PublicRoute = ({ component: Component }) => (
//    <Route
//       render={(props) =>
//          localStorageClass.isLogged() ? (
//             <Redirect
//                to={{
//                   pathname: getAdminPath("dashboard"),
//                }}
//             />
//          ) : (
//             <Component {...props} />
//          )
//       }
//    />
// );

const RestrictedAdminRoute = ({
   component: Component,
   location,
   localStorageClass,
   ...rest
}) => {
   return (
      <Route
         {...rest}
         render={(props) =>
            localStorageClass.isLogged() ? (
               <Component {...props} />
            ) : (
               <Redirect
                  to={{
                     pathname: getAdminPath("signin"),
                     search: `?redirect=${location.pathname}`,
                  }}
               />
            )
         }
      />
   );
};

const App = ({ ability }) => {
   let { locale, themeType, navStyle, layoutType, themeColor } =
      useReactiveVar(layoutSettingsVar);

   const location = useLocation();

   const setLayoutType = (layoutType) => {
      if (layoutType === LAYOUT_TYPE_FULL) {
         document.body.classList.remove("boxed-layout");
         document.body.classList.remove("framed-layout");
         document.body.classList.add("full-layout");
      } else if (layoutType === LAYOUT_TYPE_BOXED) {
         document.body.classList.remove("full-layout");
         document.body.classList.remove("framed-layout");
         document.body.classList.add("boxed-layout");
      } else if (layoutType === LAYOUT_TYPE_FRAMED) {
         document.body.classList.remove("boxed-layout");
         document.body.classList.remove("full-layout");
         document.body.classList.add("framed-layout");
      }
   };

   const setNavStyle = (navStyle) => {
      if (
         navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
         navStyle === NAV_STYLE_DARK_HORIZONTAL ||
         navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
         navStyle === NAV_STYLE_ABOVE_HEADER ||
         navStyle === NAV_STYLE_BELOW_HEADER
      ) {
         document.body.classList.add("full-scroll");
         document.body.classList.add("horizontal-layout");
      } else {
         document.body.classList.remove("full-scroll");
         document.body.classList.remove("horizontal-layout");
      }
   };

   useEffect(() => {
      // duplica importazione dei css può essere utile per frontend
      // let link = document.createElement("link");
      // link.type = "text/css";
      // link.rel = "stylesheet";
      // link.href = `/css/${themeColor}.css`; //This line is changed, this comment is for explaination purpose.
      // link.className = "gx-style";
      // document.body.appendChild(link);
   }, [themeColor]);

   useEffect(() => {
      setLayoutType(layoutType);
      setNavStyle(navStyle);
   });

   useEffect(() => {
      if (themeType === THEME_TYPE_DARK) {
         document.body.classList.add("dark-theme");
         document.body.classList.add("dark-theme");
         let link = document.createElement("link");
         link.type = "text/css";
         link.rel = "stylesheet";
         link.href = "/css/dark_theme.css";
         link.className = "style_dark_theme";
         document.body.appendChild(link);
      }
   }, [themeType]);

   //fetch frontend organization
   //se non presente è l'homepage principale e vede admin
   //altrimenti è frontend dell'organizzazione
   //usare anche per stile pagina
   const { organization, loading } = useCurrentOrganization();

   if (loading) return <CircularProgress />;

   const currentAppLocale = AppLocale[locale.locale];

   return (
      <div className={`gx-app-wrapper gx-env-${process.env.REACT_APP_NODE_ENV}`}>
         <ConfigProvider locale={currentAppLocale.antd}>
            <IntlProvider
               locale={currentAppLocale.locale}
               messages={currentAppLocale.messages}
            >
               <Switch>
                  {organization.isMainDomain() && [
                     <Route
                        key="signin"
                        exact
                        path={getAdminPath("signin")}
                        component={SignIn}
                     />,

                     <Route
                        key="forget-password"
                        exact
                        path={getAdminPath("forget-password")}
                        component={ForgetPassword}
                     />,
                     <Route
                        key="reset-password"
                        exact
                        path={getAdminPath("reset-password/:tokenId")}
                        component={ResetPassword}
                     />,
                     <Route
                        key="verify"
                        exact
                        path={[
                           "/verify/:tokenId",
                           "/verify/:tokenId/:redirectToAdmin",
                        ]}
                        component={VerifyUser}
                     />,

                     <RestrictedAdminRoute
                        key="restricted-routes"
                        path={getAdminPath()}
                        localStorageClass={localStorageClass}
                        location={location}
                        ability={ability}
                        component={AdminApp}
                     />,

                     !isDevelopment ? (
                        <Redirect
                           key="sign-in-redirect"
                           to={getAdminPath("signin")}
                        />
                     ) : null,
                  ]}
               </Switch>

               {/* Frontend Routes */}
               {organization.isOrganizationDomain() && (
                  <div className="gx-frontend-app">
                     <Switch>
                        <Route
                           key="login"
                           exact
                           path={["/", getFrontendPath(), getFrontendPath("login")]}
                           component={FrontendLogin}
                        />

                        <Route
                           key="registrati"
                           exact
                           path={getFrontendPath("registrati")}
                           component={FrontendRegistration}
                        />
                        <Route
                           key="recupero-password"
                           exact
                           path={getFrontendPath("recupero-password")}
                           component={FrontendForgetPassword}
                        />
                        <Route
                           key="reimposta-password"
                           exact
                           path={getFrontendPath("reimposta-password/:tokenId")}
                           component={FrontendResetPassword}
                        />
                        <Route
                           key="verify"
                           exact
                           path={[
                              getFrontendPath("verify/:tokenId"),
                              getFrontendPath("verify/:tokenId/:redirectToAdmin"),
                           ]}
                           component={VerifyUser}
                        />
                        <Route
                           key="cancella-prenotazione"
                           exact
                           path={getFrontendPath(
                              "delete-reservation/:reservationId/:reservationToken"
                           )}
                           component={ReservationDelete}
                        />
                        <Route
                           key="feedback-prenotazione"
                           exact
                           path={getFrontendPath("prenotazione/feedback/:id/:token")}
                           component={ReservationFeedback}
                        />

                        <Route
                           exact
                           path={getFrontendPath("termini-condizioni-uso")}
                           component={FrontendPrivacyPolicySettings}
                        />

                        <Route
                           key={FRONTEND_ROUTE_PREFIX}
                           path={getFrontendPath()}
                           component={FrontendApp}
                        />
                        {!isDevelopment && (
                           <Route key="wildcard" path="*">
                              <NotFound redirectToURL={getFrontendPath()} />
                           </Route>
                        )}
                     </Switch>
                  </div>
               )}
            </IntlProvider>
         </ConfigProvider>
      </div>
   );
};

export default memo(App);
