import { gql } from "@apollo/client";

export const GQL_PAGINATED_CUSTOMERS = gql`
   query paginatedCustomers(
      $sorting: SortingInput
      $pagination: PaginationInput
      $filter: FilterCustomerInput
   ) {
      paginatedCustomers(
         sorting: $sorting
         pagination: $pagination
         filter: $filter
      ) {
         items {
            id
            company_name
            first_name
            last_name
            email
            type
            created_at
         }
         pagination {
            page
            pages
            current_items
            total_items
            size
         }
      }
   }
`;

export const GQL_CUSTOMER_PERSON_INTERFACE = gql`
   query customer($id: String!) {
      customer(id: $id) {
         id
         first_name
         last_name
      }
   }
`;

export const GQL_UPDATE_CUSTOMER_QUERY = gql`
   query customer($id: String!) {
      customer(id: $id) {
         id
         company_name
         first_name
         last_name
         email
         phone_number
         fiscal_code
         type
         user_id
         user {
            id
            first_name
            last_name
            username
         }
         code
         created_at
         deleted_at
      }
   }
`;

export const GQL_VIEW_CUSTOMER = gql`
   query paginatedCustomerReservations($id: String!, $pagination: PaginationInput) {
      customer(id: $id) {
         id
         company_name
         first_name
         last_name
         email
         phone_number
         fiscal_code
         type
         user_id
         user {
            id
            first_name
            last_name
            username
         }
         code
         created_at
         deleted_at
      }
      paginatedCustomerReservations(
         filter: { customer_id: $id }
         pagination: $pagination
      ) {
         items {
            id
            status
            code
            time {
               date
               time_start
               time_end
               duration
            }
            created_at
            services {
               id
               name
            }
            workspace {
               id
               name
            }
            note
         }
         pagination {
            page
            pages
            current_items
            total_items
            size
         }
      }
   }
`;

export const GQL_SEARCH_CUSTOMERS = gql`
   query customers(
      $sorting: SortingInput
      $filter: FilterCustomerInput
      $limit: Int
   ) {
      customers(sorting: $sorting, filter: $filter, limit: $limit) {
         id
         first_name
         last_name
         full_name
         company_name
         fiscal_code
         phone_number
      }
   }
`;

export const GQL_LOCAL_CUSTOMER = gql`
   query customerMe {
      me @client {
         id
         customer @client {
            id
            first_name
            last_name
         }
      }
   }
`;

export const GQL_TOP_BAR_SEARCH = gql`
   query globalSearch($term: String!) {
      globalSearch(term: $term) {
         id
         type
         title
         description
         customer_id
      }
   }
`;
