import React, { useState } from "react";
import {
   Card,
   Button,
   Form,
   Row,
   Col,
   Radio,
   Space,
   Select,
   Typography,
} from "antd";
import { get } from "lodash";
import { formStyles } from "./common";
import { WorkspaceCollection } from "../../../../entities/workspace";
import { RESERVATION } from "../../../../constants/App";
import IntlMessages from "../../../../util/IntlMessages";
import { selectFilterableProps } from "../../../../util/antd";

const RadioInput = ({ workspaceCollection, ...rest }) => {
   return (
      <Radio.Group {...rest}>
         <Space direction="vertical">
            {workspaceCollection.getItems().map((item) => (
               <Radio
                  key={`workspace${item.id}`}
                  value={item.id}
                  className="gx-frontend-radio"
               >
                  {item.name} {item.getFormattedAddress()}
               </Radio>
            ))}
         </Space>
      </Radio.Group>
   );
};

const SelectInput = ({ workspaceCollection, ...rest }) => {
   return (
      <Select
         className="gx-frontend-select"
         style={{ maxWidth: "500px", width: "100%" }}
         placeholder={<IntlMessages id="frontend.seleziona_comune_ubicazione" />}
         {...selectFilterableProps()}
         {...rest}
      >
         {workspaceCollection.getItems().map((item) => (
            <Select.Option key={`workspace${item.id}`} value={item.id}>
               {item.name}
            </Select.Option>
         ))}
      </Select>
   );
};

export const FrontendReservationWorkspaces = ({
   workspaces,
   intl,
   handleOk,
   handleError,
   formContent,
}) => {
   const workspaceCollection = WorkspaceCollection(workspaces);

   const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(
      get(formContent, "workspace_id", null)
   );

   const selectedWorkspace = workspaceCollection.find(selectedWorkspaceId);

   return (
      <Form
         onFinish={handleOk}
         onFinishFailed={handleError}
         {...formStyles}
         className="gx-mt-3"
         initialValues={{
            workspace_id: get(formContent, "workspace_id", null),
         }}
         onValuesChange={(changedValue) => {
            if (changedValue.workspace_id) {
               setSelectedWorkspaceId(changedValue.workspace_id);
            }
         }}
      >
         <Card
            className="card-step"
            actions={[
               <div className="gx-text-left">
                  <Button
                     key="1"
                     htmlType="submit"
                     className="btn-lightblue uppercase border-0 gx-mb-0"
                  >
                     {intl.formatMessage({ id: "common.forward" })}
                  </Button>
               </div>,
            ]}
         >
            <Row>
               <Col xs={24} md={12}>
                  <Form.Item
                     name="workspace_id"
                     required
                     rules={[
                        {
                           required: true,
                           message: intl.formatMessage({
                              id: "customers.new_customer_required_field",
                           }),
                        },
                     ]}
                  >
                     {workspaceCollection.countItems() >
                     RESERVATION.WORKSPACE_SELECT_THRESHOLD ? (
                        <SelectInput workspaceCollection={workspaceCollection} />
                     ) : (
                        <RadioInput workspaceCollection={workspaceCollection} />
                     )}
                  </Form.Item>
               </Col>
               <Col xs={24} md={12}>
                  <Typography.Title level={5}>
                     <IntlMessages id="common.selected" />
                  </Typography.Title>

                  {selectedWorkspace.isNotNull() ? (
                     <span>
                        {selectedWorkspace.name}{" "}
                        {selectedWorkspace.getFormattedAddress()}
                     </span>
                  ) : (
                     <IntlMessages id="common.none" />
                  )}
               </Col>
            </Row>
         </Card>
      </Form>
   );
};
