import React, { useContext, useState } from "react";
import {
   PageHeader,
   Row,
   Col,
   Breadcrumb,
   Card,
   Table,
   message,
   Space,
   Tooltip,
} from "antd";
import { ReuseBoardBreadcrumbs } from "../products/common";
import IntlMessages from "../../../../util/IntlMessages";
import { AbilityContext, Can } from "../../../../acl";
import { get } from "lodash";
import { useDebouncedCallback } from "use-debounce";
import { GQL_PAGINATED_PRODUCT_RESERVATIONS } from "../../../../apollo/query/reuse-board";
import { usePersistedState } from "../../../../hooks/usePersistedState";
import { useQuery } from "@apollo/client";
import { paginationOptions } from "../../../../constants/PaginationOptions";
import { debounceSearchFilterDelay } from "../../../../util/config";
import { formatDateTime } from "../../../../util/date";
import { Link, useHistory } from "react-router-dom";
import { getAdminPath } from "../../../../util/router";
import ReservationProductAvatar from "../../../../components/ReservationProductAvatar";
import { ProductReservationStatusTag } from "./common";
import ReservationsFilters from "./filters";
import { injectIntl } from "react-intl";
import { ProductReservationStatuses } from "../../../../constants/Enums";
import PopoverProductReservation from "./popover";
import DeliveryButton from "./delivery/button";
import DeliverySchedulerTimeAlert from "./delivery/alert";
import DeliveryScheduler from "./delivery/scheduler/index";
import ExportProductReservationsButton from "./export-button";
import { REUSEBOARD_RESERVATION_EXPIRING_DAYS } from "../../../../constants/App";
import moment from "moment";
import { AlertOutlined } from "@ant-design/icons";

const BadgeExpiringDate = ({ show, message, ...rest }) => {
   if (!show) return null;

   return (
      <Tooltip title={message}>
         <AlertOutlined {...rest} />
      </Tooltip>
   );
};

function reservationIsDeliverable(reservation) {
   return [
      ProductReservationStatuses.CONFIRMED,
      ProductReservationStatuses.READY_FOR_DELIVERY,
   ].includes(reservation.status.toLowerCase());
}

const Reservations = ({ intl, match }) => {
   const history = useHistory();
   const ability = useContext(AbilityContext);
   const [selectedRows, setSelectedRows] = useState([]);
   const [showScheduler, setShowScheduler] = useState(false);

   const [tableSearchOptions, setTableSearchOptions] = usePersistedState(
      match,
      "tableFilterReuseBoardReservations",
      {
         pagination: {
            limit: parseInt(paginationOptions.pageSizeOptions[0]),
            page: 1,
         },
         filter: {
            term: "",
         },
         sorting: {
            key: "created_at",
            direction: 1,
         },
      }
   );

   const setFilter = (filter) => {
      setTableSearchOptions({
         ...tableSearchOptions,
         filter,
         pagination: {
            limit: tableSearchOptions.pagination.limit,
            page: 1,
         },
      });
   };

   const fetchTableChange = (pagination, _, sorter) => {
      setTableSearchOptions({
         ...tableSearchOptions,
         pagination: {
            limit: pagination.pageSize,
            page: pagination.current,
         },
         sorting: {
            key: sorter.field ?? "name",
            direction: sorter.order === "descend" ? -1 : 1,
         },
      });
   };

   const { data, loading, refetch } = useQuery(GQL_PAGINATED_PRODUCT_RESERVATIONS, {
      variables: tableSearchOptions,
      fetchPolicy: "no-cache",
      onError: (error) => {
         message.error(intl.formatMessage({ id: "common.default_query_error" }));
      },
   });

   const debouncedFilterCallback = useDebouncedCallback(
      (value) => setFilter(value),
      debounceSearchFilterDelay
   );

   const columns = [
      {
         title: intl.formatMessage({ id: "common.code" }),
         dataIndex: "code",
         sorter: true,
      },
      {
         title: intl.formatMessage({ id: "products.product" }),
         dataIndex: ["product", "name"],
         key: "product",
         sorter: false,
         render(text, record) {
            const { product } = record;

            if (!product) return null;

            return (
               <Link
                  to={getAdminPath(
                     `reuse-board/products/update/${record.product.id}`
                  )}
               >
                  <Space>
                     <ReservationProductAvatar product={record.product} /> {text}
                  </Space>
               </Link>
            );
         },
      },
      {
         title: intl.formatMessage({ id: "common.category" }),
         dataIndex: ["product", "category", "path_formatted"],
         responsive: ["xxl", "xl"],
      },
      {
         title: intl.formatMessage({ id: "customers.customer" }),
         dataIndex: ["customer", "full_name"],
         key: "customer",
         render(text, record) {
            return (
               <>
                  {text}
                  <br />
                  <small>{record.customer?.fiscal_code}</small>
               </>
            );
         },
      },
      // {
      //    title: intl.formatMessage({
      //       id: "customers.new_customer_fiscal_code",
      //    }),
      //    dataIndex: ["customer", "fiscal_code"],
      // },
      {
         title: intl.formatMessage({ id: "common.status" }),
         dataIndex: "status",
         key: "status",
         width: 120,
         sorter: true,
         render: (text, record) => {
            let deliveryDate = null;

            if (
               record.status.toLowerCase() ===
                  ProductReservationStatuses.READY_FOR_DELIVERY &&
               record.ready_for_delivery_at
            ) {
               const dayDiff = moment().diff(
                  moment(record.ready_for_delivery_at),
                  "days"
               );

               deliveryDate = (
                  <>
                     <small>{formatDateTime(record.ready_for_delivery_at)}</small>
                     <BadgeExpiringDate
                        show={dayDiff >= REUSEBOARD_RESERVATION_EXPIRING_DAYS}
                        message={intl.formatMessage({
                           id: "reuse_board.reservation_expired",
                        })}
                        className="gx-ml-1 gx-text-danger"
                     />
                  </>
               );
            } else if (
               record.status.toLowerCase() ===
                  ProductReservationStatuses.DELIVERED &&
               record.delivered_at
            ) {
               deliveryDate = <small>{formatDateTime(record.delivered_at)}</small>;
            }
            return (
               <>
                  <ProductReservationStatusTag status={text} />
                  {deliveryDate}
               </>
            );
         },
      },
      {
         title: intl.formatMessage({ id: "common.created_at" }),
         dataIndex: "created_at",
         width: 180,
         sorter: true,
         sortDirections: ["ascend", "descend", "ascend"],
         defaultSortOrder: "descend",
         render: (text) => formatDateTime(text),
      },
      {
         title: "",
         key: "operation",
         sorter: false,
         fixed: "right",
         align: "center",
         render: (_, record) => {
            return (
               <PopoverProductReservation
                  intl={intl}
                  record={record}
                  ability={ability}
                  history={history}
                  tableSearchOptions={tableSearchOptions}
               />
            );
         },
         width: 60,
      },
   ];

   const pagination = {
      ...paginationOptions,
      total: get(data, "paginatedProductReservations.pagination.total_items", 0),
      current: tableSearchOptions.pagination.page,
      pageSize: tableSearchOptions.pagination.limit,
   };

   // rowSelection object indicates the need for row selection
   const rowSelection = {
      onChange: (_, selectedRows) => setSelectedRows(selectedRows),
      getCheckboxProps: (record) => ({
         disabled: !reservationIsDeliverable(record),
         name: record.code,
      }),
   };

   return (
      <>
         <Row>
            <Col md={24}>
               <ReuseBoardBreadcrumbs>
                  <Breadcrumb.Item href="#">
                     <IntlMessages id="sidebar.products_reservations" />
                  </Breadcrumb.Item>
               </ReuseBoardBreadcrumbs>
            </Col>
         </Row>
         <Row>
            <Col xs={24}>
               <PageHeader
                  title={<IntlMessages id="sidebar.products_reservations" />}
                  extra={[
                     <Can I="read" a="ProductReservation" key="1">
                        <ExportProductReservationsButton
                           filters={tableSearchOptions.filter}
                        />
                     </Can>,
                     <DeliveryButton
                        className="gx-bg-success gx-text-white"
                        key="delivered"
                        selectedReservations={selectedRows}
                        onClick={() => setShowScheduler(true)}
                     />,
                  ]}
               />
            </Col>

            <Col xs={24}>
               <DeliverySchedulerTimeAlert reservations={selectedRows} />
            </Col>

            <Col xs={24}>
               <Card className="gx-card">
                  <ReservationsFilters
                     debouncedFilterCallback={debouncedFilterCallback}
                     tableSearchOptions={tableSearchOptions}
                     setFilterCallback={(filters) => setFilter(filters)}
                     resetFilters={() => {
                        setTableSearchOptions({
                           ...tableSearchOptions,
                           filter: {
                              term: "",
                           },
                        });
                     }}
                  />
                  <Row>
                     <Col md={24} sm={24} xs={24}>
                        <Table
                           columns={columns}
                           rowKey={(record) => record.id}
                           rowSelection={{
                              type: "checkbox",
                              ...rowSelection,
                              selectedRowKeys: selectedRows.map((s) => s.id),
                           }}
                           dataSource={get(
                              data,
                              "paginatedProductReservations.items",
                              []
                           )}
                           pagination={pagination}
                           loading={loading}
                           size="middle"
                           onRow={(record, rowIndex) => {
                              return {
                                 onDoubleClick: () => {
                                    history.push(
                                       `${getAdminPath(
                                          "reuse-board/reservations/update"
                                       )}/${record.id}`
                                    );
                                 },
                              };
                           }}
                           onChange={fetchTableChange}
                        />
                     </Col>
                  </Row>
               </Card>
            </Col>
         </Row>
         <DeliveryScheduler
            reservations={selectedRows}
            visible={showScheduler}
            onClose={() => setShowScheduler(false)}
            onSuccess={() => {
               setShowScheduler(false);
               setSelectedRows([]);
               refetch && refetch();
            }}
         />
      </>
   );
};

export default injectIntl(Reservations);
