import React, { useState } from "react";
import { Button, Card, Descriptions, message, Result } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";

import { useMutation, useQuery } from "@apollo/client";
import { injectIntl } from "react-intl";
import { getFrontendPath } from "../util/router";
import { GQL_RESERVATION_DETAIL_FRONTEND } from "../apollo/query/reservation";
import { GQL_RESERVATION_DELETE_CONFIRM_FRONTEND } from "../apollo/mutation/reservation";
import { get } from "lodash";
import { DATE_FORMAT } from "../constants/App";
import moment from "moment";
import { ServiceCollection } from "../entities/service";
import { WorkspaceEntity } from "../entities/workspace";
import FrontendFooter from "../routes/frontend/main/footer";
import FrontendHeader from "../routes/frontend/main/header";

const ReservationDeleteForm = ({ intl, reservation }) => {
   const history = useHistory();

   const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);

   const [deleteReservation, { loading: loadingMutation }] = useMutation(
      GQL_RESERVATION_DELETE_CONFIRM_FRONTEND
   );

   const dateFormatted = moment(reservation.time.date, "YYYY-MM-DD").format(
      DATE_FORMAT
   );
   const serviceCollection = ServiceCollection(reservation.services);

   const workspaceEntity = WorkspaceEntity(reservation.workspace);

   return (
      <>
         <FrontendHeader
            title={<IntlMessages id="reservations.reservation_delete" />}
         />
         <div className="frontend-container">
            <Result
               status="warning"
               title={
                  <h2>
                     <IntlMessages id="reservations.reservation_delete_result_confirm_message" />
                  </h2>
               }
               subTitle={
                  <div>
                     <Card
                        className="gx-mt-4 gx-mb-4"
                        style={{ maxWidth: 600, margin: "0 auto" }}
                     >
                        <Descriptions size="small" column={1} bordered={true}>
                           <Descriptions.Item
                              label={
                                 <h4 className="gx-mb-0">
                                    <IntlMessages id="common.datetime" />
                                 </h4>
                              }
                           >
                              <p className="gx-mb-0 gx-text-left">
                                 <strong>{dateFormatted}</strong>{" "}
                                 <IntlMessages id="common.at_hour" />
                                 &nbsp;
                                 <strong>{reservation.time.time_start}</strong>
                              </p>
                           </Descriptions.Item>
                           <Descriptions.Item
                              label={
                                 <h4 className="gx-mb-0">
                                    <IntlMessages id="services.services" />
                                 </h4>
                              }
                           >
                              <p className="gx-mb-0 gx-text-left">
                                 {serviceCollection.getNames()}
                              </p>
                           </Descriptions.Item>
                           <Descriptions.Item
                              label={
                                 <h4 className="gx-mb-0">
                                    <IntlMessages id="common.sede" />
                                 </h4>
                              }
                           >
                              <p className="gx-mb-0 gx-text-left">
                                 {workspaceEntity.getFormattedAddress()}
                              </p>
                           </Descriptions.Item>
                        </Descriptions>
                     </Card>
                  </div>
               }
               extra={[
                  <div>
                     <Button
                        type="danger"
                        key="console"
                        disabled={deleteButtonDisabled}
                        loading={loadingMutation}
                        onClick={(e) => {
                           e.stopPropagation();
                           setDeleteButtonDisabled(true);
                           deleteReservation({
                              variables: {
                                 args: {
                                    id: reservation.id,
                                    token: reservation.token_delete,
                                 },
                              },
                           })
                              .then(({ data }) => {
                                 if (
                                    data &&
                                    data.reservationFrontendToDeleteConfirm
                                 ) {
                                    message.success(
                                       intl.formatMessage({
                                          id: "reservations.reservation_delete_confirm",
                                       }),
                                       6,
                                       () => {
                                          setDeleteButtonDisabled(false);
                                          history.push(
                                             getFrontendPath("prenotazioni-elenco")
                                          );
                                       }
                                    );
                                 } else {
                                    setDeleteButtonDisabled(false);
                                    message.error(
                                       intl.formatMessage({
                                          id: "reservations.reservation_delete_error",
                                       })
                                    );
                                 }
                              })
                              .catch((e) => {
                                 setDeleteButtonDisabled(false);
                                 message.error(
                                    intl.formatMessage({
                                       id: "reservations.reservation_delete_error",
                                    })
                                 );
                              });
                        }}
                        style={{ height: 60 }}
                     >
                        <big>
                           <IntlMessages id="reservations.cancel_reservation" />
                        </big>
                     </Button>
                  </div>,
                  <Link to={getFrontendPath()} key="buy">
                     <Button className="btn-lightblue">
                        <IntlMessages id="404.home" />
                     </Button>
                  </Link>,
               ]}
            ></Result>
         </div>
         <FrontendFooter />
      </>
   );
};

const ReservationDelete = ({ intl }) => {
   const history = useHistory();
   const { reservationId, reservationToken } = useParams();
   const { loading, data, error } = useQuery(GQL_RESERVATION_DETAIL_FRONTEND, {
      variables: {
         args: {
            id: reservationId,
            token: reservationToken,
         },
      },
   });

   if (loading) {
      return <CircularProgress />;
   }

   const reservationToDelete = get(data, "reservationDetailFrontend");

   if (error || !reservationToDelete) {
      history.push(getFrontendPath());
      return null;
   }

   return <ReservationDeleteForm intl={intl} reservation={reservationToDelete} />;
};

export default injectIntl(ReservationDelete);
