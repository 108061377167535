import { loadStripe } from "@stripe/stripe-js/pure";
import { has } from "lodash";
import { useEffect } from "react";

loadStripe.setLoadParameters({ advancedFraudSignals: false });

export let stripePromise = null;

export function useStripeOrganizationLoader(data) {
   useEffect(() => {
      if (
         data &&
         data.me &&
         has(data.me, "current_organization.provider.stripe.public_key") &&
         !stripePromise
      ) {
         const publicKey = data.me.current_organization.provider.stripe.public_key;

         stripePromise = loadStripe(publicKey);
      }
   }, [data]);

   return stripePromise;
}
