import React, { useState, useEffect } from "react";
import { Card, Button, Form, Input, Alert, Skeleton, Tabs } from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import { useMutation } from "@apollo/client";
import { find, includes } from "lodash";
import { Elements, PaymentElement, ElementsConsumer } from "@stripe/react-stripe-js";
import { stripePromise } from "../../../../hooks/useStripeLoader";
import CircularProgress from "../../../../components/CircularProgress/index";
import { GQL_PROCESSING_RESERVATION } from "../../../../apollo/mutation/reservation";
import { defaultCatchException } from "../../../../apollo/callbacks";
import { localStorageClass } from "../../../../apollo/local/storage";
import ReservationSummaryCard from "../../../../components/Checkout/ReservationSummaryCard";
import { useHistory } from "react-router-dom";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../../../constants/ThemeSetting";
import { getFrontendPath } from "../../../../util/router";
import { PAYMENTS } from "../../../../constants/App";
import { clearErrors } from "../../../../apollo/local/error";

const Summary = ({
   formContent,
   handlePreviousStep,
   customer,
   serviceCollection,
   workspace,
   intl,
}) => {
   const [processingReservation, { loading: loadingMutation }] = useMutation(
      GQL_PROCESSING_RESERVATION
   );

   const history = useHistory();

   const onSubmit = (e) => {
      e.preventDefault();

      //arrivati a questo punto dovremmo sempre avere il reservation id settatto in localstorage
      //impostato dalla callback di processReservation se nuova
      //pre-impostato se ripresa da una esistente
      const currentReservation = localStorageClass.getReservation();

      if (!currentReservation || !currentReservation.id) {
         return null;
      }

      const note = e.currentTarget.note.value;

      processingReservation({
         variables: {
            reservation: { reservation_id: currentReservation.id, note: note },
         },
      })
         .then(({ data, errors }) => {
            if (errors) {
               defaultCatchException(errors, intl);
               return;
            }

            history.replace(getFrontendPath("prenotazione/conferma"));
         })
         .catch((err) => {
            defaultCatchException(err, intl);
         });
   };

   return (
      <form className="ant-form" onSubmit={onSubmit}>
         <ReservationSummaryCard
            className="gx-mt-3"
            availability={formContent.availability}
            workspace={workspace}
            serviceCollection={serviceCollection}
            customer={customer}
            note={formContent.note}
            intl={intl}
         />
         <Card
            className="card-step"
            title={
               <span className="color-blue font-bold uppercase">
                  <IntlMessages id="common.note" />
               </span>
            }
         >
            <Form.Item>
               <Input.TextArea
                  name="note"
                  showCount
                  maxLength={255}
                  autoSize={{ minRows: 3, maxRows: 5 }}
               />
            </Form.Item>

            <div className="gx-float-right gx-mt-4 gx-w-100 gx-mb-0">
               <Button
                  key="1"
                  onClick={handlePreviousStep}
                  className="uppercase border-0 gx-mb-0"
               >
                  <IntlMessages id="common.back" />
               </Button>
               <Button
                  disabled={loadingMutation}
                  loading={loadingMutation}
                  key="2"
                  htmlType="submit"
                  className="btn-lightblue uppercase border-0 gx-mb-0"
               >
                  <IntlMessages id="common.done" />
               </Button>
            </div>
         </Card>
      </form>
   );
};

const StripeCheckoutElements = ({ formContent }) => {
   const [isReady, setIsReady] = useState(false);

   if (!formContent.payment_intent) {
      return (
         <Alert
            showIcon={true}
            type="error"
            message={<IntlMessages id="payments.intent_error" />}
         />
      );
   }

   return (
      <div id="payment-form">
         <PaymentElement
            id="payment-element"
            onReady={(ready) => {
               setIsReady(true);
            }}
         />
         {!isReady && <Skeleton active={true} />}
      </div>
   );
};

const SummaryWithPayment = ({
   formContent,
   handlePreviousStep,
   customer,
   serviceCollection,
   workspace,
   intl,
}) => {
   const [loadingPayment, setLoadingPayment] = useState(false);
   const [paymentMessage, setPaymentMessage] = useState(null);
   const [paymentMethod, setPaymentMethod] = useState(PAYMENTS.STRIPE);
   const history = useHistory();
   const [processingReservation, { loading: loadingMutation }] = useMutation(
      GQL_PROCESSING_RESERVATION
   );

   const processPayment = async (stripe, elements) => {
      setLoadingPayment(true);

      const { error: paymentError } = await stripe.confirmPayment({
         elements,
         confirmParams: {
            return_url: `${window.location.origin}${getFrontendPath(
               "prenotazione/conferma"
            )}`,
         },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      console.log("Stripe payment error", paymentError);
      if (
         includes(
            ["payment_intent_authentication_failure", "card_error"],
            paymentError.type
         )
      ) {
         setPaymentMessage(paymentError.message);
      } else if (includes(["validation_error"], paymentError.type)) {
         //skip UI errors
      } else {
         setPaymentMessage(
            intl.formatMessage({ id: "reservations.generic_payment_error" })
         );
      }

      setLoadingPayment(false);
   };

   const onSubmit = (e, stripe, elements) => {
      e.preventDefault();

      //arrivati a questo punto dovremmo sempre avere il reservation id settatto in localstorage
      //impostato dalla callback di processReservation se nuova
      //pre-impostato se ripresa da una esistente
      const currentReservation = localStorageClass.getReservation();

      if (!currentReservation || !currentReservation.id) {
         return null;
      }

      const note = e.currentTarget.note.value;

      processingReservation({
         variables: {
            reservation: {
               reservation_id: currentReservation.id,
               payment: paymentMethod,
               note: note,
            },
         },
      })
         .then(({ data, errors }) => {
            if (errors) {
               defaultCatchException(errors, intl);
               return;
            }

            if (paymentMethod === PAYMENTS.STRIPE) {
               processPayment(stripe, elements);
            } else {
               history.replace(getFrontendPath("prenotazione/conferma"));
            }
         })
         .catch((err) => {
            defaultCatchException(err, intl);
         });
   };

   return (
      <Elements
         options={{
            locale: "it-IT",
            appearance: {
               theme: "stripe",
               variables: {
                  colorPrimary: PRIMARY_COLOR,
                  colorTextSecondary: SECONDARY_COLOR,
               },
            },
            clientSecret: formContent.payment_intent,
         }}
         stripe={stripePromise}
      >
         <ElementsConsumer>
            {({ stripe, elements }) => {
               return (
                  <form
                     className="ant-form"
                     onSubmit={(e) => onSubmit(e, stripe, elements)}
                  >
                     <ReservationSummaryCard
                        className="gx-mt-3"
                        availability={formContent.availability}
                        workspace={workspace}
                        serviceCollection={serviceCollection}
                        customer={customer}
                        note={formContent.note}
                        intl={intl}
                     />

                     <Card
                        className="card-step"
                        title={
                           <span className="color-blue font-bold uppercase">
                              <IntlMessages id="common.payment" />
                           </span>
                        }
                     >
                        <Tabs
                           destroyInactiveTabPane={true}
                           defaultActiveKey={PAYMENTS.STRIPE}
                           onChange={(v) => setPaymentMethod(v)}
                        >
                           <Tabs.TabPane
                              tab={intl.formatMessage({ id: "payments.stripe" })}
                              key={PAYMENTS.STRIPE}
                           >
                              <StripeCheckoutElements formContent={formContent} />

                              {paymentMessage && (
                                 <Alert
                                    className="gx-mt-4"
                                    type="error"
                                    message={paymentMessage}
                                    showIcon={true}
                                    onClose={() => {
                                       setPaymentMessage(null);
                                    }}
                                    closable={true}
                                 />
                              )}
                           </Tabs.TabPane>
                           <Tabs.TabPane
                              tab={intl.formatMessage({ id: "payments.offline" })}
                              key={PAYMENTS.OFFLINE}
                           >
                              {intl.formatMessage({
                                 id: "payments.offline_description",
                              })}
                           </Tabs.TabPane>
                        </Tabs>
                     </Card>

                     <Card
                        className="card-step"
                        title={
                           <span className="color-blue font-bold uppercase">
                              <IntlMessages id="common.note" />
                           </span>
                        }
                     >
                        <Form.Item>
                           <Input.TextArea
                              name="note"
                              showCount
                              maxLength={255}
                              autoSize={{ minRows: 3, maxRows: 5 }}
                           />
                        </Form.Item>

                        <div className="gx-float-right gx-mt-4 gx-w-100 gx-mb-0">
                           <Button
                              key="1"
                              onClick={handlePreviousStep}
                              className="uppercase border-0 gx-mb-0"
                           >
                              <IntlMessages id="common.back" />
                           </Button>
                           <Button
                              disabled={
                                 loadingMutation ||
                                 loadingPayment ||
                                 !stripe ||
                                 !elements
                              }
                              loading={loadingMutation || loadingPayment}
                              key="2"
                              htmlType="submit"
                              className="bg-orange white uppercase border-0 gx-mb-0"
                           >
                              <IntlMessages id="common.done" />
                           </Button>
                        </div>
                     </Card>
                  </form>
               );
            }}
         </ElementsConsumer>
      </Elements>
   );
};

export const FrontendReservationSummaryStep = (props) => {
   const {
      formContent,
      workspaces,
      processReservationIntent,
      serviceCollection,
      loadingReservationIntent,
   } = props;

   useEffect(() => {
      console.log("PROCESS reservation!");
      clearErrors();
      processReservationIntent();
   }, []);

   if (loadingReservationIntent) {
      return <CircularProgress />;
   }

   const workspace = find(
      workspaces,
      (workspace) => workspace.id === formContent.workspace_id
   );

   if (serviceCollection.shouldPay()) {
      return <SummaryWithPayment {...props} workspace={workspace} />;
   } else {
      return <Summary {...props} workspace={workspace} />;
   }
};
