import { isDevelopment } from "../util/config";

export const OrganizationEntity = (organization) => {
   return {
      ...organization,
      isMainDomain() {
         return !organization || isDevelopment;
      },
      isOrganizationDomain() {
         return !!organization || isDevelopment;
      },
   };
};
