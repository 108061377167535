import { Col, Row, Tag, Button, Typography, Badge, Alert, Space } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { formatSecondsToHHMMSS, minuteToclockFormat } from "../../util/date";
import { GQL_RESERVATION_SLOT_AVAILABILITY } from "../../apollo/query/reservation";
import CircularProgress from "../CircularProgress";
import GQLAlertErrors from "../GQLAlertErrors";
import { useQuery } from "@apollo/client";
import {
   DANGER_COLOR,
   PRIMARY_COLOR,   
} from "../../constants/ThemeSetting";

const slotLayoutProps = {
   xl: { span: 4 },
   lg: { span: 6 },
   md: { span: 6 },
   sm: { span: 8 },
   xs: { span: 8 },
};

const isSlotVisible = (slot, overbookingActivated) => {
   //lo slot potrebbe essere disponibile e ci sono solo prenotazioni in overbooking
   if (slot.overbooking_reservations >= 2) {
      return false;
   }

   if (slot.available) return true;

   if (overbookingActivated && slot.overbooking_reservations === 0) {
      return true;
   }

   return false;
};

const AvailabilitiesSlots = ({
   workspace,
   selectedDate,
   selectedSlotStart,
   duration,
   onClickSlot = null,
   showOverbooking = false,
   defaultShowOverbooking = false,
}) => {
   const intl = useIntl();

   const [activateOverbooking, setActivateOverbooking] = useState(
      defaultShowOverbooking
   );

   const { data, loading, error } = useQuery(GQL_RESERVATION_SLOT_AVAILABILITY, {
      variables: {
         date: selectedDate,
         workspace_id: workspace,
         force_service_duration: duration,
      },
      fetchPolicy: "no-cache",
   });

   if (loading) {
      return <CircularProgress style={{ height: "100%" }} />;
   }

   if (error) {
      return <GQLAlertErrors error={error} />;
   }

   const slots = data.reservationSlotsAvailability?.availables || [];

   if (slots.length === 0)
      return (
         <Alert
            message={intl.formatMessage({ id: "reservations.no_availability" })}
            type="info"
            shwoIcon
         />
      );

   const isSelected = (slot) => {
      if (!selectedSlotStart) return false;

      return selectedSlotStart === slot.start;
   };

   return (
      <>
         <Space
            className="gx-mb-3"
            style={{
               justifyContent: "space-between",
               width: "100%",
            }}
         >
            <Typography.Paragraph className="gx-mb-3">
               {intl.formatMessage(
                  { id: "availabilities.p2" },
                  { date: moment(selectedDate).format("DD-MM-YYYY") }
               )}
               &nbsp;
               {`(${intl.formatMessage({
                  id: "reservations.estimate_duration",
               })}: ${formatSecondsToHHMMSS(duration)})`}
            </Typography.Paragraph>

            {showOverbooking && (
               <Button
                  size="small"
                  onClick={() => {
                     setActivateOverbooking(!activateOverbooking);
                  }}
                  type={activateOverbooking ? "primary" : "default"}
               >
                  {!activateOverbooking
                     ? intl.formatMessage({
                          id: "availabilities.activate_overbooking",
                       })
                     : intl.formatMessage({
                          id: "availabilities.deactivate_overbooking",
                       })}
               </Button>
            )}
         </Space>
         <Row gutter={[10, 10]}>
            {slots.map((slot, index) => {
               const isVisibile = isSlotVisible(slot, activateOverbooking);

               //Non mostrare slot non disponibili
               if (!isVisibile) return null;

               return (
                  <Col key={`fa-slot${index}`} {...slotLayoutProps}>
                     {onClickSlot ? (
                        <Badge
                           count={slot.reservations}
                           size="small"
                           style={{
                              backgroundColor:
                                 slot.overbooking_reservations > 0
                                    ? DANGER_COLOR
                                    : PRIMARY_COLOR,
                              right: 15,
                           }}
                        >
                           <Button
                              disabled={!isVisibile}
                              size="small"
                              className={!slot.available ? "gx-border-danger" : ""}
                              type={isSelected(slot) ? "primary" : "default"}
                              onClick={() => {
                                 onClickSlot(
                                    selectedDate,
                                    slot,
                                    activateOverbooking
                                 );
                              }}
                           >
                              <Typography.Title
                                 level={5}
                                 className={`${
                                    isSelected(slot) ? "gx-text-white" : ""
                                 } gx-mb-0`}
                              >
                                 {minuteToclockFormat(slot.start)}
                              </Typography.Title>
                           </Button>
                        </Badge>
                     ) : (
                        <Tag color="green">
                           <Typography.Title level={5} className="gx-mb-0">
                              {minuteToclockFormat(slot.start)}
                           </Typography.Title>
                        </Tag>
                     )}
                  </Col>
               );
            })}
         </Row>
      </>
   );
};

export default AvailabilitiesSlots;
