export function dataURItoBlob(dataURI) {
   // convert base64 to raw binary data held in a string
   const byteString = window.atob(dataURI.split(",")[1]);

   // separate out the mime component
   const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

   // write the bytes of the string to an ArrayBuffer
   const arrayBuffer = new ArrayBuffer(byteString.length);
   let _ia = new Uint8Array(arrayBuffer);
   for (let i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
   }

   const dataView = new DataView(arrayBuffer);
   const blob = new Blob([dataView], { type: mimeString });
   return blob;
}

export const encodeBase64 = (file) =>
   new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
   });

export const decodeBase64 = function (s) {
   let e = {},
      i,
      b = 0,
      c,
      x,
      l = 0,
      a,
      r = "",
      w = String.fromCharCode,
      L = s.length;
   let A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
   for (i = 0; i < 64; i++) {
      e[A.charAt(i)] = i;
   }
   for (x = 0; x < L; x++) {
      c = e[s.charAt(x)];
      b = (b << 6) + c;
      l += 6;
      while (l >= 8) {
         ((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a));
      }
   }
   return r;
};

export const downloadBase64File = (filename, content) => {
   const blob = dataURItoBlob(content);
   const url = URL.createObjectURL(blob);
   const downloadLink = document.createElement("a");
   downloadLink.href = url;
   downloadLink.download = filename;
   downloadLink.onclick = function () {
      requestAnimationFrame(function () {
         URL.revokeObjectURL(url);
      });
   };
   downloadLink.click();
};

export const openDocumentWindow = (base64Doc, { title = "Documento" }) => {
   var popupWin = window.open("", "_blank");
   var keepColors = "<style>body {margin:0;overflow:hidden;}</style>";
   const blob = dataURItoBlob(base64Doc);
   const url = URL.createObjectURL(blob);
   setTimeout(() => {
      popupWin.document.write(`
           <html>
               <head>
                   <title>${title}</title>
                   ${keepColors}
               </head>
               <body>
                   <embed width="100%" height="100%" src="${url}"></embed>
               </body>
           </html>
       `);
   }, 0);
};

export const openPDFDocumentWindow = (base64Doc, autoPrint = true) => {
   const pdfBlob = dataURItoBlob(base64Doc);
   const blobURL = URL.createObjectURL(pdfBlob);
   const printWindow = window.open(blobURL, "_blank");

   if (!autoPrint) {
      return;
   }

   //Può essere a null se il browser blocca il popup
   if (printWindow) {
      printWindow.onload = () => {
         printWindow.print();
      };

      printWindow.onafterprint = function () {
         printWindow.close();
      };
   }
};

export const printPDFContent = (base64Doc) => {
   const pdfBlob = dataURItoBlob(base64Doc);
   const blobURL = URL.createObjectURL(pdfBlob);

   let iframe = document.createElement("iframe"); //load content in an iframe to print later
   document.body.appendChild(iframe);

   iframe.style.display = "none";
   iframe.src = blobURL;
   iframe.onload = function () {
      setTimeout(function () {
         iframe.focus();
         iframe.contentWindow.print();
      }, 1);
   };
};

export const printPDFWithShare = (base64Doc) => {
   const pdfBlob = dataURItoBlob(base64Doc);
   //const blobURL = URL.createObjectURL(pdfBlob);

   const file = new File([pdfBlob], "test.pdf", { type: "application/pdf" });

   navigator
      .share({
         title: "test",
         text: "test text",
         files: [file],
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
};
