import React, { useState } from "react";
import { Button, Result, Rate, Form, Typography, Divider, message } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";

import { useMutation, useQuery } from "@apollo/client";
import { injectIntl, useIntl } from "react-intl";
import { getFrontendPath } from "../util/router";
import { GQL_RESERVATION_DETAIL_FRONTEND } from "../apollo/query/reservation";
import { get } from "lodash";
import { DATE_FORMAT } from "../constants/App";
import moment from "moment";
import { ServiceCollection } from "../entities/service";
import { WorkspaceEntity } from "../entities/workspace";
import { fill } from "lodash";
import { GQL_RESERVATION_FEEDBACK_FRONTEND } from "../apollo/mutation/reservation";
import NotAllowed from "./Frontend/NotAllowed";

const FeedbackReservationSummary = ({
   serviceCollection,
   workspaceEntity,
   dateFormatted,
   reservation,
}) => {
   return (
      <h2 className="gx-mt-4 gx-mb-4">
         <strong>{serviceCollection.getNames()}</strong> presso{" "}
         <strong>
            {workspaceEntity.getName()} {workspaceEntity.getFormattedAddress()}
         </strong>{" "}
         in data <strong>{dateFormatted}</strong> alle ore{" "}
         <strong>{reservation.time.time_start}</strong>
      </h2>
   );
};

const FeedbackAlreadySet = ({
   serviceCollection,
   workspaceEntity,
   dateFormatted,
   reservation,
}) => {
   const commonProps = {
      serviceCollection,
      workspaceEntity,
      dateFormatted,
      reservation,
   };
   return (
      <Result
         status="warning"
         title={<FeedbackReservationSummary {...commonProps} />}
         subTitle={
            <Typography.Title level={4} className="gx-text-danger">
               <IntlMessages id="feedback.already_set" />
            </Typography.Title>
         }
         extra={[
            <Link to={getFrontendPath()} key="home">
               <Button>
                  <IntlMessages id="404.home" />
               </Button>
            </Link>,
         ]}
      ></Result>
   );
};

const RatingRow = ({ ratings, index, onChange }) => {
   const intl = useIntl();

   return (
      <>
         <Form.Item
            rules={[
               {
                  required: true,
                  message: intl.formatMessage({ id: "feedback.missing_rate" }),
               },
               {
                  min: 1,
                  type: "number",
                  message: intl.formatMessage({ id: "feedback.missing_rate" }),
               },
            ]}
            name={`rating_${index + 1}`}
            label={<IntlMessages id={`feedback.rating_${index + 1}`} />}
         >
            <Rate
               allowClear={true}
               count={5}
               value={ratings[index]}
               onChange={(value) => onChange(value, index)}
            />
         </Form.Item>
         <Divider />
      </>
   );
};

const FeedbackRating = ({
   serviceCollection,
   workspaceEntity,
   reservation,
   dateFormatted,
}) => {
   const commonProps = {
      serviceCollection,
      workspaceEntity,
      dateFormatted,
      reservation,
   };
   const intl = useIntl();

   const [ratingValues, setRatingValues] = useState(fill(Array(3), 0));
   const [feedbackSent, setFeedbackSent] = useState(false);
   const [feedbackReservation, { loading: loadingMutation }] = useMutation(
      GQL_RESERVATION_FEEDBACK_FRONTEND
   );

   const onFinish = (values) => {
      feedbackReservation({
         variables: {
            id: reservation.id,
            token: reservation.token_delete,
            ratings: {
               ...values,
            },
         },
      })
         .then(({ data }) => {
            if (data && data.reservationFeedback) {
               setFeedbackSent(true);
            } else {
               message.error(
                  intl.formatMessage({
                     id: "feedback.response_error",
                  })
               );
            }
         })
         .catch((e) => {
            message.error(
               intl.formatMessage({
                  id: "feedback.response_error",
               })
            );
         });
   };

   if (feedbackSent) {
      return (
         <Result
            status="success"
            title={<IntlMessages id="feedback.title_success" />}
            subTitle={
               <>
                  <IntlMessages id="feedback.description_success" />
                  <div className="gx-mt-3 gx-mb-3">
                     <FeedbackReservationSummary {...commonProps} />
                  </div>
               </>
            }
            extra={[
               <Link to={getFrontendPath()} key="home">
                  <Button>
                     <IntlMessages id="404.home" />
                  </Button>
               </Link>,
            ]}
         ></Result>
      );
   }

   return (
      <Form layout="horizontal" labelCol={{ span: 8 }} onFinish={onFinish}>
         <Result
            status="info"
            title={<IntlMessages id="feedback.title" />}
            subTitle={
               <>
                  <IntlMessages id="feedback.description" />
                  <div className="gx-mt-3 gx-mb-3">
                     <FeedbackReservationSummary {...commonProps} />
                  </div>
                  <div style={{ backgroundColor: "white" }}>
                     <Divider />
                     {ratingValues.map((_, index) => (
                        <RatingRow
                           ratings={ratingValues}
                           index={index}
                           key={index}
                           onChange={(value, index) => {
                              let newValues = [...ratingValues];
                              newValues[index] = value;
                              setRatingValues(newValues);
                           }}
                        />
                     ))}
                  </div>
               </>
            }
            extra={[
               <Button
                  type="primary"
                  htmlType="submit"
                  key="console"
                  loading={loadingMutation}
               >
                  <IntlMessages id="feedback.confirm_rating" />
               </Button>,
               <Link to={getFrontendPath()} key="home">
                  <Button>
                     <IntlMessages id="404.home" />
                  </Button>
               </Link>,
            ]}
         ></Result>
      </Form>
   );
};

const ReservationFeedbackForm = ({ intl, reservation }) => {
   const dateFormatted = moment(reservation.time.date, "YYYY-MM-DD").format(
      DATE_FORMAT
   );
   const serviceCollection = ServiceCollection(reservation.services);

   const workspaceEntity = WorkspaceEntity(reservation.workspace);

   const commonProps = {
      serviceCollection,
      workspaceEntity,
      reservation,
      dateFormatted,
   };

   return (
      <div className="gx-app-login-wrap gx-app-login-wrap-frontend frontend-container">
         <div className="gx-app-login-container gx-app-login-container-frontend">
            <div className="gx-app-logo gx-app-logo-frontend gx-mb-3 gx-mt-4">
               <img alt="" src="/assets/images/savnobook_logo.svg" />
            </div>
            <div className="gx-app-login-main-content gx-app-login-main-content-frontend gx-mt-5">
               <div className="gx-app-login-wrap">
                  {reservation.feedback ? (
                     <FeedbackAlreadySet {...commonProps} />
                  ) : (
                     <FeedbackRating {...commonProps} />
                  )}
               </div>
            </div>

            <div className="gx-mt-5 gx-mb-5">&nbsp;</div>
         </div>
      </div>
   );
};

const ReservationFeedback = ({ intl }) => {

   const { id, token } = useParams();

   const { data, loading, error } = useQuery(GQL_RESERVATION_DETAIL_FRONTEND, {
      variables: {
         args: {
            id,
            token,
         },
      },
   });

   if (loading) return <CircularProgress />;

   const reservation = get(data, "reservationDetailFrontend", null);

   if (error || !reservation) {
      return (
         <NotAllowed
            title={<IntlMessages id="common.not_allowed" />}
            subtitle={<IntlMessages id="common.not_allowed_description" />}
         />
      )
   }

   return <ReservationFeedbackForm intl={intl} reservation={reservation} />;
};

export default injectIntl(ReservationFeedback);
