import React from "react";
import { Switch, Route } from "react-router-dom";
import { getAdminPath } from "../../../util/router";
import Categories from "./categories";
import Products from "./products";
import ProductUpdate from "./products/update";
import Reservations from "./reservations";
import ReservationUpdate from "./reservations/update";

const ReuseBoard = () => {
   return (
      <Switch>
         <Route
            exact
            path={getAdminPath("reuse-board/products")}
            component={Products}
         />
         <Route
            path={getAdminPath("reuse-board/products/update/:id")}
            component={ProductUpdate}
         />
         <Route
            exact
            path={getAdminPath("reuse-board/categories")}
            component={Categories}
         />
         <Route
            exact
            path={getAdminPath("reuse-board/reservations")}
            component={Reservations}
         />
         <Route
            path={getAdminPath("reuse-board/reservations/update/:id")}
            component={ReservationUpdate}
         />
      </Switch>
   );
};

export default ReuseBoard;
