import React, { useState } from "react";
import {
    Button,
    Input,
    Breadcrumb,
    Card,
    Row,
    Col,
    Table,
    message,
    PageHeader,
    Tag,
    Modal,
    Space,
} from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import { useDebouncedCallback } from "use-debounce";
import { debounceSearchFilterDelay } from "../../../../util/config";
import { get } from "lodash";
import { getAdminPath } from "../../../../util/router";
import BreadcrumbHomeLink from "../../../../components/BreadcrumbHomeLink";
import { GQL_COMUNI_RACCOLTA } from "../../../../apollo/query/raccolte";
import { GQL_COMUNE_ZONES_UPGRADE } from "../../../../apollo/mutation/raccolte";
import { defaultCatchException, defaultMutationCallback } from '../../../../apollo/callbacks';

const ComuniRaccolte = (props) => {
    const history = useHistory();
    const intl = useIntl();

    const [setComuneZonesUpgrade, { loading: loadingMutation }] = useMutation(
        GQL_COMUNE_ZONES_UPGRADE,        
    )

    const [textFilter, setTextFilter] = useState('');

    const debouncedFilterCallback = useDebouncedCallback(
        (value) => setTextFilter(value),
        debounceSearchFilterDelay
    );

    const { data, loading, refetch } = useQuery(GQL_COMUNI_RACCOLTA, {
        variables: {
            filters: { term: textFilter },            
        },
        onError: (error) => {
            message.error(intl.formatMessage({ id: "common.default_query_error" }));
        },
    });

    const columns = [
        {
            title: intl.formatMessage({ id: "common.code" }),
            dataIndex: "istat_code",
            width: 50,
            responsive: ["xxl", "xl", "lg"],
        },
        {
            title: intl.formatMessage({ id: "common.first_name" }),
            dataIndex: "name",
            fixed: "left",
            responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
        },
        {
            title: intl.formatMessage({
                id: "raccolte.zones",
            }),
            dataIndex: "zones",
            render: (zones) => {
                return zones.map((zone) => {
                    return (
                        <Tag>
                            {zone}
                        </Tag>
                    );
                });
            }
        },
        {
            title: "",
            dataIndex: "updated_zones_at",
            key: "operation",
            fixed: "right",
            align: "center",
            render: (value, row) => {
                return (
                    <Space>
                        {value && (
                            <Button
                                type="primary"
                                size="small"
                                loading={loadingMutation}
                                className="gx-mb-0"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    Modal.confirm({
                                        title: intl.formatMessage({ id: "common.confirm" }),
                                        content: intl.formatMessage({ id: "raccolte.confirm_upgrade_calendar" }),
                                        onOk: () => {
                                            setComuneZonesUpgrade({
                                                variables: {
                                                    comune_id: row.id
                                                },
                                            })
                                                .then((data) => defaultMutationCallback(data, () => {
                                                    message.success(intl.formatMessage({ id: "common.default_mutation_success" }));
                                                    refetch();
                                                }))
                                                .catch((e) => defaultCatchException(e, intl));
                                        }
                                    })
                                }}>
                                <IntlMessages id="raccolte.upgrade_calendar" />
                            </Button>
                        )}
                    </Space>
                )
            },
            width: 60,
        },
    ];

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#">
                            <IntlMessages id="sidebar.raccolte" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#">
                            <IntlMessages id="sidebar.comuni" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={<IntlMessages id="sidebar.comuni" />}
                    />
                </Col>
                <Col md={24}>
                    <Card>
                        <Row>
                            <Col md={24} sm={24} xs={24}>
                                <Input.Group
                                    compact
                                    style={{ float: "right", maxWidth: "250px" }}
                                >
                                    <Input.Search
                                        allowClear
                                        className="input-search"
                                        placeholder={intl.formatMessage({
                                            id: "common.search_element",
                                        })}
                                        onChange={(e) => {
                                            debouncedFilterCallback(e.target.value)
                                        }}
                                    />
                                </Input.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={24} sm={24} xs={24}>
                                <Table
                                    columns={columns}
                                    rowKey={(record) => record.id}
                                    dataSource={get(data, "comuni", [])}
                                    loading={loading}
                                    size="middle"
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: () => {
                                                history.push(getAdminPath(`raccolte/comuni/update/${record.id}`));
                                            },
                                        };
                                    }}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>            
        </>
    );
};

export default ComuniRaccolte;
