import React from "react";
import { Card, Typography } from "antd";

import { BulbTwoTone, CloseCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import IntlMessages from "../../util/IntlMessages";
import { PRIMARY_COLOR, ORANGE } from "../../constants/ThemeSetting";
import { PropTypes } from "prop-types";

const { Text } = Typography;

const { Meta } = Card;

const getDefaultPropsByType = (type) => {
   switch (type) {
      case "info":
         return {
            textColor: PRIMARY_COLOR,
            backgroundColor: "#edf7ff",
            icon: <BulbTwoTone twoToneColor="#fcc200" />,
            title: <IntlMessages id="common.tip" />,
         };
      case "warning":
         return {
            textColor: ORANGE,
            backgroundColor: "#fff2c9",
            icon: <WarningTwoTone twoToneColor={ORANGE} />,
            title: <IntlMessages id="common.warning" />,
         };
      case "danger":
         return {
            textColor: PRIMARY_COLOR,
            backgroundColor: "#edf7ff",
            icon: <CloseCircleTwoTone twoToneColor="#fcc200" />,
            title: <IntlMessages id="common.danger" />,
         };
      default:
         return {
            textColor: PRIMARY_COLOR,
            backgroundColor: "#edf7ff",
            icon: <BulbTwoTone twoToneColor="#fcc200" />,
            title: <IntlMessages id="common.tip" />,
         };
   }
};

const TipsCard = ({ children, title, type = "info" }) => {
   const {
      textColor,
      backgroundColor,
      icon,
      title: defaultTitle,
   } = getDefaultPropsByType(type);

   return (
      <Card
         className="gx-mb-3"
         style={{
            backgroundColor: backgroundColor,
         }}
      >
         <Meta
            avatar={icon}
            title={
               <Text style={{ color: textColor }}>
                  {title ? title : defaultTitle}
               </Text>
            }
            description={<Text style={{ color: textColor }}>{children}</Text>}
         />
      </Card>
   );
};

TipsCard.prototype = {
   title: PropTypes.string,
   children: PropTypes.element,
   type: PropTypes.oneOf(["info", "warning", "danger"]),
};

export default TipsCard;
