import React from "react";
import {
   Button,
   Row,
   Col,
   PageHeader,
   Descriptions,
   Typography,
   Modal,
   Grid,
   message
} from "antd";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../../util/IntlMessages";
import { useMutation, useQuery } from "@apollo/client";
import { GQL_RESERVATION } from "../../../../apollo/query/reservation";
import { get } from "lodash";
import CircularProgress from "../../../../components/CircularProgress";
import { reservationDateToString } from "../../../../util/date";
import { ServiceCollection } from "../../../../entities/service";
import { WorkspaceEntity } from "../../../../entities/workspace";
import { useHistory, useParams } from "react-router";
import { getFrontendPath } from "../../../../util/router";
import { ExclamationCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import BadResponseErrorAlert from "../../../../components/BadResponseErrorAlert";
import { DATE_FORMAT, TIME_FORMAT, RESERVATION } from "../../../../constants/App";
import { GQL_DELETE_RESERVATION } from "../../../../apollo/mutation/reservation";
import { localStorageClass } from "../../../../apollo/local/storage";
import {
   defaultCatchException,
   defaultMutationCallback,
} from "../../../../apollo/callbacks";
import moment from "moment";
import ReservationDetailInfo from "../../../../components/Checkout/ReservationDetailInfo";

const { useBreakpoint } = Grid;

const FrontendReservationDetailForm = ({ intl, reservation, me, history }) => {
   const { services, workspace } = reservation;
   const serviceCollection = ServiceCollection(services);
   const fullName = get(me, "full_name", "-");

   const workspaceEntity = WorkspaceEntity(workspace);

   const [deleteReservation] = useMutation(GQL_DELETE_RESERVATION);

   const confirmDeleteReservation = (record) => {
      Modal.confirm({
         title: intl.formatMessage({ id: "common.warning" }),
         icon: <ExclamationCircleOutlined />,
         zIndex: 9999,
         content: intl.formatMessage({
            id: "reservations.reservation_delete_confirm_message",
         }),
         okText: intl.formatMessage({ id: "reservations.cancel_reservation" }),
         cancelText: intl.formatMessage({ id: "common.no" }),
         okType: "danger",
         onOk() {
            deleteReservation({ variables: { id: reservation.id } })
               .then((data) => {
                  
                  if (!data.deleteReservation) {
                     message.error(intl.formatMessage({ id: "reservations.reservation_delete_error" }));
                     return;
                  }

                  defaultMutationCallback(data, () => {
                     const currentReservationId =
                        localStorageClass.getReservationId();
                     if (currentReservationId === record.id) {
                        localStorageClass.resetReservation();
                     }

                     history.push(getFrontendPath("prenotazioni-elenco"));
                  })
               })
               .catch((e) => defaultCatchException(e, intl));
         },
      });
   };

   const screens = useBreakpoint();
   const getExtraToolbar = (extraToolbar) => {
      return [
         extraToolbar ? getCancelButtonToolbar() : null,
         <Button
            key="reserve-btn"
            className="btn-green"
            onClick={() => {
               history.push(getFrontendPath("prenotazioni-elenco"));
            }}
         >
            <IntlMessages id="reservations.my_reservations" />
         </Button>,
      ];
   };

   const getCancelButtonToolbar = () => {
      return (
         <Button
            hidden={[
               RESERVATION.STATUS.PROCESSING,
               RESERVATION.STATUS.DELETED,
            ].includes(reservation.status)}
            key="reserve-btn-delete"
            size="default"
            danger
            onClick={(e) => {
               e.stopPropagation();
               confirmDeleteReservation(reservation);
            }}
         >
            <IntlMessages id="reservations.cancel_reservation" />
         </Button>
      );
   };

   return (
      <>
         <Row>
            <Col xs={24} hidden={screens["lg"]}>
               {getExtraToolbar(false)}
            </Col>
         </Row>
         <Row>
            <Col xs={24}>
               <PageHeader
                  className="gx-page-header gx-mt-2"
                  title={
                     <h2 className="multiline-text">
                        <IntlMessages id="reservations.reservation_detail" />
                     </h2>
                  }
                  onBack={() => history.goBack()}
                  backIcon={<ArrowLeftOutlined className="gx-arrow-back" />}
                  extra={screens["lg"] ? getExtraToolbar(true) : []}
               />
            </Col>
         </Row>
         <Row>
            <Col xs={24} hidden={screens["lg"]}>
               {getCancelButtonToolbar()}
            </Col>
         </Row>
         <Row>
            <Col xs={24}>
               <BadResponseErrorAlert redirectURL={getFrontendPath()} />
            </Col>
            <Col xs={24}>
               <Descriptions
                  column={1}
                  bordered
                  className="gx-mt-4 gx-frontend-description"
               >
                  <Descriptions.Item
                     label={intl.formatMessage({ id: "reservation.code" })}
                  >
                     <Typography.Text
                        copyable={{
                           text: `#${reservation.code}`,
                        }}
                        className="color-blue"
                     >
                        #{reservation.code}
                     </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item
                     label={intl.formatMessage({ id: "common.nominativo" })}
                  >
                     {fullName}
                  </Descriptions.Item>
                  <Descriptions.Item
                     label={intl.formatMessage({
                        id: "frontend.sportello_riferimento",
                     })}
                  >
                     <strong>{workspaceEntity.getName()}</strong>&nbsp;
                     {workspaceEntity.getFormattedAddress()}
                  </Descriptions.Item>
                  <Descriptions.Item
                     label={intl.formatMessage({ id: "services.services" })}
                  >
                     {serviceCollection.getNames()}
                  </Descriptions.Item>
                  <Descriptions.Item
                     label={intl.formatMessage({
                        id: "frontend.data_ora_prenotazione",
                     })}
                  >
                     {reservationDateToString(
                        {
                           day: reservation.time.date,
                           slot: reservation.time.time_start,
                        },
                        intl
                     )}
                  </Descriptions.Item>

                  {reservation.payment.should_pay && [
                     <Descriptions.Item
                        key="price"
                        label={intl.formatMessage({
                           id: "common.price",
                        })}
                     >
                        {serviceCollection.getFormattedTotalPrice()}
                     </Descriptions.Item>,
                     <Descriptions.Item
                        key="payment_method"
                        label={intl.formatMessage({ id: "payments.method" })}
                     >
                        {intl.formatMessage({
                           id: `payments.${reservation.payment.method}`,
                        })}
                        {reservation.payment.paid_at ? (
                           <small className="gx-pl-2">
                              (
                              {intl.formatMessage(
                                 { id: `payments.paid_at` },
                                 {
                                    date: moment(reservation.payment.paid_at).format(
                                       DATE_FORMAT
                                    ),
                                    time: moment(reservation.payment.paid_at).format(
                                       TIME_FORMAT
                                    ),
                                 }
                              )}
                              )
                           </small>
                        ) : (
                           ""
                        )}
                     </Descriptions.Item>,
                  ]}

                  <Descriptions.Item
                     label={intl.formatMessage({ id: "common.status" })}
                  >
                     {intl.formatMessage({
                        id: `reservations.status_${reservation.status}`,
                     })}
                  </Descriptions.Item>

                  {reservation.note && reservation.note.length > 0 && (
                     <Descriptions.Item
                        label={intl.formatMessage({ id: "common.note" })}
                     >
                        {reservation.note}
                     </Descriptions.Item>
                  )}
               </Descriptions>
            </Col>
         </Row>
         <Row>
            <Col xs={24}>
               <ReservationDetailInfo reservation={reservation} />
            </Col>
         </Row>
      </>
   );
};

const FrontendReservationDetail = ({ intl }) => {
   const history = useHistory();
   const { id } = useParams();

   const { data, loading, error } = useQuery(GQL_RESERVATION, {
      variables: { id: id },
   });

   if (loading) return <CircularProgress />;

   if (error || !data.me) {
      return null;
   }

   if (!data.reservation) {
      history.push(getFrontendPath("prenotazioni-elenco"));
      return null;
   }

   return (
      <FrontendReservationDetailForm
         intl={intl}
         history={history}
         reservation={data.reservation}
         me={data.me}
      />
   );
};

export default injectIntl(FrontendReservationDetail);
