import { includes } from "lodash";
import { localStorageClass } from "../../../../apollo/local/storage";
import { RESERVATION } from "../../../../constants/App";

export const formStyles = {
   layout: "horizontal",
   labelCol: { span: 4 },
   wrapperCol: { span: 24 },
};

export const canResumeReservation = (reservation) => {
   //prenotazione locale
   const currentReservationStatus = localStorageClass.getReservationStatus();

   return (
      reservation &&
      (includes(
         [RESERVATION.STATUS.INTENT, RESERVATION.STATUS.INTENT_UPDATED],
         reservation.status //prenotazione server
      ) ||
         currentReservationStatus === RESERVATION.STATUS.PAYMENT_ERROR)
   );
};
