import { includes } from "lodash";
import { useMemo } from "react";
import { List, Typography, Space } from "antd";
import { DRAFTJS_FORMATTERS_COMPONENT_MAPPING } from "./formatters/draftjs-formatters";
import { SECONDARY_COLOR } from "../../../constants/ThemeSetting";

const isListBlock = (block) => {
   if (!block) return false;
   return includes(["unordered-list-item", "ordered-list-item"], block.type);
};

const isSameListBlock = (block, prevBlock) => {
   if (!block) return false;
   return (
      isListBlock(prevBlock) && isListBlock(block) && block.type === prevBlock.type
   );
};

const TitleBlock = ({ level, block }) => {
   if (level <= 4) {
      return <Typography.Title level={level}>{block.text}</Typography.Title>;
   } else if (level === 5) {
      return <Typography.Text>{block.text}</Typography.Text>;
   } else if (level === 6) {
      return <Typography.Text className="gx-fs-sm">{block.text}</Typography.Text>;
   }
};

const ListBlock = ({ block, ordered }) => {
   return (
      <List
         dataSource={block.children}
         renderItem={(child, index) => {
            return (
               <List.Item>
                  {ordered ? `${index + 1}.` : ""} {child.text}
               </List.Item>
            );
         }}
      />
   );
};

const AntBlock = ({ block }) => {
   const componentData = DRAFTJS_FORMATTERS_COMPONENT_MAPPING[block.type];

   switch (componentData.component) {
      case "typography":
         return <TitleBlock {...componentData.props} block={block} />;
      case "list":
         return <ListBlock {...componentData.props} block={block} />;
      case "blockquote":
         return (
            <div
               className="gx-pl-2"
               style={{ borderLeft: `2px solid ${SECONDARY_COLOR}` }}
            >
               {block.text}
            </div>
         );
      case "codeblock":
         return (
            <div className="gx-text-white gx-p-2 gx-bg-secondary">{block.text}</div>
         );
      case "paragraph":
         return block.text ? (
            <Typography.Paragraph>{block.text}</Typography.Paragraph>
         ) : (
            <Space size="small" />
         );
      default:
         return null;
   }
};

export const RichTextEditorViewer = ({ editorData }) => {
   const groupListBlocks = () => {
      const blocks = [];

      for (let i = 0; i < editorData.blocks.length; i++) {
         const block = editorData.blocks[i];
         const prevBlock = i > 0 ? editorData.blocks[i - 1] : null;

         if (isListBlock(block)) {
            if (!isSameListBlock(block, prevBlock)) {
               blocks.push({
                  ...block,
                  children: [
                     {
                        text: block.text,
                        type: "li-item",
                     },
                  ],
               });
            } else if (isSameListBlock(block, prevBlock)) {
               blocks[blocks.length - 1].children.push({
                  text: block.text,
                  type: "li-item",
               });
            }
         } else {
            blocks.push(block);
         }
      }
      return blocks;
   };

   const blocks = useMemo(() => {
      return groupListBlocks();
   }, [editorData]);

   return (
      <div className="reservation-text-editor">
         {blocks.map((block, index) => (
            <AntBlock block={block} key={`k-block-${index}`} />
         ))}
      </div>
   );
};
