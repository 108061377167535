import { get } from "lodash";

export const getCalendarDateCellClassName = (
   date,
   selectedMonthMoment,
   selectedDayMoment,
   availabilities
) => {
   if (!availabilities || availabilities.length === 0) {
      return "";
   }

   //se la data è quella selezionata, non aggiungere classe
   if (date.format("YYYY-MM-DD") === selectedDayMoment.format("YYYY-MM-DD")) {
      return "";
   }

   const dayOfMonth = date.date();

   const month = date.month();

   const selectedM = selectedMonthMoment.month();

   if (selectedM !== month) {
      return "";
   }

   const dayAvailabilities = get(availabilities, `[${dayOfMonth - 1}]`, []);

   const hasAvailabilities = dayAvailabilities.length > 0;

   if (!hasAvailabilities) {
      return "gx-picker-cell-not-available";
   } else {
      return "gx-picker-cell-available";
   }
};
