import React, { useState } from 'react';
import { Tag, Col, Row, Breadcrumb, PageHeader, Card, Table, Select, Modal, Form, Input, Space, Popconfirm, Typography, Button, Checkbox, message, Alert, Switch } from "antd";
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import IntlMessages from '../../../../util/IntlMessages';
import { useIntl } from 'react-intl';
import { GQL_COMUNE_GROUPED_ZONES_ADDRESSES, GQL_COMUNE_RACCOLTA, GQL_COMUNE_ZONES_ADDRESSES, GQL_COMUNE_ZONES_RACCOLTE_TYPES } from '../../../../apollo/query/raccolte';
import CircularProgress from '../../../../components/CircularProgress';
import GQLAlertErrors from '../../../../components/GQLAlertErrors';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { getAdminPath } from 'util/router';
import { useQuery } from '@apollo/client';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { includes, keyBy, first, some, pick, startsWith, update } from 'lodash'
import { randomString } from '../../../../util/string';
import { useMutation } from '@apollo/client';
import { GQL_DELETE_GROUPED_ZONE_ADDRESS, GQL_UPDATE_COMUNE, GQL_UPSERT_ZONES } from '../../../../apollo/mutation/raccolte';
import { defaultMutationCallback, defaultCatchException } from '../../../../apollo/callbacks';
import { CircularProgressOverlay } from '../../../../components/CircularProgress/index';
import BadResponseErrorAlert from '../../../../components/BadResponseErrorAlert/index';
import { debounceSearchFilterDelay } from '../../../../util/config';
import { useDebouncedCallback } from 'use-debounce';

const MODAL_WIDTH = '70%'

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputNode,
    record,
    index,
    children,
    id,
    ...restProps
}) => {

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: <IntlMessages id="validation.required" />,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const hasStreetNumbers = (zones) => {
    return zones.filter(z => Boolean(z.number)).length > 0
}

const UpdateAddressedComuneRaccolte = ({ comune, zones, raccolteTypes, address, onCancel, refetch, refetchGroupedZones }) => {

    const intl = useIntl();

    const TABLE_ID_DEFAULT_STATE = {
        added: [],
        removed: [],
        updated: []
    }

    const [upsertZones, { loading: loadingMutation }] = useMutation(GQL_UPSERT_ZONES);

    const [form] = Form.useForm();
    const [data, setData] = useState(zones);
    const [tableUpdatedId, setTableUpdatedId] = useState(TABLE_ID_DEFAULT_STATE);
    const [viewAddressesTable, setViewAddressesTable] = useState(hasStreetNumbers(zones));
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.id === editingKey;

    const onAdd = () => {
        const newData = {
            id: `add_${randomString()}`,
            number: '',
            zone: '',
            types: [],
        };
        setData([newData, ...data]);
        setEditingKey(newData.id);
        onTableUpdate('added', newData.id);
    };

    const onEdit = (record) => {
        form.setFieldsValue({
            number: '',
            zone: '',
            types: [],
            ...record,
        });
        setEditingKey(record.id);
    };

    const onCancelRow = () => {
        setEditingKey('');
    };

    const onDelete = (record) => {

        const newData = [...data];
        const index = newData.findIndex((item) => record.id === item.id);
        if (index > -1) {
            const item = newData[index];
            if (!item.number) {
                newData.splice(index, 1);
                setData(newData);
            }
            // aggiungi id tra i rimossi e cambia backgroud tabella
            onTableUpdate('removed', item.id);
        }
    }

    const onTableUpdate = (action, key) => {

        const newArray = [...tableUpdatedId[action]];

        if (!newArray.includes(key)) {
            newArray.push(key);
        }

        setTableUpdatedId({
            ...tableUpdatedId,
            [action]: newArray
        })
    }

    const onSaveRow = async (key) => {

        try {
            const row = await form.validateFields();

            const newData = [...data];
            const index = newData.findIndex((item) => key === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setData(newData);
                setEditingKey('');
                onTableUpdate('updated', key);
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
                onTableUpdate('added', key);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const getColumnInputByDataIndex = (dataIndex) => {
        switch (dataIndex) {
            case 'number':
                return <Input />
            case 'zone':
                return (
                    <Select>
                        {comune.zones.map((zone) => <Select.Option key={zone} value={zone}>{zone}</Select.Option>)}
                    </Select>
                )
            case 'types':
                return (
                    <Select mode="multiple">
                        {raccolteTypes.map((type) => <Select.Option key={type.id} value={type.name}>{type.name}</Select.Option>)}
                    </Select>
                )
            default:
                return null;
        }
    }

    const getColumns = () => {
        const columns = [
            {
                title: <IntlMessages id="raccolte.street_number" />,
                dataIndex: 'number',
                width: '25%',
                editable: true,
            },
        ]

        if (comune?.zones?.length > 0) {
            columns.push({
                title: <IntlMessages id="raccolte.zone" />,
                dataIndex: 'zone',
                width: '25%',
                editable: true,
            })
        }

        columns.push(
            {
                title: <IntlMessages id="raccolte.raccolte" />,
                dataIndex: 'types',
                width: '40%',
                editable: true,
                render(values) {
                    return values.map((value) => <Tag key={value}>{value}</Tag>)
                }
            },
            {
                title: '',
                dataIndex: 'operation',
                align: 'center',
                render: (_, record) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <span>
                            <a
                                onClick={() => onSaveRow(record.id)}
                                style={{
                                    marginRight: 8,
                                }}
                            >
                                <IntlMessages id="common.update" />
                            </a>
                            <Popconfirm title={<IntlMessages id="common.cancel_confirm" />} onConfirm={onCancelRow}>
                                <a><IntlMessages id="common.cancel" /></a>
                            </Popconfirm>
                        </span>
                    ) : (
                        !tableUpdatedId.removed.includes(record.id) ? (
                            <Space>

                                <Typography.Link disabled={editingKey !== ''} onClick={() => onEdit(record)} key="btn-edit">
                                    <EditOutlined />
                                </Typography.Link>

                                <Typography.Link disabled={editingKey !== ''} onClick={() => onDelete(record)} key="btn-delete">
                                    <DeleteOutlined />
                                </Typography.Link>
                            </Space>
                        ) : (
                            <Typography.Link disabled={editingKey !== ''} onClick={() => {
                                setTableUpdatedId({
                                    ...tableUpdatedId,
                                    removed: tableUpdatedId.removed.filter(item => item !== record.id)
                                })
                            }} key="btn-undo">
                                <IntlMessages id="common.undo" />
                            </Typography.Link>
                        )
                    );
                },
            },
        )

        return columns.map((col, index) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    inputNode: getColumnInputByDataIndex(col.dataIndex),
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    id: record.id,
                }),
            };
        });
    }

    const formToMutation = (values) => {

        const dataMap = keyBy(data, 'id');

        let mutation = {
            comune_id: comune.id,
            unique_street_number: !viewAddressesTable,
            street_id: address?.id,
            street: values.street,
            street_numbers: {
                added: [],
                updated: [],
                removed: []
            }
        }

        if (viewAddressesTable) {

            const { added, removed, updated } = tableUpdatedId;

            mutation.street_numbers.added = added?.filter(id => {
                //è presente l'id e non è tra quelli rimossi
                return id && !includes(removed, id)
            }).map((id) => {
                return pick(dataMap[id], ['number', 'zone', 'types'])
            })
            mutation.street_numbers.updated = updated?.filter(id => id && !startsWith(id, 'add_')).map((id) => {
                return pick(dataMap[id], ['number', 'zone', 'types', 'id'])
            })
            mutation.street_numbers.removed = removed?.filter(id => id && !startsWith(id, 'add_'))
        } else {
            mutation.street_numbers.added = [
                {
                    types: values.types,
                    zone: values.zone,
                }
            ]

        }

        return mutation;
    }

    const validateInput = (input) => {
        let result = {
            error: false,
            message: null
        }
        if (viewAddressesTable) {
            if (some(input.street_numbers.added, (item) => !item?.number)) {
                return {
                    error: true,
                    message: intl.formatMessage({ id: "raccolte.street_number_required" })
                };
            }

            if (some(input.street_numbers.added, (item) => !item?.number)) {
                return {
                    error: true,
                    message: intl.formatMessage({ id: "raccolte.street_number_required" })
                };
            }
        }

        return result;
    }

    return (
        <Modal
            title={address?.street ? address.street : <IntlMessages id="raccolte.new_street" />}
            width={MODAL_WIDTH}
            style={{ top: 25 }}
            visible={true}
            onCancel={onCancel}
            onOk={() => {
                form.submit();
            }}
            okButtonProps={{ loading: loadingMutation }}
            okText={<IntlMessages id="common.save" />}

        >
            <div>
                <BadResponseErrorAlert className='gx-mb-3' />
            </div>

            <div className="gx-px-3 ant-form ant-form-vertical">
                <Form
                    form={form}
                    component={false}
                    initialValues={{
                        street: address?.street || '',
                        types: zones.length > 0 ? first(zones)?.types : [],
                        zone: zones.length > 0 ? first(zones)?.zone : null,
                    }}
                    onFinish={(values) => {

                        const input = formToMutation(values);

                        const validation = validateInput(input)

                        if (validation.error) {
                            return message.error(validation.message);
                        }

                        upsertZones({ variables: { input } })
                            .then((data) => defaultMutationCallback(data, () => {
                                refetchGroupedZones();
                                onCancel();
                            }))
                            .catch((e) => defaultCatchException(e, intl));
                    }}
                    onFinishFailed={(errorInfo) => {
                        console.log('Failed:', errorInfo);
                    }}
                >

                    <Row className="gx-flex-row">
                        <Col xs={24} md={16}>
                            <Form.Item
                                name="street"
                                label={<IntlMessages id="raccolte.street" values={{ small: t => <small className="gx-pl-2">{t}</small> }} />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="validation.required" />,
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Checkbox
                                    checked={viewAddressesTable}
                                    onChange={(e) => {
                                        setViewAddressesTable(e.target.checked);
                                    }} >
                                    <IntlMessages id="raccolte.apply_to_single_street_numbers" />
                                </Checkbox>
                            </Form.Item>
                        </Col>

                    </Row>
                    {viewAddressesTable ? (
                        <div>
                            <Row className="gx-flex-row">
                                <Col xs={24} md={16}>
                                    <IntlMessages id="raccolte.street_numbers" />
                                </Col>
                                <Col xs={24} md={8} className="gx-text-right">
                                    <Space className="gx-mb-2">
                                        <Button size="small" type="dashed" className="gx-mb-0" onClick={() => {
                                            setEditingKey('');
                                            setTableUpdatedId(TABLE_ID_DEFAULT_STATE)
                                            refetch();
                                        }}>
                                            <IntlMessages id="common.undo" />
                                        </Button>
                                        <Button size="small" onClick={() => onAdd()} type="primary" className="gx-mb-0"><IntlMessages id="common.add" /></Button>
                                    </Space>
                                </Col>
                            </Row>
                            <Row style={{ flexDirection: 'row' }}>
                                <Col xs={24} md={24}>
                                    <Table
                                        key={r => r.id}
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        bordered
                                        size="small"
                                        dataSource={data}
                                        columns={getColumns()}
                                        rowClassName={(record) => {
                                            if (includes(tableUpdatedId.removed, record.id)) {
                                                return 'gx-bg-danger';
                                            } else if (includes(tableUpdatedId.added, record.id)) {
                                                return 'gx-bg-success';
                                            } else if (includes(tableUpdatedId.updated, record.id)) {
                                                return 'gx-bg-warning';
                                            }
                                        }}
                                        pagination={{
                                            onChange: onCancelRow,
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <Row className="gx-flex-row">
                            <Col xs={24} md={16}>
                                <Form.Item
                                    name="types"
                                    label={<IntlMessages id="raccolte.raccolte" />}
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="validation.required" />,
                                        }
                                    ]}
                                >
                                    <Select mode="multiple" >
                                        {raccolteTypes.map(raccolta => <Select.Option key={raccolta.id} value={raccolta.name}>{raccolta.name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                                {comune.zones && comune.zones.length > 0 && (
                                    <Form.Item
                                        name="zone"
                                        label={<IntlMessages id="raccolte.zone" />}
                                        rules={[
                                            {
                                                required: true,
                                                message: <IntlMessages id="validation.required" />,
                                            }
                                        ]}
                                    >
                                        <Select>
                                            {comune.zones.map(z => (
                                                <Select.Option value={z}>{z}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                    )}
                </Form>
            </div>
        </Modal>
    )
}

const UpdateAddressedComuneRaccolteModal = (props) => {

    const { data, loading, error: zonesError, refetch } = useQuery(GQL_COMUNE_ZONES_ADDRESSES, {
        variables: {
            filters: {
                street_slug: props.address.id,
                comune_id: props.comune.id
            },
            comune_id: props.comune.id
        }
    })

    if (zonesError) {
        return <GQLAlertErrors error={zonesError} />
    }

    if (loading) {
        return <CircularProgressOverlay />
    }

    return <UpdateAddressedComuneRaccolte
        {...props}
        zones={data?.zones || []}
        raccolteTypes={data?.raccolteTypes || []}
        refetch={refetch}
    />
}

const CreateAddressedComuneRaccolteModal = (props) => {

    const { data, loading, error: zonesError, refetch } = useQuery(GQL_COMUNE_ZONES_RACCOLTE_TYPES, {
        variables: {
            comune_id: props.comune.id
        }
    })

    if (zonesError) {
        return <GQLAlertErrors error={zonesError} />
    }

    if (loading) {
        return <CircularProgressOverlay />
    }

    return <UpdateAddressedComuneRaccolte
        {...props}
        zones={[]}
        raccolteTypes={data?.raccolteTypes || []}
        refetch={refetch}
    />
}

const UpdateComuneTipiRaccolte = ({ comune, updateComune, onUpdateSuccess,  intl }) => {
    const { data, loading, error, refetch } = useQuery(GQL_COMUNE_ZONES_RACCOLTE_TYPES, {
        variables: {
            comune_id: comune.id
        }
    })

    if (loading) {
        return <CircularProgressOverlay />
    }

    if (error) {
        return <GQLAlertErrors error={error} />
    }

    return (
        <span>
            <Form
                layout="vertical"
                initialValues={{
                    types: comune.raccolte_types || []
                }}
                onFinish={values => {                    
                    updateComune({
                        variables: {
                            input: {
                                id: comune.id,
                                raccolte_types: values.types
                            }
                        }
                    })
                        .then((data) => defaultMutationCallback(data, () => {
                            message.success(intl.formatMessage({ id: "common.default_mutation_success" }));
                            onUpdateSuccess()
                        }))
                        .catch((e) => defaultCatchException(e, intl));
                }}
            >
                <Form.Item

                    name="types"
                    label={<IntlMessages id="raccolte.raccolte" />}
                    rules={[
                        {
                            required: true,
                            message: <IntlMessages id="validation.required" />,
                        }
                    ]}
                >
                    <Select mode="multiple" >
                        {data?.raccolteTypes.map(raccolta => <Select.Option key={raccolta.id} value={raccolta.name}>{raccolta.name}</Select.Option>)}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        <IntlMessages id="common.save" />
                    </Button>
                </Form.Item>
            </Form>
        </span >
    )
}

const AddressedComuneRaccolte = ({ comune, updateComune }) => {

    const intl = useIntl();

    const [selectedAddress, setSelectedAddress] = useState(null);
    const [createAddressModalVisible, setCreateAddressModalVisible] = useState(false);

    const [deleteGroupedZoneAddress, { loading: deleteGroupedZoneAddressLoading }] = useMutation(GQL_DELETE_GROUPED_ZONE_ADDRESS)

    const [filter, setFilter] = useState({
        zone: null,
        street: null
    });

    const onChangeFilter = (key, value) => {
        setFilter({ ...filter, [key]: value });
    }

    const debouncedStreetFilterCallback = useDebouncedCallback(
        (value) => onChangeFilter('street', value),
        debounceSearchFilterDelay
    );

    const { data, loading, error, refetch, previousData } = useQuery(GQL_COMUNE_GROUPED_ZONES_ADDRESSES, {

        variables: {
            filters: {
                comune_id: comune.id,
                ...filter
            },
        },
    });

    if (loading && !data && !previousData) {
        return <CircularProgress />
    }

    if (error) {
        return <GQLAlertErrors error={error} />;
    }

    const hasZones = comune.zones && comune.zones.length > 0;

    return (
        <div>
            {comune.has_raccolte_addresses ? (
                <Card
                    className="gx-card"
                    title={<IntlMessages id="raccolte.addresses" />}
                    extra={
                        <Space>
                            <Input
                                size="small"
                                placeholder={intl.formatMessage({ id: 'common.search' })}
                                onChange={(e) => {
                                    debouncedStreetFilterCallback(e.target.value);
                                }}
                            />
                            {hasZones && (
                                <Select
                                    size="small"
                                    onChange={(value) => {
                                        if (!value) {
                                            onChangeFilter('zone', null);
                                        }
                                    }}
                                    onSelect={(value) => {
                                        onChangeFilter('zone', value);
                                    }}
                                    key="zone-filter"
                                    className="gx-mb-0 gx-pb-0 gx-mr-2"
                                    placeholder={<IntlMessages id="raccolte.zones" />}
                                    style={{ width: 200 }}
                                    value={filter.zone}
                                >
                                    {comune.zones.map(z => (
                                        <Select.Option value={z}>{z}</Select.Option>
                                    ))}
                                </Select>
                            )}
                            <Button className="gx-mb-0" icon={<PlusOutlined />} size="small" onClick={() => setCreateAddressModalVisible(true)}>
                                <IntlMessages id="common.add" />
                            </Button>
                        </Space>
                    }
                >
                    <Table
                        loading={loading}
                        size="small"
                        rowKey="id"
                        dataSource={data?.groupedZones || []}
                        columns={[
                            {
                                title: <IntlMessages id="raccolte.street" values={{ small: t => <small className="gx-pl-1">{t}</small> }} />,
                                dataIndex: 'street',
                            },
                            {
                                title: <IntlMessages id="raccolte.zones" />,
                                dataIndex: 'zones',
                                align: 'center',
                                render: (zones) => zones.join(', '),
                            },
                            {
                                title: <IntlMessages id="raccolte.street_numbers" />,
                                dataIndex: ['numbers'],
                                align: 'right',
                                render(numbers) {
                                    return numbers.filter(n => Boolean(n.number)).length
                                }
                            },
                            {
                                title: '',
                                dataIndex: 'id',
                                align: 'center',
                                width: 100,
                                render(id, row) {

                                    return (
                                        <Space>
                                            <Button
                                                key="edit"
                                                className="gx-mb-0"
                                                size="small"
                                                icon={<EditOutlined />}
                                                onClick={(e) => {
                                                    setSelectedAddress(row);
                                                }}
                                            />
                                            <Button
                                                key="delete"
                                                className="gx-mb-0"
                                                size="small"
                                                type="danger"
                                                onClick={() => {

                                                    Modal.confirm({
                                                        title: intl.formatMessage({ id: "common.warning" }),
                                                        content: intl.formatMessage({
                                                            id: "services.new_service_delete_confirm_message",
                                                        }),
                                                        okText: intl.formatMessage({ id: "common.delete" }),
                                                        cancelText: intl.formatMessage({ id: "common.no" }),
                                                        okType: "danger",
                                                        okButtonProps: {
                                                            loading: deleteGroupedZoneAddressLoading,
                                                        },
                                                        onOk: () => {
                                                            deleteGroupedZoneAddress({
                                                                variables: {
                                                                    comune_id: comune.id,
                                                                    street_slug: row.id,
                                                                }
                                                            })
                                                                .then(({ errors }) => {
                                                                    if (errors) {
                                                                        message.error(first(errors).message);
                                                                        return;
                                                                    }
                                                                    refetch();
                                                                })
                                                                .catch((e) => {
                                                                    defaultCatchException(e, intl);
                                                                });
                                                        }
                                                    })
                                                }}>
                                                <DeleteOutlined />
                                            </Button>
                                        </Space>
                                    )
                                }
                            }
                        ]}
                    />
                </Card>
            ) : (
                <Card className="gx-card" title={<IntlMessages id="raccolte.raccolte" />}>
                    <UpdateComuneTipiRaccolte comune={comune} updateComune={updateComune} intl={intl} onUpdateSuccess={(() => {
                        refetch();
                    })}/>
                </Card>

            )}
            {selectedAddress &&
                <UpdateAddressedComuneRaccolteModal
                    address={selectedAddress}
                    comune={comune}
                    onCancel={() => setSelectedAddress(null)}
                    refetchGroupedZones={refetch}
                />
            }
            {createAddressModalVisible &&
                <CreateAddressedComuneRaccolteModal
                    comune={comune}
                    onCancel={() => setCreateAddressModalVisible(false)}
                    refetchGroupedZones={refetch}
                />
            }
        </div>
    )
}

const UpdateComuneRaccolte = ({ match }) => {

    const history = useHistory();

    const intl = useIntl();

    const { params } = match;

    const [updateComune] = useMutation(GQL_UPDATE_COMUNE)

    const { data, loading, error, refetch } = useQuery(GQL_COMUNE_RACCOLTA, {
        variables: {
            filters: { id: params.id },
        },
    });

    if (loading) {
        return <CircularProgress />
    }

    if (error) {
        return <GQLAlertErrors error={error} />;
    }

    if (!data.comune) {
        return <Redirect to={getAdminPath('raccolte/comuni')} />
    }

    const { comune } = data;

    const closeHandler = () => {
        history.push(getAdminPath('raccolte/comuni'));
    }

    return (
        <div>

            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#" onClick={() => closeHandler()}>
                            <IntlMessages id="sidebar.raccolte" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#" onClick={() => closeHandler()}>
                            <Link to={getAdminPath('raccolte/comuni')}><IntlMessages id="sidebar.comuni" /></Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#">
                            {comune.name}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col md={24}>
                    <PageHeader
                        onBack={() => closeHandler()}
                        title={comune.name}
                        extra={[
                            <span key="1">
                                <Typography.Text><IntlMessages id="raccolte.handle_raccolte_addresses" /></Typography.Text>&nbsp;
                                <Switch
                                    checked={comune.has_raccolte_addresses}
                                    onChange={(value) => {
                                        updateComune({
                                            variables: {
                                                input: {
                                                    id: comune.id,
                                                    has_raccolte_addresses: value
                                                }
                                            }
                                        })
                                            .then((data) => defaultMutationCallback(data, () => {
                                                refetch()
                                            }))
                                            .catch((e) => defaultCatchException(e, intl));

                                    }} />
                            </span>
                        ]}
                    />
                    
                </Col>

                <Col md={14}>
                    <AddressedComuneRaccolte comune={comune} updateComune={updateComune}/>
                </Col>
                <Col md={10}>
                    <Card className="gx-card" title={<IntlMessages id="raccolte.zones" />}>
                        <Table
                            size="small"
                            dataSource={comune.zones.map(z => ({ name: z }))}
                            columns={[
                                {
                                    title: <IntlMessages id="common.name" />,
                                    dataIndex: 'name',
                                }
                            ]} />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default UpdateComuneRaccolte;