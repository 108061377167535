import React, { useState } from "react";
import { Card, Row, Col, message, PageHeader, Button, Input, Form } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";
import { injectIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { GQL_INIT_FRONTEND } from "../../../apollo/query/user";
import { useMutation, useQuery } from "@apollo/client";
import CircularProgress from "../../../components/CircularProgress/index";
import { getFrontendPath } from "../../../util/router";
import {
   GQL_USER_UPDATE,
   GQL_ASK_VERIFICATION_EMAIL,
   GQL_ASK_VERIFICATION_PHONE_NUMBER,
} from "../../../apollo/mutation/user";
import BadResponseErrorAlert from "../../../components/BadResponseErrorAlert";
import {
   defaultMutationCallback,
   defaultCatchException,
} from "../../../apollo/callbacks";
import {
   ITALY_PHONE_PREFIX,
   PASSWORD_MAX_LENGTH,
   PASSWORD_MIN_LENGTH,
} from "../../../constants/App";
import PhoneNumberVerificationModal from "../../../components/PhoneVerificationModal";

const passwordValidator = (password, confirmPassword, intl) => {
   const pass = password ? password.trim() : "";
   const conf = confirmPassword ? confirmPassword.trim() : "";

   if (pass.length === 0 && conf.length === 0) {
      return Promise.resolve();
   }

   if (pass !== conf) {
      return Promise.reject(
         new Error(
            intl.formatMessage({
               id: "users.new_user_passwords_not_match",
            })
         )
      );
   } else if (pass.length < PASSWORD_MIN_LENGTH) {
      return Promise.reject(
         new Error(
            intl.formatMessage({
               id: "services.min_5_chars",
            })
         )
      );
   } else if (pass.length > PASSWORD_MAX_LENGTH) {
      return Promise.reject(
         new Error(
            intl.formatMessage({
               id: "services.max_50_chars",
            })
         )
      );
   }

   return Promise.resolve();
};

const FrontendSettingsUpdateForm = ({ me, intl, refetch }) => {
   const history = useHistory();

   const [currentEmail, setCurrentEmail] = useState(me.email);
   const [currentPhoneNumber, setCurrentPhoneNumber] = useState(me.phone_number);
   const [showPhoneVerificationModal, setShowPhoneVerificationModal] =
      useState(false);
   const [clientFormErrors, setClientFormErrors] = useState(null);

   const [updateSettings, { loading: loadingMutation }] = useMutation(
      GQL_USER_UPDATE,
      {}
   );

   const [askVerificationEmail, { loading: loadingVerificationEmailMutation }] =
      useMutation(GQL_ASK_VERIFICATION_EMAIL);
   const [
      askVerificationPhoneNumber,
      { loading: loadingVerificationPhoneNumberMutation },
   ] = useMutation(GQL_ASK_VERIFICATION_PHONE_NUMBER);

   const successHandler = () => {
      message.success(intl.formatMessage({ id: "common.default_mutation_success" }));
   };

   const handleOk = (values) => {
      const { first_name, last_name, username, phone_number } = values;

      let params = {
         id: me.id,
         first_name,
         last_name,
         username,
         phone_number: phone_number ? phone_number : null, //do not pass empty string
      };

      if (values.password) {
         params = { ...params, password: values.password };
      }

      if (values.email !== me.email) {
         params = { ...params, email: values.email };
      }

      updateSettings({
         variables: { user: params },
         refetchQueries: [
            {
               query: GQL_INIT_FRONTEND,
            },
         ],
         awaitRefetchQueries: true,
      })
         .then((data) => defaultMutationCallback(data, () => successHandler()))
         .catch((e) => defaultCatchException(e, intl));
   };

   const handleError = (error) => {
      if (error.errorFields && error.errorFields.length > 0) {
         const errorsState = error.errorFields.reduce((acc, err) => {
            acc[err.name[0]] = err.errors[0];
            return acc;
         }, {});

         setClientFormErrors(errorsState);
      }
   };

   const getPhoneValidationStatus = () => {
      if (me.auth.phone_verified) {
         return "success";
      } else if (loadingVerificationPhoneNumberMutation) {
         return "validating";
      } else if (clientFormErrors && clientFormErrors["phone_number"]) {
         return "error";
      } else {
         return "warning";
      }
   };

   const getEmailValidationStatus = () => {
      if (me.auth.verified) {
         return "success";
      } else if (loadingVerificationEmailMutation) {
         return "validating";
      } else if (clientFormErrors && clientFormErrors["email"]) {
         return "error";
      } else {
         return "warning";
      }
   };

   return (
      <>
         <Form
            onFinish={handleOk}
            onFinishFailed={handleError}
            layout="horizontal"
            labelCol={{ span: 10 }}
            initialValues={me}
            autoComplete="off"
         >
            <Row>
               <Col xs={24}>
                  <PageHeader
                     className="gx-page-header"
                     onBack={() => history.goBack()}
                     backIcon={<ArrowLeftOutlined className="gx-arrow-back" />}
                     title={
                        <h2 className="multiline-text">
                           <IntlMessages id="sidebar.settings" />
                        </h2>
                     }
                     extra={[
                        <Link to={getFrontendPath("dashboard")}>
                           <Button key="back" className="gx-text-uppercase">
                              <IntlMessages id="common.home" />
                           </Button>
                        </Link>,
                        <Button
                           htmlType="submit"
                           key="submit"
                           loading={loadingMutation}
                           className="btn-lightblue"
                        >
                           {intl
                              .formatMessage({ id: "common.update" })
                              .toUpperCase()}
                        </Button>,
                     ]}
                  />
               </Col>
               <Col xs={24}>
                  <BadResponseErrorAlert />
               </Col>
               <Col xs={24} md={12}>
                  <Card
                     className="gx-card gx-card-frontend"
                     title={intl.formatMessage({
                        id: "common.personal_data",
                     })}
                  >
                     <Row>
                        <Col xs={24}>
                           <Form.Item
                              label={intl.formatMessage({
                                 id: "common.last_name",
                              })}
                              name="last_name"
                              required
                              rules={[
                                 {
                                    required: true,
                                    message: intl.formatMessage({
                                       id: "services.new_service_required_field",
                                    }),
                                 },
                              ]}
                           >
                              <Input
                                 placeholder={intl.formatMessage({
                                    id: "common.last_name",
                                 })}
                                 maxLength={50}
                                 autoComplete="off"
                                 autoFocus
                              />
                           </Form.Item>
                        </Col>
                        <Col xs={24}>
                           <Form.Item
                              label={intl.formatMessage({
                                 id: "common.first_name",
                              })}
                              name="first_name"
                              required
                              rules={[
                                 {
                                    required: true,
                                    message: intl.formatMessage({
                                       id: "services.new_service_required_field",
                                    }),
                                 },
                              ]}
                           >
                              <Input
                                 placeholder={intl.formatMessage({
                                    id: "common.first_name",
                                 })}
                                 maxLength={50}
                                 autoComplete="off"
                              />
                           </Form.Item>
                        </Col>
                        <Col xs={24}>
                           <Form.Item
                              hasFeedback
                              validateStatus={getEmailValidationStatus()}
                              label={intl.formatMessage({
                                 id: "common.email",
                              })}
                              name="email"
                              required
                              rules={[
                                 {
                                    required: true,
                                    message: intl.formatMessage({
                                       id: "services.new_service_required_field",
                                    }),
                                 },
                              ]}
                              help={
                                 ////l'utente deve prima salvare per poter inviare l'email di conferma nuovamente
                                 !me.auth.verified && me.email === currentEmail ? (
                                    <a
                                       href="/#"
                                       onClick={() => {
                                          askVerificationEmail({
                                             variables: {
                                                email: me.email,
                                                is_front_end: true,
                                             },
                                          })
                                             .then((data) =>
                                                defaultMutationCallback(data, () =>
                                                   message.success(
                                                      intl.formatMessage({
                                                         id: "common.default_mutation_success",
                                                      })
                                                   )
                                                )
                                             )
                                             .catch((e) =>
                                                defaultCatchException(e, intl)
                                             );
                                       }}
                                       type="link"
                                    >
                                       {intl.formatMessage({
                                          id: "signup.registration_resend_confirmation_email",
                                       })}
                                    </a>
                                 ) : clientFormErrors ? (
                                    clientFormErrors["email"]
                                 ) : null
                              }
                           >
                              <Input
                                 onChange={(e) => setCurrentEmail(e.target.value)}
                                 disabled={me.auth.verified}
                                 placeholder={intl.formatMessage({
                                    id: "common.email",
                                 })}
                                 maxLength={50}
                                 autoComplete="new-password"
                              />
                           </Form.Item>
                        </Col>
                        <Col xs={24}>
                           <Form.Item
                              label={intl.formatMessage({
                                 id: "common.fiscal_code",
                              })}
                              name="username"
                              required
                              rules={[
                                 {
                                    required: true,
                                    message: intl.formatMessage({
                                       id: "services.new_service_required_field",
                                    }),
                                 },
                              ]}
                           >
                              <Input
                                 placeholder={intl.formatMessage({
                                    id: "common.fiscal_code",
                                 })}
                                 maxLength={50}
                                 autoComplete="new-password"
                              />
                           </Form.Item>
                        </Col>
                        <Col xs={24}>
                           <Form.Item
                              label={intl.formatMessage({
                                 id: "common.phone_number",
                              })}
                              name="phone_number"
                              rules={[
                                 {
                                    required: false,
                                    message: intl.formatMessage({
                                       id: "services.new_service_required_field",
                                    }),
                                 },
                              ]}
                           >
                              <Input
                                 prefix={ITALY_PHONE_PREFIX}
                                 addonBefore={<div className="gx-flag it"></div>}
                                 onChange={(e) =>
                                    setCurrentPhoneNumber(e.target.value)
                                 }
                                 disabled={!me.auth.can_edit_phone_number}
                                 placeholder={intl.formatMessage({
                                    id: "common.phone_number",
                                 })}
                                 maxLength={50}
                              />
                           </Form.Item>
                        </Col>
                     </Row>
                  </Card>
               </Col>
               <Col xs={24} md={12}>
                  <Card
                     className="gx-card gx-card-frontend"
                     title={intl.formatMessage({
                        id: "settings.change_password",
                     })}
                  >
                     <Row>
                        <Col xs={24}>
                           <Form.Item
                              label={intl.formatMessage({
                                 id: "users.new_user_password",
                              })}
                              name="password"
                              rules={[
                                 ({ getFieldValue }) => ({
                                    validator(_, value) {
                                       return passwordValidator(
                                          value,
                                          getFieldValue("password_confirm"),
                                          intl
                                       );
                                    },
                                 }),
                              ]}
                           >
                              <Input.Password
                                 autoComplete="new-password"
                                 placeholder={intl.formatMessage({
                                    id: "users.new_user_password",
                                 })}
                              />
                           </Form.Item>
                        </Col>
                        <Col xs={24}>
                           <Form.Item
                              label={intl.formatMessage({
                                 id: "users.new_user_password_confirm",
                              })}
                              name="password_confirm"
                              rules={[
                                 ({ getFieldValue }) => ({
                                    validator(_, value) {
                                       return passwordValidator(
                                          getFieldValue("password"),
                                          value,
                                          intl
                                       );
                                    },
                                 }),
                              ]}
                           >
                              <Input.Password
                                 placeholder={intl.formatMessage({
                                    id: "users.new_user_password_confirm",
                                 })}
                              />
                           </Form.Item>
                        </Col>
                     </Row>
                  </Card>
               </Col>
            </Row>
         </Form>
         <PhoneNumberVerificationModal
            visible={showPhoneVerificationModal}
            onCancel={() => setShowPhoneVerificationModal(false)}
            intl={intl}
            refetch={refetch}
         />
      </>
   );
};

const FrontendSettings = ({ intl }) => {
   const { data, loading, refetch } = useQuery(GQL_INIT_FRONTEND, {
      onError: (error) => {
         message.error(intl.formatMessage({ id: "employees.error_generic" }));
      },
   });

   if (loading) {
      return <CircularProgress />;
   }

   const { me } = data;
   return <FrontendSettingsUpdateForm intl={intl} me={me} refetch={refetch} />;
};

export default injectIntl(FrontendSettings);
