import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, message, Form } from "antd";
import { Link, useHistory } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";

import { useMutation, useReactiveVar } from "@apollo/client";
import { globalSettingsVar, showLoadingProgress } from "../apollo/local/cache";
import { GQL_LOGIN } from "../apollo/mutation/user";
import { injectIntl } from "react-intl";
import { localStorageClass } from "../apollo/local/storage";
import { getAdminPath } from "../util/router";

const SignIn = ({ intl }) => {
   const [login] = useMutation(GQL_LOGIN);

   var commonProps = useReactiveVar(globalSettingsVar);
   const [checked, setChecked] = useState(false);
   const history = useHistory();

   var paramRememberMe = { username: "", password: "" };
   const localParam = localStorage.getItem("REMEMBER_ME");
   if (localParam) {
      const jsonObj = JSON.parse(localParam);
      if (jsonObj) {
         paramRememberMe = jsonObj;
      }
   }

   useEffect(() => {
      if (
         !checked &&
         paramRememberMe.username &&
         paramRememberMe.username.length > 0
      ) {
         setChecked(true);
      }
   }, [checked, history, paramRememberMe.username]);

   const rememberMeChecked = (e) => {
      if (!e.target.checked) {
         localStorage.setItem("REMEMBER_ME", null);
      }

      setChecked(e.target.checked);
   };

   const onFinish = (values) => {
      showLoadingProgress(true);
      const params = {
         username: values.email.toLowerCase(),
         password: values.password,
      };
      login({ variables: params })
         .then(({ data }) => {
            if (data && data.login) {
               localStorage.setItem(
                  "REMEMBER_ME",
                  checked ? JSON.stringify(params) : null
               );
               localStorageClass.login(data.login);
               showLoadingProgress(false);

               if (window.location.search) {
                  const queryStringParams = new URLSearchParams(
                     window.location.search
                  );
                  if (queryStringParams.has("redirect")) {
                     history.push(queryStringParams.get("redirect"));
                  } else {
                     history.push(getAdminPath("dashboard"));
                  }
               } else {
                  history.push(getAdminPath("dashboard"));
               }

               //
            } else {
               message.error(intl.formatMessage({ id: "login.signInError" }));
               showLoadingProgress(false);
            }
         })
         .catch((e) => {
            message.error(intl.formatMessage({ id: "login.signInError" }));
            showLoadingProgress(false);
         });
   };

   var inputRef = React.createRef();
   useEffect(() => {
      inputRef.current.focus();
   }, [inputRef]);

   return (
      <div className="gx-app-login-wrap">
         <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
               <div className="gx-app-logo-content">
                  <div className="gx-app-logo-content-bg"></div>
                  <div className="gx-app-logo-wid">
                     <h1>
                        <IntlMessages id="login.signIn" />
                     </h1>
                  </div>
                  <div className="gx-app-logo">
                     <img alt="" src="/assets/images/savnobook_logo_blue2.svg" />
                  </div>
               </div>
               <div className="gx-app-login-content">
                  <Form
                     initialValues={{ remember: true }}
                     name="basic"
                     onFinish={onFinish}
                     autoComplete="on"
                     className="gx-signin-form gx-form-row0"
                  >
                     <Form.Item
                        initialValue={paramRememberMe.username}
                        rules={[
                           {
                              required: true,
                              message: intl.formatMessage({
                                 id: "validation.required",
                              }),
                           },
                        ]}
                        name="email"
                     >
                        <Input
                           placeholder={intl.formatMessage({
                              id: "common.fiscal_code",
                           })}
                           type="text"
                           ref={inputRef}
                           style={{
                              textTransform: "uppercase",
                           }}
                        />
                     </Form.Item>
                     <Form.Item
                        initialValue={paramRememberMe.password}
                        rules={[
                           {
                              required: true,
                              message: intl.formatMessage({
                                 id: "validation.required",
                              }),
                           },
                        ]}
                        name="password"
                     >
                        <Input.Password
                           type="password"
                           placeholder={intl.formatMessage({
                              id: "login.password",
                           })}
                           style={{ backgroundColor: "#f5f5f5" }}
                           autoComplete="on"
                        />
                     </Form.Item>
                     <Form.Item name="ckRememberMe">
                        <Checkbox checked={checked} onChange={rememberMeChecked}>
                           Ricordami
                        </Checkbox>
                     </Form.Item>
                     <Link
                        className="gx-login-form-forgot"
                        to={getAdminPath("forget-password")}
                     >
                        <span>
                           <IntlMessages id="login.forgot_password" />
                        </span>
                     </Link>
                     <Form.Item>
                        <Button
                           type="primary"
                           className="gx-mb-0"
                           htmlType="submit"
                           name="buttonSubmit"
                        >
                           <IntlMessages id="login.signIn" />
                        </Button>

                        {/* <span>
                           <IntlMessages id="common.o" />
                        </span>{" "}
                        <Link to={getAdminPath("signup")}>
                           <IntlMessages id="registration.signup" />
                        </Link> */}
                     </Form.Item>
                  </Form>
               </div>

               {commonProps.loading ? (
                  <div className="gx-loader-view">
                     <CircularProgress />
                  </div>
               ) : null}
            </div>
         </div>
      </div>
   );
};

export default injectIntl(SignIn);
