import { InfoCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Modal, Input, Form, Button, message } from "antd";
import React from "react";
import { GQL_CATEGORY_CREATE } from "../../../../apollo/mutation/reuse-board";
import IntlMessages from "../../../../util/IntlMessages";
import {
    defaultCatchException,
    defaultMutationCallback
} from "../../../../apollo/callbacks";
import { injectIntl } from "react-intl";
import BadResponseErrorAlert from "../../../../components/BadResponseErrorAlert";

const InsertCategoryModal = ({ intl, state, onCancel, onSuccess }) => {

    const [form] = Form.useForm();

    const [insertCategory, { loading }] = useMutation(
        GQL_CATEGORY_CREATE
    );

    return (
        <Modal
            visible={state.visible}
            icon={<InfoCircleOutlined />}
            onCancel={onCancel}
            footer={null}
            title={state.parent_id ? <span><IntlMessages id="categories.new_child" values={{ name: state.parent_name }} /></span> : <span><IntlMessages id="categories.new_root" /></span>}
        >
            <Form
                form={form}
                onFinish={(values) => {
                    insertCategory({
                        variables: {
                            input: {
                                name: values.name,
                                parent_id: state.parent_id,
                            }
                        }
                    })
                        .then((data) => {
                            defaultMutationCallback(data, () => {
                                if (data.data?.createProductCategory) {
                                    form.resetFields();
                                    onSuccess()
                                } else {
                                    message.error(intl.formatMessage({ id: "common.default_mutation_error" }));
                                }
                            });
                        })
                        .catch((e) => defaultCatchException(e, intl));
                }}
                layout="vertical"
                autoComplete="off"
            >

                <BadResponseErrorAlert />

                <Form.Item
                    hasFeedback
                    label={intl.formatMessage({ id: "common.name" })}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({ id: "validation.required" }),
                        },
                    ]}
                    name="name"
                >
                    <Input
                        maxLength={127}
                        placeholder={intl.formatMessage({
                            id: "common.name",
                        })}
                    />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label={intl.formatMessage({ id: "common.description" })}
                    name="description"
                >
                    <Input.TextArea
                        maxLength={127}
                        placeholder={intl.formatMessage({
                            id: "common.description",
                        })}
                    />
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {intl.formatMessage({ id: "common.submit" })}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default injectIntl(InsertCategoryModal);
