import { fetch } from "whatwg-fetch";
import { entries } from "lodash";

export function uploadFileToPresignedURL(file, presignedPost) {
   const formData = new FormData();
   formData.append("Content-Type", file.type);

   entries(presignedPost.fields).forEach(([key, value]) => {
      formData.append(key, value);
   });

   formData.append("file", file);

   return fetch(presignedPost.url, {
      method: "POST",
      body: formData,
   })
      .then((res) => {
         const location = res.headers.get("Location");
         return decodeURIComponent(location);
      })
      .catch((error) => {
         throw error;
      });
}
