import React from 'react';
import { PageHeader, Row, Col, Breadcrumb, Button, Tree, Card, message, Empty, Form, Input, Skeleton, Alert, Space, Modal } from 'antd';
import { ReuseBoardBreadcrumbs } from '../products/common';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import IntlMessages from '../../../../util/IntlMessages';
import { Can } from "acl/index";
import { GQL_CATEGORIES_TREE, GQL_PRODUCT_CATEGORY } from '../../../../apollo/query/reuse-board';
import { injectIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import InsertCategoryModal from './insert-category-modal';
import BadResponseErrorAlert from '../../../../components/BadResponseErrorAlert';
import { GQL_CATEGORY_DELETE, GQL_CATEGORY_UPDATE } from '../../../../apollo/mutation/reuse-board';
import GQLAlertErrors from '../../../../components/GQLAlertErrors';
import { treeViewAdapter } from '../../../../util/antd';
import { defaultCatchException, defaultMutationCallback } from '../../../../apollo/callbacks';

const useCategories = ({ intl }) => {

    const { data, loading, previousData, error, refetch } = useQuery(GQL_CATEGORIES_TREE, {
        variables: {},
        onError: (error) => {
            message.error(intl.formatMessage({ id: "common.default_query_error" }));
        },
    });


    let categories = [];

    if (data) {
        categories = data.productCategories;
    } else if (previousData) {
        categories = previousData.productCategories;
    }

    return { categories: treeViewAdapter(categories), loading, error, refetch };
}

const SelectedCategory = ({ intl, id, setInsertCategoryModalState, onSubmitSuccess }) => {

    const [form] = Form.useForm();

    const [updateCategory, { loading: loadingUpdateMutation }] = useMutation(
        GQL_CATEGORY_UPDATE
    );

    const [deleteCategory, { loading: loadingDeleteMutation }] = useMutation(
        GQL_CATEGORY_DELETE
    )

    const { data, loading, error } = useQuery(GQL_PRODUCT_CATEGORY, {
        variables: {
            id: id
        },
        fetchPolicy: "no-cache",
    });

    if (error) {
        return <GQLAlertErrors error={error} />
    }

    if (loading) {
        return <Skeleton active />
    }

    const { productCategory } = data;

    return (
        <Card
            title={<IntlMessages id="categories.selected_category" />}
            className="gx-card"
            extra={
                <Space>
                    {productCategory.is_leaf ?
                        <Can I="delete" a="ProductCategory" key="1">
                            <Button
                                size="small"
                                icon={<DeleteOutlined className="gx-pr-1" />}
                                type="danger"
                                onClick={() => {
                                    Modal.confirm({
                                        title: intl.formatMessage({ id: "common.delete" }),
                                        content: intl.formatMessage({ id: "categories.ask_delete_category" }, { name: productCategory.name }),
                                        okButtonProps: { loading: loadingDeleteMutation },
                                        onOk: () => {
                                            deleteCategory({
                                                variables: {
                                                    id: id
                                                }
                                            })
                                                .then((data) => {
                                                    defaultMutationCallback(data, () => {
                                                        if (data.data?.deleteProductCategory) {
                                                            message.success(intl.formatMessage({ id: "common.default_mutation_success" }));
                                                            onSubmitSuccess();
                                                        } else {
                                                            message.error(intl.formatMessage({ id: "common.default_mutation_error" }));
                                                        }
                                                    });
                                                })
                                                .catch((e) => defaultCatchException(e, intl));
                                        }
                                    })
                                }}

                            >
                                <IntlMessages id="common.delete" />
                            </Button>
                        </Can>
                        : null}
                    {!productCategory.is_last_level ?
                        <Can I="create" a="ProductCategory" key="2">
                            <Button
                                size="small"
                                className="circle-add-button"
                                onClick={() => {
                                    setInsertCategoryModalState({
                                        visible: true,
                                        parent_id: id,
                                        parent_name: productCategory.name,
                                    })
                                }}
                                icon={<PlusOutlined />}
                            >
                                <IntlMessages id="categories.new_sub_category" />
                            </Button>
                        </Can>
                        : null
                    }
                </Space>}
        >
            <Form
                form={form}
                initialValues={productCategory}
                onFinish={(values) => {
                    updateCategory({
                        variables: {
                            input: {
                                id: id,
                                ...values
                            }
                        }
                    })
                        .then((data) => {
                            defaultMutationCallback(data, () => {
                                if (data.data?.updateProductCategory) {
                                    form.resetFields();
                                    message.success(intl.formatMessage({ id: "common.default_mutation_success" }));
                                    onSubmitSuccess();
                                } else {
                                    message.error(intl.formatMessage({ id: "common.default_mutation_error" }));
                                }
                            });
                        })
                        .catch((e) => defaultCatchException(e, intl));
                }}
                layout="vertical"
                autoComplete="off"
            >
                <BadResponseErrorAlert />

                <Alert
                    type='info'
                    message={intl.formatMessage(
                        { id: "categories.products_count" },
                        { count: productCategory.metadata?.products_count || 0 }
                    )}
                    showIcon
                />

                <Form.Item
                    hasFeedback
                    label={intl.formatMessage({ id: "common.name" })}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({ id: "validation.required" }),
                        },
                    ]}
                    name="name"
                >
                    <Input
                        maxLength={127}
                        placeholder={intl.formatMessage({
                            id: "common.name",
                        })}
                    />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label={intl.formatMessage({ id: "common.description" })}
                    name="description"
                >
                    <Input.TextArea
                        maxLength={127}
                        placeholder={intl.formatMessage({
                            id: "common.description",
                        })}
                    />
                </Form.Item>
                <Form.Item>
                    <Button loading={loadingUpdateMutation} type="primary" htmlType="submit">
                        {intl.formatMessage({ id: "common.submit" })}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

const Categories = ({ intl }) => {

    const [selectedCategory, setSelectedCategory] = React.useState(null);

    const [insertCategoryModalState, setInsertCategoryModalState] = React.useState({
        visible: false,
    });

    const { categories, loading, error, refetch } = useCategories({ intl });

    const onSelectNode = (selectedKeys, info) => {
        setSelectedCategory(selectedKeys.length > 0 ? selectedKeys[0] : null);
    }

    return (

        <>
            <Row>
                <Col md={24}>
                    <ReuseBoardBreadcrumbs>
                        <Breadcrumb.Item href="#">
                            <IntlMessages id="sidebar.products_categories" />
                        </Breadcrumb.Item>
                    </ReuseBoardBreadcrumbs>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={<IntlMessages id="sidebar.products_categories" />}
                        extra={[
                            <Can I="create" a="ProductCategory" key="1">
                                <Button
                                    type="primary"
                                    className="circle-add-button"
                                    onClick={() => {
                                        setInsertCategoryModalState({
                                            visible: true,
                                            parent_id: null,
                                            parent_name: null,
                                        })
                                    }}
                                >
                                    <PlusOutlined /> <IntlMessages id="categories.new_root_category" />
                                </Button>
                            </Can>,
                        ]}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <Card loading={loading}
                        title={<IntlMessages id="common.tree_view" />}
                        className="gx-card">
                        {categories && categories.length > 0 ?
                            <Tree
                                titleRender={(category) => {
                                    return (
                                        <div>{category.title}
                                            {!category.isLeaf &&
                                                <span className="gx-pl-5">
                                                    <PlusOutlined onClick={(e) => {
                                                        e.stopPropagation();
                                                        setInsertCategoryModalState({
                                                            visible: true,
                                                            parent_id: category.key,
                                                            parent_name: category.title,
                                                        })
                                                    }} />
                                                </span>
                                            }
                                        </div>
                                    )
                                }}
                                onSelect={onSelectNode}
                                treeData={categories}
                                showIcon={true}
                                showLine={true}
                                defaultExpandAll={true}
                            />
                            :
                            <Empty />
                        }
                    </Card>
                </Col>

                <Col xs={24} md={12}>
                    {selectedCategory ?
                        <SelectedCategory
                            key={selectedCategory}
                            id={selectedCategory}
                            setInsertCategoryModalState={setInsertCategoryModalState}
                            intl={intl}
                            onSubmitSuccess={() => {
                                setSelectedCategory(null);
                                refetch();
                            }}
                        />
                        : (
                            <Card><Empty /></Card>
                        )
                    }
                </Col>
            </Row>

            <InsertCategoryModal
                onCancel={() => {
                    setInsertCategoryModalState({
                        visible: false,
                    });
                }}
                onSuccess={() => {
                    setInsertCategoryModalState({
                        visible: false,
                    });
                    refetch()
                }}
                state={insertCategoryModalState}
            />
        </>
    );
}

export default injectIntl(Categories);