import { gql } from "@apollo/client";

export const GQL_WORKSPACES_INSERT = gql`
   mutation createWorkspace($workspace: CreateWorkspaceInput!) {
      createWorkspace(workspace: $workspace)
   }
`;

export const GQL_WORKSPACES_UPDATE = gql`
   mutation updateWorkspace($id: String!, $workspace: UpdateWorkspaceInput!) {
      updateWorkspace(id: $id, workspace: $workspace)
   }
`;

export const GQL_WORKSPACE_DELETE = gql`
   mutation deleteWorkspace($id: String!) {
      deleteWorkspace(id: $id)
   }
`;

export const GQL_WORKSPACE_CHANGE = gql`
   mutation changeWorkspace($id: String!) {
      changeWorkspace(id: $id) {
         current_workspace_id
         role
         ability {
            action
            subject
            fields
            conditions
         }
      }
   }
`;

export const GQL_WORKSPACE_UPDATE_FRONTOFFICES_AVAILABILITIES = gql`
   mutation updateWorkspaceFrontOfficesAvailabilities(
      $id: String!
      $availabilities: UpdateWorkspaceAvailabilitiesInput!
   ) {
      updateWorkspaceFrontOfficesAvailabilities(
         id: $id
         availabilities: $availabilities
      )
   }
`;
