import { createHttpLink, ApolloLink, ApolloClient, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import apolloCache from "./cache";
import { localStorageClass } from "./local/storage";
import { GQL_REFRESH_TOKEN } from "./mutation/user";
import { get } from "lodash";

const httpLink = createHttpLink({
   uri: process.env.REACT_APP_APOLLO_SERVER_URL,
   credentials: "same-origin",
   headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
         "Origin, X-Requested-With, Content-Type, Accept",
   },
});

async function getNewToken() {
   const response = await fetch(process.env.REACT_APP_APOLLO_SERVER_URL, {
      method: "POST",
      headers: {
         "Content-Type": "application/json",
         "BKL-ORGANIZATION-URL": window.location.origin,
      },
      body: JSON.stringify({
         query: GQL_REFRESH_TOKEN,
         variables: { token: localStorageClass.getRefreshToken() },
      }),
   });

   return await response.json();
}

// add the authorization to the headers
const authMiddleware = new ApolloLink(async (operation, forward) => {
   if (!localStorageClass.isLogged()) {
      return forward(operation);
   }

   const currentTS =
      Math.round(new Date().getTime() / 1000) +
      parseInt(process.env.REACT_APP_EXPIRATION_TOKEN_SPAN_SECONDS);
   const expiresAt = localStorageClass.getField("expires_at");

   if (currentTS < expiresAt) {
      operation.setContext({
         headers: {
            authorization: `Bearer ${localStorageClass.getToken()}`,
         },
      });
      return forward(operation);
   } else {
      //Refresh token
      const json = await getNewToken();
      const newToken = get(json.data, "refreshToken.token", "");
      const expiresAt = get(json.data, "refreshToken.expires_at", currentTS);
      localStorageClass.setToken(newToken, expiresAt);

      operation.setContext({
         headers: {
            authorization: `Bearer ${newToken}`,
         },
      });

      return forward(operation);
   }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
   if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
         if (error.extensions && error.extensions.response) {
            if (error.extensions.response.statusCode === 401) {
               if (localStorageClass.isLogged()) {
                  localStorageClass.logout();
                  return;
               }
            }
         }
      });
   }

   if (networkError) {
      console.log(`[Network error]: ${networkError}`);
   }
});

const apolloClient = new ApolloClient({
   cache: apolloCache,
   connectToDevTools: true,
   defaultOptions: {
      watchQuery: {
         fetchPolicy: "cache-and-network",
         errorPolicy: "all",
      },
      query: {
         errorPolicy: "all",
      },
      mutate: {
         errorPolicy: "all",
      },
   },
   link: from([authMiddleware, errorLink, httpLink]),
});

export default apolloClient;
