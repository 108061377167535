import React from "react";
import { Select } from "antd";
import { useIntl } from "react-intl";
import moment from "moment";

const ConditionalDateInputFilter = ({
   placeholder,
   defaultValue,
   onChange,
   ...rest
}) => {
   const intl = useIntl();

   const items = [
      {
         id: 1,
         label: intl.formatMessage(
            {
               id: "reuse_board.product_inactive_last_n_days",
            },
            { days: 60 }
         ),
         condition: "$lt",
         value: moment().subtract(60, "days").startOf("day").toDate(),
      },
   ];

   const selectChange = (value) =>
      onChange(
         items.find((item) => item.id === value && item.condition && item.value)
      );

   return (
      <Select
         allowClear
         placeholder={placeholder}
         onChange={selectChange}
         style={{ width: 300 }}
         {...rest}
         defaultValue={items.find(
            (item) =>
               item.condition === defaultValue?.condition &&
               item.value === defaultValue?.value
         )}
      >
         {items.map((item, idx) => (
            <Select.Option key={`cdf-input-${idx}`} value={item.id}>
               {item.label}
            </Select.Option>
         ))}
      </Select>
   );
};

export default ConditionalDateInputFilter;
