import React from "react";
import { Switch, Route } from "react-router-dom";
import { getAdminPath } from "../../../util/router";
import RaccolteComuni from "./comuni";
import UpdateComuneRaccolte from "./comuni/update";
import RaccoltePortaAPorta from "./porta-a-porta";

const Raccolte = () => {
   return (
      <Switch>
         <Route
            exact
            path={getAdminPath("raccolte/comuni")}
            component={RaccolteComuni}
         />
         <Route
            path={getAdminPath("raccolte/comuni/update/:id")}
            component={UpdateComuneRaccolte}
         />
         <Route
            exact
            path={getAdminPath("raccolte/porta-a-porta")}
            component={RaccoltePortaAPorta}
         />
      </Switch>
   );
};

export default Raccolte;
