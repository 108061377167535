import React, { useState } from "react";
import { Button, Input, Form, Result, message } from "antd";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";

import { useMutation, useReactiveVar } from "@apollo/client";
import { globalSettingsVar } from "../apollo/local/cache";
import { GQL_RESET_PASSWORD } from "../apollo/mutation/user";
import { injectIntl } from "react-intl";
import { getAdminPath } from "../util/router";

const ResetPassword = ({ intl }) => {
   var commonProps = useReactiveVar(globalSettingsVar);
   const history = useHistory();
   const match = useRouteMatch();
   const [success, setSuccess] = useState(false);
   const [loadingMutation, setLoadingMutation] = useState(false);
   const [resetPassword] = useMutation(GQL_RESET_PASSWORD);

   if (!match.params || !match.params.tokenId) {
      history.push("/");
      return null;
   }

   const onFinish = (values) => {
      setLoadingMutation(true);

      const params = { password: values.password1, token: match.params.tokenId };

      resetPassword({ variables: params })
         .then(({ data }) => {
            setLoadingMutation(false);

            if (data && data.resetPassword) {
               setSuccess(true);
            } else {
               message.error(
                  intl.formatMessage({
                     id: "login.reset_password_error_message",
                  })
               );
            }
         })
         .catch((e) => {
            setLoadingMutation(false);
            message.error(
               intl.formatMessage({ id: "login.reset_password_error_message" })
            );
         });
   };

   if (!success) {
      return (
         <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
               <div className="gx-app-login-main-content">
                  <div className="gx-app-logo-content">
                     <div className="gx-app-logo-content-bg"></div>
                     <div className="gx-app-logo-wid">
                        <h1>
                           <IntlMessages id="registration.reset_password" />
                        </h1>
                     </div>
                     <div className="gx-app-logo">
                        <img alt="" src="/assets/images/savnobook_logo_blue2.svg" />
                     </div>
                  </div>
                  <div className="gx-app-login-content">
                     <Form
                        initialValues={{ remember: true }}
                        name="basic"
                        onFinish={onFinish}
                        autoComplete="off"
                        className="gx-signin-form gx-form-row0"
                     >
                        <Form.Item
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "services.new_service_required_field",
                                 }),
                              },
                              {
                                 min: 5,
                                 message: intl.formatMessage({
                                    id: "services.min_5_chars",
                                 }),
                              },
                              {
                                 max: 50,
                                 message: intl.formatMessage({
                                    id: "services.max_50_chars",
                                 }),
                              },
                           ]}
                           name="password1"
                        >
                           <Input.Password
                              placeholder={intl.formatMessage({
                                 id: "registration.new_password",
                              })}
                           />
                        </Form.Item>
                        <Form.Item
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "services.new_service_required_field",
                                 }),
                              },
                              {
                                 min: 5,
                                 message: intl.formatMessage({
                                    id: "services.min_5_chars",
                                 }),
                              },
                              {
                                 max: 50,
                                 message: intl.formatMessage({
                                    id: "services.max_50_chars",
                                 }),
                              },
                              ({ getFieldValue }) => ({
                                 validator(_, value) {
                                    if (
                                       !value ||
                                       getFieldValue("password1") === value
                                    ) {
                                       return Promise.resolve();
                                    }
                                    return Promise.reject(
                                       new Error(
                                          intl.formatMessage({
                                             id: "services.password_not_equals",
                                          })
                                       )
                                    );
                                 },
                              }),
                           ]}
                           name="password2"
                        >
                           <Input.Password
                              placeholder={intl.formatMessage({
                                 id: "registration.new_password_confirm",
                              })}
                           />
                        </Form.Item>
                        <Form.Item>
                           <Button
                              type="primary"
                              className="gx-mb-0"
                              htmlType="submit"
                              name="buttonSubmit"
                              loading={loadingMutation}
                           >
                              <IntlMessages id="registration.reset_password" />
                           </Button>
                           <span>
                              <IntlMessages id="common.o" />
                           </span>{" "}
                           <Link to={getAdminPath("signin")}>
                              <IntlMessages id="login.signIn" />
                           </Link>
                        </Form.Item>
                     </Form>
                  </div>

                  {commonProps.loading ? (
                     <div className="gx-loader-view">
                        <CircularProgress />
                     </div>
                  ) : null}
               </div>
            </div>
         </div>
      );
   } else {
      return (
         <div className="gx-app-login-wrap">
            <Result
               key="1"
               status="success"
               title={<IntlMessages id="login.reset_password_success_title" />}
               subTitle={<IntlMessages id="login.reset_password_success_message" />}
               extra={[
                  <Link to={getAdminPath("signin")} key="1">
                     <Button key="login">
                        <IntlMessages id="login.signIn" />
                     </Button>
                  </Link>,
               ]}
            />
         </div>
      );
   }
};

export default injectIntl(ResetPassword);
