import React, { useState } from "react";
import { Alert, Button, Col, Result, Row, Select } from "antd";
import { get } from "lodash";
import { injectIntl } from "react-intl";
import IntlMessages from "../../util/IntlMessages";
import { useMutation } from "@apollo/client";
import { GQL_WORKSPACE_CHANGE } from "../../apollo/mutation/workspaces";
import { GQL_INIT_QUERY } from "../../apollo/query/user";
import { getAdminPath } from "../../util/router";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Can } from "../../acl/index";

const CurrentWorkspaceResult = ({ me, intl }) => {
   const [currentWorkspaceId, setCurrentWorkspaceId] = useState(null);
   const [switchError, setSwitchError] = useState(null);

   const [switchCurrentWorkspace, { loadingMutation }] = useMutation(
      GQL_WORKSPACE_CHANGE,
      {
         refetchQueries: [{ query: GQL_INIT_QUERY }],
      }
   );

   const workspaces = get(me, "current_organization.workspaces", []);

   const setDefaultWorkspaceTapped = () => {
      switchCurrentWorkspace({ variables: { id: currentWorkspaceId } })
         .then(({ data }) => setSwitchError(null))
         .catch((e) => setSwitchError(e));
   };

   return (
      <Row>
         <Col xs={24}>
            {workspaces.length > 0 ? (
               <Result
                  status="warning"
                  title={<IntlMessages id="workspaces.no_workspace_selected" />}
                  subTitle={
                     <Select
                        key="select-workspace"
                        className="gx-mt-3"
                        value={currentWorkspaceId}
                        size="middle"
                        onChange={(value) => setCurrentWorkspaceId(value)}
                        style={{
                           width: "70%",
                           maxWidth: "400px",
                           marginBottom: "10px",
                           textAlign: "center",
                           backgroundColor: "#00ff00 !important",
                        }}
                     >
                        {(workspaces || []).map((workspace, idx) => {
                           return (
                              <Select.Option
                                 key={`workspace-select-idx-${idx}`}
                                 value={workspace.id}
                              >
                                 {workspace.name}
                              </Select.Option>
                           );
                        })}
                     </Select>
                  }
                  extra={[
                     <Button
                        type="primary"
                        key="console"
                        disabled={currentWorkspaceId == null}
                        loading={loadingMutation}
                        onClick={setDefaultWorkspaceTapped}
                     >
                        <IntlMessages id="workspaces.select_current_workspace" />
                     </Button>,
                  ]}
               />
            ) : (
               <Result
                  status="404"
                  title={<IntlMessages id="workspaces.no_workspaces_available" />}
                  subTitle={
                     <IntlMessages id="workspaces.no_workspaces_available_p1" />
                  }
                  extra={
                     <Can I="manage" a="Workspace">
                        <Link
                           className="ant-btn ant-btn-primary"
                           to={getAdminPath("workspaces/insert")}
                        >
                           <IntlMessages id="workspaces.new_workspace_title_insert" />
                        </Link>
                     </Can>
                  }
               />
            )}
            {switchError && <Alert message={switchError.message} type="error" />}
         </Col>
      </Row>
   );
};

export default injectIntl(CurrentWorkspaceResult);
