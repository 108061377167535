import { gql } from "@apollo/client";

export const GQL_ASSETS_UPLOAD_AUTHORIZATION = gql`
   mutation uploadAuthorization($params: UploadAuthorizationInput!) {
      uploadAuthorization(params: $params)
   }
`;

export const GQL_ASSET_CREATE = gql`
   mutation createAsset($args: CreateAssetInput!) {
      createAsset(args: $args)
   }
`;

export const GQL_ASSET_DELETE = gql`
   mutation deleteAsset($id: String!) {
      deleteAsset(id: $id)
   }
`;
