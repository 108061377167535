import { gql } from "@apollo/client";

export const GQL_PRIVACY_POLICY = gql`
   query privacyPolicy {
      privacyPolicy {
         text
      }
   }
`;

export const GQL_ADMIN_PRIVACY_POLICY = gql`
   query adminPrivacyPolicy {
      adminPrivacyPolicy {
         text
      }
   }
`;
