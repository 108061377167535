import config from "./config";
import { lazy } from "react";

const { ADMIN_ROUTE_PREFIX, FRONTEND_ROUTE_PREFIX } = require("../constants/App");

export const getAdminPath = (relativePath, queryObject = null) => {
   let domain = ""; //relative
  
   if (window.location.origin !== config.adminURL) {
      domain = config.adminURL; //absolute
   }

   const queryString = queryObject
      ? `?${new URLSearchParams(queryObject).toString()}`
      : "";

   return `${domain}/${ADMIN_ROUTE_PREFIX}${
      relativePath ? `/${relativePath}` : ""
   }${queryString}`;
};

export const getFrontendPath = (relativePath = null) => {
   if (process.env.NODE_ENV === "development") {
      return `/${FRONTEND_ROUTE_PREFIX}${relativePath ? `/${relativePath}` : ""}`;
   }

   //TODO: Organization base url instead of FRONTEND_ROUTE_PREFIX
   return `${relativePath ? `/${relativePath}` : "/"}`;
};

export const pushNextOrFallback = (history, fallback, nextQueryStringObj = null) => {
   if (history.location.search) {
      const qParams = new URLSearchParams(history.location.search);
      let nextURL = qParams.get("next");

      if (nextURL) {
         if (nextQueryStringObj) {
            const nextFullURL = `${process.env.REACT_APP_ADMIN_URL}${nextURL}`;
            const nextURLObj = new URL(nextFullURL);
            const search = new URLSearchParams(nextURLObj.search);
            Object.keys(nextQueryStringObj).forEach((key) => {
               search.set(key, nextQueryStringObj[key]);
            });
            nextURL = `${nextURLObj.pathname}?${search.toString()}`;            
         }

         history.push(nextURL);
         return;
      }
   }

   if (fallback) {
      history.push(fallback);
   } else {
      history.push("/");
   }
};

/**
 * vedi https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
 * https://www.codemzy.com/blog/fix-chunkloaderror-react
 * note:
 * If you need to do multiple module imports on a single route/page and one is successful and the other isn't, the successful one could keep resetting the sessionStorage. It would create that endless refresh loop we talked about earlier.
 * In that situation, I would pass a value as a name to the lazyRetry function to identify the module.
 * @param {*} componentImport
 * @returns
 */
export const lazyWithRetry = (componentImport, name = "") => {
   return lazy(async () => {
      const key = `page${name}-has-been-force-refreshed`;

      const pageHasAlreadyBeenForceRefreshed = JSON.parse(
         window.localStorage.getItem(key) || "false"
      );

      try {
         const component = await componentImport();

         window.localStorage.setItem(key, "false");

         return component;
      } catch (error) {
         if (!pageHasAlreadyBeenForceRefreshed) {
            // Assuming that the user is not on the latest version of the application.
            // Let's refresh the page immediately.
            window.localStorage.setItem(key, "true");
            return window.location.reload();
         }

         // The page has already been reloaded
         // Assuming that user is already using the latest version of the application.
         // Let's let the application crash and raise the error.
         throw error;
      }
   });
};
