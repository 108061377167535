import React from "react";
import { Descriptions } from "antd";
import { reservationDateToString } from "../../util/date";
import { Card } from "antd";
import { WorkspaceEntity } from "../../entities/workspace";
import IntlMessages from "../../util/IntlMessages";

const ReservationSummaryCard = ({
   intl,
   customer,
   workspace,
   serviceCollection,
   availability,
   className,
   note,
}) => {
   const workspaceEntity = WorkspaceEntity(workspace);

   return (
      <Card
         title={
            <h3 className="uppercase gx-mb-0">
               <IntlMessages id="reservations.info" />
            </h3>
         }
         className={`${className} card-step`}
      >
         <Descriptions column={1}>
            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({ id: "common.nominativo" })}
                  </h4>
               }
            >
               <p className="gx-mb-0">{customer.full_name}</p>
            </Descriptions.Item>
            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({ id: "frontend.sportello_riferimento" })}
                  </h4>
               }
            >
               <p className="gx-mb-0">
                  {workspaceEntity.isNotNull() ? (
                     `${workspaceEntity.getName()} ${workspaceEntity.getFormattedAddress()}`
                  ) : (
                     <IntlMessages id="common.nd" />
                  )}
               </p>
            </Descriptions.Item>
            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({ id: "services.services" })}
                  </h4>
               }
            >
               <p className="gx-mb-0">{serviceCollection.getNames()}</p>
            </Descriptions.Item>

            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({
                        id: "frontend.data_ora_prenotazione",
                     })}
                  </h4>
               }
            >
               <p className="gx-mb-0">
                  {reservationDateToString(availability, intl)}
               </p>
            </Descriptions.Item>

            {note && note.length > 0 && (
               <Descriptions.Item
                  label={
                     <h4 className="gx-mb-0">
                        {intl.formatMessage({
                           id: "common.note",
                        })}
                     </h4>
                  }
               >
                  <p className="gx-mb-0">{note}</p>
               </Descriptions.Item>
            )}
         </Descriptions>
      </Card>
   );
};

export default ReservationSummaryCard;
