import React, { useEffect, useRef, useState } from "react";
import InputSearch from "./InputSearch";
import { Popover, message } from "antd";
import ResultList from "./ResultList";
import { useApolloClient } from "@apollo/client";
import { GQL_TOP_BAR_SEARCH } from "../../../apollo/query/customer";
import { useDebouncedCallback } from "use-debounce/lib";
import { debounceSearchFilterDelay } from "../../../util/config";
import { get } from "lodash";
import { getAdminPath } from "../../../util/router";
import { useHistory } from "react-router-dom";
import { MIN_SEARCH_LENGTH, SearchResultType } from "./constants";

function showContentList(term) {
   return term && term.length >= MIN_SEARCH_LENGTH;
}

const TopbarSearchContainer = () => {
   const client = useApolloClient();
   const [loading, setLoading] = useState(false);
   const [term, setTerm] = useState("");
   const [inputFocus, setInputFocus] = useState(false);
   const [results, setResults] = useState(null);
   const history = useHistory();
   let blurTimer = useRef();

   const fetchData = (value) => {
      setLoading(true);

      client
         .query({
            query: GQL_TOP_BAR_SEARCH,
            variables: {
               term: value,
            },
            fetchPolicy: "no-cache",
         })
         .then((res) => setResults(get(res, "data.globalSearch", [])))
         .catch((err) => {
            setResults(null);
            message.error(err.message);
         })
         .finally(() => setLoading(false));
   };

   const debouncedFilterCallback = useDebouncedCallback(
      (value) => fetchData(value),
      debounceSearchFilterDelay
   );

   useEffect(() => {
      if (showContentList(term)) {
         debouncedFilterCallback(term);
      } else {
         //Elimina lo sfarfallio della popover
         setTimeout(() => {
            setResults(null);
         }, 200);
      }
   }, [term]);

   useEffect(() => {
      return () => {
         if (blurTimer) {
            clearTimeout(blurTimer);
         }
      };
   }, []);

   const showPopover =
      inputFocus && showContentList(term) === true && results != null;

   return (
      <Popover
         placement="topLeft"
         content={
            <ResultList
               term={term}
               results={results}
               onSelect={(record) => {
                  switch (record.type) {
                     case SearchResultType.CUSTOMER:
                        history.push(getAdminPath(`customers/view/${record.id}`));
                        break;

                     case SearchResultType.RESERVATION:
                        history.push(
                           getAdminPath(
                              `customers/view/${record.customer_id}?r=${record.id}`
                           )
                        );
                        break;

                     default:
                        break;
                  }

                  setInputFocus(false);
               }}
            />
         }
         visible={showPopover}
      >
         <InputSearch
            loading={loading}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            onFocus={() => setInputFocus(true)}
            onBlur={() => {
               //Delay sul blur altrimenti non prende il click sulla lista
               if (blurTimer) {
                  clearTimeout(blurTimer);
               }

               blurTimer = setTimeout(() => {
                  setInputFocus(false);
               }, 120);
            }}
         />
      </Popover>
   );
};

export default TopbarSearchContainer;
