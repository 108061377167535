import React, { useEffect, useState } from "react";
import { Button, Result } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";

import { useMutation } from "@apollo/client";
import { GQL_VERIFY_USER } from "../apollo/mutation/user";
import { injectIntl } from "react-intl";
import { getAdminPath, getFrontendPath } from "../util/router";
import FrontendHeader from "../routes/frontend/main/header";
import FrontendFooter from "../routes/frontend/main/footer";

const VerifyUser = () => {
   const history = useHistory();
   const { tokenId, redirectToAdmin } = useParams();
   const [verifyUser] = useMutation(GQL_VERIFY_USER);
   const [loadingMutation, setLoadingMutation] = useState(true);
   const [success, setSuccess] = useState(false);

   useEffect(() => {
      verifyUser({ variables: { token: tokenId } })
         .then(({ data }) => {
            if (data && data.verifyUser.id) {
               setSuccess(true);
            }

            setLoadingMutation(false);
         })
         .catch((e) => {
            setLoadingMutation(false);
            setSuccess(false);
         });
   }, [tokenId]);

   if (!tokenId) {
      history.push("/");
      return null;
   }

   if (loadingMutation) {
      return <CircularProgress />;
   }

   const status = success ? "success" : "error";
   const title = success ? (
      <IntlMessages id="verify.verify_user_success_title" />
   ) : (
      <IntlMessages id="verify.verify_user_error_title" />
   );
   const subtitle = success ? (
      <IntlMessages id="verify.verify_user_success_message" />
   ) : (
      <IntlMessages id="verify.verify_user_error_message" />
   );

   const loginPath =
      redirectToAdmin === "1" ? getAdminPath("signin") : getFrontendPath("login");

   return (
      <>
         <FrontendHeader title={<IntlMessages id="verify.verify_user_h1" />} />
         <div className="frontend-container">
            <Result
               key="1"
               status={status}
               title={<h2>{title}</h2>}
               subTitle={<p>{subtitle}</p>}
               extra={[
                  <Link to={loginPath} key="1">
                     <Button className="gx-mt-3 frontend-btn-lg btn-grey">
                        <IntlMessages id="frontend.forget_password_back_to_login" />
                     </Button>
                  </Link>,
               ]}
            />
         </div>
         <FrontendFooter />
      </>
   );
};

export default injectIntl(VerifyUser);
