import { makeVar } from "@apollo/client";
import {
   LAYOUT_TYPE_FULL,
   NAV_STYLE_MINI_SIDEBAR,
   THEME_COLOR,
   THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";

//Layout settings
const initialLayoutSettings = {
   navStyle: NAV_STYLE_MINI_SIDEBAR,
   layoutType: LAYOUT_TYPE_FULL,
   themeType: THEME_TYPE_LITE,
   themeColor: THEME_COLOR,
   isDirectionRTL: false,
   locale: {
      languageId: "italian",
      locale: "it",
      name: "Italian",
      icon: "it",
   },
};
export const layoutSettingsVar = makeVar(initialLayoutSettings);
//

//Global variables
const initialGlobalSettings = {
   error: "",
   loading: false,
   message: "",
   navCollapsed: false,
   sideBarCollapsed: false,
   width: window.innerWidth,
   pathname: "/",
};
export const globalSettingsVar = makeVar(initialGlobalSettings);
//

export const showLoadingProgress = (showLoading) => {
   globalSettingsVar({ ...globalSettingsVar(), loading: showLoading });
};
//
