import { useEffect, useState } from "react";
import moment from "moment";
import { get } from "lodash";

const getLocalStorageJSON = (key) => {
   try {
      return JSON.parse(localStorage.getItem(key));
   } catch (err) {
      localStorage.removeItem(key);
      return null;
   }
};

//add deserialization method
const getValueFromStorage = (key, defaultValue, dataType) => {
   const genericValue = getLocalStorageJSON(key) || defaultValue;

   switch (dataType) {
      case "[moment]":
         if (!genericValue || !genericValue.length) {
            return [];
         }
         return genericValue.map((value) => {
            if (typeof value === "string") {
               return moment(value);
            }
            return value;
         });
      default:
         return genericValue;
   }
};

export function usePersistedState(match = null, key, defaultValue, dataType = null) {
   const k = `${get(match, "url", "")}_${key}`;
   const value = getValueFromStorage(k, defaultValue, dataType);
   const [state, setState] = useState(value);
   useEffect(() => {
      localStorage.setItem(k, JSON.stringify(state));
   }, [k, state]);
   return [state, setState];
}
