import React, { useState } from "react";
import {
   Breadcrumb,
   Card,
   Col,
   Divider,
   Form,
   Modal,
   PageHeader,
   Row,
   Select,
} from "antd";
import IntlMessages from "../../../util/IntlMessages";
import { injectIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import GQLAlertErrors from "../../../components/GQLAlertErrors";
import CurrentWorkspaceResult from "../../../components/CurrentWorkspaceResult";
import AvailabilitiesCalendar from "../../../components/AvailabilitiesCalendar";
import { GQL_LOCAL_ME } from "../../../apollo/query/user";
import BreadcrumbHomeLink from "../../../components/BreadcrumbHomeLink";
import CircularProgress from "../../../components/CircularProgress";
import { first } from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getAdminPath } from "../../../util/router";
import { useHistory } from "react-router-dom";
import { minuteToclockFormat } from "../../../util/date";

const serviceDurationsInSeconds = [15, 30, 45, 60].map((i) => i * 60);

const AvailabilitiesWidget = ({ me, intl }) => {
   const [duration, setDuration] = useState(first(serviceDurationsInSeconds));
   const history = useHistory();
   const workspace = me.current_workspace;

   return (
      <Card>
         <Row>
            <Col xs={24}>
               <Form
                  layout="horizontal"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 24 }}
                  initialValues={{ duration: duration }}
               >
                  <Form.Item
                     name="duration"
                     label={intl.formatMessage({
                        id: "availabilities.p1",
                     })}
                  >
                     <Select
                        style={{ width: 160 }}
                        onChange={(value) => setDuration(value)}
                     >
                        {serviceDurationsInSeconds.map((item, idx) => (
                           <Select.Option key={`serv-dur-key-${idx}`} value={item}>
                              {Math.round(item / 60)}&nbsp;
                              {intl.formatMessage({ id: "common.minutes" })}
                           </Select.Option>
                        ))}
                     </Select>
                  </Form.Item>
               </Form>

               <Divider className="gx-mb-4" />
            </Col>
         </Row>

         <AvailabilitiesCalendar
            workspace={workspace.id}
            duration={duration}
            showOverbooking={true}
            onClickSlot={(dateSelected, slot) => {
               Modal.confirm({
                  icon: <ExclamationCircleOutlined />,
                  title: intl.formatMessage({ id: "common.warning" }),
                  content: intl.formatMessage({
                     id: "availabilities.create_reservation_from_availability",
                  }),

                  onOk() {
                     history.push(
                        getAdminPath(
                           `reservations/insert?d=${dateSelected}&s=${minuteToclockFormat(
                              slot.start
                           )}&o=${slot.available ? 0 : 1}`
                        )
                     );
                  },
               });
            }}
         />
      </Card>
   );
};

const Availabilities = ({ intl }) => {
   const { data, loading, error } = useQuery(GQL_LOCAL_ME);

   if (loading) {
      return <CircularProgress />;
   }

   if (error) {
      return <GQLAlertErrors error={error} />;
   }

   const { me } = data;

   if (me && me.current_workspace == null) {
      return <CurrentWorkspaceResult me={me} />;
   }

   return (
      <>
         <Row>
            <Col md={24}>
               <Breadcrumb className="gx-pb-3">
                  <Breadcrumb.Item>
                     <BreadcrumbHomeLink showWorkspaceName={true} />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item href="#">
                     <IntlMessages id="sidebar.availabilities" />
                  </Breadcrumb.Item>
               </Breadcrumb>
            </Col>
         </Row>
         <Row>
            <Col xs={24}>
               <PageHeader title={<IntlMessages id="sidebar.availabilities" />} />
            </Col>
            <Col md={24}>
               <AvailabilitiesWidget intl={intl} me={me} />
            </Col>
         </Row>
      </>
   );
};

export default injectIntl(Availabilities);
