import { get, find } from "lodash";

export const WorkspaceEntity = (workspace) => {
   return {
      ...workspace, //spread operator di null valido!
      isNotNull: () => !!workspace,
      getName: () => {
         return get(workspace, "name", "");
      },
      getFormattedAddress: () => {
         if (workspace && workspace.location) {
            const address = get(workspace, "location.address", "");
            const cap = get(workspace, "location.cap", "");
            const comune = get(workspace, "location.comune", "");
            const province = get(workspace, "location.province", "");

            let results = [];
            [address, cap, comune, province].forEach((item) => {
               if (item && item.length > 0) {
                  results.push(item);
               }
            });

            const addressString = results.join(" ");
            if (addressString.length > 0) {
               return `(${addressString})`;
            }
         }

         return "";
      },
   };
};

export const WorkspaceCollection = (workspaces) => {
   let items = [];

   workspaces.forEach((service) => {
      items.push(WorkspaceEntity(service));
   });

   return {
      countItems() {
         return items.length;
      },
      getItems() {
         return items;
      },
      isEmpty() {
         return items.length === 0;
      },
      find(id) {
         return WorkspaceEntity(find(items, (item) => item.id === id));
      },
   };
};
