import React from "react";
import { Button, message } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import IntlMessages from "../../../../util/IntlMessages";
import { useLazyQuery } from "@apollo/client";
import { GQL_EXPORT_PRODUCTS } from "../../../../apollo/query/reuse-board";
import { useIntl } from "react-intl";
import { has } from "lodash";
import { downloadBase64File } from "../../../../util/file";

const ExportProductsButton = () => {
   const intl = useIntl();
   const [exportReuseBoardProducts, { loading }] = useLazyQuery(
      GQL_EXPORT_PRODUCTS,
      {
         onCompleted: (data) => {
            if (data && has(data, "exportReuseBoardProducts.content")) {
               try {
                  downloadBase64File(
                     intl.formatMessage({
                        id: "reuse_board.products_export_filename",
                     }),
                     data.exportReuseBoardProducts.content
                  );
               } catch (e) {
                  message.error(
                     intl.formatMessage({ id: "common.cannot_download_file" })
                  );
               }
            }
         },
         onError: (error) => {
            message.error(error.message);
         },
      }
   );

   return (
      <Button
         type="default"
         loading={loading}
         onClick={() => exportReuseBoardProducts()}
      >
         <FileExcelOutlined /> <IntlMessages id="common.export" />
      </Button>
   );
};

export default ExportProductsButton;
