import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import { getAdminPath } from "../../util/router";

const BadResponse = ({ title, subtitle, redirectURL }) => {
   return (
      <Result
         status="500"
         title={title}
         subTitle={subtitle}
         extra={
            <Link to={redirectURL}>
               <Button type="primary">
                  <IntlMessages id="404.home" />
               </Button>
            </Link>
         }
      />
   );
};

BadResponse.defaultProps = {
   redirectURL: getAdminPath(),
};

export default BadResponse;
