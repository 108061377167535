import { Spin } from "antd";
import React from "react";

const CircularProgress = ({
   className = "",
   style = null,
}) => (
   <div className={`loader ${className}`} style={style}>
      <Spin size="large" />
   </div>
);

export const CircularProgressOverlay = ({ className = "" }) => {
   return (
      <div
         className={`loader ${className} gx-bg-light-grey`}
         style={{
            position: "fixed",
            zIndex: 1000,
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            opacity: 0.5,
         }}
      >
         <Spin size="large" />
      </div>
   );
};

export default CircularProgress;
