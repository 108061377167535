import moment from "moment";
import {
   CALENDAR,
   DATETIME_FORMAT,
   DATE_FORMAT,
   RESERVATION,
} from "../constants/App";
import { find, padStart } from "lodash";

export const formatSecondsToHHMMSS = (second) => {
   const hours = Math.floor(second / 3600);
   const minutes = Math.floor((second - hours * 3600) / 60);
   const seconds = second % 60;

   if (hours > 0) {
      if (seconds > 0) {
         return `${hours}h ${padStart(minutes, 2, "0")}m ${padStart(
            seconds,
            2,
            "0"
         )}s`;
      } else {
         return `${hours}h ${padStart(minutes, 2, "0")}m`;
      }
   } else if (minutes > 0) {
      return seconds > 0
         ? `${minutes}m ${padStart(seconds, 2, "0")}s`
         : `${minutes}m`;
   }

   return `${seconds}s`;
};

export const formatByGranularity = (date, granularity) => {
   let format = null;
   switch (granularity) {
      case "week":
         format = "YYYY-WW";
         break;
      case "month":
         format = "YYYY-MM";
         break;
      case "day":
         format = "YYYY-MM-DD";
         break;
      default:
         format = "YYYY-MM-DD";
         break;
   }

   return moment(date).format(format);
};

export const clockFormatToMinute = (time) => {
   if (!time) return null;

   const [h, m] = time.split(":");

   return parseInt(h) * 60 + parseInt(m);
};

export const minuteToclockFormat = (minute) => {
   return `${padStart(Math.floor(minute / 60), 2, 0)}:${padStart(
      minute % 60,
      2,
      0
   )}`;
};

export const getTimetableIndexByDate = (date, granularity) => {
   switch (granularity) {
      case "week":
         return date.isoWeekday() - 1;
      case "month":
         return date.date() - 1;
      case "day":
         return 0;
      default:
         return date.isoWeekday() - 1;
   }
};

const isOverlappingTimes = (timesA, timesB) => {
   return !(timesA.end <= timesB.start || timesA.start >= timesB.end);
};

export const isSlotInTimetables = (date, timetables, granularity) => {
   const mDate = moment(date);

   const dayIndex = getTimetableIndexByDate(mDate, granularity);

   if (!timetables[dayIndex] || timetables[dayIndex].length === 0) {
      return false;
   }

   //controlla solo il giorno
   if (granularity === "month") return true;

   const minuteStart = clockFormatToMinute(mDate.format("HH:mm"));
   const dateTime = {
      start: minuteStart,
      end: minuteStart + CALENDAR.STEP,
   };

   const isAvalable = !!find(timetables[dayIndex], (tt) => {
      const ttDateTime = {
         start: clockFormatToMinute(tt.start),
         end: clockFormatToMinute(tt.end),
      };

      return isOverlappingTimes(dateTime, ttDateTime);
   });

   return isAvalable;
};

export const reservationTimeToEventTime = (reservation) => {
   return {
      start: moment(
         `${reservation.time.date} ${reservation.time.time_start}`,
         "YYYY-MM-DD HH:mm"
      ).toDate(),
      end: moment(
         `${reservation.time.date} ${reservation.time.time_end}`,
         "YYYY-MM-DD HH:mm"
      ).toDate(),
   };
};

/**
 * duration già considerata lato server
 * @param {*} availability slot di disponibilità {tipo DayAvailability}
 * @returns
 */
export const availabilityToSlot = (
   availability,
   stepSize = RESERVATION.AVAILABILITY_STEP_SIZE
) => {
   const slots = [];

   //avoid inf loop
   //if (!duration || duration <= 0) return slots;

   const startTime = clockFormatToMinute(availability.start);
   const endTime = clockFormatToMinute(availability.end);

   for (let i = 0; startTime + i < endTime; i += stepSize) {
      slots.push(minuteToclockFormat(startTime + i));
   }

   return slots;
};

/**
 * serve per limitare il range di orari del calendario in base alle ore effettive
 * span +/- ore
 *
 * @param {*} span
 * @returns
 */
export const getMinMaxTimeFromTimetables = (timetables, span = 1) => {
   let min = 60 * 24;
   let max = 0;

   const minTime = new Date();
   const maxTime = new Date();

   timetables.forEach((tt) => {
      if (!tt) return null;
      tt.forEach((day) => {
         const start = clockFormatToMinute(day.start);
         const end = clockFormatToMinute(day.end);
         if (end > max) {
            max = end;
         }
         if (start < min) {
            min = start;
         }
      });
   });

   if (min === 60 * 24) {
      min = 8 * 60;
   }
   if (max === 0) {
      max = 20 * 60;
   }

   minTime.setHours(Math.round(min / 60) - span, 0, 0);
   maxTime.setHours(Math.round(max / 60) + span, 0, 0);

   return [minTime, maxTime];
};

export const reservationDateToString = ({ day, slot }, intl) => {
   let availabilityFormattedString = "";

   if (day && slot) {
      availabilityFormattedString = `${moment(day).format(
         DATE_FORMAT
      )} ${intl.formatMessage({ id: "common.at_hour" })} ${slot}`;
   }

   return availabilityFormattedString;
};

export const availabilitySlotsToDictionary = (slots) => {
   let dictionarySlot = [];
   slots.forEach((s) => {
      const [h] = s.split(":");
      if (dictionarySlot[h] == null) {
         dictionarySlot[h] = [s];
      } else {
         dictionarySlot[h].push(s);
      }
   });

   return dictionarySlot;
};

export const availabilitySlotGetHour = (slot) => {
   if (!slot) {
      return null;
   }

   const [h] = slot.split(":");
   return h;
};

export const formatDate = (date, outFormat = DATE_FORMAT, inFormat = null) => {
   if (!date) return null;
   let m = null;
   if (inFormat) {
      m = moment(date, inFormat);
   } else {
      m = moment(date);
   }
   return m.format(outFormat);
};

export const formatDateTime = (date) => {
   return formatDate(date, DATETIME_FORMAT);
};
