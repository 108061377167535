import React from "react";
import { Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { COMPONENT_WIDTH, MIN_SEARCH_LENGTH } from "../constants";

const PREFIX_STYLE = { color: "#c6c6c6" };

const InputSearch = ({ value, onChange, onClear, onFocus, onBlur, loading }) => {
   const intl = useIntl();

   return (
      <Input
         size="large"
         placeholder={intl.formatMessage(
            { id: "topbar.search_in_app" },
            { len: MIN_SEARCH_LENGTH }
         )}
         prefix={
            loading ? (
               <LoadingOutlined
                  spin
                  style={PREFIX_STYLE}
                  className=" gx-pr-2 gx-mt-1"
               />
            ) : (
               <i
                  className="icon icon-search gx-pr-2 gx-mt-1"
                  style={PREFIX_STYLE}
               />
            )
         }
         allowClear
         onFocus={onFocus}
         onBlur={onBlur}
         bordered={false}
         maxLength={50}
         style={{
            fontSize: 26,
            width: COMPONENT_WIDTH,
         }}
         value={value}
         onChange={onChange}
      />
   );
};

export default InputSearch;
