import React from "react";
import { Button } from "antd";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { getFrontendPath } from "../../../util/router";
import IntlMessages from "../../../util/IntlMessages";

const FrontendDashboard = ({ intl }) => {
   return (
      <div>
         <div className="gx-app-login-container gx-app-login-container-frontend">
            <h2 className="gx-mb-5">
               <IntlMessages id="frontend.dashboard_h2" />
            </h2>
            <ul>
               <li>
                  <p className="gx-mb-1">
                     <IntlMessages id="frontend.dashboard_list_1" />
                  </p>
               </li>
               <li>
                  <p className="gx-mb-1">
                     <IntlMessages id="frontend.dashboard_list_2" />
                  </p>
               </li>
               <li>
                  <p className="gx-mb-1">
                     <IntlMessages id="frontend.dashboard_list_3" />
                  </p>
               </li>
            </ul>

            <p className="gx-mt-3 gx-mb-2">
               <IntlMessages id="frontend.dashboard_list_4" />
            </p>

            <Link to={getFrontendPath("prenotazione")}>
               <Button
                  className="gx-mb-3 frontend-btn-lg w-100 gx-mt-4"
                  htmlType="button"
               >
                  <IntlMessages id="frontend.dashboard_new_reservation" />
               </Button>
            </Link>

            <Link to={getFrontendPath("prenotazioni-elenco")}>
               <Button
                  className="gx-mb-5 frontend-btn-lg btn-green w-100 gx-mt-3"
                  htmlType="button"
               >
                  <IntlMessages id="frontend.dashboard_check_reservations" />
               </Button>
            </Link>
         </div>
      </div>
   );
};

export default injectIntl(FrontendDashboard);
