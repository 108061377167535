import React from "react";
import { Link } from "react-router-dom";
import { getFrontendPath } from "../../../util/router";
import FrontendTopbar from "../../../containers/Frontend/Topbar";
import { Row, Col } from "antd";

const FrontendHeader = ({ title, user }) => {
   return (
      <>
         <div className="frontend-container">
            <Row>
               <Col span={16}>
                  <div className="gx-app-logo gx-app-logo-frontend gx-mt-4">
                     <Link to={getFrontendPath()}>
                        <img
                           alt="Sav.no. Spa"
                           src="/assets/images/savnobook_logo.svg"
                           id="frontend_logo"
                        />
                     </Link>
                  </div>
               </Col>
               <Col span={8}>
                  <div className="gx-float-right" style={{ marginTop: "30px" }}>
                     {user && <FrontendTopbar user={user} />}
                  </div>
               </Col>
            </Row>
         </div>
         <div className="gx-w-100">
            <div className="frontend-header-breadcrumbs"></div>
            <div className="frontend-header-bar">
               <div id="frontend-header-bar-dx">
                  <img src="/assets/images/home_testata_05.png" alt="" />
               </div>
               <div className="frontend-container">
                  <h1>{title}</h1>
               </div>
            </div>
         </div>
      </>
   );
};

export default FrontendHeader;
