import { Col, Row } from "antd";
import React from "react";

const FrontendFooter = () => {
   return (
      <>
         <div className="gx-w-100 frontend-footer">
            <div className="frontend-container">
               <Row>
                  <Col sm={24} md={12} className="white gx-mb-5">
                     <strong>Sav.no. Spa</strong>

                     <p className="white gx-mt-4">
                        soggetta ad attività di direzione e coordinamento di C.I.T.
                     </p>

                     <p className="white">
                        Via Magg. G. Piovesana 158/B, 31015 - Conegliano (TV)
                     </p>

                     <p className="white">
                        P.IVA 03670260268 - C.F. e R.I. 03288870276
                        <br />
                        Cap. Soc. € 10.000.000,00 i.v. - REA: TV - 288691
                        <br />
                        E-MAIL: <a href="mailto:savno@savno.it">savno@savno.it</a>
                        <br />
                        PEC: <a href="mailto:savno@pec.it">savno@pec.it</a>
                     </p>

                     <p className="white">
                        <a
                           href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                           target="_newpage"
                        >
                           Privacy Policy
                        </a>
                     </p>

                     <a href="http://www.bacinotv1.it/it/" target="_newpage">
                        <img
                           src="/assets/images/footer_icon_cit.svg"
                           alt="Consorzio Servizi di Igiene del Territorio TV1"
                           className="gx-mr-4 gx-mt-4"
                        />
                     </a>

                     <a href="http://www.bacinosp.it/" target="_newpage">
                        <img
                           src="/assets/images/footer_icon_bacino.svg"
                           alt="Consiglio di Bacino Sinistra Piave"
                           className="gx-mt-4"
                        />
                     </a>
                  </Col>
                  <Col sm={24} md={12} className="white">
                     <Row>
                        <Col span={24}>
                           <strong>Contatti rapidi</strong>
                        </Col>
                     </Row>
                     <Row className="gx-mt-4">
                        <Col span={12} className="white">
                           <p className="gx-mb-1">Centralino</p>
                           <a href="tel:+3904381711000">Tel. 0438 1711000</a>
                        </Col>
                        <Col span={12} className="white">
                           <p className="gx-mb-1">Ufficio Contabilità</p>
                           <a href="tel:+3904381711006">Tel. 0438 1711006</a>
                        </Col>
                     </Row>
                     <Row className="gx-mt-4">
                        <Col span={12} className="white">
                           <p className="gx-mb-1">
                              Raccolte, servizi e segnalazione disservizi
                           </p>
                           <a href="tel:+3904381711001">Tel. 0438 1711001</a>
                        </Col>
                        <Col span={12} className="white">
                           <p className="gx-mb-1">Ufficio Recupero Crediti</p>
                           <a href="tel:+3904381711007">Tel. 0438 1711007</a>
                        </Col>
                     </Row>
                     <Row className="gx-mt-4">
                        <Col span={12} className="white">
                           <p className="gx-mb-1">Vigilanza Ambientale</p>
                           <a href="tel:+3904381711002">Tel. 0438 1711002</a>
                        </Col>
                        <Col span={12} className="white">
                           <p className="gx-mb-1">Ufficio Commerciale</p>
                           <a href="tel:+3904381711004">Tel. 0438 1711004</a>
                        </Col>
                     </Row>
                     <Row className="gx-mt-4">
                        <Col span={12} className="white">
                           <p className="gx-mb-1">Ufficio Clienti Tariffa</p>
                           <a href="tel:+3904381711003">Tel. 0438 1711003</a>
                        </Col>
                        <Col span={12} className="white">
                           <p className="gx-mb-1">Ufficio Rifiuti Agricoli</p>
                           <a href="tel:+3904381711005">Tel. 0438 1711005</a>
                        </Col>
                     </Row>
                  </Col>
               </Row>
               <Row>
                  <Col span={24} className="gx-text-center">
                     <img
                        src="/assets/images/sgs-certificazioni.png"
                        alt=""
                        className="certificazioni"
                     />
                     <p className="white credits">
                        Copyright © {new Date().getFullYear()} - Tutti i diritti riservati. Sav.no. Spa -{" "}
                        <a href="https://www.awom.it/">Crediti</a>
                     </p>
                  </Col>
               </Row>
            </div>
         </div>
      </>
   );
};

export default FrontendFooter;
