import { PlusOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Card, Upload, Modal, message } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
   defaultCatchException,
   defaultMutationCallback,
} from "../../../../apollo/callbacks";
import { GQL_ASSET_DELETE } from "../../../../apollo/mutation/assets";
import {
   isFileTooBig,
   isFileTypeSupported,
} from "../../../../components/UploadAsset/validators";
import { useS3Upload } from "../../../../hooks/useS3Upload";
import { encodeBase64 } from "../../../../util/file";
import IntlMessages from "../../../../util/IntlMessages";
import { AssetContextType } from "../../assets/assets.definitions";

const MAX_ATTACHMENT_UPLOADS = 5;
const MAX_FILE_SIZE_MB = 5;
const FILE_TYPES = "image/png, image/jpeg";

function resizeImage(base64Str, maxWidth = 1400, maxHeight = 1200) {
   return new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
         let canvas = document.createElement("canvas");
         const MAX_WIDTH = maxWidth;
         const MAX_HEIGHT = maxHeight;
         let width = img.width;
         let height = img.height;

         if (width > height) {
            if (width > MAX_WIDTH) {
               height *= MAX_WIDTH / width;
               width = MAX_WIDTH;
            }
         } else {
            if (height > MAX_HEIGHT) {
               width *= MAX_HEIGHT / height;
               height = MAX_HEIGHT;
            }
         }
         canvas.width = width;
         canvas.height = height;
         let ctx = canvas.getContext("2d");
         ctx.drawImage(img, 0, 0, width, height);
         resolve(canvas.toDataURL());
      };
   });
}

const uploadButton = (
   <div>
      <PlusOutlined />
      <div
         style={{
            marginTop: 8,
         }}
      >
         <IntlMessages id="common.upload_file" />
      </div>
   </div>
);

const ProductImagesUpload = ({ product, cardClassName, resizeTo = null }) => {
   const [uploadAssets] = useS3Upload(
      AssetContextType.REUSE_BOARD_PRODUCT_IMAGE,
      product.id
   );
   const [deleteAsset] = useMutation(GQL_ASSET_DELETE);
   const intl = useIntl();

   const initFiles = (product?.assets || []).map((asset) => {
      return {
         uid: asset.id,
         name: asset.file_name,
         status: "done",
         url: asset.url,
      };
   });

   const [fileList, setFileList] = useState(initFiles);
   const [previewOpen, setPreviewOpen] = useState(false);
   const [previewImage, setPreviewImage] = useState("");
   const [previewTitle, setPreviewTitle] = useState("");

   const handleCancel = () => setPreviewOpen(false);

   const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
         file.preview = await encodeBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(
         file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
   };

   const onUploadAsset = async (uploadedFile, uploadedFiles) => {
      const uploadedStateFile = {
         ...uploadedFile,
         status: "uploading",
      };

      setFileList([...fileList, uploadedStateFile]);

      const src = await encodeBase64(uploadedFile);
      const paramSrc = resizeTo
         ? await resizeImage(src, resizeTo.width, resizeTo.height)
         : src;

      return new Promise((resolve, reject) => {
         uploadAssets([uploadedFile])
            .then((res) => {
               const assetId = res && res.length > 0 ? res[0].createAsset : null;

               if (!assetId) {
                  setFileList([
                     ...fileList,
                     {
                        ...uploadedFile,
                        status: "error",
                     },
                  ]);
                  reject();
               } else {
                  setFileList([
                     ...fileList,
                     {
                        ...uploadedFile,
                        status: "done",
                        uid: assetId,
                        url: paramSrc,
                        name: uploadedFile.name,
                     },
                  ]);

                  reject();
               }
            })
            .catch((error) => {
               setFileList([...fileList, { ...uploadedFile, status: "error" }]);
               reject();
            });
      });
   };

   const onDeleteAsset = (file) => {
      deleteAsset({ variables: { id: file.uid } })
         .then((data) => defaultMutationCallback(data))
         .catch((e) => defaultCatchException(e, intl));
   };

   return (
      <Card className={cardClassName} title={<IntlMessages id="common.images" />}>
         <Upload
            multiple={false}
            accept={FILE_TYPES}
            maxCount={MAX_ATTACHMENT_UPLOADS}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            //gestire validazione e chiamata da questa callback senza usare onChange
            beforeUpload={(file, files) => {
               const mimeTypesArray = FILE_TYPES.split(",").map((type) =>
                  type.trim()
               );

               if (!isFileTypeSupported(file, mimeTypesArray)) {
                  message.error(
                     intl.formatMessage(
                        { id: "uploads.file_type_not_supported" },
                        { allowed: FILE_TYPES }
                     )
                  );
                  return false;
               }

               if (isFileTooBig(file, MAX_FILE_SIZE_MB)) {
                  message.error(
                     intl.formatMessage(
                        { id: "uploads.file_too_big" },
                        { max_file_size: MAX_FILE_SIZE_MB }
                     )
                  );
                  return false;
               }

               return onUploadAsset(file, files);
            }}
            onRemove={(file) => {
               Modal.confirm({
                  type: "confirm",
                  title: intl.formatMessage({
                     id: "services.new_service_delete_confirm_message",
                  }),
                  onOk: () => {
                     const index = fileList.indexOf(file);
                     const newFileList = fileList.slice();
                     newFileList.splice(index, 1);
                     setFileList(newFileList);
                     onDeleteAsset(file);
                  },
               });
            }}
         >
            {fileList.length <= MAX_ATTACHMENT_UPLOADS ? uploadButton : null}
         </Upload>

         <Modal
            visible={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
         >
            <img style={{ width: "100%" }} src={previewImage} />
         </Modal>
      </Card>
   );
};

export default ProductImagesUpload;
