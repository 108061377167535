import { Roles } from "../constants/Enums";
import { includes } from "lodash";

export const hasAdminAccess = (me) => {
   return includes(
      [
         Roles.SUPERADMIN,
         Roles.SUPERGUEST,
         Roles.ADMIN,
         Roles.EMPLOYEE,
         Roles.CHECKING_REPORTS,
      ],
      me.role
   );
};

export const hasStatisticsAccess = (user) => {
   return includes([Roles.SUPERADMIN, Roles.SUPERGUEST, Roles.ADMIN], user.role);
};
