import { gql } from "@apollo/client";

export const GQL_UPSERT_ZONES = gql`
    mutation upsertZones($input: UpsertZoneInput!) {
        upsertZones(input: $input, dev: false)
    }
`;

export const GQL_COMUNE_ZONES_UPGRADE = gql`
    mutation upgradeComuneZones($comune_id: String!) {
        upgradeComuneZones(comune_id: $comune_id, dev: false)
    }
`;

export const GQL_UPDATE_COMUNE = gql`
    mutation updateComune($input: UpdateComuneInput!) {
        updateComune(input: $input)
    }
`

export const GQL_DELETE_GROUPED_ZONE_ADDRESS = gql`
    mutation deleteGroupedZoneAddress($comune_id: String!, $street_slug: String!) {
        deleteGroupedZoneAddress(comune_id: $comune_id, street_slug: $street_slug, dev: false)
    }
`;

