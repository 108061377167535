import React from "react";
import { Button, Collapse, Table, Typography } from "antd";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../../util/IntlMessages";
import { formatDateTime } from "../../../../util/date";
import { ProductReservationStatusTag } from "../reservations/common";
import { RightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const ReservationsTable = ({ reservations, intl, ...rest }) => {
   if (!reservations || reservations.length === 0) return null;

   return (
      <Collapse
         defaultActiveKey={["1"]}
         {...rest}
         bordered={false}
         className="gx-mb-3 gx-bg-light-warning"
      >
         <Panel
            header={
               <Typography.Title level={5} className="gx-mb-0 gx-mt-0 gx-text-info">
                  <IntlMessages id="reuse_board.product_reservations_available" />
               </Typography.Title>
            }
            key="1"
         >
            <Table
               pagination={false}
               dataSource={reservations}
               columns={[
                  {
                     title: intl.formatMessage({
                        id: "common.datetime",
                     }),
                     dataIndex: "created_at",
                     render: (text) => <small>{formatDateTime(text)}</small>,
                     width: 130,
                  },
                  {
                     title: intl.formatMessage({
                        id: "customers.customer",
                     }),
                     dataIndex: ["customer", "full_name"],
                     key: "customer",
                  },
                  {
                     title: intl.formatMessage({
                        id: "common.email",
                     }),
                     dataIndex: ["customer", "email"],
                  },
                  {
                     title: intl.formatMessage({
                        id: "common.fiscal_code",
                     }),
                     dataIndex: ["customer", "fiscal_code"],
                  },
                  {
                     title: intl.formatMessage({
                        id: "common.status",
                     }),
                     dataIndex: "status",
                     render: (text) => <ProductReservationStatusTag status={text} />,
                  },
                  {
                     dataIndex: "id",
                     render: (id) => (
                        <Button
                           type="default"
                           size="small"
                           href={`/admin/reuse-board/reservations/update/${id}`}
                           className="gx-mb-0 gx-mt-0"
                           shape="circle"
                        >
                           <RightOutlined />
                        </Button>
                     ),
                     width: 30,
                  },
               ]}
            />
         </Panel>
      </Collapse>
   );
};

export default injectIntl(ReservationsTable);
