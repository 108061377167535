import React from "react";
import UserProfile from "../../../containers/Topbar/UserProfile";
import { injectIntl } from "react-intl";

const FrontendTopbar = ({ user }) => {
   //  const { current_organization: organization } = user;

   return (
      <ul className="gx-header-notifications gx-ml-auto">
         <li className="gx-notify">
            <UserProfile isAdminRoute={false} />
         </li>
      </ul>
   );
};

export default injectIntl(FrontendTopbar);
