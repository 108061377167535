import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form, Calendar, Alert } from "antd";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import IntlMessages from "../../../../util/IntlMessages";
import { get, upperFirst } from "lodash";
import { DATE_FORMAT } from "../../../../constants/App";
import moment from "moment";
import {
   availabilitySlotGetHour,
   availabilitySlotsToDictionary,
   availabilityToSlot,
   getTimetableIndexByDate,
} from "../../../../util/date";
import { GQL_RESERVATION_AVAILABILITY } from "../../../../apollo/query/reservation";
import { formStyles } from "./common";
import { useLazyQuery } from "@apollo/client";
import { localStorageClass } from "../../../../apollo/local/storage";

const FrontendReservationAvailabilitiesStepTable = ({
   selectedAvailability,
   onChange,   
   availabilities,
}) => {
   const [selectedHour, setSelectedHour] = useState(
      availabilitySlotGetHour(selectedAvailability.slot)
   );

   const selectedDayMonthIndex = getTimetableIndexByDate(
      moment(selectedAvailability.day),
      "month"
   );
   const dayAvailabilities =
      (availabilities && availabilities[selectedDayMonthIndex]) ?? null;

   let slots = [];

   if (dayAvailabilities) {
      dayAvailabilities.forEach((a) => {
         slots = slots.concat(availabilityToSlot(a));
      });
   }

   if (slots.length === 0)
      return (
         <Alert
            message={
               <p className="gx-mb-0">
                  <IntlMessages id="reservations.no_availability" />
               </p>
            }
            type="warning"
            showIcon={true}
         />
      );

   //
   const dictionarySlot = availabilitySlotsToDictionary(slots);

   let sortHours = [];

   for (const [key] of Object.entries(dictionarySlot)) {
      sortHours.push(key);
   }

   sortHours.sort();
   //

   const slotLayoutProps = {
      xl: { span: 4 },
      lg: { span: 6 },
      md: { span: 6 },
      sm: { span: 8 },
      xs: { span: 8 },
   };

   return (
      <div className="frontend-available-slots-container">
         <p className="gx-mb-4">
            <IntlMessages id="reservations.availability_for_hours" />
            &nbsp;
            <strong>
               {moment(selectedAvailability.day, "YYYY-MM-DD").format(DATE_FORMAT)}
            </strong>
            <br />
            <IntlMessages id="reservations.availability_for_hours_and_minutes_1" />
         </p>
         <Row gutter={[10, 10]}>
            {sortHours.length > 0 &&
               sortHours.map((item, index) => {
                  const classSel = selectedHour === item ? "selected" : "";
                  return (
                     <Col key={`fa-slot${index}`} {...slotLayoutProps}>
                        <div
                           className={`slot-container ${classSel}`}
                           onClick={(e) => {
                              e.preventDefault();

                              if (item !== selectedHour) {
                                 setSelectedHour(item);
                                 onChange(null);
                              }
                           }}
                        >
                           <div className="slot-container-time">
                              {parseInt(item)}
                           </div>
                        </div>
                     </Col>
                  );
               })}
         </Row>
         {selectedHour && (
            <>
               <p className="gx-mb-4 gx-mt-3">
                  <IntlMessages id="reservations.availability_for_hours_and_minutes_2" />
               </p>
               <Row gutter={[10, 10]} className="gx-mt-4">
                  {dictionarySlot[selectedHour] &&
                     dictionarySlot[selectedHour].length > 0 &&
                     dictionarySlot[selectedHour].map((slot, i) => {
                        const classSel =
                           get(selectedAvailability, "slot") === slot
                              ? "selected"
                              : "";
                        return (
                           <Col key={`fa-slot-minute${i}`} {...slotLayoutProps}>
                              <div
                                 className={`slot-container ${classSel}`}
                                 onClick={(e) => {
                                    e.preventDefault();
                                    onChange(slot);
                                 }}
                              >
                                 <div className="slot-container-time">{slot}</div>
                              </div>
                           </Col>
                        );
                     })}
               </Row>
            </>
         )}
      </div>
   );
};

export const FrontendReservationAvailabilitiesStep = ({
   intl,
   handleOk,
   handlePreviousStep,
   handleError,
   formContent,
   hasBackButton,
   services,
   selectedServiceIds,
}) => {
   const [formError, setFormError] = useState(false);
   const [selectedAvailability, setSelectedAvailability] = useState(
      formContent.availability
         ? formContent.availability
         : { day: moment().format("YYYY-MM-DD"), slot: null }
   );
   let [loadingAvailabilitiesData, { data, error }] = useLazyQuery(
      GQL_RESERVATION_AVAILABILITY
   );

   //Se non ho selezionato nessuna data imposto il mese corrente come default
   const [selectedMonth, setSelectedMonth] = useState(
      selectedAvailability
         ? moment(selectedAvailability.day, "YYYY-MM-DD").format("YYYY-MM")
         : moment().format("YYYY-MM")
   );

   const fetchData = (month) => {
      setSelectedMonth(month);

      loadingAvailabilitiesData({
         variables: {
            month: month,
            services_id: formContent.services_id,
            workspace_id: formContent.workspace_id,
            current_reservation_id: localStorageClass.getReservationId(),
         },
      });
   };

   useEffect(() => {
      //init query
      fetchData(selectedMonth);
   }, []);

   const selectedParam = get(
      selectedAvailability,
      "day",
      moment().format("YYYY-MM-DD")
   );
   const selectedDay = moment(selectedParam);

   const workspaceId = get(formContent, "workspace_id", null);

   const selectedServices = services.filter((s) =>
      selectedServiceIds.includes(s.id)
   );

   const duration = selectedServices.reduce(
      (acc, service) => (acc += Math.round(service.duration / 60) || 0),
      0
   );

   if (error)
      return (
         <Alert
            message={intl.formatMessage({ id: "reservations.availability_error" })}
            type="error"
         />
      );

   const availabilities = get(data, "reservationAvailability.availables");
   const selectedMonthMoment = moment(selectedMonth);

   return (
      <Form
         onFinish={(values) => {
            if (selectedAvailability.day && selectedAvailability.slot) {
               setFormError(false);
               handleOk({
                  day: moment(selectedAvailability.day, "YYYY-MM-DD"),
                  slot: selectedAvailability.slot,
               });
            } else {
               setFormError(true);
            }
         }}
         onFinishFailed={handleError}
         {...formStyles}
         initialValues={{
            day: selectedDay,
            slot: get(selectedAvailability, "slot", null),
         }}
      >
         <Card
            actions={[
               <div key="1" className="gx-text-left">
                  {hasBackButton && (
                     <Button
                        key="1"
                        onClick={handlePreviousStep}
                        className="uppercase border-0 gx-mb-0"
                     >
                        <IntlMessages id="common.back" />
                     </Button>
                  )}

                  <Button
                     key="2"
                     htmlType="submit"
                     className="btn-lightblue uppercase border-0 gx-mb-0"
                  >
                     <IntlMessages id="common.forward" />
                  </Button>
               </div>,
            ]}
            className="card-step"
         >
            <Row>
               <Col xs={24}>
                  <p>
                     <IntlMessages id="reservations.reservation_availability_description" />
                  </p>
               </Col>
            </Row>
            <Row className="gx-mt-3">
               <Col xs={24} sm={24} md={12}>
                  <Form.Item name="day">
                     <Calendar
                        headerRender={({ value, type, onChange }) => {
                           return (
                              <Row>
                                 <Col xs={10} className="gx-pt-2">
                                    <Button
                                       size="small"
                                       type="text"
                                       onClick={(e) => {
                                          e.stopPropagation();
                                          const newValue = value.clone();
                                          newValue.subtract(1, "months");
                                          onChange(newValue);
                                       }}
                                    >
                                       <LeftCircleOutlined
                                          style={{ fontSize: "20px" }}
                                       />
                                    </Button>
                                    <Button
                                       size="small"
                                       type="text"
                                       onClick={(e) => {
                                          e.stopPropagation();
                                          const newValue = value.clone();
                                          newValue.add(1, "months");
                                          onChange(newValue);
                                       }}
                                    >
                                       <RightCircleOutlined
                                          style={{ fontSize: "20px" }}
                                       />
                                    </Button>
                                 </Col>
                                 <Col xs={14} className="gx-pt-2">
                                    <h4>{upperFirst(value.format("MMMM YYYY"))}</h4>
                                 </Col>
                              </Row>
                           );
                        }}
                        onPanelChange={(date) => {
                           fetchData(date.format("YYYY-MM"));
                        }}
                        disabledDate={(date) => {
                           if (availabilities && selectedMonthMoment) {
                              const d = date.date();
                              const m = date.month();
                              const selectedM = selectedMonthMoment.month();

                              if (selectedM === m) {
                                 if (availabilities.length >= d) {
                                    return (
                                       !availabilities[d - 1] ||
                                       availabilities[d - 1].length === 0
                                    );
                                 }
                              }
                           }

                           return false;
                        }}
                        fullscreen={false}
                        onSelect={(value) => {
                           setSelectedAvailability({
                              day: value ? value.format("YYYY-MM-DD") : null,
                              slot: null,
                           });
                           setFormError(false);
                        }}
                     />
                  </Form.Item>
               </Col>
               <Col xs={24} sm={24} md={12}>
                  <Form.Item name="slot">
                     {workspaceId && (
                        <FrontendReservationAvailabilitiesStepTable
                           availabilities={availabilities}
                           selectedAvailability={selectedAvailability}
                           duration={duration}
                           onChange={(value) => {
                              setSelectedAvailability({
                                 ...selectedAvailability,
                                 slot: value,
                              });

                              setFormError(false);
                           }}
                        />
                     )}

                     {formError && (
                        <span className="gx-text-danger">
                           <IntlMessages id="reservations.reservation_time_required" />
                        </span>
                     )}
                  </Form.Item>
               </Col>
            </Row>
         </Card>
      </Form>
   );
};
