import { useIntl } from "react-intl";
import { ProductReservationStatuses } from "../../../../constants/Enums";
import { Select, Tag } from "antd";

export const ProductReservationStatusTag = ({ status }) => {
   const intl = useIntl();

   const title = intl.formatMessage({
      id: `reuse_board.product_reservations_status_${ProductReservationStatuses[status]}`,
   });

   let color = "default";
   switch (status.toLowerCase()) {
      case ProductReservationStatuses.ND:
         color = "default";
         break;

      case ProductReservationStatuses.CONFIRMED:
         color = "cyan";
         break;

      case ProductReservationStatuses.REJECTED:
         color = "red";
         break;

      case ProductReservationStatuses.READY_FOR_DELIVERY:
         color = "gold";
         break;

      case ProductReservationStatuses.DELIVERED:
         color = "green";
         break;

      default:
         break;
   }

   return (
      <Tag className="gx-mb-0" color={color}>
         {title}
      </Tag>
   );
};

export const ProductReservationStatusSelect = ({ onChange, ...rest }) => {
   const intl = useIntl();

   return (
      <Select
         {...rest}
         onChange={onChange}
         options={[
            {
               label: intl.formatMessage({
                  id: "reuse_board.product_reservations_status_nd",
               }),
               value: ProductReservationStatuses.ND,
            },
            {
               label: intl.formatMessage({
                  id: "reuse_board.product_reservations_status_confirmed",
               }),
               value: ProductReservationStatuses.CONFIRMED,
            },
            {
               label: intl.formatMessage({
                  id: "reuse_board.product_reservations_status_rejected",
               }),
               value: ProductReservationStatuses.REJECTED,
            },
            {
               label: intl.formatMessage({
                  id: "reuse_board.product_reservations_status_ready_for_delivery",
               }),
               value: ProductReservationStatuses.READY_FOR_DELIVERY,
            },
            {
               label: intl.formatMessage({
                  id: "reuse_board.product_reservations_status_delivered",
               }),
               value: ProductReservationStatuses.DELIVERED,
            },
         ]}
      />
   );
};
