import {
   Button,
   DatePicker,
   Divider,
   Form,
   Input,
   Modal,
   Select,
   Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import IntlMessages from "../../util/IntlMessages";
import PropTypes from "prop-types";
import { useForm } from "antd/lib/form/Form";
import { useIntl } from "react-intl";
import { DATE_FORMAT } from "../../constants/App";
import CodiceFiscale from "codice-fiscale-js";
import { COMUNI } from "codice-fiscale-js/src/lista-comuni";
import { startCase } from "lodash";

const FiscalCodeModal = ({ visible, onDismiss, onSuccess, params }) => {
   const intl = useIntl();
   const [form] = useForm();
   const [comuni, setComuni] = useState(COMUNI.filter((f) => f[1] !== "EE"));

   useEffect(() => {
      if (form) {
         form.setFieldsValue(params);
      }
   }, [form, visible]);

   const generateFiscalCode = (values) => {
      const cf = new CodiceFiscale({
         name: values.firstName,
         surname: values.lastName,
         gender: values.gender ? "F" : "M",
         day: values.birthday.format("D"),
         month: values.birthday.format("M"),
         year: values.birthday.format("YYYY"),
         birthplace: values.birthplace,
         birthplaceProvincia: values.birthplaceProvincia,
      });

      return cf;
   };

   return (
      <Modal
         forceRender
         title={<IntlMessages id="frontend.fiscal_code_generate" />}
         visible={visible}
         onCancel={() => onDismiss()}
         footer={null}
         maskClosable={false}
      >
         <Form
            form={form}
            initialValues={{
               ...params,
               gender: true,
               birthday_country_italy: true,
            }}
            name="basic"
            onFinish={(values) => {
               const params = {
                  ...values,
                  birthplace: comuni[values.birthplace][2],
                  birthplaceProvincia: comuni[values.birthplace][1],
               };
               const result = generateFiscalCode(params);
               onSuccess(result);
            }}
            // onFinishFailed={(errors) => {
            //    console.log(errors);
            // }}
            // onError={(error) => {
            //    console.log(error);
            // }}
            autoComplete="off"
            className="gx-signin-form gx-form-row0"
            labelCol={{ sm: 9 }}
         >
            <div className="gx-ml-3 gx-mr-3">
               <Form.Item
                  rules={[
                     {
                        required: true,
                        message: intl.formatMessage({
                           id: "services.new_service_required_field",
                        }),
                     },
                  ]}
                  name="lastName"
                  label={intl.formatMessage({
                     id: "common.last_name",
                  })}
               >
                  <Input
                     autoFocus
                     placeholder={intl.formatMessage({
                        id: "common.last_name",
                     })}
                     maxLength={50}
                  />
               </Form.Item>
               <Form.Item
                  rules={[
                     {
                        required: true,
                        message: intl.formatMessage({
                           id: "services.new_service_required_field",
                        }),
                     },
                  ]}
                  name="firstName"
                  label={intl.formatMessage({
                     id: "common.first_name",
                  })}
               >
                  <Input
                     placeholder={intl.formatMessage({
                        id: "common.first_name",
                     })}
                     maxLength={50}
                  />
               </Form.Item>
               <Form.Item
                  name="gender"
                  label={intl.formatMessage({
                     id: "common.gender",
                  })}
                  valuePropName="checked"
                  defaultChecked={true}
               >
                  <Switch
                     size="default"
                     defaultChecked={true}
                     checkedChildren={intl.formatMessage({
                        id: "common.female",
                     })}
                     unCheckedChildren={intl.formatMessage({
                        id: "common.male",
                     })}
                  />
               </Form.Item>
               <Form.Item
                  rules={[
                     {
                        required: true,
                        message: intl.formatMessage({
                           id: "services.new_service_required_field",
                        }),
                     },
                  ]}
                  name="birthday"
                  label={intl.formatMessage({
                     id: "common.birthday",
                  })}
               >
                  <DatePicker
                     format={DATE_FORMAT}
                     style={{ width: "100%" }}
                     placeholder="gg-mm-aaaa"
                  />
               </Form.Item>
               <Form.Item
                  name="birthday_country_italy"
                  label={intl.formatMessage({
                     id: "common.birthday_country",
                  })}
                  valuePropName="checked"
               >
                  <Switch
                     checkedChildren={intl.formatMessage({ id: "common.italy" })}
                     unCheckedChildren={intl.formatMessage({ id: "common.foreign" })}
                     onChange={(checked) => {
                        if (checked) {
                           setComuni(COMUNI.filter((f) => f[1] !== "EE"));
                        } else {
                           setComuni(COMUNI.filter((f) => f[1] === "EE"));
                        }
                     }}
                  />
               </Form.Item>
               <Form.Item
                  rules={[
                     {
                        required: true,
                        message: intl.formatMessage({
                           id: "services.new_service_required_field",
                        }),
                     },
                  ]}
                  name="birthplace"
                  label={intl.formatMessage({
                     id: "common.birthplace",
                  })}
               >
                  <Select
                     showSearch
                     style={{ width: "100%" }}
                     allowClear
                     filterOption={(input, option) => {
                        return option.nomeComune
                           ?.toLowerCase()
                           .startsWith(input.toLowerCase());
                     }}
                  >
                     {comuni.map((comune, idx) => {
                        const [_, provincia, nome] = comune;
                        return (
                           <Select.Option
                              key={`opt-com-${idx}`}
                              nomeComune={nome}
                              value={idx}
                           >
                              {startCase(nome.toLowerCase())}&nbsp;({provincia})
                           </Select.Option>
                        );
                     })}
                  </Select>
               </Form.Item>
            </div>
            <div className="gx-text-right">
               <Divider className="gx-mb-3" />
               <Button key="back" onClick={() => onDismiss()}>
                  <IntlMessages id="common.close" />
               </Button>

               <Button key="submit" htmlType="submit" type="primary">
                  <IntlMessages id="common.confirm" />
               </Button>
            </div>
         </Form>
      </Modal>
   );
};

FiscalCodeModal.propTypes = {
   params: PropTypes.object,
   visible: PropTypes.bool.isRequired,
   onDismiss: PropTypes.object.isRequired,
   onSuccess: PropTypes.object.isRequired,
};

export default FiscalCodeModal;
