import { gql } from "@apollo/client";

const CATEGORY_FRAGMENT = gql`
   fragment CategoryFragment on ProductCategory {
      id
      name
      description
      level
      is_root
      is_leaf
      is_last_level
   }
`;

export const GQL_PAGINATED_PRODUCTS = gql`
   query paginatedProducts(
      $sorting: SortingInput
      $pagination: PaginationInput
      $filter: FilterProductInput
   ) {
      paginatedProducts(
         sorting: $sorting
         pagination: $pagination
         filter: $filter
      ) {
         items {
            id
            name
            is_active
            is_featured
            description
            category {
               id
               path_formatted
            }
            cover {
               id
               url
            }
            created_at
         }
         pagination {
            page
            pages
            current_items
            total_items
            size
         }
      }
   }
`;

export const GQL_PRODUCT = gql`
   query product($id: String!) {
      product(id: $id) {
         id
         is_active
         is_featured
         consumable
         condition
         priority
         priority_int
         category_id
         name
         code
         tags
         description
         warehouse_reference {
            sector
            rack
         }
         warehouse_date
         meta_data {
            title
            description
            keywords
         }
         note
         created_at
         assets {
            id
            file_name
            url
         }
         reservations {
            id
            customer {
               id
               full_name
               fiscal_code
               email
               phone_number
            }
            status
            created_at
         }
         delivery_time
      }
      productCategories {
         ...CategoryFragment
         children: productCategories {
            ...CategoryFragment
            children: productCategories {
               ...CategoryFragment
            }
         }
      }
      productMetadata {
         conditions {
            id
            name
         }
         priorities {
            id
            name
         }
      }
   }
   ${CATEGORY_FRAGMENT}
`;

export const GQL_PRODUCT_CATEGORY = gql`
   query productCategory($id: String!) {
      productCategory(id: $id) {
         ...CategoryFragment
         metadata {
            products_count
         }
      }
   }
   ${CATEGORY_FRAGMENT}
`;

export const GQL_CATEGORIES_TREE = gql`
   query categoriesTree {
      productCategories {
         ...CategoryFragment
         children: productCategories {
            ...CategoryFragment
            children: productCategories {
               ...CategoryFragment
            }
         }
      }
   }
   ${CATEGORY_FRAGMENT}
`;

export const GQL_PAGINATED_PRODUCT_RESERVATIONS = gql`
   query paginatedProductReservations(
      $sorting: SortingInput
      $pagination: PaginationInput
      $filter: FilterProductReservationInput
   ) {
      paginatedProductReservations(
         sorting: $sorting
         pagination: $pagination
         filter: $filter
      ) {
         items {
            id
            code
            status
            product {
               id
               name
               cover {
                  id
                  url
               }
               category {
                  id
                  path_formatted
               }
               delivery_time
            }
            customer {
               id
               full_name
               fiscal_code
               email
            }
            created_at
            ready_for_delivery_at
            delivered_at
            document {
               id
               url
            }
         }
         pagination {
            page
            pages
            current_items
            total_items
            size
         }
      }
   }
`;

export const GQL_PRODUCT_RESERVATION = gql`
   query productReservation($id: String!) {
      productReservation(id: $id) {
         id
         code
         status
         created_at
         customer_note
         note
         rejected_note
         customer {
            id
            full_name
            fiscal_code
            email
            phone_number
         }
         product {
            id
            name
            is_active
            is_featured
            description
            category {
               id
               path_formatted
            }
            cover {
               id
               url
            }
         }
         created_at
         ready_for_delivery_at
         delivered_at
         document {
            id
            url
         }
      }
   }
`;

export const GQL_EXPORT_PRODUCTS = gql`
   query exportReuseBoardProducts {
      exportReuseBoardProducts {
         content
         filename
      }
   }
`;

export const GQL_EXPORT_PRODUCT_RESERVATIONS = gql`
   query exportReuseBoardProductReservations(
      $filters: FilterProductReservationInput
   ) {
      exportReuseBoardProductReservations(filters: $filters) {
         content
         filename
      }
   }
`;

export const GQL_PRODUCT_PRINT_LABEL = gql`
   query printReuseBoardProductLabel($id: String!) {
      printReuseBoardProductLabel(id: $id)
   }
`;

export const GQL_COUNT_PRODUCT_RESERVATIONS = gql`
   query countProductReservations($filters: FilterProductReservationInput) {
      comuni {
         id
         name
         istat_code
      }
      countProductReservations(filters: $filters) {
         items {
            reservations {
               value
               comune_code
               comune_name
            }
            date
         }
         aggregate {
            aggregate_by
            total
         }
      }
      availableProductsByCategory {
         items {
            value
            category
         }
         aggregate {
            aggregate_by
            total
         }
      }
      reservedProductsByCategory(filters: $filters) {
         items {
            value
            category
         }
         aggregate {
            aggregate_by
            total
         }
      }      
   }
`;
