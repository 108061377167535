export const DRAFTJS_FORMATTERS_COMPONENT_MAPPING = {
   "header-one": {
      component: "typography",
      props: { level: 1 },
   },
   "header-two": {
      component: "typography",
      props: { level: 2 },
   },
   "header-three": {
      component: "typography",
      props: { level: 3 },
   },
   "header-four": {
      component: "typography",
      props: { level: 4 },
   },
   "header-five": {
      component: "typography",
      props: { level: 5 },
   },
   "header-six": {
      component: "typography",
      props: { level: 6 },
   },
   blockquote: {
      component: "blockquote",
      props: null,
   },
   "code-block": {
      component: "codeblock",
      props: null,
   },
   "unordered-list-item": {
      component: "list",
      props: { ordered: false },
   },
   "ordered-list-item": {
      component: "list",
      props: { ordered: true },
   },
   unstyled: {
      component: "paragraph",
      props: null,
   },
};
