import React from "react";
import { Card, Descriptions } from "antd";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../../../util/IntlMessages";

const CustomerCard = ({ customer, note, intl }) => {
   return (
      <Card className="gx-card" title={<IntlMessages id="customers.customer" />}>
         <Descriptions column={1} bordered>
            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({ id: "common.nominativo" })}
                  </h4>
               }
            >
               <p className="gx-mb-0">{customer.full_name}</p>
            </Descriptions.Item>
            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({ id: "common.fiscal_code" })}
                  </h4>
               }
            >
               <p className="gx-mb-0">{customer.fiscal_code}</p>
            </Descriptions.Item>
            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({ id: "common.email" })}
                  </h4>
               }
            >
               <p className="gx-mb-0">{customer.email}</p>
            </Descriptions.Item>
            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({ id: "common.phone_number" })}
                  </h4>
               }
            >
               <p className="gx-mb-0">{customer.phone_number}</p>
            </Descriptions.Item>
            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({ id: "common.note" })}
                  </h4>
               }
            >
               <p className="gx-mb-0">{note}</p>
            </Descriptions.Item>
         </Descriptions>
      </Card>
   );
};

export default injectIntl(CustomerCard);
