import { gql } from "@apollo/client";

export const GQL_PROCESS_RESERVATION = gql`
   mutation processReservation($reservation: ProcessReservationInput!) {
      processReservation(reservation: $reservation) {
         id
         status
         payment_intent
      }
   }
`;

export const GQL_PROCESS_RESERVATION_ADMIN = gql`
   mutation processReservationAdmin($reservation: ProcessReservationInput!) {
      processReservationAdmin(reservation: $reservation) {
         id
         status
      }
   }
`;

export const GQL_UPDATE_RESERVATION_STATUS = gql`
   mutation updateReservationStatus(
      $id: String!
      $status: String!
      $employee_id: String
      $cancellation_reason: String
      $comune_cod_ref: String
   ) {
      updateReservationStatus(
         id: $id
         status: $status
         employee_id: $employee_id
         cancellation_reason: $cancellation_reason
         comune_cod_ref: $comune_cod_ref
      )
   }
`;

export const GQL_PROCESSING_RESERVATION = gql`
   mutation processingReservation($reservation: ProcessingReservationInput!) {
      processingReservation(reservation: $reservation)
   }
`;

export const GQL_DELETE_RESERVATION = gql`
   mutation deleteReservation($id: String!) {
      deleteReservation(id: $id)
   }
`;

export const GQL_RESERVATION_SMS = gql`
   mutation sendReservationSms($id: String!) {
      sendReservationSms(id: $id)
   }
`;

export const GQL_RESERVATION_DELETE_CONFIRM_FRONTEND = gql`
   mutation reservationFrontendToDeleteConfirm($args: FrontendReservationInput!) {
      reservationFrontendToDeleteConfirm(args: $args)
   }
`;

export const GQL_RESERVATION_FEEDBACK_FRONTEND = gql`
   mutation reservationFeedback(
      $ratings: ReservationRatingInput!
      $token: String!
      $id: String!
   ) {
      reservationFeedback(ratings: $ratings, token: $token, id: $id)
   }
`;

export const GQL_RESERVATION_EMPLOYEE_NOTE = gql`
   mutation reservationEmployeeNote($id: String!, $note: String) {
      reservationEmployeeNote(id: $id, note: $note)
   }
`;

export const UPDATE_RESERVATION_DATE = gql`
   mutation updateReservationDate(
      $id: String!
      $date: String!
      $time: String!
      $send_email: Boolean
      $is_overbooked: Boolean
   ) {
      updateReservationDate(
         id: $id
         date: $date
         time: $time
         send_email: $send_email
         is_overbooked: $is_overbooked
      )
   }
`;

export const GQL_MANUAL_SENDING_RESERVATION_MAIL = gql`
   mutation manualSendingReservationMail($id: String!) {
      manualSendingReservationMail(id: $id)
   }
`;
