import { gql } from "@apollo/client";

export const GQL_QUERY_ME = gql`
   query me {
      me {
         id
         first_name
         last_name
         full_name
         username
         email
         phone_number
         current_organization {
            id
         }
         current_workspace {
            id
            name
            created_at
         }
         workspaces {
            id
            name
            created_at
         }
         role
         ability {
            action
            subject
            fields
            conditions
         }
         auth {
            verified
            phone_verified
            can_edit_phone_number
            phone_verification_token_expire_at
         }
         assignable_roles
      }
   }
`;

export const GQL_INIT_FRONTEND = gql`
   query frontEndMe {
      me {
         id
         first_name
         last_name
         full_name
         username
         email
         phone_number
         role
         auth {
            verified
            phone_verified
            can_edit_phone_number
            phone_verification_token_expire_at
         }
         customer {
            id
            first_name
            last_name
         }
         ability {
            action
            subject
            fields
            conditions
         }
         current_workspace {
            id
            name
            created_at
         }
         workspaces {
            id
            name
            created_at
         }
         current_organization {
            id
            name
            logo {
               url
            }
            provider {
               stripe {
                  public_key
               }
            }
         }
      }
   }
`;

export const GQL_INIT_QUERY = gql`
   query initQuery {
      me {
         id
         first_name
         last_name
         full_name
         username
         email
         phone_number
         current_workspace {
            id
            name
            created_at
         }
         current_organization {
            id
            name
            base_url
         }
         workspaces {
            id
            name
            created_at
         }
         role
         ability {
            action
            subject
            fields
            conditions
         }
         assignable_roles
      }
      workspaces {
         id
         name
      }
   }
`;

export const GQL_GET_USER = gql`
   query user($id: String!) {
      user(id: $id) {
         id
         first_name
         last_name
         full_name
         username
         email
         phone_number
         role
         auth {
            disabled
            verified
            phone_verified
         }
         role_reference_id
         role_reference {
            ... on Employee {
               id
               employee_first_name: first_name
               employee_last_name: last_name
            }
            ... on Customer {
               id
               customer_first_name: first_name
               customer_last_name: last_name
            }
         }
      }
      me {
         assignable_roles
      }
   }
`;

export const GQL_LOCAL_ME = gql`
   query localMe {
      me @client {
         id
         first_name
         last_name
         full_name
         email
         phone_number
         role
         current_workspace {
            id
            name
            created_at
         }
         workspaces {
            id
            name
            created_at
         }
      }
   }
`;

export const GQL_PAGINATED_USERS = gql`
   query paginatedUsers(
      $sorting: SortingInput
      $pagination: PaginationInput
      $filter: FilterUserInput
   ) {
      paginatedUsers(sorting: $sorting, pagination: $pagination, filter: $filter) {
         items {
            id
            first_name
            last_name
            username
            email
            phone_number
            role
            role_reference {
               ... on Employee {
                  id
                  employee_first_name: first_name
                  employee_last_name: last_name
               }
               ... on Customer {
                  id
                  customer_first_name: first_name
                  customer_last_name: last_name
               }
            }
         }
         pagination {
            page
            pages
            current_items
            total_items
            size
         }
      }
   }
`;

export const GQL_SEARCH_USERS = gql`
   query users($sorting: SortingInput, $filter: FilterUserInput, $limit: Int) {
      users(sorting: $sorting, filter: $filter, limit: $limit) {
         id
         first_name
         last_name
         username
      }
   }
`;

export const GQL_ASSIGNABLE_ROLES = gql`
   query assignableRolesQuery {
      me @client {
         assignable_roles
      }
   }
`;
