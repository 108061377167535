import { useMutation, useQuery } from "@apollo/client";
import {
   Button,
   Breadcrumb,
   Row,
   Col,
   Form,
   message,
   PageHeader,
   Modal,
   Card,
   Typography,
   DatePicker,
} from "antd";
import React from "react";
import {
   GQL_PAGINATED_PRODUCT_RESERVATIONS,
   GQL_PRODUCT_RESERVATION,
} from "../../../../apollo/query/reuse-board";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router";
import IntlMessages from "../../../../util/IntlMessages";
import CircularProgress from "../../../../components/CircularProgress/index";
import {
   defaultMutationCallback,
   defaultCatchException,
} from "../../../../apollo/callbacks";
import BadResponseErrorAlert from "../../../../components/BadResponseErrorAlert";
import { getAdminPath } from "util/router";
import BadResponse from "../../../../containers/BadResponse/index";
import { ReuseBoardBreadcrumbs } from "../products/common";
import { DeleteOutlined, PrinterOutlined } from "@ant-design/icons";
import NotFound from "../../../../containers/NotFound";
import {
   GQL_PRODUCT_RESERVATION_DELETE,
   GQL_PRODUCT_RESERVATION_UPDATE,
} from "../../../../apollo/mutation/reuse-board";
import { ProductReservationStatuses } from "../../../../constants/Enums";
import CustomerCard from "./card/customer-card";
import ProductCard from "./card/product-card";
import { ProductReservationStatusSelect } from "./common";
import { formatDateTime } from "../../../../util/date";
import TextArea from "antd/lib/input/TextArea";
import { DATETIME_FORMAT } from "../../../../constants/App";
import moment from "moment";

const toRefetchQueries = [
   {
      query: GQL_PAGINATED_PRODUCT_RESERVATIONS,
      variables: {
         pagination: {
            page: 1,
         },
         filter: {},
      },
   },
];

const ReservationUpdateForm = ({ reservation, intl, refetch }) => {
   const history = useHistory();
   const [form] = Form.useForm();

   const { product, customer } = reservation;

   const closeHandler = () => {
      history.push(getAdminPath("reuse-board/reservations"));
   };
   const [updateReservation, { loading: updateLoading }] = useMutation(
      GQL_PRODUCT_RESERVATION_UPDATE,
      {
         refetchQueries: toRefetchQueries,
         fetchPolicy: "no-cache",
         awaitRefetchQueries: true,
      }
   );

   const [deleteReservation, { loading: deleteLoading }] = useMutation(
      GQL_PRODUCT_RESERVATION_DELETE,
      {
         refetchQueries: toRefetchQueries,
         fetchPolicy: "no-cache",
         awaitRefetchQueries: true,
      }
   );

   const handleOk = (values) => {
      const params = {
         id: reservation.id,
         status: values.status.toUpperCase(), //sistemare
         note: values.note,
         rejected_note: values.rejected_note,
         delivered_at: values.delivered_at
            ? values.delivered_at.toISOString()
            : null,
         ready_for_delivery_at: values.ready_for_delivery_at
            ? values.ready_for_delivery_at.toISOString()
            : null,
      };

      updateReservation({ variables: { input: params } })
         .then((data) => {
            defaultMutationCallback(data, () => {
               refetch && refetch();
               closeHandler();
            });
         })
         .catch((e) => {
            defaultCatchException(e, intl);
         });
   };

   const handleError = (error) => {
      console.log("Error", error);
   };

   const pdfHandler = (document) => window.open(document.url, "_blank");

   return (
      <>
         <Row>
            <Col md={24}>
               <ReuseBoardBreadcrumbs>
                  <Breadcrumb.Item href="#" onClick={() => closeHandler()}>
                     <IntlMessages id="sidebar.products_reservations" />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item href="#">
                     <IntlMessages id="reuse_board.update_reservation" />
                  </Breadcrumb.Item>
               </ReuseBoardBreadcrumbs>
            </Col>
         </Row>
         <Form
            form={form}
            onFinish={handleOk}
            onFinishFailed={handleError}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
               ...reservation,
               delivered_at: reservation.delivered_at
                  ? moment(reservation.delivered_at)
                  : null,
               ready_for_delivery_at: reservation.ready_for_delivery_at
                  ? moment(reservation.ready_for_delivery_at)
                  : null,
               status: ProductReservationStatuses[reservation.status],
            }}
            autoComplete="off"
         >
            <Row>
               <Col xs={24}>
                  <PageHeader
                     onBack={() => history.goBack()}
                     title={<IntlMessages id="reuse_board.update_reservation" />}
                     extra={[
                        <Button key="back" onClick={() => closeHandler()}>
                           <IntlMessages id="common.cancel" />
                        </Button>,
                        <Button
                           className="gx-bg-success gx-text-white"
                           key="download"
                           disabled={!reservation.document}
                           onClick={() => pdfHandler(reservation.document)}
                        >
                           <PrinterOutlined className="gx-mr-2" />
                           <IntlMessages id="reuse_board.reservation_pdf" />
                        </Button>,
                        <Button
                           key="delete"
                           type="danger"
                           onClick={() => {
                              Modal.confirm({
                                 title: intl.formatMessage({ id: "common.warning" }),
                                 content: intl.formatMessage({
                                    id: "common.default_mutation_ask_delete",
                                 }),
                                 okText: intl.formatMessage({ id: "common.delete" }),
                                 cancelText: intl.formatMessage({ id: "common.no" }),
                                 okType: "danger",
                                 okButtonProps: {
                                    loading: deleteLoading,
                                 },
                                 onOk: () => {
                                    deleteReservation({
                                       variables: { id: reservation.id },
                                    })
                                       .then((data) => {
                                          defaultMutationCallback(data, () => {
                                             message.success(
                                                intl.formatMessage({
                                                   id: "common.default_mutation_success",
                                                })
                                             );
                                             closeHandler();
                                          });
                                       })
                                       .catch((e) => {
                                          defaultCatchException(e, intl);
                                       });
                                 },
                              });
                           }}
                           icon={<DeleteOutlined className="gx-pr-1" />}
                        >
                           <IntlMessages id="common.delete" />
                        </Button>,
                        <Button
                           htmlType="submit"
                           key="submit"
                           type="primary"
                           loading={updateLoading}
                        >
                           {intl
                              .formatMessage({ id: "common.update" })
                              .toUpperCase()}
                        </Button>,
                     ]}
                  />
               </Col>
               <Col xs={24}>
                  <BadResponseErrorAlert />
               </Col>
            </Row>
            <Row>
               <Col xs={24} sm={24} md={24} lg={12}>
                  <Col xs={24}>
                     <ProductCard product={product} />
                     <CustomerCard
                        customer={customer}
                        note={reservation.customer_note}
                     />
                  </Col>
               </Col>
               <Col xs={24} sm={24} md={24} lg={12}>
                  <Card
                     className="gx-card"
                     title={<IntlMessages id="reservations.reservation" />}
                  >
                     <Form.Item label={<IntlMessages id="common.code" />}>
                        <Typography.Text copyable>
                           {reservation.code}
                        </Typography.Text>
                     </Form.Item>
                     <Form.Item label={<IntlMessages id="common.date" />}>
                        {formatDateTime(reservation.created_at)}
                     </Form.Item>

                     <Form.Item
                        name="status"
                        label={<IntlMessages id="common.status" />}
                        tooltip={intl.formatMessage({
                           id: "reuse_board.status_update_tooltip",
                        })}
                        required
                        rules={[
                           {
                              required: true,
                              message: intl.formatMessage({
                                 id: "services.new_service_required_field",
                              }),
                           },
                        ]}
                     >
                        <ProductReservationStatusSelect
                           onChange={(value) => {
                              form.setFieldsValue({
                                 status: value,
                                 delivered_at:
                                    value === ProductReservationStatuses.DELIVERED
                                       ? moment()
                                       : null,
                              });
                           }}
                        />
                     </Form.Item>

                     <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                           prevValues.status !== currentValues.status
                        }
                     >
                        {({ getFieldValue }) =>
                           getFieldValue("status") ===
                           ProductReservationStatuses.REJECTED ? (
                              <Form.Item
                                 name="rejected_note"
                                 label={
                                    <IntlMessages id="reuse_board.rejected_reason" />
                                 }
                              >
                                 <TextArea rows={4} maxLength={255} showCount />
                              </Form.Item>
                           ) : null
                        }
                     </Form.Item>

                     <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                           prevValues.status !== currentValues.status
                        }
                     >
                        {({ getFieldValue }) =>
                           getFieldValue("status") ===
                           ProductReservationStatuses.DELIVERED ? (
                              <Form.Item
                                 name="delivered_at"
                                 label={
                                    <IntlMessages id="reuse_board.delivered_at" />
                                 }
                                 required
                                 rules={[
                                    {
                                       required: true,
                                       message: intl.formatMessage({
                                          id: "services.new_service_required_field",
                                       }),
                                    },
                                 ]}
                              >
                                 <DatePicker showTime format={DATETIME_FORMAT} />
                              </Form.Item>
                           ) : null
                        }
                     </Form.Item>

                     <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                           prevValues.status !== currentValues.status
                        }
                     >
                        {({ getFieldValue }) =>
                           getFieldValue("status") ===
                           ProductReservationStatuses.READY_FOR_DELIVERY ? (
                              <Form.Item
                                 name="ready_for_delivery_at"
                                 label={
                                    <IntlMessages id="reuse_board.delivery_timetable" />
                                 }
                                 required
                                 rules={[
                                    {
                                       required: true,
                                       message: intl.formatMessage({
                                          id: "services.new_service_required_field",
                                       }),
                                    },
                                 ]}
                              >
                                 <DatePicker
                                    showTime
                                    format={DATETIME_FORMAT}
                                    minuteStep={5}
                                 />
                              </Form.Item>
                           ) : null
                        }
                     </Form.Item>

                     <Form.Item
                        label={intl.formatMessage({
                           id: "app_reports.operator_note",
                        })}
                        name="note"
                        tooltip={intl.formatMessage({
                           id: "common.private_field_p1",
                        })}
                     >
                        <TextArea rows={4} maxLength={255} showCount />
                     </Form.Item>
                  </Card>
               </Col>
            </Row>
         </Form>
      </>
   );
};

const ReservationUpdate = ({ match, intl }) => {
   const { id } = match.params;

   const { data, loading, error, refetch } = useQuery(GQL_PRODUCT_RESERVATION, {
      variables: { id: id },
      fetchPolicy: "no-cache",
      onError: (error) => {
         message.error(intl.formatMessage({ id: "common.default_query_error" }));
      },
   });

   if (loading) {
      return <CircularProgress />;
   }

   if (error) {
      return (
         <BadResponse title={intl.formatMessage({ id: "500.something_wrong" })} />
      );
   }

   if (!data || !data.productReservation) {
      return <NotFound />;
   }

   if (!error && data && data.productReservation) {
      const { productReservation } = data;
      return (
         <ReservationUpdateForm
            reservation={productReservation}
            intl={intl}
            refetch={refetch}
         />
      );
   }
};

export default injectIntl(ReservationUpdate);
