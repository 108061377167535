import React, { useContext, useState } from "react";
import {
   Button,
   Input,
   Breadcrumb,
   Card,
   Row,
   Col,
   Table,
   message,
   Popover,
   Modal,
   PageHeader,
   Space,
   Tag,
} from "antd";
import {
   MoreOutlined,
   PlusOutlined,
   ExclamationCircleOutlined,
   CloseOutlined,
   CheckOutlined,
   BulbOutlined,
   WarningOutlined,
} from "@ant-design/icons";
import IntlMessages from "../../../../util/IntlMessages";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { FormattedDate, injectIntl } from "react-intl";
import { Can, AbilityContext } from "acl/index";
import Text from "antd/lib/typography/Text";
import {
   GQL_PAGINATED_PRODUCTS,
   GQL_PRODUCT_PRINT_LABEL,
} from "../../../../apollo/query/reuse-board";
import { GQL_PRODUCT_DELETE } from "../../../../apollo/mutation/reuse-board";
import { paginationOptions } from "../../../../constants/PaginationOptions";
import { useDebouncedCallback } from "use-debounce";
import { debounceSearchFilterDelay } from "../../../../util/config";
import { usePersistedState } from "../../../../hooks/usePersistedState";
import { get, isArray, pick } from "lodash";
import { getAdminPath } from "../../../../util/router";
import InsertProductModal from "./insert-product-modal";
import {
   defaultCatchException,
   defaultMutationCallback,
} from "../../../../apollo/callbacks";
import { ReuseBoardBreadcrumbs } from "./common";
import ReservationProductAvatar from "../../../../components/ReservationProductAvatar";
import ExportProductsButton from "./export-button";
import { openPDFDocumentWindow } from "../../../../util/file";
import { serverTableSorter } from "../../../../util/antd";
import ConditionalDateInputFilter from "../../../../components/ConditionalDateInputFilter";


const PopoverProduct = ({
   intl,
   record,
   ability,
   history,
   tableSearchOptions,
   onPrintLabel,
}) => {
   const [deleteProduct] = useMutation(GQL_PRODUCT_DELETE, {
      refetchQueries: [
         {
            query: GQL_PAGINATED_PRODUCTS,
            variables: tableSearchOptions,
         },
      ],
      awaitRefetchQueries: true,
      fetchPolicy: "no-cache",
   });

   const userMenuOptions = (
      <ul className="gx-user-popover">
         {ability.can("update", "Product") && (
            <li
               onClick={(e) => {
                  e.stopPropagation();
                  updateProduct(record.id);
               }}
            >
               <Text type="default">
                  <IntlMessages id="common.update" />
               </Text>
            </li>
         )}

         {ability.can("manage", "Product") && (
            <li
               onClick={(e) => {
                  e.stopPropagation();
                  onPrintLabel();
               }}
            >
               <Text type="success">
                  <IntlMessages id="common.print_label" />
               </Text>
            </li>
         )}

         {ability.can("delete", "Product") && (
            <li
               onClick={(e) => {
                  e.stopPropagation();
                  confirmDeleteProduct();
               }}
            >
               <Text type="danger">
                  <IntlMessages id="common.delete" />
               </Text>
            </li>
         )}
      </ul>
   );

   const updateProduct = (productId) => {
      history.push(`${getAdminPath("reuse-board/products/update")}/${productId}`);
   };

   const confirmDeleteProduct = () => {
      Modal.confirm({
         title: intl.formatMessage({ id: "common.warning" }),
         icon: <ExclamationCircleOutlined />,
         content: intl.formatMessage({
            id: "services.new_service_delete_confirm_message",
         }),
         okText: intl.formatMessage({ id: "common.delete" }),
         cancelText: intl.formatMessage({ id: "common.no" }),
         okType: "danger",
         onOk() {
            deleteProduct({ variables: { id: record.id } })
               .then((data) => {
                  if (
                     data?.errors &&
                     isArray(data?.errors) &&
                     data?.errors.length > 0
                  ) {
                     message.error(
                        intl.formatMessage({ id: data?.errors[0].message })
                     );
                  } else {
                     defaultMutationCallback(data, () => {
                        message.success(
                           intl.formatMessage({ id: "products.delete_success" })
                        );
                     });
                  }
               })
               .catch((e) => {
                  console.log(e);
                  defaultCatchException(e, intl);
               });
         },
      });
   };

   if (ability.can("update", "Product") || ability.can("delete", "Product")) {
      return (
         <Popover
            placement="bottomRight"
            content={userMenuOptions}
            trigger="click"
            onClick={(e) => {
               e.stopPropagation();
            }}
         >
            <MoreOutlined />
         </Popover>
      );
   } else {
      return null;
   }
};

const Products = ({ match, intl }) => {
   const history = useHistory();
   const ability = useContext(AbilityContext);
   const [insertProductModalVisible, setInsertProductModalVisible] = useState(false);
   const [printLabel, { loading: loadingMutation }] = useLazyQuery(
      GQL_PRODUCT_PRINT_LABEL
   );

   const [tableSearchOptions, setTableSearchOptions] = usePersistedState(
      match,
      "tableFilterProducts",
      {
         pagination: {
            limit: parseInt(paginationOptions.pageSizeOptions[0]),
            page: 1,
         },
         filter: {
            term: "",
         },
         sorting: {
            key: "created_at",
            direction: "descend",
         },
      }
   );

   const setFilter = (value) => {
      setTableSearchOptions({
         ...tableSearchOptions,
         filter: {
            term: value ?? "",
         },
         pagination: {
            limit: tableSearchOptions.pagination.limit,
            page: 1,
         },
      });
   };

   const debouncedFilterCallback = useDebouncedCallback(
      (value) => setFilter(value),
      debounceSearchFilterDelay
   );

   //
   const columns = [
      {
         title: intl.formatMessage({ id: "common.name" }),
         dataIndex: "name",
         sorter: true,
         ...serverTableSorter("name", tableSearchOptions.sorting),
         fixed: "left",
         render(text, record) {
            return (
               <Space>
                  <ReservationProductAvatar product={record} /> {text}
               </Space>
            );
         },
      },
      {
         title: intl.formatMessage({ id: "common.path" }),
         dataIndex: ["category", "path_formatted"],
         render(text, record) {
            if (!record.category) {
               return (
                  <Tag color={record.is_active ? "red" : "blue"} className="gx-mb-0">
                     <WarningOutlined className="gx-mr-1" />
                     <IntlMessages id="categories.missing" />
                  </Tag>
               );
            } else return text;
         },
         sorter: false,
      },
      {
         title: intl.formatMessage({ id: "common.is_featured" }),
         dataIndex: "is_featured",
         width: 100,
         align: "center",
         render(isActive) {
            return isActive ? (
               <BulbOutlined className="gx-text-warning" />
            ) : (
               <CloseOutlined />
            );
         },
      },
      {
         title: intl.formatMessage({ id: "common.is_active" }),
         dataIndex: "is_active",
         width: 100,
         align: "center",
         render(isActive) {
            return isActive ? (
               <CheckOutlined className="gx-text-success" />
            ) : (
               <CloseOutlined />
            );
         },
      },
      {
         title: intl.formatMessage({ id: "common.created_at" }),
         dataIndex: "created_at",
         width: 180,
         sorter: true,
         ...serverTableSorter("created_at", tableSearchOptions.sorting),
         render: (text) => {
            return (
               <FormattedDate
                  value={text}
                  year="numeric"
                  month="2-digit"
                  day="2-digit"
                  hour="2-digit"
                  minute="2-digit"
               />
            );
         },
         responsive: ["xxl", "xl", "lg"],
      },
      {
         title: "",
         key: "operation",
         sorter: false,
         responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
         fixed: "right",
         align: "center",
         render: (_, record) => {
            return (
               <PopoverProduct
                  intl={intl}
                  record={record}
                  ability={ability}
                  history={history}
                  tableSearchOptions={tableSearchOptions}
                  onPrintLabel={() => {
                     printLabel({
                        variables: {
                           id: record.id,
                        },
                        fetchPolicy: "no-cache",
                     })
                        .then((response) => {
                           const content = get(
                              response,
                              "data.printReuseBoardProductLabel.content"
                           );

                           if (content) {
                              openPDFDocumentWindow(content);
                           }
                        })
                        .catch((error) => message.error(error.message));
                  }}
               />
            );
         },
         width: 60,
      },
   ];

   const fetchTableChange = (pagination, _, sorter) => {
      setTableSearchOptions({
         ...tableSearchOptions,
         pagination: {
            limit: pagination.pageSize,
            page: pagination.current,
         },
         sorting: {
            key: sorter.field ?? "created_at",
            direction: sorter.order,
         },
      });
   };

   const { data, loading } = useQuery(GQL_PAGINATED_PRODUCTS, {
      variables: {
         ...tableSearchOptions,
         sorting: {
            ...tableSearchOptions.sorting,
            direction: tableSearchOptions.sorting.direction === "descend" ? -1 : 1,
         },
      },
      onError: (error) => {
         message.error(intl.formatMessage({ id: "common.default_query_error" }));
      },
   });

   const pagination = {
      ...paginationOptions,
      total: get(data, "paginatedProducts.pagination.total_items", 0),
      current: tableSearchOptions.pagination.page,
      pageSize: tableSearchOptions.pagination.limit,
   };

   return (
      <>
         <Row>
            <Col md={24}>
               <ReuseBoardBreadcrumbs>
                  <Breadcrumb.Item href="#">
                     <IntlMessages id="sidebar.products" />
                  </Breadcrumb.Item>
               </ReuseBoardBreadcrumbs>
            </Col>
         </Row>
         <Row>
            <Col xs={24}>
               <PageHeader
                  title={<IntlMessages id="sidebar.products" />}
                  extra={[
                     <Can I="read" a="Product" key="1">
                        <ExportProductsButton />
                     </Can>,
                     <Can I="create" a="Product" key="2">
                        <Button
                           type="primary"
                           className="circle-add-button"
                           onClick={() => {
                              setInsertProductModalVisible(true);
                           }}
                        >
                           <PlusOutlined /> <IntlMessages id="products.new" />
                        </Button>
                     </Can>,
                  ]}
               />
            </Col>
            <Col md={24}>
               <Card>
                  <Row>
                     <Col md={24} sm={24} xs={24}>
                        <Space className="gx-mb-2">
                           <ConditionalDateInputFilter
                              placeholder={intl.formatMessage({
                                 id: "common.show_all",
                              })}
                              className="gx-mr-2 gx-mb-3"
                              onChange={(record) =>
                                 setTableSearchOptions({
                                    ...tableSearchOptions,
                                    filter: {
                                       ...tableSearchOptions.filter,
                                       last_booking: {
                                          ...pick(record, ["condition", "value"]),
                                       },
                                    },
                                 })
                              }
                              defaultValue={get(
                                 tableSearchOptions,
                                 "filter.last_booking",
                                 ""
                              )}
                           />

                           <Input.Search
                              allowClear
                              className="input-search gx-mb-3"
                              placeholder={intl.formatMessage({
                                 id: "common.search_element",
                              })}
                              onChange={(e) =>
                                 debouncedFilterCallback(
                                    get(
                                       e,
                                       "target.value",
                                       tableSearchOptions.filter.term
                                    )
                                 )
                              }
                              defaultValue={get(
                                 tableSearchOptions,
                                 "filter.term",
                                 ""
                              )}
                           />
                        </Space>
                     </Col>
                  </Row>
                  <Row>
                     <Col md={24} sm={24} xs={24}>
                        <Table
                           columns={columns}
                           rowKey={(record) => record.id}
                           dataSource={get(data, "paginatedProducts.items", [])}
                           pagination={pagination}
                           loading={loading || loadingMutation}
                           size="middle"
                           onRow={(record, rowIndex) => {
                              return {
                                 onClick: () => {
                                    history.push(
                                       `${getAdminPath(
                                          "reuse-board/products/update"
                                       )}/${record.id}`
                                    );
                                 },
                              };
                           }}
                           onChange={fetchTableChange}
                        />
                     </Col>
                  </Row>
               </Card>
            </Col>
         </Row>
         <InsertProductModal
            onCancel={() => {
               setInsertProductModalVisible(false);
            }}
            visible={insertProductModalVisible}
         />
      </>
   );
};

export default injectIntl(Products);
