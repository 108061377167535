import React from "react";
import { Alert, Typography } from "antd";
import IntlMessages from "../../util/IntlMessages";
import { RichTextEditorViewer } from "../RichTextEditor/rich-text-editor-viewer";
import { InfoCircleOutlined } from "@ant-design/icons";
import { head } from "lodash";

const ServiceAssets = ({ assets }) => {
   if (!assets || assets.length === 0) return null;

   return (
      <div className="gx-mt-3">
         <div className="gx-fs-lg gx-mb-2">
            <IntlMessages id="common.attachments" />
         </div>
         <ul>
            {assets.map((a) => {
               return (
                  <li key={`k-asset.${a.url}`} className="gx-mb-2">
                     <a href={a.url} target="_download_asset">
                        {a.file_name}
                     </a>
                  </li>
               );
            })}
         </ul>
      </div>
   );
};

const ReservationDetailInfo = ({ reservation }) => {
   const { services } = reservation;

   const detailedServices = services.filter(
      (service) => service.documentation || service.assets.length > 0
   );

   const emptyServicesDocumentation = (detailedServices, services) => {
      if (detailedServices.length === 0) return true;
      if (detailedServices.length === 1) {
         const blocks = head(services).documentation.blocks;
         if (blocks.length === 1) {
            if (
               blocks[0].type === "unstyled" &&
               blocks[0].text.trim().lenght === 0
            ) {
               return true;
            }
         }
      }

      return false;
   };

   if (emptyServicesDocumentation(detailedServices, services)) {
      return null;
   }

   return (
      <>
         <hr />
         <Typography.Paragraph>
            <IntlMessages id="frontend.giorno_appuntamento_p1" />
            &nbsp;
            <strong>
               <IntlMessages id="frontend.giorno_appuntamento_p2" />
            </strong>
         </Typography.Paragraph>
         <Typography.Paragraph>
            <strong>
               <IntlMessages id="common.warning" />:
            </strong>{" "}
            <IntlMessages id="frontend.giorno_appuntamento_p3" />
         </Typography.Paragraph>
         <Typography.Paragraph>
            <strong>
               <u>
                  <IntlMessages id="frontend.giorno_appuntamento_p4" />
               </u>
            </strong>
         </Typography.Paragraph>
         <hr />
         <Alert
            className="gx-mt-4"
            type="info"
            message={
               <div className="reservation-details-info">
                  <Typography.Title level={4}>
                     <InfoCircleOutlined />{" "}
                     <IntlMessages id="reservations.service_additional_info" />
                     <br />
                     <IntlMessages id="reservations.service_additional_info2" />
                     <br />
                  </Typography.Title>
                  {detailedServices.map((service, index) => {
                     return (
                        <div
                           className="service-detail"
                           key={`service-detail-k${index}`}
                        >
                           <Typography.Text className="gx-fs-xl gx-font-weight-bold gx-mb-3 gx-d-block gx-border-bottom-2 gx-pb-1">
                              {service.name}
                           </Typography.Text>
                           <RichTextEditorViewer
                              editorData={service.documentation}
                           />
                           <ServiceAssets assets={service.assets} />
                        </div>
                     );
                  })}
               </div>
            }
         />
      </>
   );
};

export default ReservationDetailInfo;
