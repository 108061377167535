import React from "react";
import { Select } from "antd";
import { injectIntl } from "react-intl";
import { first } from "lodash";

const CivicoRaccolteSelect = ({
   intl,
   numbers,
   onChange,
   value,
   placeholder,
   className = "",
}) => {
   return (
      <Select
         className={className}
         value={value}
         allowClear={true}
         style={{ width: 200 }}
         onChange={(value) =>
            onChange(first(numbers.filter((n) => n.number === value)))
         }
         placeholder={placeholder}
      >
         {numbers.map((c) => (
            <Select.Option
               value={c.number}
               key={`appreport_select_indirizzo_${c.number}`}
            >
               {c.number}
            </Select.Option>
         ))}
      </Select>
   );
};

export default injectIntl(CivicoRaccolteSelect);
