import React from "react";
import { Table, Tag } from "antd";
import { useIntl } from "react-intl";

function getAccumulateTimes(times, index) {
   if (index === 0) return 0;
   return times.slice(0, index).reduce((acc, time) => acc + time, 0);
}

function scheduledReservationToTableData(reservations, start) {
   if (reservations.length === 0) return [];

   const deliveryTimes = reservations.map((r) => r.product.delivery_time);

   const results = reservations.map((reservation, idx) => ({
      ...reservation,
      time: start
         .clone()
         .add("minutes", getAccumulateTimes(deliveryTimes, idx))
         .format("HH:mm"),
   }));

   return results;
}

const SchedulerTable = ({ reservations, start }) => {
   const intl = useIntl();

   const columns = [
      {
         title: intl.formatMessage({ id: "common.code" }),
         dataIndex: "code",
         width: 180,
         render: (value) => <small>{value}</small>,
      },
      {
         title: intl.formatMessage({ id: "products.product" }),
         dataIndex: ["product", "name"],
         key: "product",
         render(_, record) {
            const { product } = record;

            if (!product) return null;

            return (
               <>
                  <div>
                     <Tag className="gx-mb-0" color="green">
                        {record.product.name}
                     </Tag>
                  </div>
                  <small>{product.category.path_formatted}</small>
               </>
            );
         },
      },
      {
         title: intl.formatMessage({ id: "customers.customer" }),
         dataIndex: ["customer", "full_name"],
         key: "customer",
         render(_, record) {
            const { customer } = record;

            if (!customer) return null;

            return (
               <>
                  <div>{customer.full_name}</div>
                  <small>{customer.email}</small>
               </>
            );
         },
      },
      {
         title: intl.formatMessage({ id: "common.timetable" }),
         dataIndex: "time",
         width: 70,
      },
   ];

   return (
      <Table
         columns={columns}
         rowKey={(record) => record.id}
         dataSource={scheduledReservationToTableData(reservations, start)}
         pagination={false}
         size="middle"
      />
   );
};

export default SchedulerTable;
