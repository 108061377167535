import { get } from "lodash";
import { formatCurrency } from "../util/string";
import { roundNumber } from "../util/number";
import { convertFromRaw } from "draft-js";
import { EditorState } from "draft-js";
import { useIntl } from "react-intl";

const getFormattedPrice = (value, cent = false) => {
   if (!cent) {
      value = roundNumber(value / 100, 2);
   }

   return formatCurrency(value, 2);
};

export const ServiceEntity = (service) => {
   const price = get(service, "payment.price", 0);

   return {
      ...service,
      getFormattedPrice: (cent) => getFormattedPrice(price, (cent = false)),

      getEditorState: () => {
         if (!service.documentation) {
            return EditorState.createEmpty();
         }

         const contentState = convertFromRaw(JSON.parse(service.documentation));
         return EditorState.createWithContent(contentState);
      },
   };
};

export const ServiceCollection = (services) => {
   const intl = useIntl();

   let items = [];

   const totals = {
      price: 0,
      duration: 0,
   };

   services.forEach((service) => {
      totals.duration += service.duration;
      totals.price += get(service, "payment.price", 0);

      items.push(ServiceEntity(service));
   });

   return {
      shouldPay: () => {
         return totals.price > 0;
      },
      getNames: () => {
         return services.map((service) => service.name).join(", ");
      },
      getTotals: () => totals,
      getTotalPrice: () => totals.price,
      getFormattedTotalPrice: (cent) => {
         if (totals.price === 0) {
            return intl.formatMessage({ id: "reservations.nothing_to_pay" });
         }

         return getFormattedPrice(totals.price, (cent = false));
      },
      getItems() {
         return items;
      },
      isEmpty() {
         return items.length === 0;
      },
   };
};
