import { useQuery } from "@apollo/client";
import { GQL_CURRENT_ORGANIZATION } from "../apollo/query/organization";
import { OrganizationEntity } from "../entities/organization";

const useCurrentOrganization = () => {
   let organization = null;
   const result = useQuery(GQL_CURRENT_ORGANIZATION);

   if (result.data && result.data.currentOrganization) {
      organization = result.data.currentOrganization;
   }

   return { ...result, organization: OrganizationEntity(organization) };
};

export default useCurrentOrganization;
