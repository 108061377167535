import { InfoCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Modal, Input, Form, message, Button } from "antd";
import React, { useState } from "react";
import { GQL_VERIFY_USER_PHONE_NUMBER } from "../../apollo/mutation/user";
import IntlMessages from "../../util/IntlMessages";
import { get, first } from "lodash";
import {
   defaultCatchException,
   normalizeServerErrors,
} from "../../apollo/callbacks";

const PhoneNumberVerificationModal = ({ intl, visible, onCancel, refetch }) => {
   const [verifyUserPhoneNumber, { loading }] = useMutation(
      GQL_VERIFY_USER_PHONE_NUMBER
   );

   const [error, setError] = useState(null);

   return (
      <Modal
         visible={visible}
         icon={<InfoCircleOutlined />}
         onCancel={onCancel}
         footer={null}
         title={<IntlMessages id="signup.verify_phone_number" />}
      >
         <Form
            onFinish={(values) => {
               verifyUserPhoneNumber({ variables: { token: values.token } })
                  .then(({ data, errors }) => {
                     if (errors) {
                        const normalErrors = normalizeServerErrors(errors);
                        let error = first(normalErrors);
                        error.message = intl.formatMessage({ id: error.messageKey });
                        setError(error);
                     } else {
                        //success
                        message.success(
                           intl.formatMessage({
                              id: "signup.phone_number_validate_success",
                           })
                        );
                        onCancel();
                        refetch();
                     }
                  })
                  .catch((e) => defaultCatchException(e, intl));
            }}
            onFinishFailed={(errors) => {
               setError(get(errors, "errorFields.0.errors.0", null));
            }}
            layout="vertical"
            wrapperCol={{ span: 20 }}
            autoComplete="off"
         >
            <Form.Item
               hasFeedback
               validateStatus={error ? "error" : loading ? "validating" : null}
               help={error ? error.message : null}
               rules={[
                  {
                     required: true,
                     message: intl.formatMessage({ id: "validation.required" }),
                  },
               ]}
               name="token"
            >
               <Input
                  maxLength={6}
                  placeholder={intl.formatMessage({
                     id: "signup.insert_phone_confirmation",
                  })}
               />
            </Form.Item>
            <Form.Item>
               <Button loading={loading} type="primary" htmlType="submit">
                  {intl.formatMessage({ id: "common.submit" })}
               </Button>
            </Form.Item>
         </Form>
      </Modal>
   );
};

export default PhoneNumberVerificationModal;
