import React from "react";
import { Alert } from "antd";
import { useIntl } from "react-intl";

const GQLAlertErrors = ({ error }) => {
   const intl = useIntl();

   if (!error || error.graphQLErrors == null) {
      return (
         <Alert
            type="error"
            message={intl.formatMessage({ id: "common.unexpected_error" })}
         />
      );
   }

   return (
      <Alert
         type="error"
         message={
            <ul>
               {error.graphQLErrors.map(({ message }, i) => (
                  <li>{message}</li>
               ))}
            </ul>
         }
      />
   );
};

export default GQLAlertErrors;
