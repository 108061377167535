import { useQuery } from "@apollo/client";
import { Layout } from "antd";
import React, { useContext, useEffect } from "react";
import { injectIntl } from "react-intl";
import { AbilityContext, defineRulesFor } from "../../acl";
import { GQL_INIT_FRONTEND } from "../../apollo/query/user";
import CircularProgress from "../../components/CircularProgress";
import FrontendRoutes from "../../routes/frontend";
import { getFrontendPath } from "../../util/router";
import BadResponse from "../BadResponse";
import { Switch, Route } from "react-router";
import FrontendReservationConfirm from "../../routes/frontend/reservations/confirm";
import FrontendFooter from "../../routes/frontend/main/footer";
import FrontendHeader from "../../routes/frontend/main/header";
import IntlMessages from "../../util/IntlMessages";
import { localStorageClass } from "../../apollo/local/storage";
import { Redirect } from "react-router-dom";

const LayoutContainedApp = ({ user }) => {
   return (
      <Layout className="gx-frontend-app">
         <Layout.Content>
            <FrontendHeader
               user={user}
               title={<IntlMessages id="frontend.login_header" />}
            />
            <div className="frontend-container">
               <FrontendRoutes />
            </div>
         </Layout.Content>
         <FrontendFooter />
      </Layout>
   );
};

const ProtectedFrontendApp = ({ user }) => {
   const ability = useContext(AbilityContext);

   useEffect(() => {
      if (user) {
         ability.update(defineRulesFor(user));
      }
   }, [user, ability]);

   return (
      <Switch>
         {/* Senza layout */}
         <Route
            exact
            path={getFrontendPath("prenotazione/conferma")}
            component={FrontendReservationConfirm}
         />
         <Route to="*">
            <LayoutContainedApp user={user} />
         </Route>
      </Switch>
   );
};

const FrontendApp = ({ intl }) => {
   //TODO riorganizzare query startup, no cache per evitare duplicazione chiamate
   const {
      loading: loadingProfile,
      error: errorProfile,
      data,
   } = useQuery(GQL_INIT_FRONTEND, { fetchPolicy: "no-cache" });

   if (loadingProfile) {
      return (
         <div className="gx-loader-view">
            <CircularProgress />
         </div>
      );
   }

   if (data && data.me) {
      return <ProtectedFrontendApp user={data.me} />;
   }

   if (errorProfile) {
      return (
         <BadResponse
            title={intl.formatMessage({ id: "500.ooops" })}
            subtitle={intl.formatMessage({ id: "500.something_wrong" })}
            redirectURL={getFrontendPath()}
         />
      );
   }

   return null;
};

/**
 * evita di caricare il componente se non è settato il token di auth
 * @param {*} param0
 * @returns
 */
const FrontendAppWrapper = ({ intl, location }) => {
   //da qui routes sotto login
   if (!localStorageClass.isLogged()) {
      //
      return (
         <Redirect
            to={{
               pathname: getFrontendPath(),
               search: `?next=${encodeURIComponent(location.pathname)}`,
            }}
         />
      );
   }

   return <FrontendApp intl={intl} />;
};

export default injectIntl(FrontendAppWrapper);
