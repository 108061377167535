import React, { useEffect, useState } from "react";
import { Space, Skeleton, Button, Typography } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import { Link } from "react-router-dom";
import { getFrontendPath } from "../../../util/router";
import { localStorageClass } from "../../../apollo/local/storage";
import TipsCard from "../../../components/TipsCard";
import { useQuery } from "@apollo/client";
import { GQL_RESERVATION } from "../../../apollo/query/reservation";
import CircularProgress from "../../../components/CircularProgress/index";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { stripePromise } from "../../../hooks/useStripeLoader";
import { ServiceCollection } from "../../../entities/service";
import { useIntl } from "react-intl";
import { RESERVATION } from "../../../constants/App";
import ReservationSummaryTableCard from "../../../components/Checkout/ReservationSummaryTableCard";
import FrontendHeader from "../main/header";
import FrontendFooter from "../main/footer";

const StripeIntentResult = ({
   stripe,
   intl,
   reservation,
   serviceCollection,
   me,
}) => {
   const [paymentStatus, setPaymentStatus] = useState({
      status: null,
      message: null,
   });
   const [isLoadingPayment, setLoadingPayment] = useState(false);

   useEffect(() => {
      if (!stripe) {
         return;
      }

      //TODO controllare supporto browser
      const clientSecret = new URLSearchParams(window.location.search).get(
         "payment_intent_client_secret"
      );

      if (!clientSecret) {
         return;
      }
      setLoadingPayment(true);
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
         setLoadingPayment(false);

         if (paymentIntent) {
            switch (paymentIntent.status) {
               case "succeeded":
                  setPaymentStatus({
                     message: intl.formatMessage({
                        id: `payments.${paymentIntent.status}`,
                     }),
                     status: paymentIntent.status,
                  });
                  localStorageClass.updateReservationStatus(
                     RESERVATION.STATUS.SUCCESS
                  );
                  break;
               case "processing":
                  setPaymentStatus({
                     message: intl.formatMessage({
                        id: `payments.${paymentIntent.status}`,
                     }),
                     status: paymentIntent.status,
                  });
                  localStorageClass.updateReservationStatus(
                     RESERVATION.STATUS.PROCESSING
                  );
                  break;
               case "requires_payment_method":
                  setPaymentStatus({
                     message: intl.formatMessage({
                        id: `payments.${paymentIntent.status}`,
                     }),
                     status: paymentIntent.status,
                  });
                  localStorageClass.updateReservationStatus(
                     RESERVATION.STATUS.PAYMENT_ERROR //aggiungere status PAYMENT_ERROR?
                  );
                  break;
               default:
                  setPaymentStatus({
                     message: intl.formatMessage({ id: `payments.generic_error` }),
                     status: paymentIntent.status,
                  });
                  break;
            }
         } else {
            setPaymentStatus({
               message: intl.formatMessage({ id: `payments.generic_error` }),
            });
         }
      });
   }, [stripe]);

   if (isLoadingPayment) {
      return <Skeleton />;
   }

   return (
      <div>
         {paymentStatus.status === "succeeded" ||
         paymentStatus.status === "processing" ? (
            <>
               <h1 className="white gx-mb-4">
                  <IntlMessages id="reservations.confirm_complete_success_title" />
               </h1>
               <p className="gx-mb-4">
                  <span className="gx-text-white">
                     <IntlMessages id="reservations.confirm_complete_success_p" />
                     <br />
                     <br />
                     <IntlMessages id="common.thanks_a_lot" />
                  </span>
               </p>
            </>
         ) : (
            <>
               {paymentStatus.status === "requires_payment_method" && (
                  <TipsCard type="warning">
                     <Typography.Paragraph>
                        {paymentStatus.message}
                     </Typography.Paragraph>

                     <Link key="1" to={getFrontendPath("prenotazione")}>
                        <Button
                           key="2"
                           htmlType="submit"
                           className="bg-orange white uppercase border-0 w-100 gx-mb-0"
                        >
                           <IntlMessages id="payments.retry" />
                        </Button>
                     </Link>
                  </TipsCard>
               )}
            </>
         )}

         <div className="gx-app-login-main-content gx-app-login-main-content-frontend bg-white-15">
            <div className="gx-app-login-content white w-100">
               <ReservationSummaryTableCard
                  className="gx-mt-0"
                  availability={{
                     day: reservation.time.date,
                     slot: reservation.time.time_start,
                  }}
                  reservation={reservation}
                  serviceCollection={serviceCollection}
                  paymentStatus={paymentStatus.status}
                  me={me}
               />
            </div>
         </div>
      </div>
   );
};

const ConfirmReservationResult = ({ storageReservation, intl }) => {
   const { data, loading, error } = useQuery(GQL_RESERVATION, {
      variables: { id: storageReservation.id },
   });

   useEffect(() => {
      if (
         data &&
         data.reservation &&
         data.reservation.status === RESERVATION.STATUS.SUCCESS
      ) {
         localStorageClass.updateReservationStatus(RESERVATION.STATUS.SUCCESS);
      }
   }, [data && data.reservation]);

   if (loading) return <CircularProgress />;

   if (error || !data || !data.reservation) {
      return <ReservationNotFound />;
   }

   const { reservation, me } = data;

   const serviceCollection = ServiceCollection(reservation.services);
   const reservationNeedPayment =
      reservation.payment && reservation.payment.should_pay;

   return (
      <div>
         {reservationNeedPayment ? (
            <>
               <Elements
                  options={{
                     locale: "it-IT",
                     appearance: { theme: "stripe" },
                  }}
                  stripe={stripePromise}
               >
                  <ElementsConsumer>
                     {({ stripe, elements }) => {
                        return (
                           <StripeIntentResult
                              stripe={stripe}
                              intl={intl}
                              reservation={reservation}
                              serviceCollection={serviceCollection}
                              me={me}
                           />
                        );
                     }}
                  </ElementsConsumer>
               </Elements>
            </>
         ) : (
            <>
               <h2 className="gx-mb-4">
                  <IntlMessages id="reservations.confirm_complete_success_title" />
               </h2>
               <p className="gx-mb-4">
                  <IntlMessages id="reservations.confirm_complete_success_p" />
                  <br />
                  <br />
                  <IntlMessages id="common.thanks_a_lot" />
               </p>

               <div className="gx-app-login-main-content gx-app-login-main-content-frontend">
                  <div className="gx-app-login-content">
                     <ReservationSummaryTableCard
                        className="gx-mt-0"
                        availability={{
                           day: reservation.time.date,
                           slot: reservation.time.time_start,
                        }}
                        reservation={reservation}
                        serviceCollection={serviceCollection}
                        me={me}
                     />
                  </div>
               </div>
            </>
         )}
      </div>
   );
};

const ReservationNotFound = () => {
   return (
      <Space direction="vertical" className="gx-w-100 gx-text-center">
         <TipsCard type="warning">
            <p>
               <IntlMessages id="reservations.not_found_for_confirmation" />
            </p>
         </TipsCard>
         <Link key="1" to={getFrontendPath("prenotazioni-elenco")}>
            <Button
               key="2"
               htmlType="submit"
               className="btn-lightblue border-0 gx-mb-0"
            >
               <IntlMessages id="reservations.my_reservations" />
            </Button>
         </Link>
      </Space>
   );
};

const ConfirmReservation = () => {
   const storageReservation = localStorageClass.getReservation();

   const intl = useIntl();

   //verificare se c'è la prenotazione
   //se non c'è restituisci errore
   //se c'è query server e recuperare il client secret dal server per visualizzare il risultato

   if (!storageReservation) {
      return (
         <>
            <FrontendHeader />
            <div className="frontend-container">
               <div className="gx-app-login-container gx-app-login-container-frontend">
                  <ReservationNotFound />
               </div>
            </div>
            <FrontendFooter />
         </>
      );
   } else {
      return (
         <>
            <FrontendHeader />
            <div className="frontend-container">
               <div className="gx-app-login-container gx-app-login-container-frontend">
                  <ConfirmReservationResult
                     storageReservation={storageReservation}
                     intl={intl}
                  />
               </div>

               <div className="gx-text-center">
                  <Link to={getFrontendPath("prenotazioni-elenco")}>
                     <Button className="gx-mt-5 frontend-btn-lg btn-grey">
                        <IntlMessages id="frontend.reservation_list_title" />
                     </Button>
                  </Link>
               </div>

               <div className="gx-mt-5 gx-mb-5">&nbsp;</div>
            </div>
            <FrontendFooter />
         </>
      );
   }
};

export default ConfirmReservation;
