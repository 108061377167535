import React, { useState, useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";
import CircularProgress from "../../../components/CircularProgress";
import { globalSettingsVar, showLoadingProgress } from "../../../apollo/local/cache";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { GQL_REGISTRATION_FRONTEND, GQL_LOGIN } from "../../../apollo/mutation/user";
import { injectIntl } from "react-intl";
import { getFrontendPath } from "../../../util/router";
import { localStorageClass } from "../../../apollo/local/storage";
import {
   defaultMutationCallback,
   defaultCatchException,
} from "../../../apollo/callbacks";
import BadResponseErrorAlert from "../../../components/BadResponseErrorAlert";
import { GQL_PRIVACY_POLICY } from "../../../apollo/query/privacy-policy";
import { get } from "lodash";
import { ITALY_PHONE_PREFIX } from "../../../constants/App";
import FrontendHeader from "../main/header";
import FrontendFooter from "../main/footer";
import FiscalCodeModal from "../../../components/FiscalCodeModal";

const FrontendRegistrationForm = ({ intl, privacyText }) => {
   const history = useHistory();
   const [checked, setChecked] = useState(false);
   var commonProps = useReactiveVar(globalSettingsVar);
   const [registerUser, { loading: loadingMutation }] = useMutation(
      GQL_REGISTRATION_FRONTEND
   );
   const [login] = useMutation(GQL_LOGIN);
   //
   const [form] = Form.useForm();
   const [modalParams, setModalParams] = useState(null);
   //

   //
   useEffect(() => {
      if (localStorageClass.isLogged()) {
         history.push(getFrontendPath("dashboard"));
      }
   }, []);
   //

   const validation = (rule, value) => {
      if (checked) {
         return Promise.resolve();
      }
      return Promise.reject(
         intl.formatMessage({ id: "signup.termins_and_conditions" })
      );
   };

   const onFinish = (values) => {
      showLoadingProgress(true);

      registerUser({
         variables: {
            customer: {
               first_name: values.firstName,
               last_name: values.lastName,
               fiscal_code: values.fiscalCode,
               email: values.email,
               phone_number: values.phone_number,
               password: values.password,
               front_end_creation: true,
            },
         },
      })
         .then((data) => {
            showLoadingProgress(false);

            defaultMutationCallback(data, () => {
               if (data && data.data && data.data.createCustomerFrontend) {
                  message.success(
                     intl.formatMessage({ id: "signup.registration_complete" }),
                     () => {
                        //Effettua il login automatico

                        showLoadingProgress(true);
                        const params = {
                           username: values.fiscalCode,
                           password: values.password,
                           frontend: true,
                        };
                        login({ variables: params })
                           .then(({ data }) => {
                              if (data && data.login) {
                                 localStorage.setItem(
                                    "REMEMBER_ME",
                                    checked ? JSON.stringify(params) : null
                                 );
                                 localStorageClass.login(data.login);
                                 showLoadingProgress(false);

                                 history.push(getFrontendPath("dashboard"));
                              } else {
                                 message.error(
                                    intl.formatMessage({ id: "login.signInError" })
                                 );
                                 showLoadingProgress(false);
                              }
                           })
                           .catch((e) => {
                              history.push(getFrontendPath());
                              showLoadingProgress(false);
                           });
                        //
                     }
                  );
               } else {
                  message.error(
                     intl.formatMessage({ id: "signup.registration_error" })
                  );
               }
            });
         })
         .catch((e) => {
            showLoadingProgress(false);
            defaultCatchException(e, intl);
         });
   };

   return (
      <>
         <FrontendHeader title={<IntlMessages id="frontend.login_header" />} />
         <div className="frontend-container">
            <div className="gx-app-login-container gx-app-login-container-frontend">
               <h2 className="gx-mb-5">
                  <IntlMessages id="frontend.registration_h2" />
               </h2>
               <p className="white">
                  <IntlMessages id="frontend.registration_p1" />
               </p>
               <BadResponseErrorAlert />
               <div className="gx-app-login-form-container gx-mt-4">
                  <div className="gx-app-login-content">
                     <Form
                        form={form}
                        initialValues={{ remember: true }}
                        name="basic"
                        onFinish={onFinish}
                        autoComplete="off"
                        className="gx-signin-form gx-form-row0"
                     >
                        <div className="gx-mb-2">
                           <label className="form-label">
                              <span
                                 dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                       id: "frontend.registration_p2",
                                    }),
                                 }}
                              ></span>
                           </label>
                        </div>
                        <Form.Item
                           className="frontend-input"
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "validation.required",
                                 }),
                              },
                           ]}
                           name="lastName"
                        >
                           <Input
                              autoFocus
                              autoComplete="new-password"
                              placeholder={"es. Rossi"}
                           />
                        </Form.Item>

                        <div className="gx-mb-2">
                           <label className="form-label">
                              <span
                                 dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                       id: "frontend.registration_p3",
                                    }),
                                 }}
                              ></span>
                           </label>
                        </div>
                        <Form.Item
                           className="frontend-input"
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "validation.required",
                                 }),
                              },
                           ]}
                           name="firstName"
                        >
                           <Input
                              autoComplete="new-password"
                              placeholder={"es. Mario"}
                           />
                        </Form.Item>

                        <div className="gx-mb-2">
                           <label className="form-label">
                              <span
                                 dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                       id: "frontend.registration_p4",
                                    }),
                                 }}
                              ></span>
                           </label>
                        </div>
                        <Form.Item
                           className="frontend-input"
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "validation.required",
                                 }),
                              },
                           ]}
                           name="fiscalCode"
                        >
                           <Input
                              autoComplete="new-password"
                              placeholder={"es. XXXX00X00X000X"}
                              style={{ textTransform: "uppercase" }}
                              suffix={
                                 <span
                                    className="gx-pointer"
                                    onClick={() =>
                                       setModalParams(
                                          form.getFieldsValue([
                                             "firstName",
                                             "lastName",
                                          ])
                                       )
                                    }
                                 >
                                    <IntlMessages id="common.genera" />
                                 </span>
                              }
                           />
                        </Form.Item>
                        <div className="gx-mb-2">
                           <label className="form-label">
                              <span
                                 dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                       id: "frontend.registration_p5",
                                    }),
                                 }}
                              ></span>
                           </label>
                        </div>
                        <Form.Item className="frontend-input" name="phone_number">
                           <Input
                              prefix={ITALY_PHONE_PREFIX}
                              addonBefore={<div className="gx-flag it"></div>}
                              autoComplete="new-password"
                              placeholder={"es. 000 0000000"}
                           />
                        </Form.Item>
                        <div className="gx-mb-2">
                           <label className="form-label">
                              <span
                                 dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                       id: "frontend.registration_p6",
                                    }),
                                 }}
                              ></span>
                           </label>
                        </div>
                        <Form.Item
                           className="frontend-input"
                           name="email"
                           rules={[
                              {
                                 required: true,
                                 type: "email",
                                 message: intl.formatMessage({
                                    id: "signup.registration_invalid_email",
                                 }),
                              },
                           ]}
                        >
                           <Input
                              autoComplete="new-password"
                              placeholder={"es. mario.rossi@gmail.com"}
                              style={{ textTransform: "lowercase" }}
                           />
                        </Form.Item>
                        <div className="gx-mb-2">
                           <label className="form-label">
                              <span
                                 dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                       id: "frontend.registration_p7",
                                    }),
                                 }}
                              ></span>
                           </label>
                        </div>
                        <Form.Item
                           className="frontend-input"
                           name="password"
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "validation.required",
                                 }),
                              },
                              {
                                 min: 5,
                                 message: intl.formatMessage({
                                    id: "services.min_5_chars",
                                 }),
                              },
                              {
                                 max: 50,
                                 message: intl.formatMessage({
                                    id: "services.max_50_chars",
                                 }),
                              },
                           ]}
                        >
                           <Input.Password
                              autoComplete="new-password"
                              placeholder={"es. mariorossi1"}
                              maxLength="50"
                              iconRender={(visible) =>
                                 visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                              }
                           />
                        </Form.Item>
                        <Form.Item
                           name="checkbox"
                           rules={[{ validator: validation }]}
                           valuePropName="checked"
                        >
                           <Checkbox
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                           >
                              <span>
                                 <IntlMessages id="registration.accept" />{" "}
                                 <a
                                    href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                                    target="_privacy"
                                 >
                                    <span className="gx-signup-form-forgot gx-link underline">
                                       <strong>
                                          <IntlMessages id="registration.privacy_policy" />
                                       </strong>
                                    </span>
                                 </a>
                              </span>
                           </Checkbox>
                        </Form.Item>
                        <Form.Item>
                           <Button
                              className="gx-mb-2 frontend-btn-lg w-100"
                              htmlType="submit"
                              loading={loadingMutation}
                           >
                              <IntlMessages id="frontend.registration_register_now" />
                           </Button>
                        </Form.Item>
                     </Form>

                     {/* <Modal
                        onCancel={() => setTermsVisible(false)}
                        title={
                           <span className="color-blue">
                              <IntlMessages id="registration.term_and_condition" />
                           </span>
                        }
                        visible={termsVisible}
                        width="85%"
                        className="color-blue"
                        footer={[
                           <Button key="back" onClick={() => setTermsVisible(false)}>
                              {intl.formatMessage({ id: "common.ok" }).toUpperCase()}
                           </Button>
                        ]}
                     >
                        <div dangerouslySetInnerHTML={{ __html: privacyText }} />
                     </Modal> */}
                  </div>
                  {commonProps.loading && (
                     <div className="gx-loader-view">
                        <CircularProgress />
                     </div>
                  )}
               </div>

               <div className="gx-text-center">
                  <Link to={getFrontendPath()}>
                     <Button className="gx-mt-5 frontend-btn-lg btn-grey">
                        <IntlMessages id="frontend.forget_password_back_to_login" />
                     </Button>
                  </Link>
               </div>
            </div>
         </div>
         <FrontendFooter />
         <FiscalCodeModal
            params={modalParams}
            visible={modalParams != null}
            onDismiss={() => setModalParams(null)}
            onSuccess={(value) => {
               form.setFieldsValue({
                  firstName: value.name,
                  lastName: value.surname,
                  fiscalCode: value.code,
               });
               setModalParams(null);
            }}
         />
      </>
   );
};

const FrontendRegistration = ({ intl }) => {
   const { loading, data } = useQuery(GQL_PRIVACY_POLICY);

   if (loading) return <CircularProgress />;

   const privacyText = get(data, "privacyPolicy.text", "");

   return <FrontendRegistrationForm privacyText={privacyText} intl={intl} />;
};

export default injectIntl(FrontendRegistration);
