import React, { Suspense, useEffect } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import PrivateCASLRoute from "../../components/PrivateCASLRoute";
import IntlMessages from "../../util/IntlMessages";
import NotFound from "../../containers/NotFound";
import Settings from "./settings/index";
import { useQuery } from "@apollo/client";
import { GQL_LOCAL_ME } from "../../apollo/query/user";
//import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import { getAdminPath, lazyWithRetry } from "../../util/router";
import GQLAlertErrors from "../../components/GQLAlertErrors";
import CircularProgress from "../../components/CircularProgress";
import { Roles } from "../../constants/Enums";
import availabilities from "./availabilities";
import Raccolte from "./raccolte";
import { hasStatisticsAccess } from "../../util/user";
import ErrorBoundary from "../../components/ErrorBoundary";
import ReuseBoard from "./reuse-board";

//Lazy loading
const Services = lazyWithRetry(() => import("./services"));
const ServiceInsert = lazyWithRetry(() => import("./services/insert"));
const ServiceUpdate = lazyWithRetry(() => import("./services/update"));

const Workspaces = lazyWithRetry(() => import("./workspaces"));
const WorkspaceInsert = lazyWithRetry(() => import("./workspaces/insert"));
const WorkspaceUpdate = lazyWithRetry(() => import("./workspaces/update"));
const WorkspaceAvailabilities = lazyWithRetry(() =>
   import("./workspaces/availabilities")
);

const Employees = lazyWithRetry(() => import("./employees"));
const EmployeeInsert = lazyWithRetry(() => import("./employees/insert"));
const EmployeeUpdate = lazyWithRetry(() => import("./employees/update"));

const Customers = lazyWithRetry(() => import("./customers"));
const CustomerInsert = lazyWithRetry(() => import("./customers/insert"));
const CustomerUpdate = lazyWithRetry(() => import("./customers/update"));
const CustomerView = lazyWithRetry(() => import("./customers/view"));

const Reservations = lazyWithRetry(() => import("./reservations"));
const ReservationInsert = lazyWithRetry(() => import("./reservations/insert"));

const WorkspaceReservationsReport = lazyWithRetry(() =>
   import("./reports/WorkspaceReservationReport")
);

const Users = lazyWithRetry(() => import("./users"));
const UserInsert = lazyWithRetry(() => import("./users/insert"));
const UserUpdate = lazyWithRetry(() => import("./users/update"));

const Assets = lazyWithRetry(() => import("./assets"));

const Organizations = lazyWithRetry(() => import("./organizations"));
const OrganizationInsert = lazyWithRetry(() => import("./organizations/insert"));
const OrganizationUpdate = lazyWithRetry(() => import("./organizations/update"));

const PrivacyPolicy = lazyWithRetry(() => import("./privacy-policy"));

const AppReports = lazyWithRetry(() => import("./app-reports"));
const AppReportsUpdate = lazyWithRetry(() => import("./app-reports/update"));

const ReservationsReport = lazyWithRetry(() =>
   import("./reports/ReservationsReport")
);

const ReuseBoardReport = lazyWithRetry(() => import("./reports/ReuseBoardReport"));

const AppNotifications = lazyWithRetry(() => import("./app-notifications"));
const AppNotificationsInsert = lazyWithRetry(() =>
   import("./app-notifications/insert")
);
const AppNotificationsUpdate = lazyWithRetry(() =>
   import("./app-notifications/update")
);

const Differenziario = lazyWithRetry(() => import("./differenziario"));
//

const Admin = ({ user, ability }) => {
   const location = useLocation();

   const history = useHistory();

   const { data, loading, error } = useQuery(GQL_LOCAL_ME);

   useEffect(() => {
      //handle entry point redirection
      if (location.pathname === getAdminPath() && data && data.me) {
         if (data.me.role === Roles.REUSE_BOARD_OPERATOR) {
            history.push(getAdminPath("reuse-board/reservations"));
         }
      }
   }, [location]);

   if (loading || !data) return <CircularProgress />;

   if (error) {
      return <GQLAlertErrors error={error} />;
   }

   return (
      <ErrorBoundary>
         <Suspense fallback={<IntlMessages id="common.loading" />}>
            <Switch>
               <Route exact path={getAdminPath("settings")} component={Settings} />

               <PrivateCASLRoute
                  I="manage"
                  a="Reservation"
                  exact
                  path={[getAdminPath("reservations")].concat(
                     ![Roles.CHECKING_REPORTS, Roles.REUSE_BOARD_OPERATOR].includes(
                        data.me.role
                     )
                        ? [getAdminPath(), getAdminPath("dashboard")]
                        : []
                  )}
                  component={Reservations}
                  ability={ability}
               />

               <PrivateCASLRoute
                  I="create"
                  a="Reservation"
                  exact
                  path={getAdminPath("availabilities")}
                  component={availabilities}
               />

               <PrivateCASLRoute
                  I="create"
                  a="Reservation"
                  exact
                  path={getAdminPath("reservations/insert")}
                  component={ReservationInsert}
               />

               {hasStatisticsAccess(user) && (
                  <PrivateCASLRoute
                     I="manage"
                     a="Reservation"
                     exact
                     path={getAdminPath("reports")}
                     component={WorkspaceReservationsReport}
                  />
               )}

               {/* ADMIN */}
               <PrivateCASLRoute
                  I="manage"
                  a="Service"
                  exact
                  path={getAdminPath("services")}
                  component={Services}
               />
               <PrivateCASLRoute
                  I="create"
                  a="Service"
                  exact
                  path={getAdminPath("services/insert")}
                  component={ServiceInsert}
               />
               <PrivateCASLRoute
                  I="update"
                  a="Service"
                  exact
                  path={getAdminPath("services/update/:itemId")}
                  component={ServiceUpdate}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="Workspace"
                  exact
                  path={getAdminPath("workspaces")}
                  component={Workspaces}
               />
               <PrivateCASLRoute
                  I="create"
                  a="Workspace"
                  exact
                  path={getAdminPath("workspaces/insert")}
                  component={WorkspaceInsert}
               />

               <PrivateCASLRoute
                  I="update"
                  a="Workspace"
                  exact
                  path={getAdminPath("workspaces/update/:itemId")}
                  component={WorkspaceUpdate}
               />

               <PrivateCASLRoute
                  I="update"
                  a="Workspace"
                  exact
                  path={getAdminPath("workspaces/availabilities/:itemId")}
                  component={WorkspaceAvailabilities}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="Employee"
                  exact
                  path={getAdminPath("employees")}
                  component={Employees}
               />
               <PrivateCASLRoute
                  I="create"
                  a="Employee"
                  exact
                  path={getAdminPath("employees/insert")}
                  component={EmployeeInsert}
               />
               <PrivateCASLRoute
                  I="update"
                  a="Employee"
                  exact
                  path={getAdminPath("employees/update/:itemId")}
                  component={EmployeeUpdate}
               />

               <PrivateCASLRoute
                  I="read"
                  a="Customer"
                  exact
                  path={getAdminPath("customers")}
                  component={Customers}
               />
               <PrivateCASLRoute
                  I="read"
                  a="Customer"
                  exact
                  path={getAdminPath("customers/view/:itemId")}
                  component={CustomerView}
               />
               <PrivateCASLRoute
                  I="create"
                  a="Customer"
                  exact
                  path={getAdminPath("customers/insert")}
                  component={CustomerInsert}
               />
               <PrivateCASLRoute
                  I="update"
                  a="Customer"
                  exact
                  path={getAdminPath("customers/update/:itemId")}
                  component={CustomerUpdate}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="User"
                  exact
                  path={getAdminPath("users")}
                  component={Users}
               />
               <PrivateCASLRoute
                  I="create"
                  a="User"
                  exact
                  path={getAdminPath("users/insert")}
                  component={UserInsert}
               />
               <PrivateCASLRoute
                  I="update"
                  a="User"
                  exact
                  path={getAdminPath("users/update/:itemId")}
                  component={UserUpdate}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="Organization"
                  exact
                  path={getAdminPath("organizations")}
                  component={Organizations}
               />

               <PrivateCASLRoute
                  I="create"
                  a="Organization"
                  exact
                  path={getAdminPath("organizations/insert")}
                  component={OrganizationInsert}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="Organization"
                  path={getAdminPath("organizations/update/:itemId")}
                  component={OrganizationUpdate}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="PrivacyPolicy"
                  exact
                  path={getAdminPath("privacy-policy")}
                  component={PrivacyPolicy}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="Asset"
                  exact
                  path={getAdminPath("assets")}
                  component={Assets}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="Report"
                  exact
                  path={[getAdminPath("app-reports")].concat(
                     Roles.CHECKING_REPORTS === data.me.role
                        ? [getAdminPath(), getAdminPath("dashboard")]
                        : []
                  )}
                  component={AppReports}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="Product"
                  path={[getAdminPath("reuse-board")]}
                  component={ReuseBoard}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="AppNotification"
                  exact
                  path={[getAdminPath("app-notifications")].concat(
                     Roles.CHECKING_REPORTS === data.me.role
                        ? [getAdminPath(), getAdminPath("dashboard")]
                        : []
                  )}
                  component={AppNotifications}
               />

               <PrivateCASLRoute
                  I="create"
                  a="AppNotification"
                  exact
                  path={getAdminPath("app-notifications/insert")}
                  component={AppNotificationsInsert}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="AppNotification"
                  exact
                  path={getAdminPath("app-notifications/update/:itemId")}
                  component={AppNotificationsUpdate}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="Report"
                  exact
                  path={getAdminPath("app-reports/update/:itemId")}
                  component={AppReportsUpdate}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="Workspace"
                  exact
                  path={getAdminPath("reports/reservations")}
                  component={ReservationsReport}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="Workspace"
                  exact
                  path={getAdminPath("reports/reuse-board")}
                  component={ReuseBoardReport}
               />

               <PrivateCASLRoute
                  I="read"
                  a="Raccolta"
                  path={getAdminPath("raccolte")}
                  component={Raccolte}
               />

               <PrivateCASLRoute
                  I="manage"
                  a="Waste"
                  path={getAdminPath("differenziario")}
                  component={Differenziario}
               />

               <Route path="*" component={NotFound} />
            </Switch>
         </Suspense>
      </ErrorBoundary>
   );
};

export default Admin;
