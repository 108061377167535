import React, { useState } from "react";
import {
   Breadcrumb,
   Card,
   Row,
   Col,
   PageHeader,
   Tag,
   Divider,
   Button,
   Alert,
} from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import BreadcrumbHomeLink from "../../../../components/BreadcrumbHomeLink";
import { useIntl } from "react-intl";
import { get, isEmpty } from "lodash";
import RaccolteList from "./list";
import ComuniRaccolteSelect from "./components/comuni-raccolte-select";
import IndirizzoRaccolteSelect from "./components/indirizzo-raccolte-select";
import CivicoRaccolteSelect from "./components/civico-raccolte-select";

const ZoneTags = ({ intl, zone }) => {
   if (isEmpty(zone)) {
      return (
         <small>
            {intl.formatMessage({ id: "raccolte.comune_non_prevede_zone" })}
         </small>
      );
   }
   return (
      <>
         <small className="gx-mr-2">
            {intl.formatMessage({ id: "raccolte.zones" })}:
         </small>
         {zone.map((z, idx) => (
            <Tag key={`tag-zone-rac-${idx}`}>{z}</Tag>
         ))}
      </>
   );
};

const INITIAL_VALUE = {
   comune: null,
   indirizzo: null,
   civico: null,
};

const RaccoltePortaAPorta = (props) => {
   const [selection, setSelection] = useState(INITIAL_VALUE);
   const intl = useIntl();

   const zone = get(selection, "comune.zones", []);

   const canShowRaccolte = () => {
      const comune = get(selection, "comune", null);
      if (!comune) return false;

      if (!comune.has_raccolte_addresses) {
         return true;
      }

      if (selection.indirizzo) {
         const numbers = get(selection, "indirizzo.numbers", []).filter(
            (n) => n.number
         );

         if (numbers.length === 0) {
            return true;
         }
      }

      if (selection.civico) {
         return true;
      }

      return false;
   };

   const numbers = get(selection, "indirizzo.numbers", []).filter((n) => n.number);

   return (
      <>
         <Row>
            <Col md={24}>
               <Breadcrumb className="gx-pb-3">
                  <Breadcrumb.Item>
                     <BreadcrumbHomeLink />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item href="#">
                     <IntlMessages id="sidebar.raccolte" />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item href="#">
                     <IntlMessages id="sidebar.raccolta_porta_porta" />
                  </Breadcrumb.Item>
               </Breadcrumb>
            </Col>
         </Row>
         <Row>
            <Col xs={24}>
               <PageHeader
                  title={<IntlMessages id="sidebar.raccolta_porta_porta" />}
               />               
            </Col>
            <Col md={24}>
               <Card>
                  <Row>
                     <Col md={24} sm={24} xs={24}>
                        <ComuniRaccolteSelect
                           placeholder={intl.formatMessage({
                              id: "raccolte.seleziona_comune",
                           })}
                           onChange={(value) =>
                              setSelection({
                                 ...selection,
                                 comune: value,
                                 indirizzo: null,
                                 civico: null,
                              })
                           }
                           value={get(selection, "comune.istat_code")}
                        />
                        {selection && selection.comune && (
                           <span className="gx-ml-4">
                              <ZoneTags intl={intl} zone={zone} />
                           </span>
                        )}
                     </Col>
                  </Row>
                  {selection &&
                     selection.comune &&
                     selection.comune.has_raccolte_addresses && (
                        <Row className="gx-mt-3">
                           <Col md={12} sm={24} xs={24}>
                              <IndirizzoRaccolteSelect
                                 comuneId={selection.comune.id}
                                 placeholder={intl.formatMessage({
                                    id: "raccolte.seleziona_indirizzo",
                                 })}
                                 className="gx-w-100"
                                 onChange={(value) =>
                                    setSelection({
                                       ...selection,
                                       indirizzo: value,
                                       civico: null,
                                    })
                                 }
                                 value={get(selection, "indirizzo")}
                              />
                           </Col>
                           {numbers.length > 0 && (
                              <Col md={12} sm={24} xs={24}>
                                 <CivicoRaccolteSelect
                                    numbers={numbers}
                                    placeholder={intl.formatMessage({
                                       id: "raccolte.seleziona_civico",
                                    })}
                                    onChange={(value) =>
                                       setSelection({
                                          ...selection,
                                          civico: value,
                                       })
                                    }
                                    value={get(selection, "civico.number")}
                                 />
                              </Col>
                           )}
                        </Row>
                     )}

                  <Row hidden={!selection || !selection.comune}>
                     <Col xs={24}>
                        <Button
                           type="primary"
                           className="gx-mt-3"
                           onClick={() => setSelection(INITIAL_VALUE)}
                        >
                           {intl.formatMessage({ id: "common.reset" })}
                        </Button>
                     </Col>
                  </Row>

                  {canShowRaccolte() && (
                     <Row>
                        <Col xs={24}>
                           <Divider className="gx-mt-4 gx-mb-4" />
                           <RaccolteList selection={selection} />
                        </Col>
                     </Row>
                  )}
               </Card>
            </Col>
         </Row>
      </>
   );
};

export default RaccoltePortaAPorta;
