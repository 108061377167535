import React, { useEffect, useState } from "react";
import { Avatar, Menu, Select, Typography, Popover, Divider } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { SECONDARY_COLOR, THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { layoutSettingsVar, showLoadingProgress } from "../../apollo/local/cache";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Can } from "../../acl/index";
import { get } from "lodash";
import { GQL_INIT_QUERY, GQL_LOCAL_ME } from "../../apollo/query/user";
import { GQL_WORKSPACE_CHANGE } from "../../apollo/mutation/workspaces";
import { injectIntl } from "react-intl";
import { getAdminPath } from "../../util/router";
import { getInitials } from "../../util/string";
import config from "../../util/config";
import { hasStatisticsAccess } from "../../util/user";
import { Roles } from "../../constants/Enums";
import {
   useHistory,
   useLocation,
   useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";

const SelectWorkspace = ({ workspaces, className = "" }) => {
   const [switchCurrentWorkspace] = useMutation(GQL_WORKSPACE_CHANGE, {
      refetchQueries: [{ query: GQL_INIT_QUERY, fetchPolicy: "no-cache" }],
      awaitRefetchQueries: true,
   });

   const { data, loading, error } = useQuery(GQL_LOCAL_ME);

   if (loading || error || !data) return <div></div>;

   const { me } = data;

   const selectChangedHandler = (workspaceId) => {
      showLoadingProgress(true);

      switchCurrentWorkspace({ variables: { id: workspaceId } })
         .then(({ data }) => {
            showLoadingProgress(false);
         })
         .catch((e) => {
            showLoadingProgress(false);
         });
   };

   return (
      <div className={className} style={{ textAlign: "center" }}>
         <Select
            key="select-workspace"
            disabled={workspaces.length <= 1}
            value={get(me, "current_workspace.id", null)}
            size="middle"
            onChange={(value) => selectChangedHandler(value)}
            style={{ width: "70%", marginBottom: "10px", textAlign: "center" }}
         >
            {(workspaces || []).map((workspace, idx) => {
               return (
                  <Select.Option
                     key={`workspace-select-idx-${idx}`}
                     value={workspace.id}
                  >
                     {workspace.name}
                  </Select.Option>
               );
            })}
         </Select>
      </div>
   );
};

const OrganizationSidebarMenu = ({ organization }) => {
   const frontendURL = config.isDevelopment
      ? `http://${organization.base_url}`
      : `https://${organization.base_url}`;

   return (
      <ul className="gx-user-popover">
         <li>
            {organization.name}
            <Divider />
         </li>
         <li>
            <a className="gx-d-block" href={frontendURL}>
               <IntlMessages id="sidebar.reservation_frontend" />
            </a>
         </li>
      </ul>
   );
};

const SidebarContent = ({
   sidebarCollapsed,
   setSidebarCollapsed,
   user,
   workspaces,
   intl,
}) => {
   //const ability = useContext(AbilityContext);
   var { themeType } = useReactiveVar(layoutSettingsVar);

   const adminPath = getAdminPath();
   const location = useLocation();

   const [selectedKey, setSelectedKey] = useState(`${adminPath}/reservations`);

   useEffect(() => setSelectedKey(location.pathname), []);

   return (
      <>
         <SidebarLogo
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
         />
         <div className={`gx-sidebar-notifications`}>
            <Popover
               className="gx-d-block"
               placement="bottomLeft"
               content={
                  <OrganizationSidebarMenu
                     organization={user.current_organization}
                  />
               }
               trigger="click"
            >
               <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
                  <Avatar
                     className="gx-size-40 _gx-pointer gx-mr-3"
                     style={{ minWidth: 40, backgroundColor: SECONDARY_COLOR }}
                  >
                     {getInitials(user.current_organization.name, 2).toUpperCase()}
                  </Avatar>
                  <Typography.Text
                     ellipsis={true}
                     style={{ maxWidth: "60%" }}
                     className="gx-avatar-name"
                  >
                     {user.current_organization.name}
                  </Typography.Text>
               </div>
            </Popover>
         </div>
         <div className="gx-sidebar-content">
            <CustomScrollbars className="gx-layout-sider-scrollbar">
               <Menu
                  selectedKeys={[selectedKey]}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  style={{ overflowX: "hidden" }}
                  onSelect={(item) => {
                     setSelectedKey(item.key);
                  }}
               >
                  <Can I="manage" a="Reservation">
                     <Menu.ItemGroup
                        title={intl
                           .formatMessage({ id: "sidebar.reservations_workspace" })
                           .toUpperCase()}
                     >
                        <SelectWorkspace
                           key="select-workspace-combo"
                           workspaces={workspaces}
                           className={sidebarCollapsed ? "gx-mt-4" : ""}
                        />

                        <Menu.Item key={`${adminPath}/availabilities`}>
                           <Link to={`${adminPath}/availabilities`}>
                              <i className="icon icon-queries" />
                              <span>
                                 <IntlMessages id="sidebar.availabilities" />
                              </span>
                           </Link>
                        </Menu.Item>

                        <Menu.Item key={`${adminPath}/reservations`}>
                           <Link to={`${adminPath}/reservations`}>
                              <i className="icon icon-calendar" />
                              <span>
                                 <IntlMessages id="sidebar.reservations" />
                              </span>
                           </Link>
                        </Menu.Item>

                        {hasStatisticsAccess(user) && (
                           <Menu.Item key={`${adminPath}/reports`}>
                              <Link to={`${adminPath}/reports`}>
                                 <i className="icon icon-chart" />
                                 <span>
                                    <IntlMessages id="sidebar.stats" />
                                 </span>
                              </Link>
                           </Menu.Item>
                        )}
                     </Menu.ItemGroup>
                  </Can>

                  <Can I="manage" a="Product">
                     <Menu.ItemGroup
                        title={intl
                           .formatMessage({ id: "sidebar.reuse_board" })
                           .toUpperCase()}
                     >
                        <Menu.Item key={`${adminPath}/reuse-board/reservations`}>
                           <Link to={`${adminPath}/reuse-board/reservations`}>
                              <i className="icon icon-calendar" />
                              <span>
                                 <IntlMessages id="sidebar.products_reservations" />
                              </span>
                           </Link>
                        </Menu.Item>
                        <Menu.Item key={`${adminPath}/reuse-board/products`}>
                           <Link to={`${adminPath}/reuse-board/products`}>
                              <i className="icon icon-product-list" />
                              <span>
                                 <IntlMessages id="sidebar.products" />
                              </span>
                           </Link>
                        </Menu.Item>

                        <Menu.Item key={`${adminPath}/reuse-board/categories`}>
                           <Link to={`${adminPath}/reuse-board/categories`}>
                              <i className="icon icon-apps-new" />
                              <span>
                                 <IntlMessages id="sidebar.products_categories" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Menu.ItemGroup>
                  </Can>

                  <Menu.ItemGroup
                     title={
                        //TODO transform menu components in array and check if empty
                        user.role === Roles.REUSE_BOARD_OPERATOR
                           ? null
                           : intl
                                .formatMessage({ id: "sidebar.management" })
                                .toUpperCase()
                     }
                  >
                     <Menu.Item key={`${adminPath}/services`}>
                        <Can I="manage" a="Service">
                           <Link to={`${adminPath}/services`}>
                              <i className="icon icon-apps" />
                              <span>
                                 <IntlMessages id="sidebar.services" />
                              </span>
                           </Link>
                        </Can>
                     </Menu.Item>

                     <Menu.Item key={`${adminPath}/workspaces`}>
                        <Can I="manage" a="Workspace">
                           <Link to={`${adminPath}/workspaces`}>
                              <i className="icon icon-geo-location" />
                              <span>
                                 <IntlMessages id="sidebar.workspaces" />
                              </span>
                           </Link>
                        </Can>
                     </Menu.Item>

                     <Menu.Item key={`${adminPath}/employees`}>
                        <Can I="manage" a="Employee">
                           <Link to={`${adminPath}/employees`}>
                              <i className="icon icon-avatar" />
                              <span>
                                 <IntlMessages id="sidebar.employees" />
                              </span>
                           </Link>
                        </Can>
                     </Menu.Item>

                     <Menu.Item key={`${adminPath}/customers`}>
                        <Can I="manage" a="Customer">
                           <Link to={`${adminPath}/customers`}>
                              <i className="icon icon-files" />
                              <span>
                                 <IntlMessages id="sidebar.customers" />
                              </span>
                           </Link>
                        </Can>
                     </Menu.Item>

                     <Menu.Item key={`${adminPath}/users`}>
                        {/*altrimenti modifichi il profilo dalla topbar*/}
                        <Can I="manage" a="Customer">
                           <Link to={`${adminPath}/users`}>
                              <i className="icon icon-auth-screen" />
                              <span>
                                 <IntlMessages id="sidebar.users" />
                              </span>
                           </Link>
                        </Can>
                     </Menu.Item>

                     <Menu.Item key={`${adminPath}/assets`}>
                        <Can I="read" a="Asset">
                           <Link to={`${adminPath}/assets`}>
                              <i className="icon icon-attachment" />
                              <span>
                                 <IntlMessages id="sidebar.assets" />
                              </span>
                           </Link>
                        </Can>
                     </Menu.Item>

                     <Menu.Item key={`${adminPath}/app-reports`}>
                        <Can I="read" a="Report">
                           <Link to={`${adminPath}/app-reports`}>
                              <i className="icon icon-exclamation" />
                              <span>
                                 <IntlMessages id="sidebar.app_reports" />
                              </span>
                           </Link>
                        </Can>
                     </Menu.Item>

                     <Menu.Item key={`${adminPath}/app-notifications`}>
                        <Can I="manage" a="AppNotification">
                           <Link to={`${adminPath}/app-notifications`}>
                              <i className="icon icon-notification" />
                              <span>
                                 <IntlMessages id="sidebar.app_notifications" />
                              </span>
                           </Link>
                        </Can>
                     </Menu.Item>

                     <Menu.Item key={`${adminPath}/differenziario`}>
                        <Can I="manage" a="Waste">
                           <Link to={`${adminPath}/differenziario`}>
                              <i className="icon icon-tab" />
                              <span>
                                 <IntlMessages id="sidebar.app_differenziario" />
                              </span>
                           </Link>
                        </Can>
                     </Menu.Item>
                  </Menu.ItemGroup>

                  <Can I="manage" a="Workspace">
                     <Menu.ItemGroup
                        title={intl
                           .formatMessage({ id: "sidebar.summary" })
                           .toUpperCase()}
                     >
                        <Menu.Item key={`${adminPath}/reports/reservations`}>
                           <Link to={`${adminPath}/reports/reservations`}>
                              <i className="icon icon-chart" />
                              <span>
                                 <IntlMessages id="sidebar.frontoffices_reservation" />
                              </span>
                           </Link>
                        </Menu.Item>

                        <Menu.Item key={`${adminPath}/reports/reuse-board`}>
                           <Link to={`${adminPath}/reports/reuse-board`}>
                              <i className="icon icon-chart" />
                              <span>
                                 <IntlMessages id="sidebar.reuse_board" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Menu.ItemGroup>
                  </Can>

                  <Can I="read" a="Raccolta">
                     <Menu.ItemGroup
                        title={intl
                           .formatMessage({ id: "sidebar.raccolte" })
                           .toUpperCase()}
                     >
                        <Menu.Item key={`${adminPath}/raccolte/comuni`}>
                           <Link to={`${adminPath}/raccolte/comuni`}>
                              <i className="icon icon-geo-location" />

                              <IntlMessages id="sidebar.comuni" />
                           </Link>
                        </Menu.Item>

                        <Menu.Item key={`${adminPath}/raccolte/porta-a-porta`}>
                           <Link to={`${adminPath}/raccolte/porta-a-porta`}>
                              <i className="icon icon-frequent" />
                              <IntlMessages id="sidebar.raccolta_porta_porta" />{" "}
                           </Link>
                        </Menu.Item>
                     </Menu.ItemGroup>
                  </Can>

                  <Can I="manage" a="Organization">
                     <Menu.ItemGroup
                        title={intl
                           .formatMessage({ id: "sidebar.superadmin" })
                           .toUpperCase()}
                     >
                        <Menu.Item key={`${adminPath}/organizations`}>
                           <Link to={`${adminPath}/organizations`}>
                              <i className="icon icon-company" />
                              <span>
                                 <IntlMessages id="sidebar.organizations" />
                              </span>
                           </Link>
                        </Menu.Item>

                        <Menu.Item key={`${adminPath}/privacy-policy`}>
                           <Can I="manage" a="PrivacyPolicy">
                              <Link to={`${adminPath}/privacy-policy`}>
                                 <i className="icon icon-setting" />
                                 <span>
                                    <IntlMessages id="sidebar.privacy_policy" />
                                 </span>
                              </Link>
                           </Can>
                        </Menu.Item>
                     </Menu.ItemGroup>
                  </Can>
               </Menu>
            </CustomScrollbars>
         </div>
      </>
   );
};

SidebarContent.propTypes = {};

export default injectIntl(SidebarContent);
