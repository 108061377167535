import React, { useMemo, useState } from "react";
import { Card, Button, Form, Select, Row, Col, Typography } from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import { get, includes, isArray, isEmpty } from "lodash";
import { formStyles } from "./common";
import { selectFilterableProps } from "../../../../util/antd";
import { MAX_SERVICES_FOR_RESERVATION } from "../../../../constants/App";

export const FrontendReservationServices = ({
   services,
   intl,
   handleOk,
   handleError,
   handlePreviousStep,
   formContent,
   hasBackButton,
}) => {
   //filtra servizi disponibili in sede
   const availableServices = useMemo(() => {
      return services.filter(
         (service) =>
            !service.workspaces_id ||
            service.workspaces_id.length === 0 ||
            (formContent.workspace_id &&
               includes(service.workspaces_id, formContent.workspace_id))
      );
   }, [formContent]);

   const sid = get(formContent, "services_id", null);
   const [selectedServicesId, setSelectedServicesId] = useState(
      isArray(sid) ? sid : []
   );

   const selectedServices = availableServices.filter((s) =>
      selectedServicesId.includes(s.id)
   );

   return (
      <Form
         onFinish={handleOk}
         onFinishFailed={handleError}
         {...formStyles}
         className="gx-mt-3"
         initialValues={{
            services_id: isArray(sid) ? sid : [],
         }}
         onValuesChange={(changedValue) => {
            if (changedValue.services_id) {
               setSelectedServicesId(changedValue.services_id);
            }
         }}
      >
         <Card
            className="card-step"
            actions={[
               <div className="gx-text-left">
                  {hasBackButton && (
                     <Button
                        key="1"
                        onClick={handlePreviousStep}
                        className="uppercase border-0 gx-mb-0"
                     >
                        <IntlMessages id="common.back" />
                     </Button>
                  )}

                  <Button
                     key="2"
                     htmlType="submit"
                     className="btn-lightblue uppercase border-0 gx-mb-0"
                  >
                     <IntlMessages id="common.forward" />
                  </Button>
               </div>,
            ]}
         >
            <Row>
               <Col xs={24} md={12}>
                  <Form.Item
                     name="services_id"
                     required
                     rules={[
                        {
                           required: true,
                           message: intl.formatMessage({
                              id: "customers.new_customer_required_field",
                           }),
                        },
                     ]}
                  >
                     <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder={`${intl.formatMessage({
                           id: "frontend.reservation_select_services",
                        })} (${intl.formatMessage(
                           {
                              id: "common.max_n",
                           },
                           { value: MAX_SERVICES_FOR_RESERVATION }
                        )})`}
                        {...selectFilterableProps()}
                        onChange={(value) => {
                           if (value?.length > MAX_SERVICES_FOR_RESERVATION) {
                              value.pop();
                           }
                        }}
                     >
                        {availableServices.map((item) => (
                           <Select.Option
                              key={`service_${item.id}`}
                              value={item.id}
                              className="frontend-font"
                           >
                              {item.name}
                           </Select.Option>
                        ))}
                     </Select>
                  </Form.Item>
               </Col>
               <Col xs={24} md={12}>
                  <Typography.Title level={5}>
                     <IntlMessages id="common.selected_multi" />
                  </Typography.Title>

                  {!isEmpty(selectedServices) ? (
                     <ul>
                        {selectedServices.map((selectedService, idx) => (
                           <li key={`selServSpan-${idx}`}>
                              <u>{selectedService.name}</u>{" "}
                              {selectedService.description
                                 ? `- ${selectedService.description}`
                                 : ""}
                           </li>
                        ))}
                     </ul>
                  ) : (
                     <IntlMessages id="common.none" />
                  )}
               </Col>
            </Row>
         </Card>
      </Form>
   );
};
