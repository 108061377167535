import React from "react";
import {
   Alert,
   Button,
   Col,
   DatePicker,
   Form,
   Modal,
   Popconfirm,
   Row,
   message,
} from "antd";
import { useIntl } from "react-intl";
import SchedulerTable from "./table";
import { DATETIME_FORMAT, RESERVATION } from "../../../../../../constants/App";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { GQL_PRODUCT_SCHEDULE_RESERVATIONS } from "../../../../../../apollo/mutation/reuse-board";
import { get } from "lodash";

const DeliveryScheduler = ({ reservations, visible, onClose, onSuccess }) => {
   const intl = useIntl();
   const [form] = Form.useForm();
   const [scheduleMutation, { loading: loadingMutation }] = useMutation(
      GQL_PRODUCT_SCHEDULE_RESERVATIONS
   );

   if (!reservations || reservations.length === 0 || !visible) {
      return null;
   }

   const dismissModal = () => {
      form.resetFields();
      onClose && onClose();
   };

   return (
      <Form
         form={form}
         onFinish={(values) => {
            const { start_date_time } = values;

            //Controlla che la data sia futura
            if (start_date_time.isBefore()) {
               message.error(
                  intl.formatMessage({
                     id: "reuse_board.scheduler_start_date_time_must_be_future",
                  })
               );
               return;
            }

            const input = {
               start_date_time: start_date_time,
               reservation_ids: reservations.map((r) => r.id),
            };

            scheduleMutation({
               variables: {
                  input,
               },
            })
               .then((res) => {
                  const result = get(res, "data.scheduleProductReservation", false);
                  if (result === true) {
                     message.success(
                        intl.formatMessage({
                           id: "reuse_board.scheduler_completed",
                        })
                     );

                     form.resetFields();
                     onSuccess && onSuccess();
                  } else {
                     message.error(
                        intl.formatMessage({
                           id: "server_error.generic_error",
                        })
                     );
                  }
               })
               .catch((err) => {
                  message.error(
                     err?.message ??
                        intl.formatMessage({
                           id: "server_error.generic_error",
                        })
                  );
               });
         }}
      >
         <Modal
            title={`${intl.formatMessage({
               id: "reuse_board.reservation_delivery_drawer_title",
            })} (${reservations.length})`}
            placement="right"
            onClose={dismissModal}
            onCancel={dismissModal}
            destroyOnClose
            visible={visible}
            width={Math.min(window.innerWidth, 1000)}
            footer={[
               <Button key="back" onClick={dismissModal}>
                  {intl.formatMessage({ id: "common.cancel" })}
               </Button>,
               <Popconfirm
                  title={intl.formatMessage({ id: "common.are_you_sure" })}
                  okButtonProps={{ className: "gx-btn gx-btn-danger" }}
                  okText={intl.formatMessage({ id: "common.confirm" }).toUpperCase()}
                  cancelText={intl.formatMessage({ id: "common.no" }).toUpperCase()}
                  onConfirm={() => form.submit()}
               >
                  <Button type="primary" loading={loadingMutation}>
                     {intl.formatMessage({ id: "common.ok" }).toUpperCase()}
                  </Button>
               </Popconfirm>,
            ]}
         >
            <div className="container gx-pl-2 gx-pr-2">
               <Row>
                  <Col xs={24} className="gx-pl-0 gx-pr-0">
                     <Alert
                        type="info"
                        showIcon
                        message={
                           <small>
                              {intl.formatMessage({
                                 id: "reuse_board.reservation_delivery_drawer_p1",
                              })}
                           </small>
                        }
                     />
                  </Col>
               </Row>
               <Row>
                  <Col xs={24}>
                     <Form.Item
                        label={intl.formatMessage({
                           id: "reuse_board.reservation_scheduler_start_date_time",
                        })}
                        name="start_date_time"
                        rules={[
                           {
                              required: true,
                              message: intl.formatMessage({
                                 id: "services.new_service_required_field",
                              }),
                           },
                        ]}
                     >
                        <DatePicker
                           showTime
                           minuteStep={RESERVATION.AVAILABILITY_STEP_SIZE}
                           format={DATETIME_FORMAT}
                           disabledDate={(current) =>
                              current && current.isBefore(moment().startOf("day"))
                           }
                           onOk={(date) => {
                              if (date.isBefore()) {
                                 message.error(
                                    intl.formatMessage({
                                       id: "reuse_board.scheduler_start_date_time_must_be_future",
                                    })
                                 );
                                 form.setFieldsValue({ start_date_time: null });
                              }
                           }}
                           onChange={(date) => {
                              if (!date) {
                                 form.setFieldsValue({ start_date_time: null });
                              }
                           }}
                        />
                     </Form.Item>
                  </Col>
               </Row>
               <Row>
                  <Col xs={24}>
                     <Form.Item
                        noStyle
                        shouldUpdate={(_, currentValues) =>
                           currentValues.start_date_time != null
                        }
                     >
                        {({ getFieldValue }) =>
                           getFieldValue("start_date_time") != null ? (
                              <SchedulerTable
                                 reservations={reservations}
                                 start={getFieldValue("start_date_time")}
                              />
                           ) : null
                        }
                     </Form.Item>
                  </Col>
               </Row>
            </div>
         </Modal>
      </Form>
   );
};

export default DeliveryScheduler;
