import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import { useMutation, useReactiveVar } from "@apollo/client";
import { GQL_LOGIN } from "../../../apollo/mutation/user";
import { localStorageClass } from "../../../apollo/local/storage";
import { globalSettingsVar, showLoadingProgress } from "../../../apollo/local/cache";
import CircularProgress from "../../../components/CircularProgress";
import { getFrontendPath, pushNextOrFallback } from "../../../util/router";
import FrontendHeader from "../main/header";
import FrontendFooter from "../main/footer";

const FrontendLogin = () => {
   const intl = useIntl();

   const [login, { loading: loadingMutation }] = useMutation(GQL_LOGIN);

   var commonProps = useReactiveVar(globalSettingsVar);
   const [checked, setChecked] = useState(false);
   const history = useHistory();

   var paramRememberMe = { username: "", password: "" };
   const localParam = localStorage.getItem("REMEMBER_ME");
   if (localParam) {
      const jsonObj = JSON.parse(localParam);
      if (jsonObj) {
         paramRememberMe = jsonObj;
      }
   }

   //
   useEffect(() => {
      if (localStorageClass.isLogged()) {
         history.push(getFrontendPath("dashboard"));
      }
   });
   //

   useEffect(() => {
      if (!checked && paramRememberMe.username?.length > 0) {
         setChecked(true);
      }
   }, [checked, history, paramRememberMe.username?.length]);

   const rememberMeChecked = (e) => {
      if (!e.target.checked) {
         localStorage.setItem("REMEMBER_ME", null);
      }

      setChecked(e.target.checked);
   };

   const onFinish = (values) => {
      showLoadingProgress(true);
      const params = {
         username: values.email,
         password: values.password,
         frontend: true,
      };
      login({ variables: params })
         .then(({ data, errors }) => {
            showLoadingProgress(false);

            if (data && data.login) {
               localStorage.setItem(
                  "REMEMBER_ME",
                  checked ? JSON.stringify(params) : null
               );
               localStorageClass.login(data.login);
               
               pushNextOrFallback(history, getFrontendPath("dashboard"));
            } else {
               let messageKey = "login.signInError";
               if (errors && errors.length > 0) {
                  if (intl.messages[errors[0].message]) {
                     messageKey = errors[0].message;
                  }
               }
               message.error(intl.formatMessage({ id: messageKey }));
            }
         })
         .catch((e) => {
            message.error(intl.formatMessage({ id: "login.signInError" }));
            showLoadingProgress(false);
         });
   };

   var inputRef = React.createRef();

   useEffect(() => {
      inputRef.current.focus();
   }, [inputRef]);

   return (
      <>
         <FrontendHeader title={<IntlMessages id="frontend.login_header" />} />
         <div className="frontend-container">
            <div className="gx-app-login-container gx-app-login-container-frontend">
               <h2 className="gx-mb-5">
                  <IntlMessages id="frontend.login_h2" />
               </h2>
               <p>
                  <span
                     dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                           id: "frontend.login_p1",
                        }),
                     }}
                  ></span>
               </p>
               <Link to={getFrontendPath("registrati")}>
                  <Button
                     className="gx-mt-2 frontend-btn-lg w-100"
                     htmlType="submit"
                     name="buttonSubmit"
                  >
                     <IntlMessages id="frontend.login_sign_up_now" />
                  </Button>
               </Link>
               <p className="gx-mt-5">
                  <IntlMessages id="frontend.login_p2" />
               </p>
               <div className="gx-app-login-form-container">
                  <div className="gx-app-login-content">
                     <Form
                        initialValues={{ remember: true }}
                        name="basic"
                        onFinish={onFinish}
                        autoComplete="on"
                        className="gx-signin-form gx-form-row0"
                     >
                        <div className="gx-mb-2">
                           <label>
                              <IntlMessages id="frontend.login_label_username" />
                           </label>
                        </div>
                        <Form.Item
                           className="frontend-input"
                           initialValue={paramRememberMe.username}
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "validation.required",
                                 }),
                              },
                           ]}
                           name="email"
                        >
                           <Input
                              placeholder={"es. RSSMRA80A01F205X"}
                              ref={inputRef}
                              style={{ textTransform: "uppercase" }}
                           />
                        </Form.Item>
                        <div className="gx-mb-2">
                           <label>
                              <IntlMessages id="frontend.login_label_password" />
                           </label>
                        </div>
                        <Form.Item
                           className="frontend-input"
                           initialValue={paramRememberMe.password}
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "validation.required",
                                 }),
                              },
                           ]}
                           name="password"
                        >
                           <Input.Password
                              type="password"
                              placeholder={"es. mariorossi1"}
                              autoComplete="on"
                           />
                        </Form.Item>
                        <Form.Item name="ckRememberMe" className="gx-mb-5">
                           <Checkbox checked={checked} onChange={rememberMeChecked}>
                              <label>
                                 <IntlMessages id="frontend.login_label_remember" />
                              </label>
                           </Checkbox>
                        </Form.Item>
                        <Form.Item>
                           <Button
                              className="gx-mb-0 frontend-btn-lg w-100"
                              htmlType="submit"
                              name="buttonSubmit"
                              loading={loadingMutation}
                           >
                              <IntlMessages id="frontend.login_label_access" />
                           </Button>
                        </Form.Item>
                        <p>
                           <IntlMessages id="frontend.login_not_remember_password" />{" "}
                           <Link
                              className="gx-login-form-forgot underline"
                              to={getFrontendPath("recupero-password")}
                           >
                              <IntlMessages id="frontend.login_click_here" />
                           </Link>{" "}
                           <IntlMessages id="frontend.login_not_remember_password2" />
                        </p>
                     </Form>
                  </div>

                  {commonProps.loading ? (
                     <div className="gx-loader-view">
                        <CircularProgress />
                     </div>
                  ) : null}
               </div>
            </div>
         </div>
         <FrontendFooter />
      </>
   );
};

export default FrontendLogin;
