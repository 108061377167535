import { Alert, Button, List } from "antd";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import Item from "./item";
import { COMPONENT_WIDTH } from "../constants";
import CustomScrollbars from "../../../../util/CustomScrollbars";
import { useHistory } from "react-router-dom";
import { getAdminPath } from "../../../../util/router";

export const MAX_LIST_HEIGHT = 340;

const ResultList = ({ term, results, onSelect }) => {
   const intl = useIntl();
   const history = useHistory();

   useEffect(() => {
      //useRef non funziona sul componente List quindi devo usare il DOM
      const list = window.document.getElementById("message-list");
      if (list) {
         list.scrollTop = 0;
      }
   }, [results]);

   if (!results) {
      return null;
   }

   if (results.length === 0) {
      return (
         <Alert
            type="info"
            message={intl.formatMessage({ id: "topbar.no_results_found" })}
            style={{ minWidth: COMPONENT_WIDTH }}
            className="gx-text-center gx-mb-0"
            action={
               <Button
                  size="small"
                  type="default"
                  className="gx-mb-0"
                  onClick={() => {
                     if (term) {
                        history.push(
                           getAdminPath(
                              `customers/insert/?term=${encodeURIComponent(term)}`
                           )
                        );
                     }
                  }}
               >
                  {intl.formatMessage({ id: "customers.create_new" })}
               </Button>
            }
         />
      );
   }

   return (
      <CustomScrollbars
         style={{
            minWidth: COMPONENT_WIDTH,
            maxHeight: MAX_LIST_HEIGHT,
         }}
         className="gx-layout-sider-scrollbar"
      >
         <List
            id="message-list"
            size="small"
            bordered={false}
            dataSource={results}
            rowKey={(record) => `${record.id}-${record.type}}`}
            renderItem={(item) => (
               <Item item={item} onSelect={(record) => onSelect(record)} />
            )}
            footer={
               results.length > 0 ? (
                  <div className="gx-text-center">
                     <small>
                        {`${results.length} ${intl.formatMessage({
                           id:
                              results.length === 1
                                 ? "common.result"
                                 : "common.results",
                        })}`}
                     </small>
                  </div>
               ) : null
            }
         />
      </CustomScrollbars>
   );
};

export default ResultList;
