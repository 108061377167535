import React from "react";
import { Select } from "antd";
import { injectIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { GQL_COMUNE_GROUPED_ZONES_ADDRESSES } from "../../../../../apollo/query/raccolte";
import { first, get } from "lodash";

const IndirizzoRaccolteSelect = ({
   intl,
   comuneId,
   onChange,
   value,
   placeholder,
   className = "",
}) => {
   const { data, loading } = useQuery(GQL_COMUNE_GROUPED_ZONES_ADDRESSES, {
      variables: {
         filters: { comune_id: comuneId },
      },
      onError: (error) => {},
   });

   const indirizzi = get(data, "groupedZones", []);

   return (
      <Select
         loading={loading}
         className={className}
         value={!loading ? get(value, "street") : null}
         allowClear={true}
         style={{ width: 200 }}
         onChange={(value) =>
            onChange(first(indirizzi.filter((ind) => ind.id === value)))
         }
         showSearch
         filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
         }
         placeholder={placeholder}
      >
         {indirizzi.map((c) => (
            <Select.Option value={c.id} key={`appreport_select_indirizzo_${c.id}`}>
               {c.street}
            </Select.Option>
         ))}
      </Select>
   );
};

export default injectIntl(IndirizzoRaccolteSelect);
