import { gql } from "@apollo/client";

export const GQL_COMUNI_RACCOLTA = gql`
   query comuni($filters: FilterComuniInput) {
      comuni(filters: $filters) {
         id
         istat_code
         name
         zones
         updated_zones_at         
         has_raccolte_addresses
      }
   }
`;

export const GQL_COMUNE_RACCOLTA = gql`
   query comune($filters: FilterComuneInput!) {
      comune(filters: $filters) {
         id
         istat_code
         name
         zones
         has_raccolte_addresses
         raccolte_types
      }
   }
`;

export const GQL_COMUNE_GROUPED_ZONES_ADDRESSES = gql`
   query getGroupedZones($filters: FilterZonesInput!) {
      groupedZones(filters: $filters, dev: false) {
         id
         zones
         numbers {
            number
            zone
         }
         street
         street_search
         types
      }
   }
`;

export const GQL_COMUNE_ZONES_ADDRESSES = gql`
   query getZones($filters: FilterZonesInput!, $comune_id: String) {
      zones(filters: $filters, dev: false) {
         id
         zone
         number
         types
      }
      raccolteTypes(filters: { comune_id: $comune_id }) {
         id
         name
      }
   }
`;

export const GQL_COMUNE_ZONES_RACCOLTE_TYPES = gql`
   query getZones($comune_id: String) {
      raccolteTypes(filters: { comune_id: $comune_id }) {
         id
         name
      }
   }
`;

export const GQL_COMUNE_DATE_RACCOLTE = gql`
   query raccolte($filters: FilterRaccoltaInput!) {
      raccolte(filters: $filters) {
         year
         date
         types
      }
   }
`;
