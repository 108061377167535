import React from "react";
import { List, Typography } from "antd";
import {
   CalendarOutlined,
   RightCircleOutlined,
   UserOutlined,
} from "@ant-design/icons";
import { SearchResultType } from "../constants";

const AVATAR_STYLE = { fontSize: 24, marginTop: 6, color: "#d5d5d5" };

const Item = ({ item, onSelect }) => {
   const avatar =
      item.type === SearchResultType.CUSTOMER ? (
         <UserOutlined style={AVATAR_STYLE} />
      ) : (
         <CalendarOutlined style={AVATAR_STYLE} />
      );

   return (
      <List.Item
         style={{ cursor: "pointer" }}
         actions={[<RightCircleOutlined />]}
         onClick={() => onSelect && onSelect(item)}
      >
         <List.Item.Meta
            avatar={avatar}
            title={
               <Typography.Text mark className="gx-mb-0 gx-pb-0">
                  <span className="gx-pl-1">{item.title}</span>
               </Typography.Text>
            }
            description={<small className="gx-pl-1">{item.description}</small>}
         />
      </List.Item>
   );
};

export default Item;
