import React from "react";
import { Select } from "antd";
import PropTypes from "prop-types";

const WAREHOUSE_SELECT_TYPE = {
   SECTOR: "sector",
   RACK: "rack",
};

function formatOptions(options) {
   return options.map((option) => ({
      label: option,
      value: option,
   }));
}

function getTypeOptions(type) {
   switch (type) {
      case "rack":
         return formatOptions(Array.from({ length: 99 }, (_, index) => index + 1));
      case "sector":
      default:
         const alpha = Array.from(Array(26)).map((e, i) => i + 65);
         const alphabet = alpha.map((x) => String.fromCharCode(x));
         return formatOptions(alphabet);
   }
}

const WarehouseSelect = ({ type = WAREHOUSE_SELECT_TYPE.SECTOR, ...rest }) => {
   return <Select showSearch allowClear options={getTypeOptions(type)} {...rest} />;
};

WarehouseSelect.propTypes = {
   type: PropTypes.oneOf(Object.keys(WAREHOUSE_SELECT_TYPE)).isRequired,
};

export default WarehouseSelect;
