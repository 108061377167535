import { InfoCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Modal, Input, Form, Button, message } from "antd";
import React from "react";
import { GQL_PRODUCT_CREATE } from "../../../../apollo/mutation/reuse-board";
import IntlMessages from "../../../../util/IntlMessages";
import {
    defaultCatchException,
    defaultMutationCallback
} from "../../../../apollo/callbacks";
import TipsCard from "../../../../components/TipsCard";
import { injectIntl } from "react-intl";
import { getAdminPath } from "../../../../util/router";
import { useHistory } from "react-router-dom";
import BadResponseErrorAlert from "../../../../components/BadResponseErrorAlert";

const InsertProductModal = ({ intl, visible, onCancel }) => {

    const history = useHistory();

    const [insertProduct, { loading }] = useMutation(
        GQL_PRODUCT_CREATE
    );

    return (
        <Modal
            visible={visible}
            icon={<InfoCircleOutlined />}
            onCancel={onCancel}
            footer={null}
            title={<IntlMessages id="products.new" />}
        >
            <Form
                onFinish={(values) => {
                    insertProduct({
                        variables: {
                            input: {
                                name: values.name,
                            }
                        }
                    })
                        .then((data) => {
                            defaultMutationCallback(data, () => {
                                if (data.data?.createProduct) {
                                    onCancel();
                                    history.push(getAdminPath(`reuse-board/products/update/${data.data.createProduct}`));
                                } else {
                                    message.error(intl.formatMessage({ id: "common.default_mutation_error" }));
                                }
                            });
                        })
                        .catch((e) => defaultCatchException(e, intl));
                }}
                layout="vertical"
                autoComplete="off"
            >
                <TipsCard type="info">
                    <IntlMessages id="products.insert_tips" />
                </TipsCard>

                <BadResponseErrorAlert />

                <Form.Item
                    hasFeedback
                    label={intl.formatMessage({ id: "common.name" })}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({ id: "validation.required" }),
                        },
                    ]}
                    name="name"
                >
                    <Input
                        maxLength={127}
                        placeholder={intl.formatMessage({
                            id: "common.name",
                        })}
                    />
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {intl.formatMessage({ id: "common.submit" })}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default injectIntl(InsertProductModal);
