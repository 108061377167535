import React, { useState } from "react";
import { Button, Input, Form, Result, message } from "antd";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../../components/CircularProgress";

import { useMutation, useReactiveVar } from "@apollo/client";
import { globalSettingsVar } from "../../../apollo/local/cache";
import { GQL_RESET_PASSWORD } from "../../../apollo/mutation/user";
import { injectIntl } from "react-intl";
import { getFrontendPath } from "../../../util/router";
import FrontendFooter from "../main/footer";
import FrontendHeader from "../main/header";

const FrontendResetPassword = ({ intl }) => {
   var commonProps = useReactiveVar(globalSettingsVar);
   const history = useHistory();
   const match = useRouteMatch();
   const [success, setSuccess] = useState(false);
   const [loadingMutation, setLoadingMutation] = useState(false);
   const [resetPassword] = useMutation(GQL_RESET_PASSWORD);

   if (!match.params || !match.params.tokenId) {
      history.push("/");
      return null;
   }

   const onFinish = (values) => {
      setLoadingMutation(true);

      const params = { password: values.password1, token: match.params.tokenId };

      resetPassword({ variables: params })
         .then(({ data }) => {
            setLoadingMutation(false);

            if (data && data.resetPassword) {
               setSuccess(true);
            } else {
               message.error(
                  intl.formatMessage({
                     id: "login.reset_password_error_message",
                  })
               );
            }
         })
         .catch((e) => {
            setLoadingMutation(false);
            message.error(
               intl.formatMessage({ id: "login.reset_password_error_message" })
            );
         });
   };

   if (!success) {
      return (
         <>
            <FrontendHeader
               title={<IntlMessages id="registration.reset_password" />}
            />
            <div className="frontend-container">
               <div className="gx-app-login-container gx-app-login-container-frontend">
                  <h2 className="gx-mb-4">
                     <IntlMessages id="registration.reset_password" />
                  </h2>
                  <div className="gx-app-login-main-content gx-app-login-main-content-frontend">
                     <div className="gx-app-login-content">
                        <Form
                           initialValues={{ remember: true }}
                           name="basic"
                           onFinish={onFinish}
                           autoComplete="off"
                           className="gx-signin-form gx-form-row0"
                        >
                           <Form.Item
                              className="frontend-input"
                              rules={[
                                 {
                                    required: true,
                                    message: intl.formatMessage({
                                       id: "services.new_service_required_field",
                                    }),
                                 },
                                 {
                                    min: 5,
                                    message: intl.formatMessage({
                                       id: "services.min_5_chars",
                                    }),
                                 },
                                 {
                                    max: 50,
                                    message: intl.formatMessage({
                                       id: "services.max_50_chars",
                                    }),
                                 },
                              ]}
                              name="password1"
                           >
                              <Input.Password
                                 autoFocus
                                 autoComplete="new-password"
                                 placeholder={intl.formatMessage({
                                    id: "registration.new_password",
                                 })}
                              />
                           </Form.Item>
                           <Form.Item
                              className="frontend-input"
                              rules={[
                                 {
                                    required: true,
                                    message: intl.formatMessage({
                                       id: "services.new_service_required_field",
                                    }),
                                 },
                                 {
                                    min: 5,
                                    message: intl.formatMessage({
                                       id: "services.min_5_chars",
                                    }),
                                 },
                                 {
                                    max: 50,
                                    message: intl.formatMessage({
                                       id: "services.max_50_chars",
                                    }),
                                 },
                                 ({ getFieldValue }) => ({
                                    validator(_, value) {
                                       if (
                                          !value ||
                                          getFieldValue("password1") === value
                                       ) {
                                          return Promise.resolve();
                                       }
                                       return Promise.reject(
                                          new Error(
                                             intl.formatMessage({
                                                id: "services.password_not_equals",
                                             })
                                          )
                                       );
                                    },
                                 }),
                              ]}
                              name="password2"
                           >
                              <Input.Password
                                 autoComplete="new-password"
                                 placeholder={intl.formatMessage({
                                    id: "registration.new_password_confirm",
                                 })}
                              />
                           </Form.Item>
                           <Form.Item>
                              <Button
                                 className="gx-mb-0 frontend-btn-lg w-100"
                                 htmlType="submit"
                                 name="buttonSubmit"
                                 loading={loadingMutation}
                              >
                                 <IntlMessages id="registration.reset_password" />
                              </Button>
                           </Form.Item>
                        </Form>
                     </div>

                     {commonProps.loading ? (
                        <div className="gx-loader-view">
                           <CircularProgress />
                        </div>
                     ) : null}
                  </div>

                  <div className="gx-text-center">
                     <Link to={getFrontendPath()}>
                        <Button className="gx-mt-5 frontend-btn-lg btn-grey">
                           <IntlMessages id="frontend.forget_password_back_to_login" />
                        </Button>
                     </Link>
                  </div>
               </div>
            </div>
            <FrontendFooter />
         </>
      );
   } else {
      return (
         <>
            <FrontendHeader
               title={<IntlMessages id="registration.reset_password" />}
            />
            <div className="frontend-container">
               <Result
                  key="1"
                  status="success"
                  title={
                     <div>
                        <IntlMessages id="login.reset_password_success_title" />
                     </div>
                  }
                  subTitle={
                     <div>
                        <IntlMessages id="login.reset_password_success_message" />
                     </div>
                  }
                  extra={[
                     <Link to={getFrontendPath("login")}>
                        <Button
                           key="login"
                           className="frontend-btn-lg w-auto btn-grey"
                        >
                           <IntlMessages id="login.signIn" />
                        </Button>
                     </Link>,
                  ]}
               />
            </div>
            <FrontendFooter />
         </>
      );
   }
};

export default injectIntl(FrontendResetPassword);
