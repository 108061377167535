import React from "react";
import { Row, Col, PageHeader, message } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router";
import { getFrontendPath } from "../../../util/router";
import BadResponseErrorAlert from "../../../components/BadResponseErrorAlert";
import { useQuery } from "@apollo/client";
import { GQL_PRIVACY_POLICY } from "../../../apollo/query/privacy-policy";
import { get } from "lodash";
import { ArrowLeftOutlined } from "@ant-design/icons";
import CircularProgress from "../../../components/CircularProgress";
import FrontendHeader from "../main/header";
import FrontendFooter from "../main/footer";

const FrontendPrivacyPolicySettings = ({ intl }) => {
   const history = useHistory();
   const { loading, data, error } = useQuery(GQL_PRIVACY_POLICY);

   if (error) {
      message.error(
         intl.formatMessage({ id: "privacy-policy.generic_server_error" })
      );
      return null;
   }

   const privacyText = get(data, "privacyPolicy.text", "");

   return (
      <>
         <FrontendHeader title={<IntlMessages id="frontend.login_header" />} />
         <div className="frontend-container">
            <div className="">
               <Row>
                  <Col md={24}>
                     <PageHeader
                        className="gx-page-header"
                        title={
                           <h2 className="multiline-text">
                              <IntlMessages id="sidebar.privacy_policy" />
                           </h2>
                        }
                        onBack={() => history.goBack()}
                        backIcon={<ArrowLeftOutlined className="gx-arrow-back" />}
                     />
                  </Col>
                  <Col md={24}>
                     <BadResponseErrorAlert redirectURL={getFrontendPath()} />
                  </Col>
                  <Col md={24}>
                     {loading ? (
                        <CircularProgress />
                     ) : (
                        <p dangerouslySetInnerHTML={{ __html: privacyText }} />
                     )}
                  </Col>
               </Row>
            </div>
         </div>
         <FrontendFooter />
      </>
   );
};

export default injectIntl(FrontendPrivacyPolicySettings);
