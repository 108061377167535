export const CustomerTypes = {
   PRIVATE: "PRIVATE",
   COMPANY: "COMPANY",
};

export const Roles = {
   SUPERADMIN: "superadmin",
   ADMIN: "admin",
   SUPERGUEST: "superguest",
   EMPLOYEE: "employee",
   CUSTOMER: "customer",
   CHECKING_REPORTS: "checking_reports",
   REUSE_BOARD_OPERATOR: "reuse_board_operator",
};

export const ResponseErrorType = {
   GRAPHQL_ERROR: 1,
   NETWORK_ERROR: 2,
};

export const AssetContextType = {
   SERVICE_ATTACHMENTS: "SERVICE_ATTACHMENTS",
};

export const ProductReservationStatuses = {
   ND: "nd",
   CONFIRMED: "confirmed",
   REJECTED: "rejected",
   READY_FOR_DELIVERY: "ready_for_delivery",
   DELIVERED: "delivered",
};
