export const DATE_FORMAT = "DD-MM-YYYY";

export const DATETIME_FORMAT = "DD-MM-YYYY HH:mm";

export const TIME_FORMAT = "HH:mm";

export const ADMIN_ROUTE_PREFIX = "admin";
export const FRONTEND_ROUTE_PREFIX = "servizi-online";

export const PASSWORD_MIN_LENGTH = 5;
export const PASSWORD_MAX_LENGTH = 50;
export const CALENDAR = {
   STEP: 5, //min
   POLLING_INTERVAL_REFETCH: 20000, //20sec
};

export const RESERVATION = {
   STATUS: {
      INTENT: "intent",
      INTENT_UPDATED: "intent_updated",
      PROCESSING: "processing",
      PAYMENT_ERROR: "payment_error", //solo lato client per gestire nuovo tentativo di pagamento
      SUCCESS: "success",
      CLOSED: "closed",
      DELETED: "deleted",
   },
   AVAILABILITY_STEP_SIZE: 15, //min
   MODE: {
      WORKSPACE: "workspace",
      EMPLOYEE: "employee",
   },
   WORKSPACE_SELECT_THRESHOLD: 10,
};

export const PAYMENTS = {
   STRIPE: "stripe",
   OFFLINE: "offline",
};

export const ITALY_PHONE_PREFIX = "+39";

export const APP_REPORT = {
   STATUS: {
      ND: "nd",
      PROCESSING: "processing",
      SUCCESS: "success",
      DELETED: "deleted",
   },
};

//Numero massimo di servizi selezionabili per prenotazione
export const MAX_SERVICES_FOR_RESERVATION = 2;

//Giorni dopo i quali una prenotazione della bacheca in pronta consegna risulta scaduta
export const REUSEBOARD_RESERVATION_EXPIRING_DAYS = 15;
