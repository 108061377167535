import React, { useContext, useEffect } from "react";
import { Layout, Row, Col } from "antd";
import Sidebar from "../Sidebar/index";
import Topbar from "../Topbar/index";
import { footerText, appVersion, commitHash } from "util/config";
import AdminRoutes from "routes/admin";
import BadResponse from "../BadResponse";
import { get } from "lodash";

import {
   NAV_STYLE_ABOVE_HEADER,
   NAV_STYLE_BELOW_HEADER,
   NAV_STYLE_DARK_HORIZONTAL,
   NAV_STYLE_DEFAULT_HORIZONTAL,
   NAV_STYLE_DRAWER,
   NAV_STYLE_FIXED,
   NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
   NAV_STYLE_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";

import CircularProgress from "../../components/CircularProgress";
import { layoutSettingsVar } from "../../apollo/local/cache";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GQL_INIT_QUERY } from "../../apollo/query/user";
import { AbilityContext, defineRulesFor } from "../../acl/index";
import { injectIntl } from "react-intl";
import { Roles } from "../../constants/Enums";

const ProtectedMainApp = ({ user, workspaces }) => {
   const ability = useContext(AbilityContext);
   const { navStyle } = useReactiveVar(layoutSettingsVar);

   useEffect(() => {
      if (user) {
         const rules = defineRulesFor(user);
         ability.update(rules);
      }
   }, [user, ability]);

   const getContainerClass = (navStyle) => {
      switch (navStyle) {
         case NAV_STYLE_DARK_HORIZONTAL:
            return "gx-container-wrap";
         case NAV_STYLE_DEFAULT_HORIZONTAL:
            return "gx-container-wrap";
         case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
            return "gx-container-wrap";
         case NAV_STYLE_BELOW_HEADER:
            return "gx-container-wrap";
         case NAV_STYLE_ABOVE_HEADER:
            return "gx-container-wrap";
         default:
            return "";
      }
   };
   const getNavStyles = (navStyle) => {
      switch (navStyle) {
         case NAV_STYLE_FIXED:
            return <Topbar />;
         case NAV_STYLE_DRAWER:
            return <Topbar />;
         case NAV_STYLE_MINI_SIDEBAR:
            return <Topbar />;
         default:
            return null;
      }
   };

   return (
      <Layout className="gx-app-layout gx-admin-app">
         <Sidebar user={user} workspaces={workspaces} />
         <Layout>
            {getNavStyles(navStyle)}
            <Layout.Content
               className={`gx-layout-content ${getContainerClass(navStyle)} `}
            >
               <AdminRoutes user={user} ability={ability} />
               <Layout.Footer>
                  <Row>
                     <Col md={12}>
                        <div className="gx-layout-footer-content">{footerText}</div>
                     </Col>
                     <Col md={12}>
                        <div className="gx-layout-footer-content gx-text-right">
                           <small>
                              v.{appVersion} - h.{commitHash}
                           </small>
                        </div>
                     </Col>
                  </Row>
               </Layout.Footer>
            </Layout.Content>
         </Layout>
      </Layout>
   );
};

const MainApp = ({ intl }) => {
   const {
      loading: loadingProfile,
      error: errorProfile,
      data,
   } = useQuery(GQL_INIT_QUERY);

   if (loadingProfile) {
      return (
         <div className="gx-loader-view">
            <CircularProgress />
         </div>
      );
   }

   if (errorProfile) {
      return (
         <BadResponse
            title={intl.formatMessage({ id: "500.ooops" })}
            subtitle={intl.formatMessage({ id: "500.something_wrong" })}
         />
      );
   }

   const role = get(data, "me.role", null);
   if (!role || role === Roles.CUSTOMER) {
      return (
         <BadResponse
            title={intl.formatMessage({ id: "500.unauthorized" })}
            redirectURL="/"
         />
      );
   }

   return <ProtectedMainApp user={data.me} workspaces={data.workspaces} />;
};

export default injectIntl(MainApp);
