import React, { useEffect, useState } from "react";
import { Alert, Button, Calendar, Col, Row } from "antd";
import { injectIntl, useIntl } from "react-intl";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { get, upperFirst } from "lodash";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import AvailabilitiesSlots from "./slots";
import { GQL_RESERVATION_AVAILABILITY_CALENDAR } from "../../apollo/query/reservation";
import { getCalendarDateCellClassName } from "./common";

const AvailabilitiesCalendar = ({
   workspace,
   duration,
   onClickSlot,
   showOverbooking = false,
   reservation = null,
}) => {
   const intl = useIntl();
   const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));

   let [loadingData, { data, error }] = useLazyQuery(
      GQL_RESERVATION_AVAILABILITY_CALENDAR
   );

   //Se non ho selezionato nessuna data imposto il mese corrente come default
   const [selectedMonth, setSelectedMonth] = useState(
      currentDate
         ? moment(currentDate).format("YYYY-MM")
         : moment().format("YYYY-MM")
   );

   const fetchData = (month) => {
      setSelectedMonth(month);

      loadingData({
         variables: {
            month: month,
            granularity: "month",
            force_service_duration: duration,
            workspace_id: workspace,
            current_reservation_id: reservation ? reservation.id : null,
         },
      });
   };

   useEffect(() => {
      //init query
      fetchData(selectedMonth);
   }, [duration]);

   if (error)
      return (
         <Alert
            message={intl.formatMessage({ id: "reservations.availability_error" })}
            type="error"
         />
      );

   const openings = get(data, "workspace.openings", []);
   const availabilities = get(data, "reservationAvailability.availables");
   const selectedMonthMoment = moment(selectedMonth);

   return (
      <Row>
         <Col xs={24} sm={8}>
            <Calendar
               headerRender={({ value, type, onChange }) => {
                  return (
                     <Row>
                        <Col xs={10} className="gx-pt-2">
                           <Button
                              size="small"
                              type="text"
                              onClick={(e) => {
                                 e.stopPropagation();
                                 const newValue = value.clone();
                                 newValue.subtract(1, "months");
                                 onChange(newValue);
                              }}
                           >
                              <LeftCircleOutlined />
                           </Button>
                           <Button
                              size="small"
                              type="text"
                              onClick={(e) => {
                                 e.stopPropagation();
                                 const newValue = value.clone();
                                 newValue.add(1, "months");
                                 onChange(newValue);
                              }}
                           >
                              <RightCircleOutlined />
                           </Button>
                        </Col>
                        <Col xs={14} className="gx-pt-2">
                           <h4 className="color-blue" style={{ display: "inline" }}>
                              {upperFirst(value.format("MMMM YYYY"))}
                           </h4>
                        </Col>
                     </Row>
                  );
               }}
               dateFullCellRender={(date) => {
                  const className = getCalendarDateCellClassName(
                     date,
                     selectedMonthMoment,
                     moment(currentDate),
                     availabilities
                  );

                  return (
                     <div
                        class={`ant-picker-cell-inner ant-picker-calendar-date ${className}`}
                     >
                        <div class="ant-picker-calendar-date-value">
                           {date.format("DD")}
                        </div>
                     </div>
                  );
               }}
               onPanelChange={(date) => {
                  setCurrentDate(date ? date.format("YYYY-MM-DD") : null);
                  fetchData(date.format("YYYY-MM"));
               }}
               disabledDate={(date) => {
                  if (openings && selectedMonthMoment) {
                     const d = date.date();
                     const m = date.month();
                     const selectedM = selectedMonthMoment.month();

                     if (selectedM === m) {
                        if (openings.length >= d) {
                           //disabilita anche disponibilità con array vuoto
                           return !openings[d - 1] || openings[d - 1].length === 0;
                        }
                     }
                  }

                  return false;
               }}
               fullscreen={false}
               onSelect={(value) => {
                  setCurrentDate(value ? value.format("YYYY-MM-DD") : null);
               }}
            />
         </Col>
         <Col xs={24} sm={16}>
            {currentDate ? (
               <AvailabilitiesSlots
                  onClickSlot={onClickSlot}
                  workspace={workspace}
                  duration={duration}
                  selectedDate={currentDate}
                  showOverbooking={showOverbooking}
               />
            ) : (
               <Alert
                  message={intl.formatMessage({ id: "reservations.select_day" })}
                  type="info"
               />
            )}
         </Col>
      </Row>
   );
};

export default injectIntl(AvailabilitiesCalendar);
