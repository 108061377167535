import React from "react";
import { Alert } from "antd";
import { useQuery } from "@apollo/client";
import { GQL_COMUNE_DATE_RACCOLTE } from "../../../../apollo/query/raccolte";
import { first, get, isEmpty } from "lodash";
import CircularProgress from "../../../../components/CircularProgress";
import RaccolteTable from "./table";
import IntlMessages from "../../../../util/IntlMessages";

function getSelectionZone(comune, indirizzo, civico) {
   const comuneZone = get(comune, "zones", []);

   if (isEmpty(comuneZone)) {
      //Il comune non ha la divisione per zone nella raccolta porta a porta
      return null;
   }

   if (civico && civico.zone) {
      return civico.zone;
   }

   return first(get(indirizzo, "zones", []));
}

function getDistinctYears(raccolte) {
   let years = [];
   raccolte.forEach((d) => {
      //Le raccolte con il nuovo sistema sono disponibili solo a partire dal 2023
      if (d.year >= 2023 && !years.includes(d.year)) {
         years.push(d.year);
      }
   });
   return years.sort().reverse();
}

const RaccolteList = ({ selection }) => {
   const { comune, indirizzo, civico } = selection;
   const zone = getSelectionZone(comune, indirizzo, civico);
   const raccolteTypes = get(indirizzo, "types");

   const { data, loading, error } = useQuery(GQL_COMUNE_DATE_RACCOLTE, {
      variables: {
         filters: {
            comune_id: comune.id,
            istat_code: comune.istat_code,
            types: raccolteTypes,
            zone: zone,
            next: false,
         },
      },
   });

   if (loading) return <CircularProgress />;

   const raccolte = get(data, "raccolte", []);
   const years = getDistinctYears(raccolte);
   if (error) return <Alert type="error" message={error.message} />;

   return (
      <>
         {raccolteTypes && (
            <Alert
               message={<IntlMessages id="raccolte.tipo_raccolte" />}
               description={raccolteTypes.join(", ")}
               type="info"
            />
         )}
         <RaccolteTable zone={zone} years={years} raccolte={raccolte} />
      </>
   );
};

export default RaccolteList;
