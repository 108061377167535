import React, { useMemo, useState } from "react";
import {
   Row,
   Col,
   Button,
   PageHeader,
   Steps,
   Result,
   Grid,
   Alert,
   Typography,
   Tag,
} from "antd";
import { injectIntl } from "react-intl";
import BadResponseErrorAlert from "../../../components/BadResponseErrorAlert";
import IntlMessages from "../../../util/IntlMessages";
import { getFrontendPath } from "../../../util/router";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GQL_RESERVATION_INIT } from "../../../apollo/query/reservation";
import CircularProgress from "../../../components/CircularProgress";
import { get, head, includes, keys, indexOf } from "lodash";
import {
   defaultCatchException,
   normalizeServerErrors,
} from "../../../apollo/callbacks";
import { FrontendReservationAvailabilitiesStep } from "./step/availability";
import { FrontendReservationServices } from "./step/services";
import { FrontendReservationWorkspaces } from "./step/workspace";
import { FrontendReservationSummaryStep } from "./step/summary";
import { GQL_PROCESS_RESERVATION } from "../../../apollo/mutation/reservation";
import { ServiceCollection } from "../../../entities/service";
import { localStorageClass } from "../../../apollo/local/storage";
import moment from "moment";
import { canResumeReservation } from "./step/common";
import { WorkspaceEntity } from "../../../entities/workspace";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { SECONDARY_COLOR } from "../../../constants/ThemeSetting";
import { reservationDateToString } from "../../../util/date";
import { serverErrorsVar } from "../../../apollo/local/error";

const { useBreakpoint } = Grid;

const FrontendReservationCheckoutForm = ({
   intl,
   organization,
   customer,
   services,
   workspaces,
   reservation,
   me,
}) => {
   const showWorkspaceStep = () => workspaces.length > 1;
   const showServicesStep = () => services.length > 1;

   //ultimo step dal quale riprendere la prenotazione
   //siccome la prenotazione viene salvata come intent all'accesso dell'ultimo step
   //la prenotazione può riprendere dall'ultimo step -1
   const getLastStepIndex = () => {
      return 2;
   };

   const getFirstStepIndex = () => {
      let stepIndex = 0;

      if (!showWorkspaceStep()) {
         if (showServicesStep()) {
            stepIndex = 1;
         } else {
            stepIndex = 2;
         }
      }

      return stepIndex;
   };

   const firstActiveStep = getFirstStepIndex();

   const getInitState = () => {
      if (reservation) {
         return {
            step: getLastStepIndex(),
            initFormValues: {
               customer_id: customer.id,
               workspace_id:
                  workspaces.length === 1
                     ? head(workspaces).id
                     : get(reservation, "workspace.id", null),
               services_id: reservation.services
                  .filter((reserved) =>
                     includes(
                        services.map((s) => s.id),
                        reserved.id
                     )
                  )
                  .map((s) => s.id),
               availability: {
                  day: moment(reservation.time.date, "YYYY-MM-DD").format(
                     "YYYY-MM-DD"
                  ),
                  slot: reservation.time.time_start,
               },
               payment_intent: null, //create new one??
               note: reservation.note,
            },
         };
      } else {
         return {
            step: firstActiveStep,
            initFormValues: {
               customer_id: customer.id,
               workspace_id: workspaces.length === 1 ? head(workspaces).id : null,
               services_id: services.length === 1 ? [services.map((s) => s.id)] : [],
               availability: null,
               payment_intent: null,
               note: "",
            },
         };
      }
   };

   const { step, initFormValues } = getInitState();

   const [currentStep, setCurrentStep] = useState(step);
   const [stepperStatus, setStepperStatus] = useState("process");

   //inizialmente true per evitare di passare intent null a stripe al primo caricamento
   const [loadingReservationIntent, setLoadingReservationIntent] = useState(true);
   const [formContent, setFormContent] = useState(initFormValues);

   const [processReservation, { loading: loadingMutation }] = useMutation(
      GQL_PROCESS_RESERVATION
   );

   const screens = useBreakpoint();

   const handlePreviousStep = () => {
      setStepperStatus("process");

      //Se sono nello step delle disponibilità e c'è solo un servizio devo saltare dirretamente allo step 0 non allo step 1
      if (currentStep === 2 && !showServicesStep()) {
         setCurrentStep(currentStep - 2);
      } else {
         setCurrentStep(currentStep - 1);
      }
   };

   /**
    * Processa l'intento di pagamento della prenotazione in creazione o update (con reservation id)
    * quando l'utente arriva nello step che comprende il pagamento (summary)
    */
   const processReservationIntent = () => {
      const currentReservation = localStorageClass.getReservation();

      setLoadingReservationIntent(true);

      processReservation({
         variables: {
            reservation: {
               customer_id: formContent.customer_id,
               services_id: formContent.services_id,
               workspace_id: formContent.workspace_id,
               date: formContent.availability.day.format("YYYY-MM-DD"),
               time_start: formContent.availability.slot,
               reservation_id: currentReservation ? currentReservation.id : null,
            },
         },
      })
         .then(({ data, errors }) => {
            if (errors) {
               const items = normalizeServerErrors(errors);

               serverErrorsVar({
                  hasErrors: true,
                  items: items,
               });
            }
            if (data && data.processReservation) {
               //console.log("data.processReservation", data.processReservation);
               localStorageClass.setReservation(data.processReservation);

               if (data.processReservation.payment_intent) {
                  setFormContent({
                     ...formContent,
                     payment_intent: data.processReservation.payment_intent,
                  });
               }
            }
            setLoadingReservationIntent(false);
         })
         .catch((e) => {
            setLoadingReservationIntent(false);
            defaultCatchException(e, intl);
         });
   };

   const handleError = (error) => {
      setStepperStatus("error");
   };

   const serviceCollection = useMemo(() => {
      const selectedServices = services.filter((s) =>
         includes(formContent.services_id, s.id)
      );
      return ServiceCollection(selectedServices);
   }, [formContent.services_id]);

   const commonProps = {
      intl,
      handleOk,
      handleError,
      handlePreviousStep,
      customer,
      workspaces,
      services,
      organization,
      formContent,
      serviceCollection,
      me,
   };

   const selectedWorkspace = head(
      workspaces.filter((w) => w.id === formContent.workspace_id)
   );

   const selectedWorkspaceEntity = WorkspaceEntity(selectedWorkspace);

   let steps = [
      {
         step: 0,
         title: (
            <h3>
               <IntlMessages id="frontend.seleziona_comune_ubicazione" />&nbsp;
               {selectedWorkspaceEntity.isNotNull() && currentStep !== 0 ? (
                  <Tag color={SECONDARY_COLOR} className="gx-ml-2">
                     {selectedWorkspaceEntity.getName()}
                  </Tag>
               ) : null}
            </h3>
         ),
         Component: <FrontendReservationWorkspaces {...commonProps} />,
         hidden: !showWorkspaceStep(),
         field_name: "workspace_id",
      },
      {
         step: 1,
         title: (
            <h3>
               <IntlMessages id="services.services" />{" "}
               {!serviceCollection.isEmpty() && currentStep !== 1 ? (
                  <Tag color={SECONDARY_COLOR} className="gx-ml-2">
                     {serviceCollection.getNames()}
                  </Tag>
               ) : null}
            </h3>
         ),
         Component: (
            <FrontendReservationServices
               {...commonProps}
               hasBackButton={firstActiveStep < 1}
            />
         ),
         hidden: !showServicesStep(),
         field_name: "services_id",
      },
      {
         step: 2,
         title: (
            <h3>
               <IntlMessages id="reservations.new_reservation_availabilities_p1" />
               &nbsp;
               {formContent.availability && currentStep !== 2 ? (
                  <Tag color={SECONDARY_COLOR}>
                     {reservationDateToString(formContent.availability, intl)}
                  </Tag>
               ) : null}
            </h3>
         ),
         Component: (
            <FrontendReservationAvailabilitiesStep
               {...commonProps}
               selectedServiceIds={formContent.services_id}
               hasBackButton={firstActiveStep < 2}
            />
         ),
         field_name: "availability",
      },
      {
         step: 3,
         title: <h3>{intl.formatMessage({ id: "common.summary" })}</h3>,
         Component: (
            <FrontendReservationSummaryStep
               loadingMutation={loadingMutation}
               loadingReservationIntent={loadingReservationIntent}
               processReservationIntent={processReservationIntent}
               {...commonProps}
            />
         ),
         field_name: "note",
      },
   ];

   /**
    * resetta i valori degli step successivi se il precedente è cambiato
    */
   const resetNextStepValues = (state, currentField, values) => {
      const fields = steps.map((step) => step.field_name);

      for (let i = indexOf(fields, currentField); i < fields.length; i++) {
         if (state[currentField] !== values[currentField]) {
            state[fields[i]] = null;
         }
      }

      return state;
   };

   function handleOk(values) {
      setStepperStatus("process");

      let updatedFormContent = { ...formContent };

      const fieldToUpdate = steps[currentStep].field_name;

      updatedFormContent = resetNextStepValues(
         updatedFormContent,
         fieldToUpdate,
         values
      );

      if (keys(values).length > 1) {
         updatedFormContent[fieldToUpdate] = values;
      } else {
         updatedFormContent[fieldToUpdate] = values[keys(values)[0]];
      }

      //
      let stepper = 1;

      if (currentStep === 0) {
         //Servizi è lo step successivo
         if (!showServicesStep()) {
            //Se c'è un solo servizio va assegnato di default e si passa direttamente allo step delle disponibilità
            const fieldToUpdate = steps[1].field_name;
            updatedFormContent[fieldToUpdate] = [head(services).id];

            stepper = 2;
         }
      }

      setFormContent(updatedFormContent);
      setCurrentStep(currentStep + stepper);
      //
   }

   const getCurrentStep = (item, index) => {
      if (item.hidden) return null;

      if (index === currentStep) {
         return item.Component;
      }

      return null;
   };

   return (
      <Row gutter={0}>
         <Col xs={24} md={24} sm={24}>
            <BadResponseErrorAlert redirectURL={getFrontendPath()} />
         </Col>
         <Col xs={24} md={24} sm={24}>
            <Steps
               current={currentStep}
               direction="vertical"
               status={stepperStatus}
               size={screens["sm"] ? "default" : "small"}
               className={`${
                  (screens["xs"] || screens["sm"]) && !screens["md"]
                     ? "gx-steps-mobile"
                     : ""
               } gx-mt-3`}
            >
               {steps.map((item, index) => (
                  <Steps.Step
                     key={`step-${index}`}
                     title={
                        <span className="color-blue font-bold">{item.title}</span>
                     }
                     description={getCurrentStep(item, index)}
                  />
               ))}
            </Steps>
         </Col>
      </Row>
   );
};

const FrontendReservationInsert = (props) => {
   const { services } = props;
   const screens = useBreakpoint();
   const history = useHistory();

   const getExtraToolbar = () => {
      return [
         <Link key="2" to={getFrontendPath("prenotazioni-elenco")}>
            <Button key="reserve-btn" className="btn-green">
               <IntlMessages id="reservations.my_reservations" />
            </Button>
         </Link>,
      ];
   };

   return (
      <div>
         <Row hidden={screens["lg"]}>
            <Col md={24} sm={24} className="gx-mt-2 gx-mb-2">
               {getExtraToolbar()}
            </Col>
         </Row>
         <Row>
            <Col md={24} sm={24}>
               <PageHeader
                  className="gx-page-header"
                  title={
                     <h2 className="multiline-text">
                        <IntlMessages id="reservations.new_reservation_title_insert_long" />
                     </h2>
                  }
                  extra={screens["lg"] ? getExtraToolbar() : []}
                  onBack={() => history.push(getFrontendPath())}
                  backIcon={<ArrowLeftOutlined className="gx-arrow-back" />}
               />
            </Col>
            <Col md={24} sm={24}>
               <p className="gx-mb-4">
                  <IntlMessages id="reservations.new_reservation_description" />
               </p>
            </Col>
         </Row>
         <Row gutter={0}>
            {services.length === 0 ? (
               <Col md={24} sm={24}>
                  <Result
                     status="warning"
                     title={
                        <IntlMessages id="reservations.services_not_available" />
                     }
                  />
               </Col>
            ) : (
               <Col md={24} sm={24} xs={24} lg={24}>
                  <FrontendReservationCheckoutForm {...props} />
               </Col>
            )}
         </Row>
      </div>
   );
};

const FrontendReservationUpdate = (props) => {
   const { reservation, services } = props;
   return (
      <div className="frontend-container">
         <Row>
            <Col md={24} sm={24}>
               <PageHeader
                  className="gx-page-header"
                  title={
                     <h2>
                        <IntlMessages id="common.reservation" />
                        &nbsp;
                        <small>
                           <Typography.Text
                              copyable={{
                                 text: `#${reservation.code}`,
                              }}
                              className="color-blue"
                           >
                              #{reservation.code}
                           </Typography.Text>
                        </small>
                     </h2>
                  }
                  extra={[
                     <Link key="1" to={getFrontendPath("prenotazioni-elenco")}>
                        <Button type="default" className="btn-green">
                           <IntlMessages id="reservations.my_reservations" />
                        </Button>
                     </Link>,
                  ]}
               />
            </Col>
         </Row>
         <Row gutter={0}>
            {services.length === 0 ? (
               <Col md={24} sm={24}>
                  <Result
                     status="warning"
                     title={
                        <IntlMessages id="reservations.services_not_available" />
                     }
                  />
               </Col>
            ) : (
               <Col md={24} sm={24} xs={24} lg={24}>
                  <FrontendReservationCheckoutForm {...props} />
               </Col>
            )}
         </Row>
      </div>
   );
};

const FrontendReservationCheckout = ({ intl }) => {
   const currentReservation = localStorageClass.getReservation();

   const currentReservationId = currentReservation ? currentReservation.id : "fkid"; //hack per forzare l'id, TODO dividere chiamata

   const { loading, error, data } = useQuery(GQL_RESERVATION_INIT, {
      variables: { reservation_id: currentReservationId },
      fetchPolicy: "no-cache",
   });

   if (loading) return <CircularProgress />;

   if (!data || !data.me || !data.services) {
      //se mancano dati base restituisci errore
      //TODO gestire meglio errori
      defaultCatchException(error, intl);
      return <BadResponseErrorAlert />;
   } else if (error || !canResumeReservation(data.reservation)) {
      //se c'è un errore,
      //la prenotazione non viene trovata
      //la prenotazione è completata o in processo di pagamento o uno stato superiore
      //proseguire con nuova prenotazione  (no return)
      localStorageClass.resetReservation();
   }

   if (!data.me.customer) {
      return (
         <Alert
            className="frontend-container"
            showIcon={true}
            type="error"
            message={intl.formatMessage({ id: "frontend.customer_not_found" })}
         />
      );
   }

   const services = get(data, "services", []);
   const workspaces = get(data, "workspaces", []);
   const customer = get(data, "me.customer");
   const organization = get(data, "me.current_organization");
   const reservation = get(data, "reservation", null);
   const me = get(data, "me", null);

   if (canResumeReservation(reservation)) {
      return (
         <FrontendReservationUpdate
            reservation={reservation}
            organization={organization}
            customer={customer}
            workspaces={workspaces}
            services={services}
            me={me}
            intl={intl}
         />
      );
   } else {
      return (
         <FrontendReservationInsert
            organization={organization}
            customer={customer}
            workspaces={workspaces}
            services={services}
            me={me}
            intl={intl}
         />
      );
   }
};

export default injectIntl(FrontendReservationCheckout);
