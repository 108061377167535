import React from "react";
import { Select } from "antd";
import { injectIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { GQL_COMUNI_RACCOLTA } from "../../../../../apollo/query/raccolte";
import { first, get } from "lodash";

const ComuniRaccolteSelect = ({
   intl,
   onChange,
   value,
   placeholder,
   className = "",
}) => {
   const { data, loading } = useQuery(GQL_COMUNI_RACCOLTA, {
      onError: (error) => { },
   });

   const comuni = get(data, "comuni", []);

   return (
      <Select
         loading={loading}
         className={className}
         value={!loading ? value : null}
         allowClear={true}
         style={{ width: 200 }}
         onChange={(value) =>
            onChange(first(comuni.filter((c) => c.istat_code === value)))
         }
         showSearch
         filterOption={(input, option) =>
            option.children.toLowerCase().startsWith(input.toLowerCase())
         }
         placeholder={
            placeholder ??
            intl.formatMessage({
               id: "common.search_by_comune",
            })
         }
      >
         {comuni.map((c) => (
            <Select.Option
               value={c.istat_code}
               key={`appreport_select_comune_${c.istat_code}`}
            >
               {c.name}
            </Select.Option>
         ))}
      </Select>
   );
};

export default injectIntl(ComuniRaccolteSelect);
