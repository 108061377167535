export const getInitials = (str, maxChars = null, uppercase = true) => {
   const inits = str
      .split(" ")
      .map((n) => {
         if (!n[0]) return "";
         return uppercase ? `${n[0].toUpperCase()}` : `${n[0]}`;
      })
      .join("");

   if (maxChars) {
      return inits.slice(0, maxChars);
   }

   return inits;
};

export const formatPercent = (value, digit = 2) => {
   const formatter = new Intl.NumberFormat("it-IT", {
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
   });
   return formatter.format(value) + `%`;
};

export const formatCurrency = (number, digits = 0, currency = "EUR") => {
   return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
   }).format(number);
};

/**
 * Non sicura, non usare per autenticazione ma solo per riferimento
 * @param chars
 * @param pool
 * @returns
 */
 export const randomString = function (
   chars = 17,
   pool = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
 ) {
   let text = '';
   for (let i = 0; i < chars; i++) {
     text += pool.charAt(Math.floor(Math.random() * pool.length));
   }
 
   return text;
 };
