import { isArray, isString } from "lodash";
export const selectFilterableProps = () => {
   return {
      showSearch: true,
      filterOption: (input, option) => {
         const searchable = isArray(option.children)
            ? option.children[0]
            : option.children;

         if (isString(searchable)) {
            return searchable?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
         }
         return true;
      },
   };
};

export const treeViewAdapter = (categories, level = 0) => {
   return (categories || []).map((category) => {
      return {
         key: category.id,
         value: category.id,
         title: category.name,
         children: treeViewAdapter(category.children, level + 1),
         isLeaf: category.is_leaf,
      };
   });
};

export const serverTableSorter = (name, state) => {
   let sortOrder;

   if (name === state.key) {
      let direction = null;
      if (Number.isInteger(state.direction)) {
         direction = state.direction === -1 ? "descend" : "ascend";
      } else {
         direction = state.direction;
      }

      sortOrder = direction;
   } else {
      sortOrder = false;
   }

   return {
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortOrder,
      sorter: true,
   };
};
