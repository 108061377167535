import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import NextApp from "./NextApp";
import * as serviceWorker from "./registerServiceWorker";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

Sentry.init({
   dsn: process.env.REACT_APP_SENTRY_DSN,
   integrations: [new Integrations.BrowserTracing()],
   tracesSampleRate: 0.1,
   environment: process.env.REACT_APP_ENV,
   enabled: process.env.REACT_APP_ENV !== "development",
   release: process.env.REACT_APP_GIT_LAST_COMMIT,
});

//console.log({ env: process.env })

ReactDOM.render(<NextApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
