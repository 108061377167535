const packageJson = require("../../package.json");

module.exports = {
   footerText: process.env.REACT_APP_FOOTER_TEXT,
   appVersion: packageJson.version,
   commitHash: process.env.REACT_APP_GIT_LAST_COMMIT
      ? process.env.REACT_APP_GIT_LAST_COMMIT.substring(0, 7)
      : "#",
   debounceSearchFilterDelay: 500, //delay in ms
   isDevelopment: process.env.REACT_APP_NODE_ENV === "development",
   adminURL: process.env.REACT_APP_ADMIN_URL,
};
