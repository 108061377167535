import React from "react";
import { Route } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Can } from "../../acl/index";
import PropTypes from "prop-types";

const PrivateCASLRoute = ({
   intl,
   I,
   a,
   component: Component,
   ability,
   ...rest
}) => {
   return (
      <Can I={I} a={a}>
         <Route component={Component} {...rest} />
      </Can>
   );
};

PrivateCASLRoute.propTypes = {
   I: PropTypes.string.isRequired,
   a: PropTypes.string.isRequired,
   component: PropTypes.elementType.isRequired,
};

export default injectIntl(PrivateCASLRoute);
