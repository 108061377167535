import React, { useState } from "react";
import { Row, Col, Input, DatePicker, Button, Checkbox, Tooltip } from "antd";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import { ProductReservationStatusSelect } from "./common";
import { DATE_FORMAT } from "../../../../constants/App";
import moment from "moment";

const ReservationsFilters = ({
   intl,
   tableSearchOptions,
   debouncedFilterCallback,
   setFilterCallback,
   resetFilters,
}) => {
   const { filter } = tableSearchOptions;
   const [term, setTerm] = useState(filter.term);

   return (
      <Row gutter={10} className="gx-mb-3">
         <Col xl={5}>
            <ProductReservationStatusSelect
               allowClear
               placeholder={intl.formatMessage({
                  id: "reservations.filter_by_state",
               })}
               style={{ width: 240 }}
               onChange={(value) => {
                  setFilterCallback({
                     ...filter,
                     status: value,
                  });
               }}
               value={get(tableSearchOptions, "filter.status")}
               className="gx-mb-2"
            />
         </Col>
         <Col xl={5}>
            <DatePicker.RangePicker
               format={DATE_FORMAT}
               className="gx-mb-2"
               onChange={(values) => {
                  setFilterCallback({
                     ...filter,
                     date_range: values
                        ? {
                             min_date: values[0]
                                .startOf("day")
                                .format("YYYY-MM-DD HH:mm:ss"),
                             max_date: values[1]
                                .endOf("day")
                                .format("YYYY-MM-DD HH:mm:ss"),
                          }
                        : null,
                  });
               }}
               value={
                  filter.date_range
                     ? [
                          moment(filter.date_range.min_date).startOf("day"),
                          moment(filter.date_range.max_date).endOf("day"),
                       ]
                     : null
               }
            />
         </Col>
         <Col xl={4}>
            <DatePicker
               format={DATE_FORMAT}
               className="gx-mb-2"
               style={{ width: 200 }}
               placeholder={intl.formatMessage({ id: "reuse_board.delivery_date" })}
               onChange={(value) => {
                  setFilterCallback({
                     ...filter,
                     delivery_date: value ? value.format("YYYY-MM-DD") : null,
                  });
               }}
               value={filter.delivery_date ? moment(filter.delivery_date) : null}
            />
         </Col>
         <Col xl={5}>
            <Input.Search
               style={{
                  maxWidth: "250px",
               }}
               allowClear
               className="input-search gx-mb-2"
               placeholder={intl.formatMessage({
                  id: "common.search_element",
               })}
               onChange={(e) => {
                  setTerm(e.target.value || "");
                  debouncedFilterCallback({
                     ...filter,
                     term: get(e, "target.value", filter.term),
                  });
               }}
               defaultValue={get(tableSearchOptions, "filter.term", "")}
               value={term}
            />
         </Col>
         <Col xl={3}>
            <Checkbox
               className="gx-mt-0"
               onChange={(e) =>
                  setFilterCallback({
                     ...filter,
                     status: null,
                     is_expired: e.target.checked,
                  })
               }
               checked={filter.is_expired}
            >
               <Tooltip
                  title={intl.formatMessage({
                     id: "reuse_board.reservations_expired_p",
                  })}
               >
                  {intl.formatMessage({ id: "reuse_board.reservations_expired" })}
               </Tooltip>
            </Checkbox>
         </Col>
         <Col xl={2}>
            <Button
               type="default"
               className="gx-mb-2"
               onClick={() => {
                  resetFilters && resetFilters();
                  setTerm("");
               }}
            >
               {intl.formatMessage({ id: "common.reset" })}
            </Button>
         </Col>
      </Row>
   );
};

export default injectIntl(ReservationsFilters);
