import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { Ability, AbilityBuilder, defineAbility } from "@casl/ability";
import { Roles } from "../constants/Enums";

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export default defineAbility((can, cannot) => {});

export function defineRulesFor(user) {
   const { can, rules } = new AbilityBuilder(Ability);

   if (user.role === Roles.SUPERADMIN) {
      can("manage", "all");
      return rules;
   }

   return user.ability;
}
