import React from "react";
import { Button } from "antd";
import IntlMessages from "../../../../../util/IntlMessages";

const DeliveryButton = ({ selectedReservations, onClick, ...rest }) => {
   const counter = selectedReservations ? selectedReservations.length : 0;

   return (
      <Button disabled={counter === 0} onClick={onClick} {...rest}>
         <IntlMessages id="reuse_board.reservation_set_delivery" />
         &nbsp;
         {counter > 0 && <>({counter})</>}
      </Button>
   );
};

export default DeliveryButton;
