import React, { useState } from "react";
import { Button, Input, Form, Result, message } from "antd";
import { Link } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";

import { useMutation, useReactiveVar } from "@apollo/client";
import { globalSettingsVar } from "../apollo/local/cache";
import { GQL_ASK_RESET_PASSWORD } from "../apollo/mutation/user";
import { injectIntl } from "react-intl";
import { getAdminPath } from "../util/router";

const ForgetPassword = ({ intl }) => {
   const commonProps = useReactiveVar(globalSettingsVar);
   const [success, setSuccess] = useState(false);
   const [loadingMutation, setLoadingMutation] = useState(false);
   const [forgetPassword] = useMutation(GQL_ASK_RESET_PASSWORD);

   const onFinish = (values) => {
      setLoadingMutation(true);

      const params = {
         email: values.email.toLowerCase(),
         fiscal_code: values.fiscal_code.toUpperCase(),
      };
      forgetPassword({ variables: params })
         .then(({ data }) => {
            setLoadingMutation(false);

            if (data && data.askResetPassword) {
               setSuccess(true);
            } else {
               message.error(
                  intl.formatMessage({
                     id: "login.forgot_password_error_message",
                  })
               );
            }
         })
         .catch((e) => {
            setLoadingMutation(false);
            message.error(
               intl.formatMessage({ id: "login.forgot_password_error_message" })
            );
         });
   };

   if (!success) {
      return (
         <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
               <div className="gx-app-login-main-content">
                  <div className="gx-app-logo-content">
                     <div className="gx-app-logo-content-bg"></div>
                     <div className="gx-app-logo-wid">
                        <h1>
                           <IntlMessages id="login.forgot_password" />
                        </h1>
                        <p>
                           <IntlMessages id="login.forgot_info_rescue" />
                        </p>
                     </div>
                     <div className="gx-app-logo">
                        <img alt="" src="/assets/images/savnobook_logo_blue2.svg" />
                     </div>
                  </div>
                  <div className="gx-app-login-content">
                     <Form
                        initialValues={{ remember: true }}
                        name="basic"
                        onFinish={onFinish}
                        autoComplete="off"
                        className="gx-signin-form gx-form-row0"
                     >
                        <Form.Item
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "services.new_service_required_field",
                                 }),
                              },
                           ]}
                           name="email"
                        >
                           <Input
                              placeholder={intl.formatMessage({
                                 id: "common.email",
                              })}
                              type="email"
                           />
                        </Form.Item>
                        <Form.Item
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "services.new_service_required_field",
                                 }),
                              },
                           ]}
                           name="fiscal_code"
                        >
                           <Input
                              placeholder={intl.formatMessage({
                                 id: "common.fiscal_code",
                              })}
                           />
                        </Form.Item>
                        <Form.Item>
                           <Button
                              type="primary"
                              className="gx-mb-0"
                              htmlType="submit"
                              name="buttonSubmit"
                              loading={loadingMutation}
                           >
                              <IntlMessages id="registration.reset_password" />
                           </Button>
                           <span>
                              <IntlMessages id="common.o" />
                           </span>{" "}
                           <Link to={getAdminPath("signin")}>
                              <IntlMessages id="login.signIn" />
                           </Link>
                        </Form.Item>
                     </Form>
                  </div>

                  {commonProps.loading ? (
                     <div className="gx-loader-view">
                        <CircularProgress />
                     </div>
                  ) : null}
               </div>
            </div>
         </div>
      );
   } else {
      return (
         <div className="gx-app-login-wrap">
            <Result
               key="1"
               status="success"
               title={<IntlMessages id="login.forgot_password_success_title" />}
               subTitle={<IntlMessages id="login.forgot_password_success_message" />}
               extra={[
                  <Link to={getAdminPath("signin")}>
                     <Button key="login">
                        <IntlMessages id="login.signIn" />
                     </Button>
                  </Link>,
               ]}
            />
         </div>
      );
   }
};

export default injectIntl(ForgetPassword);
