import React from "react";
import { message, Popover, Modal } from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { GQL_PAGINATED_PRODUCT_RESERVATIONS } from "../../../../apollo/query/reuse-board";
import { useMutation } from "@apollo/client";
import { getAdminPath } from "../../../../util/router";
import Text from "antd/lib/typography/Text";
import { GQL_PRODUCT_RESERVATION_DELETE } from "../../../../apollo/mutation/reuse-board";
import {
   defaultCatchException,
   defaultMutationCallback,
} from "../../../../apollo/callbacks";
import { injectIntl } from "react-intl";

const PopoverProductReservation = ({
   intl,
   record,
   ability,
   history,
   tableSearchOptions,
}) => {
   const [updateStatus] = useMutation(GQL_PRODUCT_RESERVATION_DELETE, {
      refetchQueries: [
         {
            query: GQL_PAGINATED_PRODUCT_RESERVATIONS,
            variables: tableSearchOptions,
         },
      ],
      awaitRefetchQueries: true,
      fetchPolicy: "no-cache",
   });

   const userMenuOptions = (
      <ul className="gx-user-popover">
         {ability.can("update", "Product") && (
            <li
               onClick={(e) => {
                  e.stopPropagation();
                  updateReservation(record.id);
               }}
            >
               <Text type="default">
                  <IntlMessages id="common.update" />
               </Text>
            </li>
         )}

         {ability.can("delete", "ProductReservation") && (
            <li
               onClick={(e) => {
                  e.stopPropagation();
                  confirmDeleteProductReservation(record.id);
               }}
            >
               <Text type="danger">
                  <IntlMessages id="common.delete" />
               </Text>
            </li>
         )}
      </ul>
   );

   const updateReservation = (reservationId) => {
      history.push(
         `${getAdminPath("reuse-board/reservations/update")}/${reservationId}`
      );
   };

   const confirmDeleteProductReservation = () => {
      Modal.confirm({
         title: intl.formatMessage({ id: "common.warning" }),
         icon: <ExclamationCircleOutlined />,
         content: intl.formatMessage({
            id: "reuse_board.reservation_delete_confirm_message",
         }),
         okText: intl.formatMessage({ id: "common.delete" }),
         cancelText: intl.formatMessage({ id: "common.no" }),
         okType: "danger",
         onOk() {
            updateStatus({
               variables: {
                  id: record.id,
               },
            })
               .then((data) =>
                  defaultMutationCallback(data, () => {
                     message.success(
                        intl.formatMessage({
                           id: "reuse_board.reservation_delete_success",
                        })
                     );
                  })
               )
               .catch((e) => defaultCatchException(e, intl));
         },
      });
   };

   if (
      ability.can("update", "ProductReservation") ||
      ability.can("delete", "ProductReservation")
   ) {
      return (
         <Popover
            placement="bottomRight"
            content={userMenuOptions}
            trigger="click"
            onClick={(e) => {
               e.stopPropagation();
            }}
         >
            <MoreOutlined />
         </Popover>
      );
   }

   return null;
};

export default injectIntl(PopoverProductReservation);
