import React from "react";
import { Button, Card, Descriptions, Empty } from "antd";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../../../util/IntlMessages";
import { getAdminPath } from "util/router";
import { LinkOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ProductCard = ({ product, intl }) => {
   const history = useHistory();

   if(!product) return (
      <Card className="gx-card" title={<IntlMessages id="products.product" />}>
         <Empty description={<IntlMessages id="products.not_found" />} />
      </Card>
   )

   return (
      <Card className="gx-card" title={<IntlMessages id="products.product" />}>
         <Descriptions column={1} bordered>
            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({ id: "common.name" })}
                  </h4>
               }
            >
               <p className="gx-mb-0">{product.name}</p>
            </Descriptions.Item>
            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({ id: "common.description" })}
                  </h4>
               }
            >
               <p className="gx-mb-0">{product.description}</p>
            </Descriptions.Item>
            <Descriptions.Item
               label={
                  <h4 className="gx-mb-0">
                     {intl.formatMessage({ id: "common.category" })}
                  </h4>
               }
            >
               <p className="gx-mb-0">{product.category?.path_formatted}</p>
            </Descriptions.Item>
         </Descriptions>
         <div className="w-100 gx-text-right gx-mt-3">
            <Button
               type="default"
               size="small"
               onClick={(e) => {
                  e.preventDefault();
                  history.push(
                     `${getAdminPath("reuse-board/products/update")}/${product.id}`
                  );
               }}
            >
               <IntlMessages id="reuse_board.product_detail" />
               <LinkOutlined className="gx-ml-1" />
            </Button>
         </div>
      </Card>
   );
};

export default injectIntl(ProductCard);
