import BreadcrumbHomeLink from "../../../../components/BreadcrumbHomeLink";
import { Breadcrumb } from "antd";
import IntlMessages from "../../../../util/IntlMessages";

export const ReuseBoardBreadcrumbs = ({ children }) => {
   return (
      <Breadcrumb className="gx-pb-3">
         <Breadcrumb.Item>
            <BreadcrumbHomeLink />
         </Breadcrumb.Item>
         <Breadcrumb.Item>
            <IntlMessages id="sidebar.reuse_board" />
         </Breadcrumb.Item>
         {children}
      </Breadcrumb>
   );
};
