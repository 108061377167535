import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { getFrontendPath } from "../../../util/router";
import IntlMessages from "../../../util/IntlMessages";

const NotAllowed = ({ title, subtitle, redirectURL }) => {
   return (
      <Result
         status="403"
         title={title}
         subTitle={subtitle}
         extra={
            <Link to={redirectURL}>
               <Button type="primary">
                  <IntlMessages id="login.signIn" />
               </Button>
            </Link>
         }
      />
   );
};

NotAllowed.defaultProps = {
   redirectURL: getFrontendPath('login'),   
};

export default NotAllowed;
