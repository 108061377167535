import React, { useEffect, useState } from "react";
import { Select, Table, Tag, Typography } from "antd";
import { first } from "lodash";
import { useIntl } from "react-intl";
import { DATE_FORMAT } from "../../../../constants/App";
import moment from "moment";

function parsingRaccolteByYear(raccolte, year) {
   if (!year) return [];
   return raccolte.filter((r) => r.year === year);
}

const RaccolteTable = ({ zone, years, raccolte }) => {
   const y = first(years, null);
   const [selectedYear, setSelectedYear] = useState(y);
   const [results, setResults] = useState(parsingRaccolteByYear(raccolte, y));
   const intl = useIntl();

   useEffect(
      () => setResults(parsingRaccolteByYear(raccolte, selectedYear)),
      [raccolte, selectedYear]
   );

   const columns = [
      {
         title: intl.formatMessage({ id: "common.date" }),
         dataIndex: "date",
         sorter: false,
         fixed: "left",
         responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
         width: 120,
         render: (text) => moment(text).format(DATE_FORMAT),
      },
      {
         title: intl.formatMessage({ id: "raccolte.raccolte" }),
         dataIndex: "types",
         sorter: false,
         fixed: "left",
         responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
         render: (data) =>
            data.map((d, idx) => (
               <Tag className="gx-mb-0" key={`tag-tabl-rac-${idx}`}>
                  {d}
               </Tag>
            )),
      },
   ];

   return (
      <>
         {intl.formatMessage({ id: "common.year" })}

         <Select
            className="gx-ml-1"
            placeholder={intl.formatMessage({ id: "common.year" })}
            value={selectedYear}
            onSelect={(value) => setSelectedYear(value)}
         >
            {years.map((y) => (
               <Select.Option value={y} key={`rac_year_${y}`}>
                  {y}
               </Select.Option>
            ))}
         </Select>

         <Table
            title={() =>
               zone ? (
                  <Typography.Title level={5}>
                     {intl.formatMessage({ id: "raccolte.zona_di_competenza" })}
                     :&nbsp;
                     {zone.toUpperCase()}
                  </Typography.Title>
               ) : null
            }
            className="gx-mt-3"
            size="middle"
            pagination={false}
            dataSource={results}
            columns={columns}
            rowKey={(record) => `${record.year}-${record.date}`}
         />
      </>
   );
};

export default RaccolteTable;
