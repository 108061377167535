import {
   useApolloClient,
   useLazyQuery,
   useMutation,
   useQuery,
} from "@apollo/client";
import {
   Button,
   Input,
   Breadcrumb,
   Card,
   Row,
   Col,
   Form,
   message,
   PageHeader,
   Modal,
   Select,
   Switch,
   TreeSelect,
   Divider,
   DatePicker,
   Alert,
} from "antd";
import React, { useState } from "react";
import {
   GQL_PAGINATED_PRODUCTS,
   GQL_PRODUCT,
   GQL_PRODUCT_PRINT_LABEL,
} from "../../../../apollo/query/reuse-board";
import {
   GQL_PRODUCT_DELETE,
   GQL_PRODUCT_UPDATE,
} from "../../../../apollo/mutation/reuse-board";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router";
import IntlMessages from "../../../../util/IntlMessages";
import CircularProgress from "../../../../components/CircularProgress/index";
import {
   defaultMutationCallback,
   defaultCatchException,
} from "../../../../apollo/callbacks";
import BadResponseErrorAlert from "../../../../components/BadResponseErrorAlert";
import { getAdminPath } from "util/router";
import { AssetContextType } from "../../../../constants/Enums";
import { useS3Upload } from "../../../../hooks/useS3Upload";
import BadResponse from "../../../../containers/BadResponse/index";
import { ReuseBoardBreadcrumbs } from "./common";
import { treeViewAdapter } from "../../../../util/antd";
import { DeleteOutlined, PrinterOutlined } from "@ant-design/icons";
import NotFound from "../../../../containers/NotFound";
import ProductImagesUpload from "./images";
import moment from "moment";
import { DATE_FORMAT } from "../../../../constants/App";
import ReservationsTable from "./reservations-table";
import WarehouseSelect from "./components/warehouse-select";
import { isArray } from "lodash";

const toRefetchQueries = [
   {
      query: GQL_PAGINATED_PRODUCTS,
      variables: {
         pagination: {
            page: 1,
         },
      },
   },
];

const ProductUpdateForm = ({
   product,
   categoriesTree,
   productMetadata,
   assets = [],
   workspaces = [],
   intl,
}) => {
   const history = useHistory();

   const client = useApolloClient();

   const [loadingPrinterQuery, setLoadingPrinterQuery] = useState(false);

   const [uploadAssets] = useS3Upload(AssetContextType.PRODUCT_ATTACHMENTS);

   const closeHandler = () => {
      history.push(getAdminPath("reuse-board/products"));
   };

   const [loading, setLoading] = useState(false);

   const [updateProduct, { loading: updateLoading }] = useMutation(
      GQL_PRODUCT_UPDATE,
      {
         refetchQueries: toRefetchQueries,
         fetchPolicy: "no-cache",
         awaitRefetchQueries: true,
      }
   );

   const [deleteProduct, { loading: deleteLoading }] = useMutation(
      GQL_PRODUCT_DELETE,
      {
         refetchQueries: toRefetchQueries,
         fetchPolicy: "no-cache",
         awaitRefetchQueries: true,
      }
   );

   const handleOk = (values) => {
      setLoading(true);

      //Upload degli assets se ci sono
      if (values.assets_upload) {
         uploadAssets(values.assets_upload)
            .then((data) => {
               console.log("uploadAssets OK", data);
               updateProductMutation(product.id, values, data);
            })
            .catch((error) => {
               console.log("uploadAssets ERROR", error);
               defaultCatchException(error, intl);
               setLoading(false);
            });
      } else {
         updateProductMutation(product.id, values);
      }
   };

   const updateProductMutation = (productId, values, dataUpload = []) => {
      let params = {
         id: productId,
         is_active: values.is_active,
         is_featured: values.is_featured,
         consumable: values.consumable,
         name: values.name,
         description: values.description,
         category_id: values.category_id || null,
         condition: values.condition,
         priority: values.priority || 1,
         tags: values.tags || [],
         delivery_time: parseInt(values.delivery_time),
         warehouse_reference_sector: values.warehouse_reference_sector
            ? String(values.warehouse_reference_sector)
            : null,
         warehouse_reference_rack: values.warehouse_reference_rack
            ? String(values.warehouse_reference_rack)
            : null,
         warehouse_date: values.warehouse_date
            ? values.warehouse_date.format("YYYY-MM-DD")
            : null,
         note: values.note || "",
         meta_data: {
            title: values.meta_title,
            description: values.meta_description,
            keywords: values.meta_keywords,
         },
      };

      updateProduct({ variables: { input: params } })
         .then((data) => {
            defaultMutationCallback(data, () => {
               setLoading(false);
               closeHandler();
            });
         })
         .catch((e) => {
            defaultCatchException(e, intl);
            setLoading(false);
         });
   };

   const handleError = (error) => {
      console.log("Error", error);
   };

   return (
      <>
         <Row>
            <Col md={24}>
               <ReuseBoardBreadcrumbs>
                  <Breadcrumb.Item href="#" onClick={() => closeHandler()}>
                     <IntlMessages id="sidebar.products" />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item href="#">
                     <IntlMessages id="products.update" />
                  </Breadcrumb.Item>
               </ReuseBoardBreadcrumbs>
            </Col>
         </Row>
         <Form
            onFinish={handleOk}
            onFinishFailed={handleError}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
               ...product,
               priority: product.priority_int,
               warehouse_reference_sector: product.warehouse_reference?.sector ?? "",
               warehouse_reference_rack: product.warehouse_reference?.rack ?? "",
               warehouse_date: product.warehouse_date
                  ? moment(product.warehouse_date)
                  : null,
               meta_title: product.meta_data?.title || "",
               meta_description: product.meta_data?.description || "",
               meta_keywords: product.meta_data?.keywords || [],
               consumable: product.consumable || false,
            }}
            autoComplete="off"
         >
            <Row>
               <Col xs={24}>
                  <PageHeader
                     onBack={() => history.goBack()}
                     title={
                        <span>
                           <IntlMessages id="products.update" /> #{product.code}
                        </span>
                     }
                     extra={[
                        <Button key="back" onClick={() => closeHandler()}>
                           <IntlMessages id="common.cancel" />
                        </Button>,
                        <Button
                           key="submit"
                           type="primary"
                           className="gx-bg-success gx-border-0"
                           loading={loadingPrinterQuery}
                           onClick={() => {
                              setLoadingPrinterQuery(true);
                              client
                                 .query({
                                    query: GQL_PRODUCT_PRINT_LABEL,
                                    variables: {
                                       id: product.id,
                                    },
                                    fetchPolicy: "no-cache",
                                 })

                                 .then((response) => {
                                    setLoadingPrinterQuery(false);
                                    defaultMutationCallback(response, () => {
                                       message.success(
                                          intl.formatMessage({
                                             id: "common.default_mutation_success",
                                          })
                                       );
                                    });
                                 })
                                 .catch((error) => {
                                    setLoadingPrinterQuery(false);
                                    message.error(error.message);
                                 });
                           }}
                        >
                           <PrinterOutlined />
                           {intl.formatMessage({ id: "common.print_label" })}
                        </Button>,
                        <Button
                           key="delete"
                           type="danger"
                           onClick={() => {
                              Modal.confirm({
                                 title: intl.formatMessage({ id: "common.warning" }),
                                 content: intl.formatMessage({
                                    id: "common.default_mutation_ask_delete",
                                 }),
                                 okText: intl.formatMessage({ id: "common.delete" }),
                                 cancelText: intl.formatMessage({ id: "common.no" }),
                                 okType: "danger",
                                 okButtonProps: {
                                    loading: deleteLoading,
                                 },
                                 onOk: () => {
                                    deleteProduct({ variables: { id: product.id } })
                                       .then((data) => {
                                          if (
                                             data?.errors &&
                                             isArray(data?.errors) &&
                                             data?.errors.length > 0
                                          ) {
                                             message.error(
                                                intl.formatMessage({
                                                   id: data?.errors[0].message,
                                                })
                                             );
                                          } else {
                                             defaultMutationCallback(data, () => {
                                                message.success(
                                                   intl.formatMessage({
                                                      id: "products.delete_success",
                                                   })
                                                );
                                             });
                                             closeHandler();
                                          }
                                       })
                                       .catch((e) => {
                                          defaultCatchException(e, intl);
                                       });
                                 },
                              });
                           }}
                           icon={<DeleteOutlined className="gx-pr-1" />}
                        >
                           <IntlMessages id="common.delete" />
                        </Button>,
                        <Button
                           htmlType="submit"
                           key="update"
                           type="primary"
                           loading={loading || updateLoading}
                        >
                           {intl
                              .formatMessage({ id: "common.update" })
                              .toUpperCase()}
                        </Button>,
                     ]}
                  />
               </Col>
               
               <Col xs={24}>
                  <BadResponseErrorAlert />
               </Col>

               {product.is_active && !product.category_id && (
                  <Col xs={24}>
                     <Alert
                        showIcon
                        message={<IntlMessages id="categories.missing_p1" />}
                        type="error"
                     />
                  </Col>
               )}

               <Col xs={24}>
                  <ReservationsTable
                     reservations={product.reservations}
                     className="gx-mb-4"
                  />
               </Col>
               <Col xs={24} sm={24} md={24} lg={12}>
                  <Card
                     className="gx-card"
                     title={<IntlMessages id="common.public_informations" />}
                  >
                     <Form.Item
                        label={intl.formatMessage({
                           id: "common.is_active",
                        })}
                        valuePropName="checked"
                        name="is_active"
                     >
                        <Switch />
                     </Form.Item>

                     <Form.Item
                        label={intl.formatMessage({
                           id: "common.is_featured",
                        })}
                        valuePropName="checked"
                        name="is_featured"
                     >
                        <Switch />
                     </Form.Item>

                     <Form.Item
                        label={intl.formatMessage({
                           id: "common.consumable",
                        })}
                        tooltip={<IntlMessages id="products.consumable_tip" />}
                        valuePropName="checked"
                        name="consumable"
                     >
                        <Switch />
                     </Form.Item>

                     <Form.Item
                        rules={[
                           {
                              required: true,
                              message: intl.formatMessage({
                                 id: "validation.required",
                              }),
                           },
                        ]}
                        label={<IntlMessages id="products.condition" />}
                        name="condition"
                     >
                        <Select>
                           {productMetadata.conditions.map((condition) => (
                              <Select.Option key={condition.id} value={condition.id}>
                                 <IntlMessages
                                    id={`products.condition.${condition.name?.toLowerCase()}`}
                                 />
                              </Select.Option>
                           ))}
                        </Select>
                     </Form.Item>

                     <Form.Item
                        name="priority"
                        label={<IntlMessages id="common.priority" />}
                     >
                        <Select>
                           {productMetadata.priorities.map((priority) => (
                              <Select.Option
                                 key={priority.id}
                                 value={parseInt(priority.id)}
                              >
                                 <IntlMessages
                                    id={`products.priority.${priority.name?.toLowerCase()}`}
                                 />
                              </Select.Option>
                           ))}
                        </Select>
                     </Form.Item>

                     <Form.Item
                        label={intl.formatMessage({
                           id: "common.name",
                        })}
                        name="name"
                        required
                        rules={[
                           {
                              required: true,
                              message: intl.formatMessage({
                                 id: "validation.required",
                              }),
                           },
                        ]}
                     >
                        <Input
                           placeholder={intl.formatMessage({
                              id: "common.name",
                           })}
                           maxLength={127}
                           autoFocus
                        />
                     </Form.Item>

                     <Form.Item
                        name="category_id"
                        label={<IntlMessages id="common.category" />}
                        tooltip={<IntlMessages id="products.category_tip" />}
                     >
                        <TreeSelect
                           showSearch
                           style={{
                              width: "100%",
                           }}
                           dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                           }}
                           allowClear
                           treeDefaultExpandAll
                           treeData={categoriesTree}
                           treeLine
                           treeNodeFilterProp="title"
                        />
                     </Form.Item>

                     <Form.Item
                        name="tags"
                        label={<IntlMessages id="common.tags" />}
                     >
                        <Select mode="tags"></Select>
                     </Form.Item>

                     <Form.Item
                        label={intl.formatMessage({
                           id: "common.description",
                        })}
                        name="description"
                     >
                        <Input.TextArea
                           placeholder={intl.formatMessage({
                              id: "common.description",
                           })}
                           maxLength={500}
                           rows={8}
                           showCount
                           autoComplete="off"
                        />
                     </Form.Item>
                  </Card>

                  <Card
                     className="gx-card"
                     title={<IntlMessages id="common.meta_data" />}
                     hidden={true}
                  >
                     <Form.Item
                        label={intl.formatMessage({
                           id: "common.meta_title",
                        })}
                        name="meta_title"
                        tooltip={<IntlMessages id="products.meta_title_tip" />}
                     >
                        <Input
                           placeholder={intl.formatMessage({
                              id: "common.meta_title",
                           })}
                           maxLength={127}
                        />
                     </Form.Item>

                     <Form.Item
                        label={intl.formatMessage({
                           id: "common.meta_description",
                        })}
                        name="meta_description"
                        tooltip={<IntlMessages id="products.meta_description_tip" />}
                     >
                        <Input.TextArea
                           placeholder={intl.formatMessage({
                              id: "common.meta_description",
                           })}
                           maxLength={255}
                           rows={3}
                           showCount
                           autoComplete="off"
                        />
                     </Form.Item>

                     <Form.Item
                        name="meta_keywords"
                        label={<IntlMessages id="common.meta_keywords" />}
                     >
                        <Select mode="tags"></Select>
                     </Form.Item>
                  </Card>
               </Col>
               <Col xs={24} sm={24} md={24} lg={12}>
                  <Card
                     className="gx-card"
                     title={<IntlMessages id="common.private_informations" />}
                  >
                     <Divider className="gx-mt-0">
                        <IntlMessages id="common.warehouse" />
                     </Divider>

                     <Form.Item
                        name="warehouse_date"
                        label={<IntlMessages id="products.warehouse_date" />}
                     >
                        <DatePicker format={DATE_FORMAT} />
                     </Form.Item>

                     <Form.Item
                        name="warehouse_reference_sector"
                        label={
                           <IntlMessages id="products.warehouse_reference_sector" />
                        }
                     >
                        <WarehouseSelect type="sector" style={{ maxWidth: 156 }} />
                     </Form.Item>

                     <Form.Item
                        name="warehouse_reference_rack"
                        label={
                           <IntlMessages id="products.warehouse_reference_rack" />
                        }
                     >
                        <WarehouseSelect type="rack" style={{ maxWidth: 156 }} />
                     </Form.Item>

                     <Form.Item
                        name="delivery_time"
                        label={<IntlMessages id="products.delivery_time" />}
                        tooltip={<IntlMessages id="products.delivery_time_p1" />}
                        required
                        rules={[
                           {
                              required: true,
                              message: intl.formatMessage({
                                 id: "validation.required",
                              }),
                           },
                        ]}
                     >
                        <Input
                           type="number"
                           min={0}
                           max={999}
                           step={1}
                           addonAfter={<IntlMessages id="common.min" />}
                           style={{ maxWidth: 120 }}
                        />
                     </Form.Item>

                     <Divider>
                        <IntlMessages id="common.other" />
                     </Divider>

                     <Form.Item
                        label={intl.formatMessage({
                           id: "common.note",
                        })}
                        name="note"
                     >
                        <Input.TextArea
                           placeholder={intl.formatMessage({
                              id: "common.note",
                           })}
                           maxLength={500}
                           rows={8}
                           showCount
                           autoComplete="off"
                        />
                     </Form.Item>
                  </Card>

                  <ProductImagesUpload
                     product={product}
                     cardClassName="gx-card"
                     resizeTo={{ width: 1400, height: 1200 }}
                  />
               </Col>
            </Row>
         </Form>
      </>
   );
};

const ProductUpdate = ({ match, intl }) => {
   const { id } = match.params;

   const { data, loading, error } = useQuery(GQL_PRODUCT, {
      variables: { id: id, context: AssetContextType.PRODUCT_ATTACHMENTS },
      fetchPolicy: "no-cache",
      onError: (error) => {
         message.error(intl.formatMessage({ id: "common.default_query_error" }));
      },
   });

   if (loading) {
      return <CircularProgress />;
   }

   if (error) {
      return (
         <BadResponse title={intl.formatMessage({ id: "500.something_wrong" })} />
      );
   }

   if (!data || !data.product) {
      return <NotFound />;
   }

   if (!error && data && data.product) {
      const { product, productCategories, productMetadata } = data;
      return (
         <ProductUpdateForm
            product={product}
            categoriesTree={treeViewAdapter(productCategories)}
            productMetadata={productMetadata}
            //assets={assets}
            //workspaces={workspaces}
            intl={intl}
         />
      );
   }
};

export default injectIntl(ProductUpdate);
