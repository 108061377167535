import React, { useState, useEffect } from "react";
import { Button, Input, Form, Result, message } from "antd";
import { Link, useHistory } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../../components/CircularProgress";

import { useMutation, useReactiveVar } from "@apollo/client";
import { globalSettingsVar } from "../../../apollo/local/cache";
import { GQL_ASK_RESET_PASSWORD } from "../../../apollo/mutation/user";
import { injectIntl } from "react-intl";
import { getFrontendPath } from "../../../util/router";
import { localStorageClass } from "../../../apollo/local/storage";
import FrontendHeader from "../main/header";
import FrontendFooter from "../main/footer";
import { first, isArray } from "lodash";

function showGqlError(errors, intl) {
   if (errors && isArray(errors) && errors.length > 0) {
      const error = first(errors);
      message.error(
         intl.formatMessage({
            id: error.message,
         })
      );
   } else {
      message.error(
         intl.formatMessage({
            id: "login.forgot_password_error_message",
         })
      );
   }
}

const FrontendForgetPassword = ({ intl }) => {
   const commonProps = useReactiveVar(globalSettingsVar);
   const [success, setSuccess] = useState(false);
   const [forgetPassword, { loading: loadingMutation }] = useMutation(
      GQL_ASK_RESET_PASSWORD
   );
   const history = useHistory();

   //
   useEffect(() => {
      if (localStorageClass.isLogged()) {
         history.push(getFrontendPath("prenotazione"));
      }
   });
   //

   const onFinish = (values) => {
      const params = {
         email: values.email.toLowerCase(),
         fiscal_code: values.fiscal_code.toUpperCase(),
         is_front_end: true,
      };
      forgetPassword({ variables: params })
         .then((response) => {
            if (response && response?.data?.askResetPassword) {
               setSuccess(true);
            } else {
               showGqlError(response?.errors, intl);
            }
         })
         .catch((e) => {
            message.error(
               intl.formatMessage({ id: "login.forgot_password_error_message" })
            );
         });
   };

   if (!success) {
      return (
         <>
            <FrontendHeader title="Password dimenticata" />

            <div className="frontend-container">
               <div className="gx-app-login-container gx-app-login-container-frontend">
                  <h2 className="gx-mb-5">
                     <span
                        dangerouslySetInnerHTML={{
                           __html: intl.formatMessage({
                              id: "frontend.forget_password_t1",
                           }),
                        }}
                     ></span>
                  </h2>
                  <p className="white gx-mb-4">
                     <IntlMessages id="frontend.forget_password_p1" />
                  </p>
                  <div className="gx-app-login-form-container">
                     <div className="gx-app-login-content">
                        <Form
                           initialValues={{ remember: true }}
                           name="basic"
                           onFinish={onFinish}
                           autoComplete="off"
                           className="gx-signin-form gx-form-row0"
                        >
                           <div className="gx-mb-2">
                              <label className="white">
                                 <span
                                    dangerouslySetInnerHTML={{
                                       __html: intl.formatMessage({
                                          id: "frontend.forget_password_p2",
                                       }),
                                    }}
                                 ></span>
                              </label>
                           </div>
                           <Form.Item
                              className="frontend-input"
                              rules={[
                                 {
                                    required: true,
                                    message: intl.formatMessage({
                                       id: "services.new_service_required_field",
                                    }),
                                 },
                              ]}
                              name="email"
                           >
                              <Input
                                 autoComplete="new-password"
                                 autoFocus
                                 placeholder={"es. mario.rossi@gmail.com"}
                                 type="email"
                              />
                           </Form.Item>

                           <div className="gx-mb-2">
                              <label className="white">
                                 <span
                                    dangerouslySetInnerHTML={{
                                       __html: intl.formatMessage({
                                          id: "frontend.forget_password_p3",
                                       }),
                                    }}
                                 ></span>
                              </label>
                           </div>
                           <Form.Item
                              className="frontend-input"
                              rules={[
                                 {
                                    required: true,
                                    message: intl.formatMessage({
                                       id: "services.new_service_required_field",
                                    }),
                                 },
                              ]}
                              name="fiscal_code"
                           >
                              <Input
                                 autoComplete="new-password"
                                 placeholder={"es. XXXX00X00X000X"}
                                 style={{ textTransform: "uppercase" }}
                              />
                           </Form.Item>

                           <Form.Item>
                              <Button
                                 className="gx-mb-0 frontend-btn-lg w-100"
                                 htmlType="submit"
                                 name="buttonSubmit"
                                 loading={loadingMutation}
                              >
                                 <IntlMessages id="registration.reset_password" />
                              </Button>
                           </Form.Item>
                           <p className="white">
                              <IntlMessages id="frontend.forget_password_p4" />
                           </p>
                        </Form>
                     </div>

                     {commonProps.loading ? (
                        <div className="gx-loader-view">
                           <CircularProgress />
                        </div>
                     ) : null}
                  </div>

                  <div className="gx-text-center">
                     <Link to={getFrontendPath()}>
                        <Button className="gx-mt-5 frontend-btn-lg btn-grey">
                           <IntlMessages id="frontend.forget_password_back_to_login" />
                        </Button>
                     </Link>
                  </div>
               </div>

               <div className="gx-mt-5 gx-mb-5">&nbsp;</div>
            </div>
            <FrontendFooter />
         </>
      );
   } else {
      return (
         <>
            <FrontendHeader />
            <div class="frontend-container">
               <Result
                  key="1"
                  status="success"
                  title={
                     <div>
                        <IntlMessages id="login.forgot_password_success_title" />
                     </div>
                  }
                  subTitle={
                     <div>
                        <IntlMessages id="login.forgot_password_success_message" />
                     </div>
                  }
                  extra={[
                     <Link to={getFrontendPath("login")}>
                        <Button
                           key="login"
                           className="frontend-btn-lg w-auto btn-grey"
                        >
                           <IntlMessages id="frontend.login_label_access" />
                        </Button>
                     </Link>,
                  ]}
               />
            </div>
            <FrontendFooter />
         </>
      );
   }
};

export default injectIntl(FrontendForgetPassword);
