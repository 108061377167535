import itLang from "./entries/it_IT";
import "moment/locale/it";
import moment from "moment";

const AppLocale = {
   it: itLang,
};

moment.locale("it");

export default AppLocale;
