import { gql } from "@apollo/client";

export const GQL_FETCH_CALENDAR = gql`
   query getCalendar(
      $granularity: String!
      $date: String!
      $status: [ReservationStatus]
      $services: [String]
      $customers: [String]
      $workspace: String
   ) {
      workspace {
         id
         #timetables(granularity: $granularity, date: $date) {
         #   granularity
         #   availables {
         #      start
         #      end
         #   }
         #}
         openings(granularity: $granularity, date: $date) {            
            start: time_start
            end: time_end
         }
      }
      reservations(
         filter: {
            status: $status
            services: $services
            customers: $customers
            date: { value: $date, granularity: $granularity }
            workspace: $workspace
         }
      ) {
         id
         note
         status
         code
         time {
            date
            time_start
            time_end
            duration
         }
         workspace {
            id
            name
         }
         customer {
            id
            first_name
            last_name
            full_name
            company_name
         }
         employee {
            id
            first_name
            last_name
            full_name
         }
         payment {
            should_pay
            status
            price
            paid_at
         }
         services {
            id
            name
         }
         is_overbooked
         created_at
      }
   }
`;

export const GQL_RESERVATION_AVAILABILITY = gql`
   query reservationAvailability(
      $month: String!
      $services_id: [String!]
      $workspace_id: String
      $current_reservation_id: String
      $force_service_duration: Int
      $is_admin: Boolean
   ) {
      reservationAvailability(
         date: $month
         services_id: $services_id
         workspace_id: $workspace_id
         current_reservation_id: $current_reservation_id
         force_service_duration: $force_service_duration
         is_admin: $is_admin
      ) {
         granularity
         availables {
            start: time_start
            end: time_end
         }
      }
   }
`;

export const GQL_RESERVATION_AVAILABILITY_CALENDAR = gql`

   query reservationAvailabilityCalendar(
      $workspace_id: String!
      $granularity: String!
      $month: String!
      $current_reservation_id: String
      $force_service_duration: Int
      $services_id: [String!]
   ) {
      workspace(id: $workspace_id) {
         id
         openings(granularity: $granularity, date: $month, is_reservable: true) {
            date
            time_start
            time_end
         }
      }
      reservationAvailability(
         date: $month
         services_id: $services_id
         workspace_id: $workspace_id
         current_reservation_id: $current_reservation_id
         force_service_duration: $force_service_duration
         is_admin: true
      ) {
         granularity
         availables {
            time_start
            time_end
         }
      }
   }
`;

export const GQL_RESERVATION_SLOT_AVAILABILITY = gql`
   query reservationSlotsAvailability(
      $date: String!
      $services_id: [String!]
      $workspace_id: String
      $current_reservation_id: String
      $force_service_duration: Int
      #$is_admin: Boolean
   ) {
      reservationSlotsAvailability(
         date: $date
         services_id: $services_id
         workspace_id: $workspace_id
         current_reservation_id: $current_reservation_id
         force_service_duration: $force_service_duration
         #is_admin: $is_admin
      ) {
         granularity
         availables {
            start
            end            
            available
            reservations
            overbooking_reservations
         }
      }
   }
`;

export const GQL_PAGINATED_RESERVATIONS = gql`
   query paginatedCustomerReservations(
      $filter: FilterCustomerReservationInput
      $pagination: PaginationInput
   ) {
      paginatedCustomerReservations(filter: $filter, pagination: $pagination) {
         items {
            id
            status
            code
            time {
               date
               time_start
               time_end
               duration
            }
            created_at
            services {
               id
               name
            }
            workspace {
               id
               name
            }
            note
         }
         pagination {
            page
            pages
            current_items
            total_items
            size
         }
      }
   }
`;

export const GQL_RESERVATION_INIT = gql`
   query reservationInit($reservation_id: String!) {
      me {
         id
         first_name
         last_name
         full_name
         username
         current_organization {
            id
            name
         }
         customer {
            id
            full_name
         }
      }
      services {
         id
         name
         description
         duration
         workspaces_id
         payment {
            price
         }
      }
      workspaces {
         id
         name
         description
         location {
            cap
            city
            address
            comune
            lat
            lon
            country
            province
         }
      }
      reservation(id: $reservation_id) {
         id
         status
         code
         time {
            date
            time_start
            time_end
            duration
         }
         note
         workspace {
            id
            name
         }
         services {
            id
            name
         }

         payment {
            should_pay
            price
            stripe_intent_id
         }
      }
   }
`;

export const GQL_RESERVATION = gql`
   query reservation($id: String!) {
      reservation(id: $id) {
         id
         status
         code
         time {
            date
            time_start
            time_end
            duration
         }
         note
         feedback {
            created_at
            rating_1
            rating_2
            rating_3
         }
         workspace {
            id
            name
            description
            location {
               cap
               city
               address
               comune
               lat
               lon
               country
               province
            }
         }
         services {
            id
            name
            description
            documentation {
               blocks {
                  key
                  type
                  text
                  depth
               }
            }
            assets {
               id
               file_name
               url
            }
            payment {
               price
            }
         }
         customer {
            id
            full_name
         }
         payment {
            should_pay
            price
            stripe_intent_id
            method
            paid_at
         }
         organization {
            id
            can_send_sms
         }
         has_sent_sms
      }
      me {
         full_name
         auth {
            phone_verified
         }
      }
   }
`;

export const GQL_RESERVATION_ADMIN = gql`
   query reservation($id: String!) {
      reservation(id: $id) {
         id
         status
         code
         time {
            date
            time_start
            time_end
            duration
         }
         note
         workspace {
            id
            name
            description
            location {
               cap
               city
               address
               comune
               lat
               lon
               country
               province
            }
         }
         feedback {
            created_at
            rating_1
            rating_2
            rating_3
         }
         services {
            id
            name
            duration
            payment {
               price
            }
         }
         customer {
            id
            full_name
            company_name
            code
            email
            user {
               id
               email
            }
         }
         employee {
            id
            full_name
         }
         payment {
            should_pay
            price
            stripe_intent_id
            method
            paid_at
            status
         }
         created_at
         closed_at
         cancellation_reason
         employee_note
         comune_ref {
            istat_code
            name
         }
         made_by_frontend
         user {
            name
         }
         is_overbooked
      }
   }
`;

export const GQL_EXPORT_RESERVATIONS = gql`
   query exportReservations($filter: FilterReservationInput) {
      exportReservations(filter: $filter) {
         content
         filename
      }
   }
`;

export const GQL_EXPORT_RESERVATIONS_REPORT = gql`
   query exportReservationsReport($filter: FilterReservationInput) {
      exportReservationsReport(filter: $filter) {
         content
         filename
      }
   }
`;

export const GQL_RESERVATION_DETAIL_FRONTEND = gql`
   query reservationDetailFrontend($args: FrontendReservationInput!) {
      reservationDetailFrontend(args: $args) {
         id
         token_delete
         time {
            date
            time_start
            time_end
            duration
         }
         note
         workspace {
            id
            name
            location {
               address
               cap
               comune
               province
            }
         }
         services {
            id
            name
         }
      }
   }
`;

export const GQL_RESERVATION_COUNT_AGGREGATION = gql`
   query countReservations($filters: FilterReservationInput) {
      countReservations(filters: $filters) {
         items {
            reservations
            date
         }
         aggregate {
            aggregate_by
            total
         }
      }
      aggregateReservations(aggregate_by: "service", filters: $filters) {
         items {
            reservations
            percent
            group_id
            group_name
         }
         aggregate {
            aggregate_by
            total
         }
      }
   }
`;

export const GQL_RESERVATION_COUNT_AGGREGATION_BY_WORKSPACE = gql`
   query countWorkspacedReservations($filters: FilterReservationInput) {
      workspaces {
         id
         name
      }
      countWorkspacedReservations(filters: $filters) {
         items {
            reservations {
               value
               workspace_id
            }
            date
         }
         aggregate {
            aggregate_by
            total
         }
      }
      aggregateReservationsByService: aggregateReservations(
         aggregate_by: "service"
         filters: $filters
      ) {
         items {
            reservations
            percent
            group_id
            group_name
         }
         aggregate {
            aggregate_by
            total
         }
      }
      aggregateReservationsByWorkspace: aggregateReservations(
         aggregate_by: "workspace"
         filters: $filters
      ) {
         items {
            reservations
            percent
            group_id
            group_name
         }
         aggregate {
            aggregate_by
            total
         }
      }
   }
`;
