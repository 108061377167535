import React from "react";
import { Avatar } from 'antd'
import { FileImageOutlined } from "@ant-design/icons";


const getImage = (product) => {    

    if (product.cover) {
        return product.cover;
    } else if(product.assets && product.assets.length > 0) {
        return product.assets[0];
    } else {
        return null;
    }
}

const ReservationProductAvatar = ({ product, size = 48 }) => {

    if (!product) {
        return null;
    }

    const image = getImage(product);

    if (image) {
        return (
            <Avatar
                shape="square"
                size={size}
                src={image.url}
            />
        )
    } else {
        return (
            <Avatar
                shape="square"
                size={size}
                icon={<FileImageOutlined />}
            />
        )
    }    
}

export default ReservationProductAvatar;

