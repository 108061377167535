import { gql } from "@apollo/client";

export const GQL_REGISTRATION = gql`
   mutation createUser($user: CreateUserInput!) {
      createUser(user: $user)
   }
`;

export const GQL_REGISTRATION_FRONTEND = gql`
   mutation createCustomerFrontend($customer: CreateRegisteredCustomerInput!) {
      createCustomerFrontend(customer: $customer)
   }
`;

export const GQL_LOGIN = gql`
   mutation Login(
      $username: String!
      $password: String!
      $frontend: Boolean! = false
   ) {
      login(username: $username, password: $password, frontend: $frontend) {
         token
         refresh_token
         expires_at
      }
   }
`;

//String format used in fetch request (not gql!)
export const GQL_REFRESH_TOKEN = `
  mutation refreshToken($token: String!){
    refreshToken(token: $token){
      token
      refresh_token
      expires_at
    }
  }
`;

export const GQL_ASK_RESET_PASSWORD = gql`
   mutation askResetPassword(
      $is_front_end: Boolean
      $email: String!
      $fiscal_code: String!
   ) {
      askResetPassword(
         is_front_end: $is_front_end
         email: $email
         fiscal_code: $fiscal_code
      )
   }
`;

export const GQL_RESET_PASSWORD = gql`
   mutation resetPassword($password: String!, $token: String!) {
      resetPassword(password: $password, token: $token)
   }
`;

export const GQL_VERIFY_USER = gql`
   mutation verifyUser($token: String!) {
      verifyUser(token: $token) {
         id
      }
   }
`;

export const GQL_VERIFY_USER_PHONE_NUMBER = gql`
   mutation verifyUserPhoneNumber($token: String!) {
      verifyUserPhoneNumber(token: $token)
   }
`;

export const GQL_USER_UPDATE = gql`
   mutation updateUser($user: UpdateUserInput!) {
      updateUser(user: $user)
   }
`;

export const GQL_ASK_VERIFICATION_EMAIL = gql`
   mutation askVerificationEmail($email: String!, $is_front_end: Boolean) {
      askVerificationEmail(email: $email, is_front_end: $is_front_end)
   }
`;

export const GQL_ASK_VERIFICATION_PHONE_NUMBER = gql`
   mutation askVerificationPhoneNumber {
      askVerificationPhoneNumber
   }
`;

export const GQL_USER_DELETE = gql`
   mutation deleteUser($id: String!) {
      deleteUser(id: $id)
   }
`;

export const GQL_USER_ADD_NEW = gql`
   mutation createUser($user: CreateUserInput!) {
      createUser(user: $user)
   }
`;

export const GQL_ADMIN_USER_ADD_NEW = gql`
   mutation adminCreateUser($user: AdminCreateUserInput!) {
      adminCreateUser(user: $user)
   }
`;

export const GQL_ADMIN_USER_UPDATE = gql`
   mutation adminUpdateUser($id: String!, $user: AdminUpdateUserInput!) {
      adminUpdateUser(id: $id, user: $user)
   }
`;

export const GQL_ADMIN_USER_LOGOUT = gql`
   mutation logout {
      logout
   }
`;
