import React, { useState } from "react";
import {
   Row,
   Col,
   PageHeader,
   Button,
   Table,
   message,
   Modal,
   Select,
   Empty,
   Popover,
   Grid,
} from "antd";
import { FormattedDate, injectIntl } from "react-intl";
import BadResponseErrorAlert from "../../../components/BadResponseErrorAlert";
import IntlMessages from "../../../util/IntlMessages";
import { getFrontendPath } from "../../../util/router";
import {
   ExclamationCircleOutlined,
   InfoCircleOutlined,
   FilterOutlined,
   ArrowLeftOutlined,
} from "@ant-design/icons";
import { useHistory, useRouteMatch } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import { GQL_PAGINATED_RESERVATIONS } from "../../../apollo/query/reservation";
import { GQL_DELETE_RESERVATION } from "../../../apollo/mutation/reservation";
import { get } from "lodash";
import { paginationOptions } from "../../../constants/PaginationOptions";
import { usePersistedState } from "../../../hooks/usePersistedState";
import {
   defaultCatchException,
   defaultMutationCallback,
} from "../../../apollo/callbacks";
import moment from "moment";
import { localStorageClass } from "../../../apollo/local/storage";
import { RESERVATION } from "../../../constants/App";
import { canResumeReservation } from "./step/common";

const { useBreakpoint } = Grid;

const FrontendReservations = ({ intl }) => {
   const history = useHistory();
   const match = useRouteMatch();
   const currentReservation = localStorageClass.getReservation();

   const minDateToIntervalString = (min_date) => {
      const today = moment().startOf("week");

      if (min_date === today.startOf("month").format("YYYY-MM-DD")) {
         return "month";
      } else if (
         min_date ===
         today.startOf("month").subtract(3, "months").format("YYYY-MM-DD")
      ) {
         return "3months";
      }

      return "week";
   };

   const [persistentStore, _] = usePersistedState(
      match,
      "tableFilterReservations",
      null
   );
   let defaultFilterInterval = "week";
   if (persistentStore) {
      defaultFilterInterval = minDateToIntervalString(
         persistentStore.filter.min_date
      );
   }

   const [selectedFilterInterval, setSelectedFilterInterval] =
      useState(defaultFilterInterval);

   const comboFilterIntervalOptions = [
      {
         value: "week",
         text: intl.formatMessage({ id: "common.last_week" }),
      },
      {
         value: "month",
         text: intl.formatMessage({ id: "common.last_month" }),
      },
      {
         value: "3months",
         text: intl.formatMessage({ id: "common.last_3_months" }),
      },
   ];

   const intervalStringToMinDate = (interval) => {
      const today = moment().startOf("week");

      if (interval === "month") {
         return today.startOf("month").format("YYYY-MM-DD");
      } else if (interval === "3months") {
         return today.startOf("month").subtract(3, "months").format("YYYY-MM-DD");
      }

      return today.format("YYYY-MM-DD");
   };

   const [tableSearchOptions, setTableSearchOptions] = usePersistedState(
      match,
      "tableFilterReservations",
      {
         pagination: {
            limit: parseInt(paginationOptions.pageSizeOptions[0]),
            page: 1,
         },
         filter: {
            min_date: intervalStringToMinDate(selectedFilterInterval),
         },
      }
   );

   const [deleteReservation] = useMutation(GQL_DELETE_RESERVATION, {
      refetchQueries: [
         {
            query: GQL_PAGINATED_RESERVATIONS,
            variables: tableSearchOptions,
         },
      ],
      awaitRefetchQueries: true,
   });

   const setFilter = (value) => {
      setSelectedFilterInterval(value);

      setTableSearchOptions({
         ...tableSearchOptions,
         filter: {
            min_date:
               intervalStringToMinDate(value) ??
               intervalStringToMinDate(selectedFilterInterval),
         },
         pagination: {
            limit: tableSearchOptions.pagination.limit,
            page: 1,
         },
      });
   };

   const confirmDeleteReservation = (record) => {
      Modal.confirm({
         title: intl.formatMessage({ id: "common.warning" }),
         icon: <ExclamationCircleOutlined />,

         content: intl.formatMessage({
            id: "reservations.reservation_delete_confirm_message",
         }),
         okText: intl.formatMessage({ id: "reservations.cancel_reservation" }),
         cancelText: intl.formatMessage({ id: "common.no" }),
         okType: "danger",
         onOk() {
            deleteReservation({ variables: { id: record.id } })
               .then((data) => {

                  if(!data.deleteReservation){
                     message.error(intl.formatMessage({ id: "reservations.reservation_delete_error" }));
                     return;
                  }

                  defaultMutationCallback(data, () => {
                     const currentReservationId =
                        localStorageClass.getReservationId();
                     if (currentReservationId === record.id) {
                        localStorageClass.resetReservation();
                     }
                  })

               })
               .catch((e) => defaultCatchException(e, intl));
         },
      });
   };

   const askResumeReservation = (currentReservation) => {
      Modal.confirm({
         title: intl.formatMessage({ id: "common.tip" }),
         icon: <InfoCircleOutlined />,
         content: intl.formatMessage({
            id: "reservations.reservation_resume_message",
         }),
         okText: intl.formatMessage({ id: "reservations.resume" }),
         cancelText: intl.formatMessage({
            id: "reservations.new_reservation_title_insert",
         }),
         width: 516,
         okType: "primary",
         okButtonProps: { className: "gx-mt-3 gx-mt-sm-0 gx-ml-0 gx-ml-sm-2" },
         onOk() {
            history.push(getFrontendPath("prenotazione"));
         },
         onCancel() {
            deleteReservation({ variables: { id: currentReservation.id } })
               .then((data) => {
                  localStorageClass.resetReservation();
                  history.push(getFrontendPath("prenotazione"));
               })
               .catch((e) => {
                  localStorageClass.resetReservation();
                  history.push(getFrontendPath("prenotazione"));
               });
         },
      });
   };

   //
   const columns = [
      {
         title: intl.formatMessage({ id: "common.datetime" }),
         dataIndex: "name",
         sorter: false,
         fixed: "left",
         responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
         render: (text, record) => {
            return (
               <>
                  <FormattedDate
                     value={record.time.date}
                     year="numeric"
                     month="2-digit"
                     day="2-digit"
                  />

                  {` ${intl.formatMessage({ id: "common.at_hour" })} ${record.time.time_start
                     }`}
               </>
            );
         },
      },
      {
         title: intl.formatMessage({ id: "common.status" }),
         dataIndex: "status",
         sorter: false,
         //responsive: ["xxl", "xl", "lg"],
         render: (value, record) => {
            return intl.formatMessage({ id: `reservations.status_${value}` });
         },
      },
      {
         title: intl.formatMessage({ id: "services.services" }),
         dataIndex: "description",
         sorter: false,
         responsive: ["xxl", "xl", "lg"],
         render: (text, record) => {
            let servicesName = [];
            record.services.map((s) => servicesName.push(s.name));
            return servicesName.join(", ");
         },
      },
      {
         title: intl.formatMessage({ id: "common.sede" }),
         dataIndex: "workspace.name",
         sorter: false,
         responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
         render: (text, record) => record.workspace.name,
      },
      {
         title: "",
         key: "operation",
         sorter: false,
         responsive: ["xxl", "xl", "lg", "md"],
         fixed: "right",
         align: "center",
         render: (text, record) => {
            if (
               [
                  RESERVATION.STATUS.DELETED,
                  RESERVATION.STATUS.PROCESSING,                  
               ].includes(record.status)
            ) {
               return null;
            }

            return (
               <Button
                  size="default"
                  className="gx-mb-0"
                  danger
                  onClick={(e) => {
                     e.stopPropagation();
                     confirmDeleteReservation(record);
                  }}
               >
                  <IntlMessages id="common.cancel" />
               </Button>
            );
         },
      },
   ];

   const fetchTableChange = (pagination, _, sorter) => {
      setTableSearchOptions({
         ...tableSearchOptions,
         pagination: {
            limit: pagination.pageSize,
            page: pagination.current,
         },
      });
   };

   const { data, loading } = useQuery(GQL_PAGINATED_RESERVATIONS, {
      variables: tableSearchOptions,
      onError: (error) => {
         message.error(intl.formatMessage({ id: "reservations.error_generic" }));
      },
   });

   const pagination = {
      ...paginationOptions,
      total: get(data, "paginatedCustomerReservations.pagination.total_items", 0),
      current: tableSearchOptions.pagination.page,
      pageSize: tableSearchOptions.pagination.limit,
   };

   const screens = useBreakpoint();

   const getFilterToolbar = () => {
      return (
         <Popover
            placement="bottomRight"
            content={
               <>
                  <p className="gx-d-block gx-mb-2 frontend-font">
                     <IntlMessages id="common.date" />
                  </p>
                  <Select
                     size="small"
                     defaultValue={selectedFilterInterval}
                     onChange={setFilter}
                  >
                     {comboFilterIntervalOptions.map((c, i) => (
                        <Select.Option
                           key={`combo-filter-${i}`}
                           value={c.value}
                           className="frontend-font"
                        >
                           {c.text}
                        </Select.Option>
                     ))}
                  </Select>
               </>
            }
            trigger="click"
         >
            <Button className="gx-mb-0">
               <IntlMessages id="common.filter" />
               &nbsp;
               <FilterOutlined />
            </Button>
         </Popover>
      );
   };

   const getExtraToolbar = (extraToolbar) => {
      const extraButtons = [
         <Button
            key="reserve-btn"
            className="btn-lightblue gx-mb-0"
            onClick={() => {
               //domandare di proseguire con la prenotazione in corso solo se la prenotazione è in determinati stati
               if (canResumeReservation(currentReservation)) {
                  askResumeReservation(currentReservation);
               } else {
                  history.push(getFrontendPath("prenotazione"));
               }
            }}
         >
            <IntlMessages id="app.reservations.new_reservation" />
         </Button>,
      ];

      if (extraToolbar) {
         return [getFilterToolbar(), ...extraButtons];
      }

      return extraButtons;
   };

   return (
      <div>
         <Row hidden={screens["lg"]}>
            <Col md={24} sm={24} className="gx-mt-2 gx-mb-4">
               {getExtraToolbar(false)}
            </Col>
         </Row>
         <Row>
            <Col md={24} sm={24}>
               <PageHeader
                  className="gx-page-header"
                  title={
                     <h2 className="multiline-text">
                        <IntlMessages id="reservations.my_reservations" />
                     </h2>
                  }
                  extra={
                     screens["lg"] ? getExtraToolbar(true) : [getFilterToolbar()]
                  }
                  onBack={() => history.push(getFrontendPath())}
                  backIcon={<ArrowLeftOutlined className="gx-arrow-back" />}
               />
            </Col>
            <Col xs={24}>
               <BadResponseErrorAlert redirectURL={getFrontendPath()} />
            </Col>
            <Col xs={24}>
               <Row>
                  <Col xs={24}>
                     <Table
                        className="frontend-table"
                        rowClassName="frontend-table-row"
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={get(
                           data,
                           "paginatedCustomerReservations.items",
                           []
                        )}
                        pagination={pagination}
                        loading={loading}
                        size="middle"
                        onRow={(record, rowIndex) => {
                           return {
                              onClick: () =>
                                 history.push(`prenotazione/dettaglio/${record.id}`),
                           };
                        }}
                        locale={{
                           emptyText: (
                              <Empty
                                 image={Empty.PRESENTED_IMAGE_SIMPLE}
                                 description={intl.formatMessage({
                                    id: "reservations.empty",
                                 })}
                              />
                           ),
                        }}
                        onChange={fetchTableChange}
                     />
                  </Col>
               </Row>
            </Col>
         </Row>
      </div>
   );
};

export default injectIntl(FrontendReservations);
