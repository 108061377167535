import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import { WorkspaceEntity } from "../../entities/workspace";
import IntlMessages from "../../util/IntlMessages";
import { DATE_FORMAT } from "../../constants/App";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { GQL_RESERVATION_SMS } from "../../apollo/mutation/reservation";
import { InfoCircleOutlined } from "@ant-design/icons";
import { injectIntl } from "react-intl";
import ReservationDetailInfo from "./ReservationDetailInfo";

const ReservationSummaryTableCard = ({
   reservation,
   serviceCollection,
   availability,
   paymentStatus,
   me,
   intl,
}) => {
   const gutter = 16;

   const { workspace, customer, organization, note } = reservation;

   const [showSmsButton, setShowSmsButton] = useState(
      !reservation.has_sent_sms &&
         organization.can_send_sms &&
         me.auth.phone_verified
   );

   const workspaceEntity = WorkspaceEntity(workspace);

   const [sendSms, { loading }] = useMutation(GQL_RESERVATION_SMS);

   const validPaymentStatus =
      !paymentStatus ||
      paymentStatus === "succeeded" ||
      paymentStatus === "processing";

   return (
      <>
         <Row gutter={gutter} className="gx-mb-2">
            <Col md={6}>
               <p>
                  <IntlMessages id="reservation.code" />
               </p>
            </Col>
            <Col md={18}>
               <p>
                  <strong>{reservation.code}</strong>
               </p>
            </Col>
         </Row>
         <Row gutter={gutter} className="gx-mb-2">
            <Col md={6}>
               <p>
                  <IntlMessages id="common.nominativo" />
               </p>
            </Col>
            <Col md={18}>
               <p>
                  <strong>{customer.full_name}</strong>
               </p>
            </Col>
         </Row>
         <Row gutter={gutter} className="gx-mb-2">
            <Col md={6}>
               <p>
                  <IntlMessages id="frontend.sportello_riferimento" />
               </p>
            </Col>
            <Col md={18}>
               <p>
                  {workspace ? (
                     `${workspace.name} ${workspaceEntity.getFormattedAddress()}`
                  ) : (
                     <IntlMessages id="common.nd" />
                  )}
               </p>
            </Col>
         </Row>
         <Row gutter={gutter} className="gx-mb-2">
            <Col md={6}>
               <p>
                  <IntlMessages id="common.service" />
               </p>
            </Col>
            <Col md={18}>
               <p>{serviceCollection.getNames()}</p>
            </Col>
         </Row>
         <Row gutter={gutter} className="gx-mb-2">
            <Col md={6}>
               <p>
                  <p>
                     <IntlMessages id="common.the_day" />
                  </p>
               </p>
            </Col>
            <Col md={8}>
               <p>
                  <strong>{moment(availability.day).format(DATE_FORMAT)}</strong>
               </p>
            </Col>
            <Col md={5}>
               <p>
                  <IntlMessages id="common.at_hour" />
               </p>
            </Col>
            <Col md={5}>
               <p>
                  <strong>{availability.slot}</strong>
               </p>
            </Col>
         </Row>

         {note && (
            <Row gutter={gutter} className="gx-mb-2">
               <Col md={6}>
                  <p>
                     <IntlMessages id="common.note" />
                  </p>
               </Col>
               <Col>
                  <p>{note}</p>
               </Col>
            </Row>
         )}

         <ReservationDetailInfo reservation={reservation} />

         {showSmsButton && validPaymentStatus && (
            <>
               <Row gutter={gutter} className="gx-mt-4 gx-mb-4">
                  <Col>
                     <p>
                        <IntlMessages id="reservations.confirm_sms_memo_p" />
                     </p>
                  </Col>
               </Row>
               <Row gutter={gutter} className="gx-mb-2">
                  <Col md={24}>
                     <Button
                        loading={loading}
                        type="default"
                        className="frontend-btn-lg btn-blue border-0 gx-mb-0"
                        onClick={(e) => {
                           e.preventDefault();
                           sendSms({ variables: { id: reservation.id } }).then(
                              () => {
                                 setShowSmsButton(false);
                              }
                           );
                        }}
                     >
                        <IntlMessages id="reservations.send_sms_memo" />
                     </Button>
                  </Col>
               </Row>
            </>
         )}

         {!me.auth.phone_verified && organization.can_send_sms && (
            <p>
               <InfoCircleOutlined />
               &nbsp;
               <IntlMessages id="reservations.verify_phone_for_reminder" />
            </p>
         )}
      </>
   );
};

export default injectIntl(ReservationSummaryTableCard);
