import { gql } from "@apollo/client";

export const GQL_PRODUCT_CREATE = gql`
   mutation createProduct($input: CreateProductInput!) {
      createProduct(input: $input)
   }
`;

export const GQL_PRODUCT_UPDATE = gql`
   mutation updateProduct($input: UpdateProductInput!) {
      updateProduct(input: $input)
   }
`;

export const GQL_PRODUCT_DELETE = gql`
   mutation deleteProduct($id: String!) {
      deleteProduct(id: $id)
   }
`;

export const GQL_CATEGORY_CREATE = gql`
   mutation createProductCategory($input: CreateProductCategoryInput!) {
      createProductCategory(input: $input)
   }
`;

export const GQL_CATEGORY_UPDATE = gql`
   mutation updateProductCategory($input: UpdateProductCategoryInput!) {
      updateProductCategory(input: $input)
   }
`;

export const GQL_CATEGORY_DELETE = gql`
   mutation deleteProductCategory($id: String!) {
      deleteProductCategory(id: $id)
   }
`;

export const GQL_PRODUCT_RESERVATION_DELETE = gql`
   mutation deleteProductReservation($id: String!) {
      deleteProductReservation(id: $id)
   }
`;

export const GQL_PRODUCT_RESERVATION_UPDATE = gql`
   mutation updateProductReservation($input: UpdateProductReservationInput!) {
      updateProductReservation(input: $input)
   }
`;

export const GQL_PRODUCT_SCHEDULE_RESERVATIONS = gql`
   mutation scheduleProductReservation($input: ScheduleReservationsInput!) {
      scheduleProductReservation(input: $input)
   }
`;
