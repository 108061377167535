import React from "react";
import { Alert } from "antd";
import { useIntl } from "react-intl";

function formatMinutes(minutes) {
   const hours = Math.floor(minutes / 60);
   const mins = minutes % 60;

   return `${hours}h ${mins}m`;
}

const DeliverySchedulerTimeAlert = ({ reservations }) => {
   const counter = reservations ? reservations.length : 0;
   const intl = useIntl();

   if (counter === 0) {
      return null;
   }

   const sum = reservations.reduce(
      (acc, reservation) => acc + reservation.product.delivery_time,
      0
   );

   return (
      <Alert
         type="info"
         message={intl.formatMessage(
            { id: "reuse_board.reservation_delivery_time" },
            { time: formatMinutes(sum) }
         )}
      />
   );
};

export default DeliverySchedulerTimeAlert;
