import React, { memo, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { ConfigProvider, Layout } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import IntlMessages from "../util/IntlMessages";
import FrontendReservations from "../routes/frontend/reservations";
import FrontendReservationCheckout from "../routes/frontend/reservations/checkout";
import FrontendReservationDetail from "../routes/frontend/reservations/detail";
import FrontendSettings from "../routes/frontend/settings";
import FrontendDashboard from "../routes/frontend/dashboard";
import NotFound from "../containers/NotFound";
import { getFrontendPath } from "../util/router";
import ErrorBoundary from "../components/ErrorBoundary";

const FrontendRoutes = () => {
   const currentAppLocale = AppLocale["it"];

   return (
      <ConfigProvider locale={currentAppLocale.antd}>
         <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
         >
            <ErrorBoundary>
               <Suspense fallback={<IntlMessages id="common.loading" />}>
                  <Switch>
                     <Route
                        exact
                        path={getFrontendPath("dashboard")}
                        component={FrontendDashboard}
                     />

                     <Route
                        exact
                        path={getFrontendPath("prenotazione")}
                        component={FrontendReservationCheckout}
                     />

                     <Route
                        exact
                        path={getFrontendPath("prenotazione/dettaglio/:id")}
                        component={FrontendReservationDetail}
                     />

                     <Route
                        exact
                        path={getFrontendPath("prenotazioni-elenco")}
                        component={FrontendReservations}
                     />

                     <Route
                        exact
                        path={getFrontendPath("impostazioni")}
                        component={FrontendSettings}
                     />

                     <Route path={getFrontendPath("*")}>
                        <Layout className="gx-app-layout">
                           <Layout.Content className="gx-layout-content">
                              <NotFound redirectToURL={getFrontendPath()} />
                           </Layout.Content>
                        </Layout>
                     </Route>
                  </Switch>
               </Suspense>
            </ErrorBoundary>
         </IntlProvider>
      </ConfigProvider>
   );
};

export default memo(FrontendRoutes);
